import GaEventHandler from './utils/dataLayer';

// BEFORE dispatch middlewares, can call logic before action gets dispatched using current
// state

export const beforeMiddlewares = [];

// AFTER dispatch middlewares, will receive *updated* state then call global sideeffects
export const afterDispatchHandleGaEvents = (action: Action, state: StoreState, dispatch) => {
  switch (action.type) {
    case 'SET_SHIPPING_METHOD':
      GaEventHandler.addShippingInfo(state.cart, state.shipMethod, state.gtmNullPayload);

    case 'SPC_GO_STEP_3':
      GaEventHandler.pushToDataLayer({
        event: 'AnalyticsEvent',
        eventCategory: 'store',
        eventAction: 'spc_section_transition',
        eventLabel: 'billing',
      });

    case 'SPC_STEP_3_COMPLETE':
      GaEventHandler.addPaymentInfo(state.cart, state.billing, state.gtmNullPayload);
      GaEventHandler.pushToDataLayer({
        event: 'AnalyticsEvent',
        eventCategory: 'store',
        eventAction: 'spc_section_transition',
        eventLabel: 'order_review',
      });
  }
};

export const afterMiddlewares = [afterDispatchHandleGaEvents];

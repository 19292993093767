import React from 'react';
import ShippingForm from './ShippingForm';
import ShippingInfo from './ShippingInfo';
import { STEP } from '../../constants';
import { useStore } from '../../utils/storeUtils';

interface Props {
  street?: string;
  geoState?: string;
}

function ShippingContainer(props: Props) {
  const [storeState, setStoreState] = useStore();

  const { street = '', geoState = '' } = props;
  const { isProcessing, paymentMethod, step } = storeState;
  const disabledClass = isProcessing ? ' mod-disabled' : '';

  let component;
  const viewable = paymentMethod != 'applepay' && !isProcessing;

  if (step > STEP.SHIP_METHOD) {
    const className = viewable ? '' : ' mod-hide';
    component = <ShippingInfo {...{ className }} />;
  } else {
    component = <ShippingForm disabledBtnClass={disabledClass} {...{ street, geoState }} />;
  }
  return <div className="spc-outer">{component}</div>;
}

export default ShippingContainer;

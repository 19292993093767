import React from 'react';
import getDisplayName from '../../utils/getDisplayName';
import { useStoreContext } from '../../utils/storeUtils';

interface InjectedProps {
  braintreeInstance: any;
}

const braintreeVendorTypes = ['ApplePay', 'PayPal', 'HostedFields'];

// Responsible for guaranteeing that braintree instance is loaded
// and the component can render
export default function withBraintreeLoader<T>(Component: React.ComponentType<T>) {
  const WithBraintreeLoader = (props: Omit<T, keyof InjectedProps>) => {
    const { storeState, dispatch } = useStoreContext();
    const { braintreeInstance, region } = storeState;

    const type = Component.displayName;
    if (!braintreeVendorTypes.includes(type)) {
      console.error('Unsupported type for WithBraintreeLoader ', type);
      return null;
    }
    if (!braintreeInstance) return null;

    const allowApplePay = storeState.enableApplepay;
    const allowPayPal = storeState.enablePaypal;
    const allowVenmo = storeState.enableVenmo;
    if (type === 'ApplePay' && !allowApplePay) return null;
    if (type == 'PayPal' && !allowPayPal) return null;
    if (type == 'Venmo' && !allowVenmo) return null;

    return <Component braintreeInstance={braintreeInstance} {...(props as T)} />;
  };
  WithBraintreeLoader.displayName = `WithBraintreeLoader(${getDisplayName(Component)})`;
  return WithBraintreeLoader;
}

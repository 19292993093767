export const ANIMATION_CLASSNAME = 'mod-anim';
export const BILLING_METHODS = {
  CREDIT_CARD: 'credit_card',
  FREE: 'free',
  PAYPAL: 'paypal',
  APPLEPAY: 'applepay',
  VENMO: 'venmo',
  SAVED_PAYMENT: 'saved_payment',
};
export const BILLING_ADDRESS = {
  SHIPPING: 0,
  CUSTOM: 1,
  SAVED: 2,
  SAVED_CUSTOM: 3,
};
export const DEBOUNCE_TIME = 750;
export const CC = {
  fields: ['number', 'expirationDate', 'cvv'],
  number: '#js-card-number',
  expirationDate: '#js-expiration-date',
  cvv: '#js-cvv',
};
export const ELEMENTS = {
  addressSection: 'js-address',
  verification: 'js-verification',
  accept: 'js-accept',
  billing: 'js-billing',
  ccCVV: 'js-cvv',
  ccExp: 'js-expiration-date',
  ccForm: 'js-card-form',
  ccNum: 'js-card-number',
  review: 'js-review',
  root: 'js-spc',
  shipping: 'js-shipping',
  shippingMethod: 'js-shipping-method',
  email: 'js-shipping-email',
  phone: 'js-shipping-phone',
  firstName: 'js-shipping-firstname',
  lastName: 'js-shipping-lastname',
  company: 'js-shipping-company',
  address: 'js-shipping-address',
  city: 'js-shipping-city',
  state: 'js-shipping-state',
  postalCode: 'js-shipping-zip',
  country: 'js-shipping-country',
  address2: 'js-shipping-address2',
};
export const ERROR_MESSAGES = {
  ADDRESS: 'There was a problem with processing your address information. Please try again.',
  BILLING: 'Please return to the billing section to try again.',
  DECLINE: 'Your payment method was declined. Please try again with a different payment method.',
  EMPTY_CART: 'Cannot process your request.',
  SHIP_METHOD: 'There was a problem processing your shipping information. Please try again.',
  DEFAULT: 'Something went wrong.',
  BILLING_PROCESSING:
    'Something went wrong. Please try a different payment method or try again later.',
};
export const FETCH_TIMEOUT = 25000;
export const GIFT_WRAP_OPTIONS = {
  GIFT: 'gift',
  PRIVACY: 'privacy',
};
export const KEY_CODE = {
  ENTER: 13,
  SPACE: 32,
};
export const KEY_NAME = {
  ENTER: 'Enter',
};
export const MAGENTO_ERROR_KEYWORDS = {
  ADDRESS: 'address',
  AVS: 'avs',
  DECLINE: 'declined',
  POSTAL_CODE: 'postal code',
};
export const NON_CC = {
  paypal: 'PayPal',
  applepay: 'Apple Pay',
  venmo: 'Venmo',
};
export const SCROLL_TIME = 550;
export const SHIPPING_CODES = {
  EXPRESS: 'matrixrate_express',
  EXPEDITED: 'matrixrate_intermediate',
  STANDARD: 'matrixrate_standard',
  BOOK_EXPRESS: 'matrixrate_book_express',
  BOOK_EXPEDITED: 'matrixrate_book_intermediate',
  BOOK_STANDARD: 'matrixrate_book_standard',
};
export const STEP = {
  ADDRESS: 1,
  VERIFICATION: 2,
  SHIP_METHOD: 3,
  BILLING: 4,
  REVIEW: 5,
};
export const GDPR_COOKIE = 'gdpr_cookies_notice';
export const GDPR_COOKIE_CHOICES = ['ads', 'strict'];
export const WEBPACK_SIGNAL = 'webpackClose';

import * as React from 'react';
import { URLS, REGION, SUBSCRIPTION_REGIONS } from '../../../../regions';

interface Props {
  isDesktop: boolean;
}

const SubTOSLink = ({ isDesktop }: Props) => {
  const hideTestInfo = SUBSCRIPTION_REGIONS.includes(REGION) ? '' : 'mod-hidden';
  const linkClass = isDesktop ? 'spc-footer-big-div-a' : 'spc-footer-links-a';
  return (
    <a
      data-test-id="footer-sub-tos-link"
      className={`${linkClass} ${hideTestInfo} spc-footer-big-nowrap`}
      href={URLS.SUBSCRIPTION_TERMS_OF_SERVICE}
      target="_blank"
      rel="noopener noreferrer"
    >
      Membership Terms of Service
    </a>
  );
};

export default SubTOSLink;

import React from 'react';
import info from '../../images/info.svg';
import { URLS } from '../../regions';
import SubscriptionName from './SubscriptionName';
import SubscriptionProfile from './SubscriptionProfile';

interface Props {
  profiles: Profile[];
  startSubCancellationFlow: (
    subscriptionId: string,
    sampleShippingCountry: SampleShippingCountry,
    isTotalHealth: boolean,
  ) => void;
  savedPayments: SavedPayment[];
  confirmChangePayment: (
    token: string,
    subscriptionId: string,
    lastFour: string,
    confirmActionType: ConfirmationActions,
  ) => void;
}

const SubscriptionProfiles = ({
  profiles,
  startSubCancellationFlow,
  savedPayments,
  confirmChangePayment,
}: Props) => {
  const premiumProfiles = () => {
    const children = profiles
      .filter(profile => profile.genotyped || !profile.hasCancelledSub)
      .filter(profile => profile.isSubscriber)
      .map(profile => {
        return (
          <SubscriptionProfile
            key={profile.id}
            profile={profile}
            startSubCancellationFlow={startSubCancellationFlow}
            savedPayments={savedPayments}
            confirmChangePayment={confirmChangePayment}
          />
        );
      });

    if (!children.length) return null;
    return children;
  };
  const pendingTotalHealthProfiles = () =>
    profiles
      .filter(profile => profile.hasPendingTotalHealthUpgrade && !profile.isSubscriber)
      .map(profile => (
        <SubscriptionProfile
          key={profile.id}
          profile={profile}
          startSubCancellationFlow={startSubCancellationFlow}
          savedPayments={savedPayments}
          confirmChangePayment={confirmChangePayment}
        />
      ));

  const noMembershipsNotice = () => {
    const displayedProfiles = profiles.filter(
      profile =>
        (profile.isSubscriber && (profile.genotyped || !profile.hasCancelledSub)) ||
        (!profile.isSubscriber && profile.hasPendingTotalHealthUpgrade),
    );
    if (displayedProfiles.length > 0) {
      return null;
    } else {
      return (
        <li>
          <div className="spc-spi-subs-empty-profiles">There are no memberships to cancel.</div>
        </li>
      );
    }
  };

  const hasTotalHealthProfile = profiles.some(
    profile => profile.hasPendingTotalHealthUpgrade || profile.subscriptionInfo?.isTotalHealth,
  );

  const TOTAL_HEALTH_CUSTOMER_CARE_ARTICLE = '18680166647191';
  const TTAM_PLUS_CUSTOMER_CARE_ARTICLE = '360042867394';

  const subscriptionCustomerCareArticleNumber = hasTotalHealthProfile
    ? TOTAL_HEALTH_CUSTOMER_CARE_ARTICLE
    : TTAM_PLUS_CUSTOMER_CARE_ARTICLE;

  return (
    <ul className="spc-spi-subs-ul">
      {premiumProfiles()}
      {pendingTotalHealthProfiles()}
      {noMembershipsNotice()}
      <li className="spc-spi-subs-disclaimer">
        <img className="spc-spi-subs-info" src={info} alt="info icon" width="14" height="14" />
        Only <SubscriptionName useServiceMark={true} /> and{' '}
        <SubscriptionName isTotalHealth={true} /> eligible profiles appear here.{' '}
        <a
          href={`${URLS.CUSTOMER_CARE}/articles/${subscriptionCustomerCareArticleNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more about <SubscriptionName /> and <SubscriptionName isTotalHealth={true} />
        </a>
      </li>
    </ul>
  );
};

export default SubscriptionProfiles;

import React from 'react';
import PropTypes from 'prop-types';
import handleKeyDown from '../../utils/handleKeyDown';
import Modal from './withModalv2';

const propTypes = {
  button: PropTypes.string.isRequired,
  goToCart: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const ApplePayErrorPopup = props => (
  <Modal modalName="ERROR">
    <div>
      <h2 className="spc-verification-h2 mod-font">{props.title}</h2>
      <div className="spc-paypal-error-modal-body">
        Due to applicable regulations, you must{' '}
        <button
          className="spc-modal-link spc-button-link"
          onClick={e => {
            e.stopPropagation();
            props.goToCart();
          }}
          onKeyDown={handleKeyDown}
          type="button"
          tabIndex="0"
        >
          change the shipping destination
        </button>{' '}
        of your order to a US address or order from one of our international websites.
        <br />
        <br />
        If you want to ship to Canada, visit the{' '}
        <a className="spc-modal-link" href="http://store.23andme.com/en-ca/cart">
          Canada site
        </a>
        . If you want to ship a kit to another country, visit the{' '}
        <a className="spc-modal-link" href="http://store.23andme.com/en-int/cart">
          International site
        </a>
        .
      </div>
      <div className="spc-verification-edit">
        <button
          onClick={e => {
            e.stopPropagation();
            props.goToCart();
          }}
          className="spc-verification-div-button"
          type="button"
        >
          {props.button}
        </button>
      </div>
    </div>
  </Modal>
);

ApplePayErrorPopup.propTypes = propTypes;

export default ApplePayErrorPopup;

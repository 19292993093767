/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Modal from '../common/withModalv2';
import { URLS } from '../../regions';

interface Props {
  authClass?: string;
}

export default function AuthenticationWithModal({ authClass }: Props) {
  return (
    <Modal hideOnBackgroundClick={true} modalName="AUTH" klass={authClass}>
      <iframe
        src={URLS.AUTHENTICATION}
        width="100%"
        height="100%"
        name="spc-auth-iframe"
        id="spc-auth-iframe"
        scrolling="no"
      />
    </Modal>
  );
}

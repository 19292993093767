import React from 'react';
import PropTypes from 'prop-types';

import Necessary from './AllNecessary';
import AllAds from './AllAds';
import StrictInfo from './StrictInfo';
import IntroInfo from './IntroInfo';
import AdsInfo from './AdsInfo';
import Regarding from './Regarding';
import Switch from './Switch';
import { DivFadeIn, InfoWrap, AdvancedAds, FlexRow } from './Styled/Choices';
import { ButtonsWrap, ButtonHollow, ButtonFull } from '../Styled/common';
import Collapsible from './Collapsible';

const propTypes = {
  handleSwitch: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  choice: PropTypes.string.isRequired,
};

const Choices = ({ handleSwitch, hide, submit, choice }) => (
  <DivFadeIn>
    <Regarding />
    <InfoWrap>
      <IntroInfo />
      <StrictInfo />
      <Collapsible renderToggleText={isOpen => (isOpen ? 'Hide Cookies' : 'Show Cookies')}>
        <Necessary />
      </Collapsible>
    </InfoWrap>
    <AdvancedAds>
      <FlexRow>
        <AdsInfo choice={choice} />
        <Switch handleSwitch={handleSwitch} choice={choice} />
      </FlexRow>
      <Collapsible renderToggleText={isOpen => (isOpen ? 'Hide Cookies' : 'Show Cookies')}>
        <AllAds />
      </Collapsible>
    </AdvancedAds>

    <ButtonsWrap>
      <ButtonHollow short onClick={hide}>
        cancel
      </ButtonHollow>
      <ButtonFull left onClick={submit}>
        submit preferences
      </ButtonFull>
    </ButtonsWrap>
  </DivFadeIn>
);

Choices.propTypes = propTypes;

export default Choices;

import React from 'react';

interface Props {
  height?: string;
  width?: string;
  className?: string;
}

const Amex = ({ height = '46px', width = '46px', className = '' }: Props) => (
  <div className="spc-no-events" tabIndex={-1}>
    <svg
      id="Icons"
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 90 90"
    >
      <title>American Express payment method</title>
      <symbol id="Icon_amex" viewBox="-45 -27.5 90 55">
        <path
          className="spc-amex0"
          d="M45-23.5c0-2.2-1.8-4-4-4h-82c-2.2 0-4 1.8-4 4v47c0 2.2 1.8 4 4 4h82c2.2 0 4-1.8 4-4v-47z"
        />
        <path className="spc-amex1" d="M34.5-13.4h-69.4v25.5h69.4z" />
        <path
          className="spc-amex2"
          d="M34.9-9.2c0-1.7-1.1-2.6-3.1-2.6H28v1.7h3.8c.4 0 .6.1.8.2.1.1.2.3.2.5s-.1.5-.2.6c-.1.1-.3.2-.7.2-1.8.1-4.1-.1-4.1 2.5 0 1.2.8 2.4 2.8 2.4h3.9v-1.7h-3.6c-.4 0-.6 0-.8-.1-.2-.2-.3-.4-.3-.6 0-.3.2-.5.4-.6.2-.1.5-.1.8-.1h1.1c1.1 0 1.8-.2 2.3-.6.3-.5.5-1 .5-1.8M26.8-7.4c-.5.4-1.2.6-2.3.6h-1.1c-.3 0-.5.1-.8.1-.2.1-.4.3-.4.6s.1.5.3.6c.2.1.4.1.8.1h3.6v1.7H23c-2.1 0-2.8-1.3-2.8-2.4 0-2.6 2.3-2.5 4.1-2.5.4 0 .6-.1.7-.2.1-.1.2-.3.2-.6 0-.2-.1-.4-.2-.5-.2-.1-.4-.1-.8-.1h-3.8v-1.7h3.8c2 0 3.1.8 3.1 2.6 0 .7-.2 1.2-.5 1.7M19.3-10.1h-4.6v1.7h4.5v1.6h-4.5v1.5h4.6v1.7h-6.5v-8.1h6.5zM10.7-4c-.6.3-1.4.4-2.4.4H3.8v-8.2h2v3h2.1c.7 0 1.1-.1 1.4-.4.3-.4.3-1.1.3-1.6v-1h1.9v1.6c0 .7-.1 1.1-.3 1.5-.2.2-.5.5-1 .7.5.2 1.4.9 1.4 2.2.1.9-.3 1.4-.9 1.8M-.4-3.6h-6.2l-2.5-2.7-2.4 2.7h-7.9v-8.2h7.7l2.5 2.7 2.4-2.7H-3V-9h2.5c1.7 0 3.4.5 3.4 2.7S1.2-3.6-.4-3.6"
        />
        <path
          className="spc-amex1"
          d="M9.1-7c-.3-.1-.6-.1-.9-.1l-2.4-.1v1.9h2.4c.4 0 .7 0 .9-.2.2-.1.4-.4.4-.7 0-.3-.1-.6-.4-.8M-.4-7.4H-3v2.1h2.6c.7 0 1.2-.3 1.2-1S.3-7.4-.4-7.4M-7.9-7.7l3 3.2V-11zM-12.6-10.1h-4.8v1.7h4.3v1.6h-4.3v1.5h4.9l2.1-2.4z"
        />
        <path
          className="spc-amex2"
          d="M29.5 2.3h-2.8L23 8.4V2.3h-3.9l-.8 1.8h-4.1l-.8-1.8h-2.3c-.9 0-2.2.2-2.9.9-.6.7-1 1.6-1 3.1 0 1.2.2 2.3 1.1 3.2.6.6 1.6.9 3 .9h1.9V8.7h-1.9c-.7 0-1.1-.1-1.5-.5-.4-.4-.5-1-.5-1.9 0-.9.2-1.5.5-1.9.4-.2 1-.4 1.5-.4h.9l2.8 6.4h2.9l3.3-7.7v7.7h3l3.4-5.7v5.7h2V2.3zM6.4 10.5H4.5V2.3h1.9zM2.3 10.1c-.6.4-1.3.4-2.3.4h-4.5V2.3h1.9v3h2.1C.2 5.3.7 5.2 1 5c.3-.4.3-1.1.3-1.6v-1h2V4c0 .7-.1 1.1-.4 1.5-.2.1-.5.4-1 .6.6.2 1.4.9 1.4 2.1 0 1-.4 1.5-1 1.9M-5.9 4h-4.5v1.6h4.5v1.7h-4.5v1.5h4.5v1.7h-6.5V2.3h6.5zM-13.9 10.5h-3.2l-2.4-5.6-2.5 5.6h-3.1V2.8l-3.4 7.7h-2.9l-3.5-8.2h2.1l.8 1.8h4.1l.7-1.8h4v6.4l2.8-6.4h1.7l2.9 6.4V2.3h1.9z"
        />
        <path
          className="spc-amex1"
          d="M17.7 5.8l-1.4 3.3L15 5.8zM.8 7.1C.5 7 .2 7-.2 7h-2.4v1.8h2.4c.3 0 .7 0 1-.1.2-.2.4-.5.4-.8 0-.4-.2-.7-.4-.8M-31.3 5.8l1.3 3.3 1.4-3.3z"
        />
        <path
          className="spc-amex2"
          d="M43 22h-86l8.1-15.4 2.4 5.4h5.1l.7-1.3V12h5.9l1.3-2.9 1.3 2.9H.7c.9 0 1.6-.2 2.2-.6v.6h5.2v-.6c.9.5 2 .6 3.2.6h7.5l.7-1.3V12H25l.8-1.3V12h5.4V.7h-5.5l-1.1 1.7V.7h-6.8L17 2.6h-1.7L14.5.7h-3.6c-1.1 0-2.1.3-2.8.7V.7H-.3v2.6c0 .4-.1.4-.3.4h-.3v-3h-16.3v1.5l-.6-1.5h-3.4l-.6 1.4V.7h-6.6l-.7 1.9h-1.7l-.8-1.9H-35l-8-20.4h86l-8.5 6.9c-.7-.3-1.8-.5-2.8-.5h-5.1v.7c-.6-.5-1.6-.7-2.6-.7H8v2.6c0 .4-.1.4-.4.4h-.2v-3H2.1v3.1c-.9-.4-1.9-.4-2.7-.4h-.6v-2.7h-6.4l-1.5 1.8-1.7-1.8h-10.3V-2h10.5l1.5-1.8L-7.5-2h7c.8 0 2.2-.1 2.7-.7v.7h6.3c.6 0 1.9-.1 2.7-.7v.7h9.5v-.7c.6.5 1.6.7 2.4.7h5.3v-.7c.6.4 1.4.7 2.5.7h3.6L43 22z"
        />
      </symbol>
      <use
        xlinkHref="#Icon_amex"
        width="90"
        height="55"
        id="XMLID_82_"
        x="-45"
        y="-27.5"
        transform="matrix(1 0 0 -1 45 45)"
        overflow="visible"
      />
    </svg>
  </div>
);

Amex.displayName = 'Amex';

export default Amex;

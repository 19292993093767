import React from 'react';
import add from '../../../images/ic_add.svg';
import {
  PaymentMethodRadioGroup,
  Props as PaymentMethodRadioGroupProps,
} from './PaymentMethodRadioGroup';
import { useSavedPayments, useCreditCard } from './hooks';

export type ClickAddPaymentCard = React.PointerEventHandler<HTMLButtonElement>;

interface Props extends PaymentMethodRadioGroupProps {
  showError?: boolean;

  // event handler for "Add payment card" button
  onClickAddPaymentCard?: ClickAddPaymentCard;
}

/**
 * Wrapper component for payment method fields (primarily for consistent structure)
 */
export const PaymentMethodSection = ({
  selectedMethod,
  showError,
  onChange,
  onClickEditSavedPayment,
  onClickAddPaymentCard,
}: Props) => {
  const { hasSavedPayment } = useSavedPayments();
  const { hasCreditCardData } = useCreditCard();
  const paymentOptionsDetected = hasCreditCardData || hasSavedPayment;
  return (
    <section>
      <PaymentMethodRadioGroup {...{ selectedMethod, onChange, onClickEditSavedPayment }} />
      {showError && <p>Please select a payment method</p>}
      {onClickAddPaymentCard && paymentOptionsDetected && (
        <button
          data-stor-id="enter-credit-card-link"
          className="sd-button-anchor spc-add-to-list"
          onClick={onClickAddPaymentCard}
        >
          <img className="spc-subs-back-btn-icon" src={add} width="24" height="24" alt="" />
          Add credit card
        </button>
      )}
    </section>
  );
};

import * as React from 'react';
import getDisplayName from '../../utils/getDisplayName';
import { ANIMATION_CLASSNAME } from '../../constants';
import { useState, useEffect } from 'react';

interface InjectedProps {
  animClass: string;
}

export default function withAnim<T>(Component: React.ComponentType<T>) {
  const WithAnim = (props: Omit<T, keyof InjectedProps>) => {
    const [animClass, setAnimClass] = useState(` ${ANIMATION_CLASSNAME}`);
    useEffect(() => {
      const timeout = setTimeout(() => {
        setAnimClass('');
      }, 50);
      return () => clearTimeout(timeout);
    });

    return <Component animClass={animClass} {...(props as T)} />;
  };
  WithAnim.displayName = `withAnim(${getDisplayName(Component)})`;
  return WithAnim;
}

import { CC } from '../../../../constants';
import { REGION } from '../../../../regions';
import { defaultFieldInfo, refineAddressValidation } from '../../../../utils/i18nFields';
import { getListOfInvalidFields } from '../../../../utils/getListOfInvalidFields';

/**
 * Validates billing address form input values
 *
 * @param billingAddress - billing address form data
 * @param fieldInfo - i18n field info retrieved from either `defaultFieldInfo` the `fetchLabels` call
 * @returns - an array of input names that have errors
 */
export const validateBillingAddress = (
  billingAddress: Address,
  fieldInfo: FieldInfo = defaultFieldInfo[REGION],
) => {
  const errors = getListOfInvalidFields(billingAddress, refineAddressValidation(fieldInfo));

  return errors;
};

const validatePaymentMethodRadioGroup = (method: MethodOptions) => {
  // require payment method to be selected
  return !method.type ? ['method'] : [];
};

const validateBillingAddressRadioGroup = (billingAddress: Address) => {
  // require billing address to be selected
  return !billingAddress ? ['address'] : [];
};

interface ValidatePaymentCardFormOptions {
  /**
   * i18n field info retrieved from either `defaultFieldInfo` the `fetchLabels` call
   */
  billingAddressFieldInfo: FieldInfo;

  /**
   * Extract the billing address from the form fields, or from the saved address?
   */
  showAddressFields?: boolean;
}

/**
 * Validates PaymentCardForm input values
 *
 * @param formData - form input values from PaymentCardForm
 * @param options - additional arguments; see ValidatePaymentCardFormOptions type for details
 * @returns - an array of input names that have errors
 */
export const validatePaymentCardForm = (
  formData: PaymentFormData,
  { billingAddressFieldInfo, showAddressFields }: ValidatePaymentCardFormOptions,
) => {
  const { paymentCard, billingAddress } = formData;

  const hasPaymentCardError =
    !paymentCard ||
    !['cardType', 'lastFour', 'nonce'].reduce(
      (foundError, key) => foundError && paymentCard.hasOwnProperty(key),
      true,
    );

  const billingAddressErrors = !showAddressFields
    ? validateBillingAddressRadioGroup(billingAddress)
    : validateBillingAddress(billingAddress, billingAddressFieldInfo);

  const errors = hasPaymentCardError
    ? ['paymentCard', ...billingAddressErrors]
    : [...billingAddressErrors];

  return errors;
};

/**
 * Validates PaymentMethodForm input values
 *
 * @param formData - form input values from PaymentMethodForm
 * @returns - an array of input names that have errors
 */
export const validatePaymentMethodForm = (formData: PaymentFormData) => {
  return [
    ...validatePaymentMethodRadioGroup(formData.method),
    ...validateBillingAddressRadioGroup(formData.billingAddress),
  ];
};

/**
 * Validates PaymentMethodForm input values
 *
 * @param formData - form input values from PaymentMethodForm
 * @returns - an array of input names that have errors
 */
export const validateSavedPaymentMethodForm = (formData: PaymentFormData) => {
  return [...validatePaymentMethodRadioGroup(formData.method)];
};

/**
 * Util for focusing on first invalid form field
 *
 * @param errors Array of invalid form field names
 */
export const focusFirstInvalidField = (errors: string[]) => {
  if (!errors.length) {
    return;
  }

  const firstElementName = errors[0];

  // TODO: implement using input refs
  // (not the React way, but easier to implement in this case)
  const element: HTMLElement =
    firstElementName === 'paymentCard'
      ? document.querySelector(CC.number)
      : document.querySelector(`[name="${errors[0]}"]`);

  if (element) {
    element.setAttribute('tabindex', '-1');
    element.focus();
    element.removeAttribute('tabindex');
  }
};

import React, { useState } from 'react';
import { PaymentMethodForm } from './PaymentMethodForm';
import { AddPaymentCardForm } from './AddPaymentCardForm';
import { useSavedPayments, useCreditCard } from './hooks';
import { useStoreContext } from '../../../utils/storeUtils';

type Props = PaymentFormProps;

/**
 * Payment component that collects and exports payment information and manages saved payments and
 * billing addresses.
 */
export const Payment = ({ initialFormData, onSubmitSuccess }: Props) => {
  /**
   * State to keep track of what screen the user is on. Can be seen as an in-memory router.
   * States are modeled similar to how React Router routes are set up.
   *
   * Choosing to use React state as in-memory router for the Payment component to match how store
   * works. However, could consider migrating this over to React Router if that's the direction team
   * wants to go. Would need to figure out how to make routes reusable.
   */
  const { hasSavedPayment } = useSavedPayments();
  const { hasCreditCardData } = useCreditCard();
  const initialScreen: PaymentScreen =
    hasSavedPayment || hasCreditCardData
      ? { name: 'payment-method' }
      : { name: 'payment-card/add' };
  const [screen, setScreen] = useState<PaymentScreen>({ name: 'payment-method' });

  const renderScreen = () => {
    switch (screen.name) {
      default:
        console.log('Payment:renderScreen unexpected screen name detected.');
      case 'payment-method':
        // NOTE: onClickEditSavedPayment, onClickAddPaymentCard, onClickEditAddress,
        // onClickAddAddress are optional props. If not needed, they can e removed, and the
        // associated buttons will not render.
        return (
          <PaymentMethodForm
            initialFormData={initialFormData}
            onSubmitSuccess={onSubmitSuccess}
            onClickEditSavedPayment={(_event, { token }) => {
              setScreen({
                name: 'payment-card/edit',
                params: { token },
              });
            }}
            onClickAddPaymentCard={() => {
              setScreen({ name: 'payment-card/add' });
            }}
            onClickEditAddress={(_event, { addressId }) => {
              setScreen({
                name: 'billing-address/edit',
                params: { addressId },
              });
            }}
            onClickAddAddress={() => {
              setScreen({ name: 'billing-address/add' });
            }}
          />
        );
      case 'payment-card/add':
        return (
          <AddPaymentCardForm
            initialFormData={initialFormData}
            onSubmitSuccess={(_event, data) => {
              if (hasSavedPayment) {
                setScreen({ name: 'payment-method' });
              } else {
                onSubmitSuccess(_event, data);
              }
            }}
            onCancelOperation={() => {
              setScreen({ name: 'payment-method' });
            }}
            redirectsToOrderReview={!hasSavedPayment}
          />
        );
      case 'payment-card/edit':
        return (
          <AddPaymentCardForm
            initialFormData={initialFormData}
            paymentMethodToken={screen.params.token}
            onSubmitSuccess={() => {
              setScreen({ name: 'payment-method' });
            }}
            onCancelOperation={() => {
              setScreen({ name: 'payment-method' });
            }}
            redirectsToOrderReview={false}
          />
        );
      case 'billing-address/add':
        // TODO: implement AddBillingAddressForm
        return <p>AddBillingAddressForm</p>;
      case 'billing-address/edit':
        // TODO: implement EditBillingAddressForm
        return <p>EditBillingAddressForm</p>;
    }
  };

  return renderScreen();
};

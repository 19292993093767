import placeholderData from './utils/placeholderData';
import getContext from './utils/getContext';
import { STEP, NON_CC, BILLING_ADDRESS, SHIPPING_CODES } from './constants';
import { REGION, COOKIE_BANNER_REGIONS, GDPR_REGIONS, URLS } from './regions';
import { parseIsoCountryCode } from './utils/i18nFields';

let checkout = getContext() as CheckoutContext;

if (!checkout) {
  window.dataLayer = [];
  checkout = placeholderData;
}
const {
  account,
  billingCountries,
  cart,
  kit,
  expressStatesBlacklist,
  hasSubscribedProfile,
  hideUkPromoPricing,
  showUkShippingUpdate,
  showIntShippingUpdate,
  isChipUpgrade,
  isBookPurchase,
  isMinicart,
  isPrepaidSubscription,
  hasDiscountedPrepaidSubscription,
  hasTotalHealth,
  hasTotalHealthUpgrade,
  isEmptyCart,
  hasPendingTotalHealth,
  isApiUp,
  isSubsEnabled,
  payment,
  planId,
  profile,
  profiles,
  isMultiProfileAccount,
  shipMethod,
  shippingAddress,
  shippingCountries,
  shippingMethods,
  states,
  subscriptionInfo,
  subBundleInfo,
  billingInfo,
  openSubsTab,
  hasSurveyResults,
  youdotUrl,
  pubUrl,
  successUrl,
  isMobileWebView,
  heapId,
  region,
  membership,
  labs = {},
  clinic = {},
  orderCreationDate,
  preserveDefaultPayment,
  showHistoricalMatches,
  showRecordsArchives,
  enableApplepay = checkout.enableApplepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments(),
  enablePaypal,
  enableVenmo,
  gtmNullPayload,
} = checkout;

const gdprAvailable = true;
const nonCC = payment && !!NON_CC[payment.method];
const hasSavedCreditCard = !!(
  account.paymentMethods.length && account.paymentMethods[0]?.isCreditCard
);
const addressSourceKey = hasSavedCreditCard ? 'SAVED' : 'SHIPPING';
let customAddress = {};
// TODO - labs starts with the custom address empty, until a user enters one
// Meanwhile, kit purchsae starts with the custom address populated.
// Investigate whether kits purchase also requires a pre-population.
if (hasSavedCreditCard && !Object.keys(labs).length) {
  const paymentMethod = account.paymentMethods[0];
  const { firstName, lastName, address, city, state, country, postalCode } = paymentMethod;
  customAddress = Object.assign({ firstName, lastName, address, city, state, country, postalCode });
}

const defaultShipMethod: ShipMethod = {
  code: isBookPurchase ? SHIPPING_CODES.BOOK_STANDARD : SHIPPING_CODES.STANDARD,
  displayPrice: isBookPurchase ? '$4.95' : '$9.95',
  title: 'Standard',
};

const initialState: InitialState = {
  nonCC,
  gdprAvailable,
  accountEmail: isChipUpgrade || hasTotalHealthUpgrade ? account.email : null,
  expressStatesBlacklist,
  billingCountries,
  profile: isChipUpgrade || hasTotalHealthUpgrade ? profile : null,
  isMultiProfileAccount,
  isChipUpgrade,
  isBookPurchase,
  shippingCountries,
  showGDPR: gdprAvailable && GDPR_REGIONS.includes(REGION),
  hasSavedCreditCard,
  account,
  address: Object.assign({}, shippingAddress, {
    country:
      shippingAddress && shippingAddress.country
        ? shippingAddress.country
        : parseIsoCountryCode(REGION),
  }),
  billing: Object.assign(
    {
      addressSource: BILLING_ADDRESS[addressSourceKey],
      customAddress,
      enableSavedPayment: false,
      saveCreditCardOptIn: false,
      lastFour: '1111',
      cardType: 'Visa',
    },
    payment,
  ),
  cart,
  confirmation: {
    body: '',
    title: '',
  },
  kit,
  displayAuthenticationWindow: false,
  editableClass: '',
  error: {
    backTo: 'address',
    body: '',
    button: '',
    countryCode: '',
    method: '',
    step: STEP.ADDRESS,
    title: '',
  },
  hasSubscribedProfile,
  hideUkPromoPricing,
  showUkShippingUpdate,
  showIntShippingUpdate,
  isBillingPromo: false,
  isMinicart,
  isSubsEnabled,
  isPrepaidSubscription,
  hasTotalHealth,
  hasTotalHealthUpgrade,
  isEmptyCart,
  hasPendingTotalHealth,
  hasDiscountedPrepaidSubscription,
  isApiUp,
  loading: nonCC,
  paymentMethod: payment ? payment.method : null,
  profiles,
  shipMethod:
    (shippingMethods && shippingMethods.find(method => method.code === shipMethod)) ||
    defaultShipMethod,
  shippingMethods,
  shippingStates: states && Array.from(states),
  billingStates: states && Array.from(states),
  showCookieBanner: COOKIE_BANNER_REGIONS.includes(REGION),
  step: nonCC ? STEP.BILLING : STEP.ADDRESS,
  verifiedAddress: null,
  showOrderDetails: isMinicart || isChipUpgrade,
  modalOpen: '',
  isProcessing: false,
  pubUrl: pubUrl || URLS.HOME,
  youdotUrl: youdotUrl || URLS.YOUDOT,
  planId,
  subscriptionInfo,
  subBundleInfo,
  billingInfo,
  openSubsTab,
  hasSurveyResults,
  successUrl,
  isMobileWebView,
  heapId,
  region,
  membership,
  labs,
  clinic,
  enablePaypal,
  enableVenmo,
  enableApplepay:
    enableApplepay && window.ApplePaySession && window.ApplePaySession.canMakePayments(),
  gtmNullPayload,
  // Braintree state
  areHostedFieldsPopulated: false,
  settingUpHostedFields: false,
  braintreeInstance: null,
  inputFields: null,
  orderCreationDate,
  preserveDefaultPayment,
  showHistoricalMatches,
  showRecordsArchives,
};

export default initialState;

import { ERROR_MESSAGES } from '../constants';

export function baseReducer(state: StoreState, action: Action): StoreState {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return { ...state, ...action.data };
    case 'SHOW_ERROR':
      if (!action.data.body) action.data.body = ERROR_MESSAGES.DEFAULT;
      const { title = 'Oops!', body = action.data.body, button = 'Try again' } = action.data;
      return {
        ...state,
        error: { title, body, button, ...action.data },
        modalOpen: 'ERROR',
        loading: false,
        isProcessing: false,
      };
    case 'PROCESSING':
      return {
        ...state,
        isProcessing: true,
      };
    case 'SET_SHIPPING_METHOD':
      const isBillingPromo = parseFloat(action.data.cart.totals.total) === 0;
      let paymentMethod = state.paymentMethod;
      if (isBillingPromo) {
        paymentMethod = 'free';
      } else {
        if (
          state.account.paymentMethods.length &&
          paymentMethod !== 'paypal' &&
          paymentMethod !== 'applepay'
          // TODO remove this entire block, don't think there is any reason to set saved_payment as method before step 3
        ) {
          paymentMethod = 'saved_payment';
        }
      }
      return {
        ...state,
        isProcessing: false,
        isBillingPromo,
        ...action.data,
        paymentMethod,
      };

    case 'SET_BRAINTREE_INSTANCE':
      return {
        ...state,
        ...action.data,
      };

    case 'NEXT_STEP':
      return {
        ...state,
        step: action.data.step,
      };

    default:
      return state;
  }
}

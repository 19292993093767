/* eslint react/no-did-mount-set-state:0 */

import React, { Component } from 'react';
import Banner from './Banner';
import Popup from './Popup';
import LinkToPopup from './LinkToPopup';
import { ShowProvider } from './Provider';
import { GDPR_LINKS_ATTRIBUTE } from './common/constants';

class OptIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkEls: [],
    };
  }

  componentDidMount() {
    const gdprLinks = document.querySelectorAll(`[${GDPR_LINKS_ATTRIBUTE}]`);
    const linkEls = [];
    [].forEach.call(gdprLinks, (el, ix) => {
      linkEls.push(<LinkToPopup key={ix} el={el} className={el.getAttribute('data-gdpr-link')} />);
    });
    this.setState({ linkEls: Object.assign([], linkEls) });
  }

  render() {
    return (
      <ShowProvider>
        <Banner />
        <Popup />
        {this.state.linkEls}
      </ShowProvider>
    );
  }
}

export default OptIn;

/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Store } from '../../pageSetup';
import { updateStore, UseStoreCallback } from '../../utils/storeUtils';
import AddressForm from './AddressForm';
import AddressInfo from './AddressInfo';
import {
  basicAddressFieldRequirements,
  extendedAddressFieldRequirements,
  getListOfInvalidFields,
} from '../../utils/getListOfInvalidFields';
import debounce from '../../utils/debounce';
import { scroller } from '../../utils/scroller';
import GaEventHandler from '../../utils/dataLayer';
import { ELEMENTS, ERROR_MESSAGES, DEBOUNCE_TIME, SCROLL_TIME, STEP } from '../../constants';
import { COUNTRY_TO_REGION_MAP, REGION, COUNTRIES, TOTAL_HEALTH_ALIAS } from '../../regions';
import withDisabledButton from '../common/withDisabledButton';
import { defaultFieldInfo, refineAddressValidation } from '../../utils/i18nFields';
import {
  fetchLabels,
  fetchShippingMethods,
  fetchStates,
  postShippingAddress,
} from '../../utils/storeApi';
import CountrySuggestionPopup from '../common/CountrySuggestionPopup';
import { captureEmail } from '../../utils/captureEmail';
import { getTrackedProduct } from '../../utils/captureEmail';
import { goToStep } from '../../actions/thunks';

const defaultProps = {
  showEmptyCartError() {},
};

interface Props {
  address: Address;
  disabledBtnClass: string;
  disableButton: (boolean) => {};
  showEmptyCartError: (any?) => {};
  showError: (any) => {};
}

interface State {
  address: Address;
  disableCountrySelector: boolean;
  errors: string[];
  fieldInfo: FieldInfo;
  isReadyForContinue: boolean;
  suggestedCountry: string;
  okayToSendEmail?: boolean;
}

const addressValidation = Object.assign(
  {},
  basicAddressFieldRequirements,
  extendedAddressFieldRequirements,
);

class AddressContainer extends React.Component<Props, State> {
  static contextType = Store;
  updateStore: UseStoreCallback;
  context: { storeState: StoreState; dispatch: any };
  countrySuggestion: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      address: props.address,
      disableCountrySelector: false,
      errors: [],
      fieldInfo: defaultFieldInfo[REGION],
      isReadyForContinue: false,
      suggestedCountry: 'US',
      okayToSendEmail: false,
    };
  }

  componentDidMount() {
    this.updateStore = updateStore.bind(this, this.context.dispatch);
    this.readyForContinue();
    this.readyForContinue = debounce(this.readyForContinue, DEBOUNCE_TIME);
    this.addProfileInformationToAddress();
    this.setCountry();
  }

  componentWillReceiveProps() {
    const incorrectAddressMethods: [BillingMethods, BillingMethods, BillingMethods] = [
      'paypal',
      'applepay',
      'venmo',
    ];
    // clear incorrect shipping country loaded from paypal / apple pay / venmo
    if (
      incorrectAddressMethods.includes(this.context.storeState.paymentMethod) &&
      COUNTRY_TO_REGION_MAP[this.state.address.country] !== REGION
    ) {
      this.clearAddressFields();
    }
  }

  clearAddressFields = () => {
    const address = Object.assign({}, this.state.address, {
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: this.props.address.country,
    });
    this.setState({ address });
  };

  handleCheckBox = okayToSendEmail => {
    this.setState({ okayToSendEmail });
  };

  handleFetchedStates = states => {
    this.setState({ disableCountrySelector: false });
    this.updateStore({ shippingStates: states });
  };

  handleFetchedLabels = fieldInfo => {
    this.setState({ fieldInfo });
  };

  handleFetchedShippingMethods = shippingMethods => {
    // Set whatever the first shipping method is as the default
    const [shipMethod] = shippingMethods;
    this.updateStore({ shippingMethods, shipMethod });
  };

  handleCountryChange = (country: string) => {
    if (REGION === 'enint' && country in COUNTRY_TO_REGION_MAP) {
      this.setState({ suggestedCountry: country });
      this.countrySuggestion.hideModal(false);
      return;
    }
    this.setState({ disableCountrySelector: true });
    fetchStates(country, this.handleFetchedStates, this.props.showError);
    fetchLabels(country, this.handleFetchedLabels, this.props.showError);
    fetchShippingMethods(country, this.handleFetchedShippingMethods, this.props.showError);
  };

  handleChange = event => {
    this.clearErr(event.target.name);
    const address: Address = {};
    if (event.target.name === 'country') {
      this.handleCountryChange(event.target.value);
      address.state = '';
    }
    address[event.target.name] = event.target.value;
    this.setState(
      { address: Object.assign({}, this.state.address, address) },
      this.readyForContinue,
    );
  };

  clearErr = errName => {
    const errors = this.state.errors && this.state.errors.slice();
    if (errors.length === 0) {
      return;
    }
    this.setState({
      errors: errors.filter(name => name !== errName),
    });
  };

  processErrors = issues => {
    this.setState({
      errors: issues.slice(),
    });
  };

  handleCheckoutAbandonmentEmail = () => {
    const { address, okayToSendEmail } = this.state;
    const { cart } = this.context.storeState;
    const isAvailableRegion = COUNTRIES.GDPR_REGIONS.includes(address.country);
    const isNotOkayToSendEmail = address.country !== COUNTRIES.US && !okayToSendEmail;
    if (isAvailableRegion) {
      if (isNotOkayToSendEmail) return;
      const locale = `en-${address.country.toLocaleLowerCase()}`;
      captureEmail({
        email: address.email,
        locale,
        addToCartType: getTrackedProduct(cart.kits),
      });
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    // MONEY-2591: gtm total health event
    const { cart, gtmNullPayload } = this.context.storeState;
    const totalHealthKits = cart.kits.filter(kit => kit['alias'] === TOTAL_HEALTH_ALIAS);
    if (totalHealthKits.length > 0) {
      GaEventHandler.trackTotalHealth(cart.id, totalHealthKits.length, gtmNullPayload);
    }

    this.handleCheckoutAbandonmentEmail();
    const issues = this.validateAddress();
    if (issues.length === 0) {
      return this.updateAddressFields();
    }
    scroller(`#${ELEMENTS[issues[0]]}`, SCROLL_TIME, -50);
  };

  readyForContinue = () => {
    const issues = getListOfInvalidFields(
      this.state.address,
      refineAddressValidation(this.state.fieldInfo, addressValidation),
    );
    this.setState({ isReadyForContinue: issues.length === 0 });
  };

  validateAddress = () => {
    const issues = getListOfInvalidFields(
      this.state.address,
      refineAddressValidation(this.state.fieldInfo, addressValidation),
    );
    this.processErrors(issues);
    return issues;
  };

  showAddressError = () => {
    this.props.showError({
      title: 'Address Error',
      body: ERROR_MESSAGES.ADDRESS,
      button: 'edit address',
      element: 'address',
      step: this.context.storeState.step,
    });
  };

  handleSubmitAddressError = (data: { rejectCart?: boolean } = {}) => {
    if (data.rejectCart) {
      return this.props.showEmptyCartError();
    }
    this.showAddressError();
    this.props.disableButton(false);
  };

  handleAddressCallback = data => {
    // Why are there two error branches? Backend should return an error here
    if (data.error) {
      return this.handleSubmitAddressError(data);
    }
    if (data.shippingMethods) {
      const { shippingMethods } = data;
      const isBillingPromo = this.context.storeState.cart.isFree;
      this.updateStore({ shippingMethods, isBillingPromo });
    }
    const { address } = this.state;
    this.updateStore({ address });
    if (data.statusLabel === 'good') {
      // if valid address, go to shipping method (skip address verification)
      this.context.dispatch(goToStep(STEP.SHIP_METHOD, ELEMENTS.shipping));
    } else {
      this.updateStore({ verifiedAddress: data.verifiedAddress });
      this.context.dispatch(goToStep(STEP.VERIFICATION));
      GaEventHandler.pushToDataLayer({
        event: 'AnalyticsEvent',
        eventCategory: 'store',
        eventAction: 'spc_section_transition',
        eventLabel: 'address_verification',
      });
    }
    this.props.disableButton(false);
  };

  updateAddressFields = () => {
    this.props.disableButton(true);
    postShippingAddress(
      this.state.address,
      this.handleAddressCallback,
      this.handleSubmitAddressError,
    );
  };

  addProfileInformationToAddress = () => {
    const { address } = this.state;

    const profile = this.context.storeState.profile;
    if (profile) {
      const sampleShippingCountry = profile.sampleShippingCountry;
      address.firstName = profile.firstName;
      address.lastName = profile.lastName;
      address.email = this.context.storeState.accountEmail;
      if (
        sampleShippingCountry &&
        this.context.storeState.shippingCountries.filter(
          country => country.value === sampleShippingCountry,
        ).length > 0
      ) {
        address.country = profile.sampleShippingCountry;
      }
    }
    this.setState({
      address,
    });
  };

  // refactor so components that can use this info use it if it's there
  setCountry = () => {
    const { profile, shippingCountries } = this.context.storeState;
    if (profile) {
      const address = { ...this.state.address };
      const { sampleShippingCountry } = profile;
      if (
        sampleShippingCountry &&
        shippingCountries.some(country => country.value === sampleShippingCountry)
      ) {
        address.country = profile.sampleShippingCountry;
      }
      this.handleCountryChange(address.country);
    }
  };

  render() {
    let utilAddress;

    if (this.context.storeState.step > STEP.ADDRESS) {
      utilAddress = <AddressInfo address={this.props.address} />;
    } else {
      utilAddress = (
        <AddressForm
          address={this.state.address}
          disabledBtnClass={this.props.disabledBtnClass}
          disableCountrySelector={this.state.disableCountrySelector}
          errors={this.state.errors}
          fieldInfo={this.state.fieldInfo}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          isReadyForContinue={this.state.isReadyForContinue}
          handleCheckBox={this.handleCheckBox}
          okayToSendEmail={this.state.okayToSendEmail}
        />
      );
    }

    return (
      <div className="spc-outer">
        {utilAddress}
        <CountrySuggestionPopup
          country={this.state.suggestedCountry}
          ref={instance => {
            this.countrySuggestion = instance;
          }}
        />
      </div>
    );
  }
  static defaultProps = defaultProps;
}

export default withDisabledButton(AddressContainer);

import CustomerCareContactAll from './CustomerCareContactAll';
import CustomerCareContactEnCa from './CustomerCareContactEnCa';
import CustomerCareContactEnGb from './CustomerCareContactEnGb';

const CustomerCareContactGroup = {
  enus: CustomerCareContactAll,
  enca: CustomerCareContactEnCa,
  engb: CustomerCareContactEnGb,
  eneu: CustomerCareContactAll,
  enint: CustomerCareContactAll,
};

export default CustomerCareContactGroup;

import React from 'react';
import { ELEMENTS } from '../../../constants';
import { getFieldLabel, isFieldPresent, isFieldRequired } from '../../../utils/i18nFields';
import TextInput from '../TextInput';
import CountryField from '../intl/CountryField/CountryField';
import StateField from '../intl/StateField/StateField';

interface Props {
  values: Address;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  errors?: string[];
  stateOptions?: GeoState[];
  countryOptions?: Country[];
  disableCountryField?: boolean;
  fieldInfo: FieldInfo;
}

/**
 * Form fields for entering in new billing address
 */
export const BillingAddressFields = ({
  values,
  onChange,
  errors = [],
  stateOptions,
  countryOptions,
  disableCountryField = false,
  fieldInfo,
}: Props) => {
  return (
    <div>
      <TextInput
        id={ELEMENTS.firstName}
        name="firstName"
        text="First name"
        value={values.firstName}
        onChange={onChange}
        showError={errors.includes('firstName')}
      />
      <TextInput
        id={ELEMENTS.lastName}
        name="lastName"
        text="Last name"
        value={values.lastName}
        onChange={onChange}
        showError={errors.includes('lastName')}
      />
      <TextInput
        id={ELEMENTS.address}
        name="address"
        text="Billing street address"
        value={values.address}
        onChange={onChange}
        showError={errors.includes('address')}
      />
      <TextInput
        id={ELEMENTS.address2}
        name="address2"
        text="Apt, suite, unit, etc"
        value={values.address2}
        onChange={onChange}
        isRequired={false}
      />
      {countryOptions && (
        <CountryField
          country={values.country}
          countries={countryOptions}
          forShipping={false}
          handleChange={onChange}
          errors={errors}
          focusLabel="Country"
          disableCountrySelector={disableCountryField}
        />
      )}
      <TextInput
        id={ELEMENTS.city}
        name="city"
        text="City"
        value={values.city}
        onChange={onChange}
        showError={errors.includes('city')}
        isRequired={isFieldRequired(fieldInfo, 'city')}
      />
      <StateField
        state={values.state}
        states={stateOptions}
        errors={errors}
        handleChange={onChange}
        placeHolder={getFieldLabel(fieldInfo, 'state', 'State')}
        isPresent={isFieldPresent(fieldInfo, 'state')}
        isRequired={isFieldRequired(fieldInfo, 'state')}
      />
      <TextInput
        id={ELEMENTS.postalCode}
        name="postalCode"
        text={getFieldLabel(fieldInfo, 'postalCode', 'Postal Code')}
        value={values.postalCode}
        onChange={onChange}
        showError={errors.includes('postalCode')}
        isPresent={isFieldPresent(fieldInfo, 'postalCode')}
        isRequired={isFieldRequired(fieldInfo, 'postalCode')}
      />
    </div>
  );
};

import React from 'react';
import PaypalLogo from '../../Svgs/PaypalLogo';
import VenmoLogo from '../../Svgs/VenmoLogo';
import SavedPaymentMethod from '../../common/SavedPaymentMethod';
import PaymentMethodsGroup from '../../common/intl/PaymentMethodsGroup/PaymentMethodsGroup';
import { BILLING_METHODS } from '../../../constants';
import { REGION } from '../../../regions';
import withArrayDisplay from '../../common/withArrayDisplay';
import handleKeyDown from '../../../utils/handleKeyDown';
import venmo from 'braintree-web/venmo';

interface SavedPaymentProps {
  savedPayment: SavedPayment;
}

interface PaymentMethodProps {
  handleChange: (target) => void;
  method: string;
  paypalEnabled: boolean;
  venmoEnabled: boolean;
}

const SavedPayment = (props: SavedPaymentProps & PaymentMethodProps) => {
  const { handleChange, method, savedPayment } = props;
  return savedPayment && savedPayment.isCreditCard ? (
    <div
      className="spc-billing-text spc-billing-text-saved-payment spc-pointer"
      onClick={e => {
        const input = e.currentTarget.firstElementChild as HTMLInputElement;
        input.checked = true;
        handleChange({
          target: {
            name: input.name,
            value: input.value,
          },
        });
      }}
      onKeyDown={handleKeyDown}
      role="listitem"
    >
      <label htmlFor="saved-payment" className="spc-billing-text-screenreader-only">
        Saved Payment
      </label>
      <input
        id="saved-payment"
        type="radio"
        name="method"
        checked={method === BILLING_METHODS.SAVED_PAYMENT}
        value={BILLING_METHODS.SAVED_PAYMENT}
        onChange={e => {
          handleChange(e);
        }}
        tabIndex={0}
      />
      <SavedPaymentMethod savedPayment={savedPayment} />
    </div>
  ) : null;
};

const CreditCard = (props: PaymentMethodProps) => {
  const { handleChange, method } = props;
  const PaymentMethods = PaymentMethodsGroup[REGION];
  return (
    <div
      className="spc-billing-text spc-pointer"
      onClick={e => {
        //remove these... they don't work
        const input = e.currentTarget.firstElementChild as HTMLInputElement;
        input.checked = true;
        handleChange({
          target: {
            name: input.name,
            value: input.value,
          },
        });
      }}
      onKeyDown={handleKeyDown}
      role="listitem"
    >
      <label htmlFor="credit-card" className="spc-billing-text-screenreader-only">
        Credit Card
      </label>
      <input
        id="credit-card"
        type="radio"
        name="method"
        checked={method === BILLING_METHODS.CREDIT_CARD}
        value={BILLING_METHODS.CREDIT_CARD}
        onChange={e => {
          handleChange(e);
        }}
        tabIndex={0}
      />
      <PaymentMethods />
    </div>
  );
};

const Paypal = (props: PaymentMethodProps) => {
  const { handleChange, method, paypalEnabled } = props;
  if (!paypalEnabled) {
    return null;
  }
  return (
    <div
      className="spc-billing-text spc-pointer"
      onClick={e => {
        const input = e.currentTarget.firstElementChild as HTMLInputElement;
        input.checked = true;
        handleChange({
          target: {
            name: input.name,
            value: input.value,
          },
        });
      }}
      onKeyDown={handleKeyDown}
      role="listitem"
    >
      <label htmlFor="paypal" className="spc-billing-text-screenreader-only">
        PayPal
      </label>
      <input
        id="paypal"
        type="radio"
        name="method"
        checked={method === BILLING_METHODS.PAYPAL}
        value={BILLING_METHODS.PAYPAL}
        onChange={e => {
          handleChange(e);
        }}
        tabIndex={0}
      />
      <PaypalLogo height="45px" className="spc-billing-radio" />
    </div>
  );
};

const Venmo = (props: PaymentMethodProps) => {
  const { handleChange, method, venmoEnabled } = props;
  if (!venmoEnabled || !venmo.isBrowserSupported()) {
    return null;
  }
  return (
    <div
      className="spc-billing-text spc-pointer"
      onClick={e => {
        const input = e.currentTarget.firstElementChild as HTMLInputElement;
        input.checked = true;
        handleChange({
          target: {
            name: input.name,
            value: input.value,
          },
        });
      }}
      onKeyDown={handleKeyDown}
      role="listitem"
    >
      <label htmlFor="venmo" className="spc-billing-text-screenreader-only">
        Venmo
      </label>
      <input
        id="venmo"
        type="radio"
        name="method"
        checked={method === BILLING_METHODS.VENMO}
        value={BILLING_METHODS.VENMO}
        onChange={e => {
          handleChange(e);
        }}
        tabIndex={0}
      />
      <VenmoLogo height="29px" className="spc-billing-radio" />
    </div>
  );
};

const PaymentMethodOption = () => {};
PaymentMethodOption.componentArray = [SavedPayment, CreditCard, Paypal, Venmo];

export default withArrayDisplay(PaymentMethodOption);
export { SavedPayment, CreditCard, Paypal, Venmo };

import React from 'react';
import { useSavedBillingAddresses } from './hooks';
import { RadioOption } from './RadioOption';

export type ClickEditAddressEventHandler = (
  event: React.PointerEvent<HTMLButtonElement>,
  args: {
    addressId: string;
  },
) => void;

export interface Props {
  selectedAddressId: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    args: {
      addressId: string;
    },
  ) => void;

  // event handler for "Edit" button in address radio option
  onClickEditAddress?: ClickEditAddressEventHandler;
}

/**
 * Radio buttons for selecting saved billing address
 */
export const SavedBillingAddressRadioGroup = ({
  selectedAddressId,
  onChange,
  onClickEditAddress,
}: Props) => {
  const {
    savedBillingAddresses,
    hasNewBillingAddress,
    newBillingAddress,
  } = useSavedBillingAddresses();

  const renderRadioOption = address => (
    <RadioOption
      key={address.id}
      id={`address-${address.id}`}
      name="address"
      value={address.id}
      checked={selectedAddressId === address.id}
      onChange={event => {
        onChange(event, { addressId: address.id });
      }}
      onClickEdit={
        onClickEditAddress &&
        function handleClickEditAddress(event) {
          onClickEditAddress(event, { addressId: address.id });
        }
      }
    >
      <>
        {address.firstName} {address.lastName}
        <br />
        {address.address}
        <br />
        {address.city}, {address.state}
        <br />
        {address.postalCode}
      </>
    </RadioOption>
  );

  return (
    <fieldset>
      <legend>Billing Address</legend>
      {hasNewBillingAddress() && renderRadioOption(newBillingAddress(true))}
      {savedBillingAddresses.map(address => renderRadioOption(address))}
    </fieldset>
  );
};

import React, { Fragment } from 'react';
import P from './Styled/IntroInfo';

const IntroInfo = () => (
  <Fragment>
    <P>
      Cookies are text files, containing small amounts of information, which are downloaded to your
      browser (such as a computer or smartphone) when you visit a website. Cookies do lots of
      different jobs, like helping us understand how this website is being used, letting you
      navigate between pages efficiently, remembering your preferences, and generally improving your
      browsing experience.
    </P>
    <P>
      Below we explain different categories of cookies you may interact with while visiting,
      navigating, and otherwise interacting with our website. Please choose whether this website may
      use Functional and/or Advertising cookies, as described below.
    </P>
  </Fragment>
);

export default IntroInfo;

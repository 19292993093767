module.exports = {
  GDPR_COOKIE: 'gdpr_cookies_notice',
  GDPR_VISITED_COOKIE: 'gdpr_visited',
  GDPR_LINKS_ATTRIBUTE: 'data-gdpr-link',
  REQUIRED_COOKIES: [
    'AWSALB',
    '__cfduid',
    'uuid',
    'sessionid',
    'cart_count',
    'cookies_notice',
    'cookies_notice_reset',
    'csrftoken',
    'gdpr_cookies_notice',
    'selected_country',
    'uuid',
    '_help_center_session',
    '_zendesk_session',
    '_zendesk_shared_session',
    'gh_src',
    'gdpr_visited',
    'profile',
    'notice_banner',
  ],
};

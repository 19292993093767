import React, { useState } from 'react';
import { BILLING_METHODS, BILLING_ADDRESS } from '../../../constants';
import {
  SavedBillingAddressSection,
  ClickAddAddressEventHandler,
} from './SavedBillingAddressSection';
import { ClickEditSavedPaymentEventHandler } from './PaymentMethodRadioGroup';
import { ClickEditAddressEventHandler } from './SavedBillingAddressRadioGroup';
import { PaymentMethodSection, ClickAddPaymentCard } from './PaymentMethodSection';
import { useErrors, useSavedBillingAddresses, useSavedPayments, useCreditCard } from './hooks';
import { PayPalButton } from './PayPalButton';
import { ApplePayButton } from './ApplePayButton';
import { useStoreContext } from '../../../utils/storeUtils';
import { updateGlobalStore } from '../../../actions/actions';
import {
  focusFirstInvalidField,
  validatePaymentMethodForm,
  validateSavedPaymentMethodForm,
} from './utils/validation';

interface Props extends PaymentFormProps {
  // event handler for the "Edit" buttons for saved payment options
  onClickEditSavedPayment?: ClickEditSavedPaymentEventHandler;

  // event handler for "Add payment card" button
  onClickAddPaymentCard?: ClickAddPaymentCard;

  // event handler for "Edit" button for saved billing address options
  onClickEditAddress?: ClickEditAddressEventHandler;

  // event handler for "Add a new address" button
  onClickAddAddress?: ClickAddAddressEventHandler;
}

/**
 * Form for selecting payment method to use for checkout. This is the initial form that the user
 * lands on.
 */
export const PaymentMethodForm = ({
  onSubmitSuccess,
  onClickEditSavedPayment,
  onClickAddPaymentCard,
  onClickEditAddress,
  onClickAddAddress,
}: Props) => {
  // global state values
  const {
    getSavedPaymentByToken,
    defaultSavedPayment,
    hasSavedPayment,
    setStateForSavedPayment,
    filterForPaymentCard,
  } = useSavedPayments();
  const { hasCreditCardData } = useCreditCard();
  const { storeState, dispatch } = useStoreContext();

  const defaultPaymentMethod = (): MethodOptions => {
    if (hasCreditCardData) {
      return {
        type: BILLING_METHODS.CREDIT_CARD,
        token: storeState.billing.nonce,
      };
    }
    if (hasSavedPayment) {
      return {
        type: BILLING_METHODS.SAVED_PAYMENT,
        token: defaultSavedPayment.token,
      };
    }
    return {
      type: null,
      token: null,
    };
  };

  // controlled form input values
  const [method, setMethod] = useState<MethodOptions>(defaultPaymentMethod());

  // form state utils
  const { errors, setErrors, clearError } = useErrors();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmittedWithErrors, setIsSubmittedWithErrors] = useState(false);

  const handleErrors = (errors: string[]) => {
    setErrors(errors);
    setIsSubmittedWithErrors(true);
    setIsProcessing(false);
    focusFirstInvalidField(errors);
  };

  const setStateForPayment = (nonce, address, method) => {
    dispatch(
      updateGlobalStore({
        billing: {
          ...storeState.billing,
          nonce,
          addressSource: BILLING_ADDRESS.CUSTOM,
          customAddress: address,
          method: method,
          cardType: '',
        },
      }),
    );
    onSubmitSuccess(null);
  };

  const canProceedToCheckout = hasCreditCardData || hasSavedPayment;
  const btnText = canProceedToCheckout ? 'Checkout with credit card' : 'Add credit card';

  return (
    <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
      <form
        data-jest-id="payment-method-form"
        noValidate
        onSubmit={event => {
          event.preventDefault();
          setIsProcessing(true);

          if (!canProceedToCheckout) {
            onClickAddPaymentCard(null);
            return;
          }

          // A new credit card will already be in the global state.
          // PayPal and Apple Pay automatically redirect to order review.
          // So only a saved payment needs to be loaded into the global state at this point.
          if (method.type === BILLING_METHODS.SAVED_PAYMENT) {
            const selectedSavedPayment = filterForPaymentCard(getSavedPaymentByToken(method.token));
            setStateForSavedPayment(selectedSavedPayment, method.token, storeState.account);
          }
          onSubmitSuccess(event, null);
        }}
      >
        <PaymentMethodSection
          selectedMethod={method}
          showError={isSubmittedWithErrors && errors.includes('method')}
          onChange={(_event, { method }) => {
            clearError('method');
            setMethod(method);
          }}
          {...{
            onClickEditSavedPayment,
            onClickAddPaymentCard,
          }}
        />
        <input
          type="submit"
          className="card-button top-margin"
          value={btnText}
          data-jest-id="clinic-select-payment-method-button"
          data-stor-id="clinic-select-payment-method-button"
          disabled={isSubmittedWithErrors && Boolean(errors.length)}
        />
      </form>
      <div className="card-button-paypal">
        <PayPalButton onReceivePayPalPayment={setStateForPayment} />
      </div>
      <div className="card-button-applepay">
        <ApplePayButton onReceiveApplePayPayment={setStateForPayment} />
      </div>
    </div>
  );
};

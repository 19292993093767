import React from 'react';
import initialState from './initialState';
import { REGION, HEAP_REGIONS } from './regions';
import { trackCohortData } from './utils/heapTracker';
import { baseReducer } from './reducers/baseReducer';
import { useReducerWithMiddleware } from './utils/storeUtils';
import { beforeMiddlewares, afterMiddlewares } from './middleware';

export const Store = React.createContext(null);

export function StoreProvider(props: { children: React.ReactNode }) {
  const [storeState, dispatch] = useReducerWithMiddleware(
    baseReducer,
    initialState,
    beforeMiddlewares,
    afterMiddlewares,
  );

  const value: { storeState: StoreState; dispatch: Dispatch } = { storeState, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

export function setupPage() {
  // Send Optimizely cohort data to heap analytics
  if (HEAP_REGIONS.includes(REGION)) {
    trackCohortData();
  }
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import TextInput from '../../TextInput';
import StateField from '../StateField/StateField';
import CountryField from '../CountryField/CountryField';
import { ELEMENTS } from '../../../../constants';
import {
  getFieldLabel,
  isFieldPresent,
  defaultFieldInfo,
  isFieldRequired,
} from '../../../../utils/i18nFields';
import GaEventHandler from '../../../../utils/dataLayer';
import handleKeyDown from '../../../../utils/handleKeyDown';

interface Props {
  states: GeoState[];
  countries?: Country[];
  forShipping?: boolean;
  fieldInfo?: typeof defaultFieldInfo;
  address: Address;
  errors?: FormError[];
  handleChange?: (e: any) => void;
  elemClass?: string;
  disableCountrySelector?: boolean;
  readOnly: boolean;
  forPayment?: boolean;
}

const AddressFieldsi18nMulti = ({
  states,
  countries = [],
  forShipping = true,
  fieldInfo = [],
  address,
  errors = [],
  handleChange = () => {},
  elemClass = 'spc-input-wrap',
  disableCountrySelector = false,
  readOnly = false,
  forPayment = false,
}: Props) => (
  <div className={elemClass}>
    <div
      className="spc-input-wrap-text"
      onClick={e => {
        e.stopPropagation();
        GaEventHandler.pushToDataLayer({
          event: 'AnalyticsEvent',
          eventCategory: 'store',
          eventAction: 'spc_section_transition',
          eventLabel: 'shipping',
        });
      }}
      onKeyDown={handleKeyDown}
    >
      <TextInput
        id={ELEMENTS.firstName}
        name="firstName"
        text="First name"
        value={address.firstName}
        onChange={handleChange}
        showError={errors.includes('firstName')}
        readOnly={readOnly}
      />
      <TextInput
        id={ELEMENTS.lastName}
        name="lastName"
        text="Last name"
        value={address.lastName}
        onChange={handleChange}
        showError={errors.includes('lastName')}
        readOnly={readOnly}
      />
    </div>
    {!forPayment && (
      <TextInput
        id={ELEMENTS.company}
        name="company"
        text="Company (optional)"
        value={address.company}
        onChange={handleChange}
        isRequired={false}
      />
    )}
    <TextInput
      id={ELEMENTS.address}
      name="address"
      text={`${forPayment ? 'Street ' : ''}Address`}
      value={address.address}
      onChange={handleChange}
      showError={errors.includes('address')}
    />
    {!forPayment && (
      <TextInput
        id={ELEMENTS.address2}
        name="address2"
        text="Apartment, Suite, Building (optional)"
        value={address.address2}
        onChange={handleChange}
        isRequired={false}
      />
    )}
    <CountryField
      country={address.country}
      countries={countries}
      forShipping={forShipping}
      handleChange={handleChange}
      errors={errors}
      focusLabel={'Country'}
      disableCountrySelector={disableCountrySelector}
      readOnly={readOnly}
    />
    <TextInput
      id={ELEMENTS.city}
      name="city"
      text="City"
      value={address.city}
      onChange={handleChange}
      showError={errors.includes('city')}
      isRequired={isFieldRequired(fieldInfo, 'city')}
    />
    <StateField
      state={address.state}
      states={states}
      errors={errors}
      handleChange={handleChange}
      placeHolder={getFieldLabel(fieldInfo, 'state', 'State')}
      isPresent={isFieldPresent(fieldInfo, 'state')}
      isRequired={isFieldRequired(fieldInfo, 'state')}
    />
    <TextInput
      id={ELEMENTS.postalCode}
      name="postalCode"
      text={getFieldLabel(fieldInfo, 'postalCode', 'Postal Code')}
      value={address.postalCode}
      onChange={handleChange}
      showError={errors.includes('postalCode')}
      isPresent={isFieldPresent(fieldInfo, 'postalCode')}
      isRequired={isFieldRequired(fieldInfo, 'postalCode')}
    />
  </div>
);

export default AddressFieldsi18nMulti;

import React, { useState, useEffect, useCallback } from 'react';
import { useStore, showError } from '../utils/storeUtils';
import ErrorModal from '../components/common/ErrorPopup';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SubscriptionInfo from '../components/SubscriptionPayment/SubscriptionInfo';
import SubscriptionPaymentHeader from '../components/SubscriptionPayment/SubscriptionPaymentHeader';
import SubscriptionProfileHeader from '../components/SubscriptionPayment/SubscriptionProfileHeader';
import SubscriptionRenewalInfo from '../components/SubscriptionPayment/SubscriptionRenewalInfo';
import SubscriptionTermsOfServiceAll from '../components/common/intl/TermsOfServiceGroup/SubscriptionTermsOfServiceAll';
import UpgradePaymentHeader from '../components/SubscriptionPayment/UpgradePaymentHeader';
import UpgradeSubscriptionInfo from '../components/SubscriptionPayment/UpgradeSubscriptionInfo';

import { Store } from '../pageSetup';
import { BILLING_METHODS } from '../constants';
import { createSubscription, createSubscriptionRenewal } from '../utils/storeApi';
import { URLS } from '../regions';
import PaymentForm from '../components/common/PaymentForm';
import Checkbox from '../components/common/Checkbox';
import { identify } from '../utils/heapTracker';
import GaEventHandler from '../utils/dataLayer';

const cleanSubParams = () => {
  const cleanedUrl = window.location.href
    .split('&')
    .filter(urlPart => !urlPart.startsWith('plan') && !urlPart.startsWith('sub_coupon'))
    .join('&');
  window && window.history.replaceState(null, '', cleanedUrl);
};

export default function SubscriptionPaymentPage() {
  const [storeState, updateStoreState] = useStore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _, dispatch } = React.useContext(Store);
  const initialState = {
    subId: '',
  };
  const [state, setState] = useState(initialState);
  const [checkboxState, setCheckboxState] = useState({ checked: false, hasErr: false });
  const subscriptionInfo = storeState.subscriptionInfo;
  const isSubscriptionRenewal = subscriptionInfo && subscriptionInfo.isSubscriptionRenewal;
  const allowApplePay = storeState.enableApplepay;
  const allowPaypal = storeState.enablePaypal;

  const pageClassname = isSubscriptionRenewal ? 'mod-renewal' : 'mod-sub';

  useEffect(() => {
    // Remove "Plan" and "Coupon" query params
    cleanSubParams();
  }, []);

  useEffect(() => {
    // Identify the heap ID if it's there
    storeState.heapId && identify(storeState.heapId);
  }, [storeState.heapId]);

  const subscriptionCallback = useCallback(
    (token: string, billingAddress: object = null, isPaypalPayment = false) => {
      const {
        account: { paymentMethods },
      } = storeState;
      const payment = paymentMethods.filter(payment => payment.token === token);

      const callbackMethod = storeState.subscriptionInfo.isSubscriptionRenewal
        ? createSubscriptionRenewal
        : createSubscription;
      const { subscriptionInfo, kit, gtmNullPayload } = storeState;
      GaEventHandler.addPaymentInfoSubs(subscriptionInfo, payment, kit, gtmNullPayload);
      callbackMethod(
        {
          paymentMethodToken: token,
          planId: storeState.planId,
          profileId: storeState.account.currentProfileId,
          billingAddress: billingAddress,
          isPaypalPayment: isPaypalPayment,
        },
        data => {
          setState({ ...state, subId: data.subscriptionId });
          window.location.href = `${URLS.SUBSCRIPTION_RECEIPT}${data.subscriptionId}`;
        },
        e => {
          const errorData: ErrorType = {};
          if (e) errorData.body = e;
          showError(dispatch, errorData);
        },
      );
    },
    [
      dispatch,
      state,
      storeState.planId,
      storeState.account.currentProfileId,
      storeState.kit,
      storeState.subscriptionInfo,
    ],
  );

  const acceptTerms = () => {
    setCheckboxState(prevCheckboxState => {
      const checked = !prevCheckboxState.checked;
      let hasErr;
      if (checked && prevCheckboxState.hasErr) {
        hasErr = false;
      }
      return { checked, hasErr };
    });
  };

  const setValidationError = (bool: boolean) => {
    setCheckboxState({ ...checkboxState, hasErr: bool });
  };

  const renderCheckboxErrMsg = () => {
    if (!checkboxState.hasErr) return;
    return (
      <h3 className="spc-billing-err mod-terms" data-stor-id="spc-tos-error-message">
        Please accept the {isSubscriptionRenewal ? 'membership terms' : 'terms of service'}.
      </h3>
    );
  };

  const renderTOSCheckbox = () => {
    return (
      <div className="spc-tos-wrap">
        <div className="spc-checkbox-outer">
          <div className="spc-tos">
            {renderCheckboxErrMsg()}
            <div className={`spc-checkbox-outer ${checkboxState.hasErr ? 'mod-err' : ''}`}>
              <Checkbox
                checked={checkboxState.checked}
                dataStorId="spc-accept-tos-checkbox"
                handleCheck={acceptTerms}
                ariaLabel="I accept the Terms of Service"
              />
              <SubscriptionTermsOfServiceAll />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const paymentFormCallback = useCallback(
    (token: string = null, billingAddress: object = null, isPaypalPayment = false) => {
      if (!token) {
        const { paymentMethods } = storeState.account;
        token = paymentMethods[0].token; // first payment is the default
      }
      subscriptionCallback(token, billingAddress, isPaypalPayment);
    },
    [storeState.subscriptionInfo.isHealthUpgrade, subscriptionCallback],
  );

  const logoUrl = storeState.account.isAuthenticated ? storeState.youdotUrl : storeState.pubUrl;

  return (
    <div>
      <div className={`spc-subs`} data-stor-id="subs-header">
        <Header logoUrl={logoUrl} />
        <div className="spc-subs-container">
          <div className={'spc-payment spc-subs-row'}>
            <div className={`spc-subs-column mod-left ${pageClassname}`}>
              {storeState.subscriptionInfo.isHealthUpgrade ? (
                <UpgradeSubscriptionInfo />
              ) : isSubscriptionRenewal ? (
                <SubscriptionRenewalInfo />
              ) : (
                <SubscriptionInfo />
              )}
            </div>
            <div className={`spc-subs-column mod-right ${pageClassname}`}>
              <div id="add-subs-form" className="spc-subs-addform">
                <SubscriptionProfileHeader />
                {storeState.subscriptionInfo.isHealthUpgrade && <UpgradePaymentHeader />}
                {!storeState.subscriptionInfo.isHealthUpgrade && (
                  <SubscriptionPaymentHeader isSubscriptionRenewal={isSubscriptionRenewal} />
                )}
                <PaymentForm
                  total={0}
                  method={BILLING_METHODS.CREDIT_CARD}
                  savePaymentCallback={paymentFormCallback}
                  creditCardCopy={'Checkout with credit card'}
                  isSub={true}
                  allowApplepay={allowApplePay}
                  allowPaypal={allowPaypal}
                  aboveSubmitEl={renderTOSCheckbox}
                  setValidationError={setValidationError}
                  isAuthorized={checkboxState.checked}
                  paypalLabel={'subscribe'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spc-subs-footer-container">
        <Footer showSubDisclaimer={true} />
        <div className="spc-error-wrap">
          <ErrorModal />
        </div>
      </div>
    </div>
  );
}

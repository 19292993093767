import React from 'react';

interface Props {
  action: string;
  paymentCardFields: React.ReactNode;
}

/**
 * Wrapper component for payment card fields (primarily for consistent structure)
 */
export const PaymentCardSection = ({ action, paymentCardFields }: Props) => {
  return (
    <section>
      <h3 className="spc-labs-h3">{action}</h3>
      {paymentCardFields}
    </section>
  );
};

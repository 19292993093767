import React from 'react';

interface Props {
  forShipping?: boolean;
  readOnly?: boolean;
}

const ShippingCountryTooltipEnGb = ({ forShipping = true, readOnly = false }: Props) => {
  let tooltipText;
  if (!readOnly) {
    tooltipText = (
      <div className="tooltip-data" role="tooltip">
        UK kits are for customers in England, Scotland, Wales and Northern Ireland. If you want to
        ship a kit to the Republic of Ireland, visit the{' '}
        <a className="spc-tooltip-link" href="https://www.23andme.com/en-eu/">
          EU site
        </a>
        . If you want to ship a kit to another country, visit the{' '}
        <a className="spc-tooltip-link" href="https://www.23andme.com/en-int/">
          International site
        </a>
        .
      </div>
    );
  } else if (readOnly) {
    tooltipText = (
      <div className="tooltip-data" role="tooltip">
        UK kits are for customers in England, Scotland, Wales and Northern Ireland.
      </div>
    );
  }

  if (forShipping) {
    return (
      <div className="spc-tooltip mod-select">
        <span
          data-placement="right"
          data-trigger="hover"
          className="tooltip mod-select spc-tooltip-a"
          aria-disabled="true"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <span className="tooltip-span" />?{tooltipText}
        </span>
      </div>
    );
  } else {
    return <span />;
  }
};

export default ShippingCountryTooltipEnGb;

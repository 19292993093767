import * as React from 'react';
import SavedPaymentCta from './SavedPaymentCta';

interface Props {
  savedPayments: SavedPayment[];
  preserveDefaultPayment: boolean;
  updatePaymentCallback: (token: string, address: string) => void;
  confirmActionCallback: (
    token: string,
    lastFour: string,
    confirmActionType: ConfirmationActions,
  ) => void;
}

const SavedPayment = ({
  savedPayments,
  preserveDefaultPayment,
  updatePaymentCallback,
  confirmActionCallback,
}: Props) => {
  const renderPaymentInfo = (savedPayment: SavedPayment) => {
    return (
      <div className="spc-spi-paymentmethod" data-stor-id="spi-paymentmethod">
        <div className="spc-spi-paymentmethod-info">
          <img src={savedPayment.imageUrl} alt="logo" />
        </div>
        <div className="spc-spi-paymentmethod-info">
          <p>**** **** **** {savedPayment.lastFour}</p>
          <p>
            {savedPayment.firstName}&nbsp;{savedPayment.lastName}
          </p>
        </div>
        <div className="spc-spi-paymentmethod-info">
          <p>{savedPayment.address}</p>
          <p>
            {savedPayment.city}, {savedPayment.state}
          </p>
          <p>{savedPayment.postalCode}</p>
        </div>
      </div>
    );
  };

  const renderDefault = (savedPayment: SavedPayment) => {
    if (savedPayment.default) return <p>Default Payment</p>;
    return (
      <SavedPaymentCta
        savedPaymentCallback={() =>
          confirmActionCallback(savedPayment.token, savedPayment.lastFour, 'UPDATE_PAYMENT')
        }
        ariaLabel="set as default payment method"
        ctaText="Set as Default Payment"
      />
    );
  };

  return (
    <>
      {savedPayments.map(savedPayment => {
        const removeButtonDisabled =
          savedPayment.hasSubscriptions || (savedPayment.default && preserveDefaultPayment);
        const removeButtonDisabledTooltipText = removeButtonDisabled
          ? 'You cannot remove the payment method at this time.'
          : '';

        return (
          <div key={savedPayment.token} className="spc-spi-paymentmethod-container">
            {renderPaymentInfo(savedPayment)}
            <div className="spc-spi-paymentmethod">
              <SavedPaymentCta
                savedPaymentCallback={() =>
                  updatePaymentCallback(savedPayment.token, savedPayment.addressId)
                }
                ariaLabel="update this payment method"
                ctaText="Edit"
              />
              <SavedPaymentCta
                savedPaymentCallback={() =>
                  confirmActionCallback(savedPayment.token, savedPayment.lastFour, 'DELETE_PAYMENT')
                }
                ariaLabel="delete this payment method"
                ctaText="Remove"
                dataStorId="spi-delete-button"
                disableAction={removeButtonDisabled}
                toolTipText={removeButtonDisabledTooltipText}
              />
              {renderDefault(savedPayment)}
            </div>
          </div>
        );
      })}
    </>
  );
};

SavedPayment.displayName = 'SavedPayment';
export default SavedPayment;

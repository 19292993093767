import React, { useState, useEffect, useRef } from 'react';
import { useStore } from '../../utils/storeUtils';
import ErrorModal from '../../components/common/ErrorPopup';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Store } from '../../pageSetup';
import { BILLING_METHODS } from '../../constants';
import PaymentForm from '../../components/common/PaymentForm';
import { identify } from '../../utils/heapTracker';
import { ActivationCard } from '../../components/SubscriptionRenewalOptIn/ActivationCard';
import SubscriptionRenewalOptInDisclaimer from '../../components/SubscriptionRenewalOptIn/SubscriptionRenewalOptInDisclaimer';
import { SubscriptionActivationConfirmation } from '../../components/SubscriptionRenewalOptIn/SubscriptionActivationConfirmation';
import GaEventHandler from '../../utils/dataLayer';
import { setOptInSubscriptionRenewalValue } from './subscriptionRenewalOptInActions';
import { ActivationOptOut } from './ActivationOptOut';

export default function SubscriptionPaymentPage() {
  const [storeState] = useStore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _, dispatch } = React.useContext(Store);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [addingPaymentDetails, setAddingPaymentDetails] = useState(false);
  const allowApplePay = storeState.enableApplepay;
  const allowPaypal = storeState.enablePaypal;
  const confirmationRef = useRef<HTMLDivElement>(null);
  const currentProfileId = storeState.account.currentProfileId;

  useEffect(() => {
    GaEventHandler.pushToDataLayer({
      event: 'gaEvent',
      gaEventCategory: 'product_interstitial_activation',
      gaEventAction: 'viewed_interstitial',
      gaEventLabel: 'activation_prompt_interstitial',
      gaEventValue: 0,
      gaEventNonInteractionBool: false,
    });
  }, []);

  useEffect(() => {
    // Identify the heap ID if it's there
    storeState.heapId && identify(storeState.heapId);
  }, [storeState.heapId]);

  const trackPaymentInformation = (isApplepayPayment: Boolean, isPaypalPayment: Boolean) => {
    let paymentMethod = 'credit_card_activation';
    if (isApplepayPayment) {
      paymentMethod = 'apple_pay_activation';
    }
    if (isPaypalPayment) {
      paymentMethod = 'pay_pal_activation';
    }
    GaEventHandler.pushToDataLayer({
      event: 'gaEvent',
      gaEventCategory: 'product_interstitial_activation',
      gaEventAction: 'cta_click',
      gaEventLabel: paymentMethod,
      gaEventValue: 0,
      gaEventNonInteractionBool: false,
    });
  };

  const renderForm = () => {
    return (
      <>
        <div className="spc-optin-payment-options">
          <div className="spc-optin-title">&nbsp;</div>
          <div className="spc-optin-payment-description">Zero commitment and zero cost today.</div>
          <PaymentForm
            total={0}
            method={BILLING_METHODS.CREDIT_CARD}
            savePaymentCallback={optIn}
            creditCardCopy={'Save your credit card info'}
            isSub={false}
            showSavedPayment={true}
            allowApplepay={allowApplePay}
            allowPaypal={allowPaypal}
            cancel={true}
            isAuthorized={true}
            addingPaymentDetailsCallback={(isAdding: boolean) => setAddingPaymentDetails(isAdding)}
            trackPaymentInformationCallback={trackPaymentInformation}
          />
          {!addingPaymentDetails && <ActivationOptOut />}
        </div>
        <SubscriptionRenewalOptInDisclaimer />
      </>
    );
  };

  const optIn = async () => {
    confirmationRef?.current?.scrollIntoView({ behavior: 'smooth' });
    await setOptInSubscriptionRenewalValue(
      {
        optInStatus: 'yes',
        currentProfileId,
      },
      dispatch,
    );
    setDisplayConfirmation(true);
  };

  const logoUrl = storeState.account.isAuthenticated ? storeState.youdotUrl : storeState.pubUrl;

  return (
    <div className="spc-spi">
      <Header logoUrl={logoUrl} />
      <div ref={confirmationRef} className={`mod-optin ${displayConfirmation ? '' : 'mod-gray'}`}>
        <div className={`spc-optin-container ${displayConfirmation ? 'mod-confirmation' : ''}`}>
          {displayConfirmation ? (
            <div className="mod-optin-confirmation">
              <SubscriptionActivationConfirmation />
            </div>
          ) : (
            <>
              <ActivationCard />
              {renderForm()}
            </>
          )}
        </div>
        <div className="spc-subs-footer-container">
          <Footer showSubDisclaimer={false} forSubscription={true} />
          <div className="spc-error-wrap">
            <ErrorModal />
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { createApplePay, createApplePayRequest } from '../../../utils/braintree';
import withBraintreeLoader from '../../common/withBraintreeLoader';

declare global {
  interface Window {
    ApplePaySession?: any;
  }
}

interface Props {
  braintreeInstance: any;
  savePaymentCallback: (address: Address, payload: any) => void;
  canSubmit: () => boolean;
  klass?: string;
  amount: string;
  disabled: boolean;
}

interface ApplePayAddress {
  addressLines: string[]; // [AddressLine1, AddressLine2]
  administrativeArea: string; // "CA"
  country: string; // "United States"
  countryCode: string; // "us"
  familyName: string; // "LastName"
  givenName: string; // "FirstName"
  locality: string; // "Sunnyvale"
  phoneticFamilyName?: string; // ""
  phoneticGivenName?: string; // ""
  postalCode: string; // "94086"
  subAdministrativeArea?: string; // ""
  subLocality?: string; // ""
}

function ApplePay({
  braintreeInstance,
  savePaymentCallback,
  canSubmit,
  disabled,
  klass = '',
  amount,
}: Props) {
  const [applePayInstance, setApplePayInstance] = useState(false);
  useEffect(() => {
    if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
      return;
    }
    createApplePay(braintreeInstance, setApplePayInstance);
  }, [braintreeInstance]);

  const getAddressFromApplepayAddress = (applePayData: ApplePayAddress): Address => {
    const address: Address = {};
    address.firstName = applePayData.givenName;
    address.lastName = applePayData.familyName;
    address.address = applePayData.addressLines[0];
    if (applePayData.addressLines.length > 1) {
      address.address2 = applePayData.addressLines[1];
    }
    address.city = applePayData.locality;
    address.state = applePayData.administrativeArea;
    address.postalCode = applePayData.postalCode;
    address.country = applePayData.countryCode;
    return address;
  };

  const handlePayment = payload => {
    const address = getAddressFromApplepayAddress(payload.billingContact);
    savePaymentCallback(address, payload);
  };

  const handleClick = () => {
    if (!canSubmit()) return false;
    createApplePayRequest(applePayInstance, handlePayment, amount);
  };

  if (!applePayInstance) return null;
  return (
    <button className={`apple-pay-button ${klass}`} disabled={disabled} onClick={handleClick} />
  );
}

ApplePay.displayName = 'ApplePay';

export default withBraintreeLoader(ApplePay);

import React, { useState, useMemo } from 'react';
import HostedFields from '../../Billing/PaymentMethodBody/HostedFields';
import withBraintree, { BraintreeInjectedProps } from '../../Billing/withBraintree';
import { PaymentCardSection } from './PaymentCardSection';
import { BillingAddressSection } from './BillingAddressSection';
import {
  useBillingAddressForm,
  usePaymentCardFormErrors,
  useSavedBillingAddresses,
  useSavedPayments,
} from './hooks';
import { focusFirstInvalidField, validatePaymentCardForm } from './utils/validation';
import {
  ClickAddAddressEventHandler,
  SavedBillingAddressSection,
} from './SavedBillingAddressSection';
import { ClickEditAddressEventHandler } from './SavedBillingAddressRadioGroup';
import Checkbox from '../../common/Checkbox';
import { basicAddressFieldRequirements } from '../../../utils/getListOfInvalidFields';

export interface SharedProps {
  // event handler for "Edit" button in address radio option
  onClickEditAddress?: ClickEditAddressEventHandler;
}

interface Props extends PaymentFormProps, SharedProps {
  // adding or editing a card?
  action: 'add' | 'edit';

  submitButtonText?: string;
}

/**
 * Generic form for collecting payment card and billing address information.
 *
 * Use to compose GuestPaymentForm, AddPaymentCardForm, and EditPaymentCardForm.
 */
export const _PaymentCardForm = ({
  action,
  initialFormData,
  onSubmitSuccess,
  onClickEditAddress,
  onCancelOperation,
  redirectsToOrderReview,
  paymentMethodToken,

  // injected props from withBraintree
  setupHostedFields,
  returnSubmitHostedFields,
  softResetHostedFields,
}: Props & BraintreeInjectedProps) => {
  const {
    billingAddress,
    updateBillingAddress,
    stateOptions,
    disableCountryField,
    billingAddressFieldInfo,
  } = useBillingAddressForm(initialFormData?.billingAddress); // internal state for controlled billing address fields
  const {
    hasSavedBillingAddress,
    getSavedBillingAddressById,
    defaultSelectedAddress,
    newBillingAddress,
  } = useSavedBillingAddresses(); // saved billing addresses from global state

  // Choosing a default selected address. If this is a payment edit operation, use that address.
  const { getSavedPaymentByToken } = useSavedPayments();
  const savedPayment = getSavedPaymentByToken(paymentMethodToken);
  const initialAddressId = !!savedPayment ? savedPayment.addressId : defaultSelectedAddress;
  const [addressId, setAddressId] = useState<string | null>(initialAddressId);
  const initialAddress = getSavedBillingAddressById(addressId, billingAddress);

  const formData = { paymentCard: undefined, billingAddress: initialAddress };
  const { errors, setErrors, clearError } = usePaymentCardFormErrors(formData, {
    initialBillingAddressFieldInfo: billingAddressFieldInfo,
  });

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmittedWithErrors, setIsSubmittedWithErrors] = useState(false);
  const [isSavePaymentChecked, savePaymentMethod] = useState(false);
  const [showAddressFields, toggleAddressFields] = useState(!hasSavedBillingAddress);

  const initialSubmitButtonText = redirectsToOrderReview ? 'Review order' : 'Continue';
  const [submitButtonText, setSubmitButtonText] = useState(
    paymentMethodToken ? 'Update card' : initialSubmitButtonText,
  );

  const getFormData = async () => {
    const paymentCard = await returnSubmitHostedFields();
    const savedBillingAddress = showAddressFields
      ? billingAddress // get billing address from controlled form fields
      : getSavedBillingAddressById(addressId); // get saved billing address from global state

    return {
      paymentCard,
      billingAddress: !savedBillingAddress ? newBillingAddress() : savedBillingAddress,
      saveCreditCardOptIn: isSavePaymentChecked,
      token: paymentMethodToken,
    };
  };

  useMemo(() => {
    const savedBillingAddress = showAddressFields
      ? billingAddress // get billing address from controlled form fields
      : getSavedBillingAddressById(addressId); // get saved billing address from global state
    const data = {
      billingAddress: !savedBillingAddress ? newBillingAddress() : savedBillingAddress,
    };
    const addressErrors = validatePaymentCardForm(data, {
      billingAddressFieldInfo,
      showAddressFields,
    });
    Object.keys(basicAddressFieldRequirements).forEach(field => {
      if (!errors.includes(field) && addressErrors.includes(field)) {
        errors.push(field);
      } else if (errors.includes(field) && !addressErrors.includes(field)) {
        errors.splice(errors.indexOf(field), 1);
      }
    });

    setErrors(errors);
  }, [showAddressFields, errors]);

  return (
    <form
      noValidate
      onSubmit={async event => {
        event.preventDefault();
        setIsProcessing(true);
        const data = await getFormData();
        const errors = validatePaymentCardForm(data, {
          billingAddressFieldInfo,
          showAddressFields,
        });
        if (errors.length) {
          setErrors(errors);
          setIsSubmittedWithErrors(true);
          setIsProcessing(false);
          focusFirstInvalidField(errors);
        } else {
          // pass form data to onSubmitSuccess callback
          onSubmitSuccess(event, { data });

          // Reset global store state. The hosted fields will not be repopulated if we return to this component.
          softResetHostedFields();
        }
      }}
      data-jest-id="payment-card-form"
    >
      <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
        <PaymentCardSection
          paymentCardFields={<HostedFields setupHostedFields={setupHostedFields} />}
          {...{ action }}
        />
        {!paymentMethodToken && (
          <>
            <Checkbox
              dataStorId="clinic-save-payment-opt-in-checkbox"
              checked={isSavePaymentChecked}
              handleCheck={() => {
                const switchToChecked = !isSavePaymentChecked;
                savePaymentMethod(switchToChecked);
                if (!redirectsToOrderReview) {
                  setSubmitButtonText(switchToChecked ? 'Save Card' : 'Use Card');
                }
              }}
              text="Save as default payment method"
              ariaLabel="Save as default payment method"
            />
          </>
        )}
      </div>
      <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
        {showAddressFields ? (
          <BillingAddressSection
            action={action}
            inputValues={billingAddress}
            inputErrors={isSubmittedWithErrors ? errors : []} // display errors only after form submission
            stateOptions={stateOptions}
            fieldInfo={billingAddressFieldInfo}
            disableCountryField={disableCountryField}
            onChangeInput={event => {
              const fieldName = event.target.name;
              const fieldValue = event.target.value;
              const isRequiredField = Object.keys(basicAddressFieldRequirements).includes(
                fieldName,
              );

              if (errors.includes(fieldName) && fieldValue) {
                // field now has a value - clear any errors
                clearError(fieldName);
              } else if (!errors.includes(fieldName) && !fieldValue && isRequiredField) {
                // field is empty - include it along with other errors if it is required
                errors.push(fieldName);
                setErrors(errors);
              }

              updateBillingAddress({
                [fieldName]: fieldValue,
              });
            }}
          />
        ) : (
          <SavedBillingAddressSection
            selectedAddressId={addressId}
            showError={isSubmittedWithErrors && errors.includes('address')} // display errors only after form submission
            onChange={(_event, { addressId }) => {
              if (errors.includes('address')) {
                clearError('address');
              }
              setAddressId(addressId);
            }}
            onClickAddAddress={() => {
              toggleAddressFields(!showAddressFields);
            }}
            {...{
              onClickEditAddress,
            }}
          />
        )}
      </div>
      <div className="card-button-wrapper">
        <button
          type="submit"
          className={`spc-labs-submit-button card-button${
            isProcessing ? ' spc-next-button mod-disabled' : ''
          }`}
          data-stor-id="clinic-submit-cc-button"
          disabled={isProcessing || errors.length > 0}
        >
          {submitButtonText}
        </button>
      </div>

      {!isProcessing && (
        <>
          <input
            type="button"
            value="Back"
            className="sd-button-anchor spc-labs-back-button"
            onClick={() => {
              if (hasSavedBillingAddress && showAddressFields) {
                toggleAddressFields(false);
                setIsProcessing(false);
                setIsSubmittedWithErrors(false);
              } else {
                onCancelOperation();
                softResetHostedFields();
              }
            }}
          />
        </>
      )}
    </form>
  );
};

export const PaymentCardForm = withBraintree(_PaymentCardForm);

import React from 'react';
import { URLS } from '../../regions';

export default function SubscriptionRenewalOptInDisclaimer() {
  return (
    <>
      <div className="spc-optin-disclaimer-renewal">
        <p>
          If you do not have a payment method, please contact Customer Care. By adding a payment
          method, you agree to the&nbsp;
          <a
            className="spc-optin-disclaimer-renewal-tos"
            href={URLS.SUBSCRIPTION_TERMS_OF_SERVICE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Membership Terms
          </a>
          &nbsp;and authorize 23andMe to charge your payment method annually each time your
          membership renews. We&apos;ll send a reminder before your renewal date. Cancel at any time
          in Account Settings.&nbsp;
          <a
            className="spc-optin-disclaimer-renewal-tos"
            href="https://customercare.23andme.com/hc/en-us/articles/360042867394-23andMe-"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          .
        </p>
      </div>
    </>
  );
}

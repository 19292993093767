import React, { useEffect, useState, useCallback } from 'react';
import { scroller } from '../../utils/scroller';
import { CC } from '../../constants';
import { REGION } from '../../regions';
import {
  createHostedFields,
  createPaypal,
  createVenmo,
  createDataCollector,
} from '../../utils/braintree';
import { useStoreContext } from '../../utils/storeUtils';
import { setupBraintree } from '../../utils/braintree';
import { setGlobalBraintreeInstance, updateGlobalStore } from '../../actions/actions';
import * as braintree from 'braintree-web';
import { PayPal } from 'braintree-web';
import { Venmo } from 'braintree-web';

export interface BraintreeInjectedProps {
  handleSubmitHostedFields: () => void;
  returnSubmitHostedFields: () => Promise<any>;
  handleSubmitPaypal: (any) => void;
  handleSubmitVenmo: (any) => void;
  setupHostedFields: () => void;
  softResetHostedFields: () => void;
}

const getInputFields = () => ({
  showError: false,
  isEmpty: true,
  isValid: false,
});

interface ValidatedFieldsRef {
  current: {
    count: number;
    fields: PaymentInputFields;
  };
}

export default function withBraintree<T>(WrappedComponent: React.ComponentType<T>) {
  const WithBraintree = (props: Omit<T, keyof BraintreeInjectedProps>) => {
    const { storeState, dispatch } = useStoreContext();
    const { braintreeInstance, settingUpHostedFields, inputFields } = storeState;
    const total = parseFloat(storeState.cart?.totals?.total);

    const [hostedFieldsInstance, setHostedFieldsInstance]: [
      braintree.HostedFields,
      React.Dispatch<React.SetStateAction<braintree.HostedFields>>,
    ] = useState(null);

    const [paypalInstance, setPaypalInstance] = useState<PayPal>(null);
    const [venmoInstance, setVenmoInstance] = useState<Venmo>(null);
    const [isTeardownReady, setIsTeardownReady] = useState(false);

    const validatedFieldsRef: ValidatedFieldsRef = React.useRef();

    const setupPaymentGateway = braintreeInstance => {
      createPaypal(braintreeInstance, setPaypalInstance);
      createVenmo(braintreeInstance, setVenmoInstance);
      createDataCollector(braintreeInstance);
    };

    const setupValidatedFieldsRef = () => {
      const fields = {
        number: getInputFields(),
        cvv: getInputFields(),
        expirationDate: getInputFields(),
      };

      validatedFieldsRef.current = validatedFieldsRef.current || { count: 0, fields };
      return;
    };

    useEffect(() => {
      if (braintreeInstance) return;
      setupBraintree(braintreeInstance => {
        dispatch(setGlobalBraintreeInstance(braintreeInstance));
      });
    }, []);

    useEffect(() => {
      // Use a ref because event listener will get stale state... this is duplicated in storeState :(
      setupValidatedFieldsRef();
      setInputFields(validatedFieldsRef.current.fields);
    }, []);

    useEffect(() => {
      if (!braintreeInstance) return;
      setupPaymentGateway(braintreeInstance);
    }, [braintreeInstance]);

    //helpers
    const extractHostedFieldInputStatus = (field, fields) => {
      const inputFields = validatedFieldsRef.current.fields;
      const newInputFields = Object.assign({}, inputFields);
      CC.fields.forEach(key => {
        newInputFields[key].isEmpty = fields[key].isEmpty;
        newInputFields[key].isValid = fields[key].isValid;
      });
      newInputFields[field].showError = false;
      setInputFields(newInputFields);
    };

    const setInputFields = (newFields: PaymentInputFields) => {
      if (validatedFieldsRef.current) validatedFieldsRef.current.fields = newFields;
      dispatch(updateGlobalStore({ inputFields: newFields }));
    };

    const setAreHostedFieldsPopulated = () => {
      const areHostedFieldsPopulated = validatedFieldsRef.current.count === CC.fields.length;
      dispatch(updateGlobalStore({ areHostedFieldsPopulated }));
    };

    const softResetHostedFields = () => {
      dispatch(updateGlobalStore({ areHostedFieldsPopulated: false }));
    };

    //setup functions
    const destroyHostedFields = callback => {
      const prevIsTeardownReady = isTeardownReady;

      setIsTeardownReady(false);
      if (prevIsTeardownReady && hostedFieldsInstance !== null) {
        hostedFieldsInstance.teardown(() => callback());
      } else {
        callback();
      }
    };

    const configureHostedFields = useCallback(fieldInstance => {
      dispatch(updateGlobalStore({ settingUpHostedFields: false }));

      if (fieldInstance === null) {
        setIsTeardownReady(false);
        return;
      }

      setHostedFieldsInstance(fieldInstance);
      setIsTeardownReady(true);
      configureHostedFieldListeners(fieldInstance);
      fieldInstance.focus('number');
    }, []);

    const setupHostedFields = useCallback(() => {
      if (settingUpHostedFields || !braintreeInstance) {
        return;
      }
      dispatch(updateGlobalStore({ settingUpHostedFields: true }));
      // clear out error messages for freshly built hosted fields form
      const fields = Object.assign(inputFields);
      CC.fields.forEach(key => {
        fields[key].showError = false;
      });
      setInputFields(fields);

      destroyHostedFields(() => {
        createHostedFields(braintreeInstance, configureHostedFields);
      });
    }, [braintreeInstance]);

    const configureHostedFieldListeners = (fieldsInstance: braintree.HostedFields) => {
      fieldsInstance.on('notEmpty', e => {
        extractHostedFieldInputStatus(e.emittedBy, e.fields);
        const field = e.fields[e.emittedBy];
        const label = field.container.parentNode.parentNode.querySelector('div.label');
        if (label) label.className = 'label mod-not-empty';
        validatedFieldsRef.current.count++;
        setAreHostedFieldsPopulated();
      });

      fieldsInstance.on('validityChange', e => {
        extractHostedFieldInputStatus(e.emittedBy, e.fields);
      });

      fieldsInstance.on('empty', e => {
        extractHostedFieldInputStatus(e.emittedBy, e.fields);
        const field = e.fields[e.emittedBy];
        const label = field.container.parentNode.parentNode.querySelector('div.label');
        if (label) label.className = 'label mod-empty';
        validatedFieldsRef.current.count--;
        setAreHostedFieldsPopulated();
      });
    };

    //handler functions
    const handleSubmitPaypal = (callbackSuccess, useCheckout = true) => {
      const tokenizationOptions: { flow: string; currency?: string; amount?: number } = {
        flow: 'vault',
      };
      // Use 'checkout' flow for US region (pass total amount; no billing agreement)
      if (REGION === 'enus' && useCheckout) {
        tokenizationOptions.flow = 'checkout';
        tokenizationOptions.currency = 'USD';
        tokenizationOptions.amount = total;
      }

      const tokenizePaypal = instance => {
        instance.tokenize(tokenizationOptions, (err, payload) => {
          if (err) {
            return;
          }
          callbackSuccess(payload);
        });
      };

      if (paypalInstance) {
        tokenizePaypal(paypalInstance);
      } else {
        createPaypal(braintreeInstance, instance => {
          setPaypalInstance(instance);
          tokenizePaypal(instance);
        });
      }
    };

    const handleSubmitVenmo = callbackSuccess => {
      const tokenizationOptions: {
        flow: string;
        currency?: string;
        amount?: number;
        processOptions?: object;
      } = {
        flow: 'vault',
        processOptions: {
          submitForSettlement: true,
        },
      };

      tokenizationOptions.flow = 'checkout';
      tokenizationOptions.currency = 'USD';
      tokenizationOptions.amount = total;

      const tokenizeVenmo = instance => {
        instance.tokenize(tokenizationOptions, (err, payload) => {
          if (err) {
            return;
          }
          callbackSuccess(payload);
        });
      };

      if (venmoInstance) {
        if (venmoInstance.isBrowserSupported()) {
          tokenizeVenmo(venmoInstance);
        }
      } else {
        createVenmo(braintreeInstance, instance => {
          if (instance.isBrowserSupported()) {
            setVenmoInstance(instance);
            tokenizeVenmo(instance);
          }
        });
      }
    };

    const foundHostedFieldIssues = errors => {
      if (!errors) {
        return false;
      }
      const inputFields = validatedFieldsRef.current.fields;
      const newFields = Object.assign({}, inputFields);
      if (errors.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
        if (errors.details && errors.details.invalidFieldKeys) {
          errors.details.invalidFieldKeys.forEach(key => {
            newFields[key].showError = true;
          });
        }
      } else {
        CC.fields.forEach(key => {
          newFields[key].showError = true;
        });
      }
      setInputFields(newFields);
      scroller(CC.number, 200, -150);
      return true;
    };

    const handleSubmitHostedFields = useCallback(
      (callbackSuccess, callbackError = null) => {
        hostedFieldsInstance.tokenize((err, payload) => {
          if (foundHostedFieldIssues(err)) {
            return callbackError && callbackError(err);
          }
          callbackSuccess({
            cardType: payload.details ? payload.details.cardType : '',
            lastFour: payload.details ? payload.details.lastFour : '',
            nonce: payload.nonce,
          });
        });
      },
      [hostedFieldsInstance],
    );

    const returnSubmitHostedFields = useCallback(
      (callbackError = null) => {
        const data = hostedFieldsInstance
          .tokenize()
          .then(payload => ({
            cardType: payload.details ? payload.details.cardType : '',
            lastFour: payload.details ? payload.details.lastFour : '',
            nonce: payload.nonce,
          }))
          .catch(err => {
            foundHostedFieldIssues(err);
            callbackError && callbackError(err);
          });

        return data;
      },
      [hostedFieldsInstance],
    );

    return (
      <WrappedComponent
        handleSubmitHostedFields={handleSubmitHostedFields}
        returnSubmitHostedFields={returnSubmitHostedFields}
        softResetHostedFields={softResetHostedFields}
        handleSubmitPaypal={handleSubmitPaypal}
        handleSubmitVenmo={handleSubmitVenmo}
        setupHostedFields={setupHostedFields}
        paypalInstance={paypalInstance}
        venmoInstance={venmoInstance}
        {...(props as T)}
      />
    );
  };
  return WithBraintree;
}

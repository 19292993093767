import React, { Component } from 'react';

import AdsTable from './AdsTable';

class AllNecessary extends Component {
  constructor(props) {
    super(props);

    this.ads = [
      {
        id: '23andme',
        company: '23andme',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'aws',
        company: 'Amazon Web Services',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'cloudflare',
        company: 'Cloudflare',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'greenhouse',
        company: 'Greenhouse',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'newrelic',
        company: 'New Relic',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'zendesk',
        company: 'Zendesk',
        domain: '23andme.com, customercare.23andme.com',
      },
    ];
  }

  render() {
    return <AdsTable ads={this.ads} />;
  }
}

export default AllNecessary;

import { useStoreContext } from '../../../../utils/storeUtils';
import { updateGlobalStore } from '../../../../actions/actions';
import { BILLING_ADDRESS, BILLING_METHODS } from '../../../../constants';

export const useSavedPayments = () => {
  const { storeState, dispatch } = useStoreContext();
  const savedPayments = storeState.account.paymentMethods.filter(payment => payment.isCreditCard);
  const hasSavedPayment = savedPayments.length > 0;
  const defaultSavedPayment = hasSavedPayment ? savedPayments[0] : undefined; // default payment is always first
  const getSavedPaymentByToken = (token: string) =>
    savedPayments.find(savedPayment => savedPayment.token === token);

  const buildSavedPaymentPayload = (nonce, address) => {
    const hasAddressId = address.id !== undefined;
    const addressPayload = hasAddressId
      ? { billingAddressId: address.id }
      : { billingAddress: { ...address } };
    return {
      paymentMethodNonce: nonce,
      makeDefault: true,
      ...addressPayload,
    };
  };

  const setStateForSavedPayment = (paymentCard, token, account) => {
    dispatch(
      updateGlobalStore({
        account: account,
        billing: {
          ...storeState.billing,
          ...paymentCard,
          nonce: '',
          token: token,
          method: BILLING_METHODS.SAVED_PAYMENT,
          customAddress: {},
          addressSource: BILLING_ADDRESS.SAVED,
        },
      }),
    );
  };

  const filterForPaymentCard = savedPayment => ({
    lastFour: savedPayment.lastFour,
    cardType: savedPayment.cardType,
    method: savedPayment.method,
  });

  return {
    savedPayments,
    defaultSavedPayment,
    hasSavedPayment,
    getSavedPaymentByToken,
    setStateForSavedPayment,
    buildSavedPaymentPayload,
    filterForPaymentCard,
  };
};

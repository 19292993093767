/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import handleKeyDown from '../../utils/handleKeyDown';
import classNames from 'classnames';

interface Props {
  text?: string;
  checked: boolean;
  name?: string;
  id?: string;
  handleCheck: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  ariaLabel: string;
  dataStorId?: string;
  hasError?: boolean;
  ariaDisabled?: boolean;
  darkBorder?: boolean;
}

export default function Checkbox({
  text,
  checked,
  name,
  id,
  handleCheck,
  dataStorId,
  ariaLabel,
  hasError,
  darkBorder = false,
}: Props) {
  const checkBoxClassnames = classNames(`spc-checkbox`, {
    error: hasError,
    'spc-checkbox-dark': darkBorder,
  });
  return (
    <div className="spc-checkbox-wrap">
      <div className={checkBoxClassnames} data-jest-id={`${dataStorId}-checkbox-container`}>
        <input
          type="checkbox"
          readOnly={true}
          name={name}
          id={id}
          data-testid="checkbox"
          onChange={handleCheck}
          checked={checked}
          data-stor-id={dataStorId}
          data-jest-id={dataStorId}
          tabIndex={0}
          aria-label={ariaLabel}
          onKeyPress={handleKeyDown}
          aria-checked={checked}
        />
        <span aria-hidden={true}>&#x2713;</span>
      </div>
      <label
        htmlFor={name}
        aria-hidden={true}
        data-stor-id={`${dataStorId}-label`}
        data-jest-id={`${dataStorId}-label`}
      >
        {text}
      </label>
    </div>
  );
}

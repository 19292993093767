import styled, { keyframes } from 'styled-components';
import { media, fadeIn } from './common';

const contentIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -100px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
const Div = styled.div`
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  animation: ${fadeIn} 0.5s;
`;
const Content = styled.div`
  background-color: #fff;
  max-width: 909px;
  margin: 8% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 83%;
  animation: ${contentIn} 0.5s;
  ${media.nonMobile`
    padding: 30px 60px;
  `};
`;
const Span = styled.span`
  float: right;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  font-size: 27px;
  outline: none;
  background: transparent !important;
  ${media.nonMobile`
    margin-right: -35px;
    margin-top: -20px;
  `};
`;
export { Div, Content, Span };

import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import withBraintreeLoader from '../common/withBraintreeLoader';

interface Props {
  handleClick: (any) => void;
  label?: string;
}
function PayPal(props: Props) {
  return (
    <PayPalScriptProvider>
      <PayPalButtons
        style={{ shape: 'pill', label: props.label, height: 49 }}
        fundingSource="paypal"
        onClick={props.handleClick}
        // using disabled=true to prevent paypalbuttons api and use our own logic
        disabled={true}
      />
    </PayPalScriptProvider>
  );
}
PayPal.displayName = 'PayPal';

export default withBraintreeLoader(PayPal);

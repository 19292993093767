import React from 'react';
import { ELEMENTS } from '../../../../constants';
import { URLS } from '../../../../regions';

const TermsOfServiceEnEu = () => (
  <div id={ELEMENTS.accept} className="spc-summary-tos-eu">
    I accept the&nbsp;
    <a
      data-test-id="checkout-accept-tos-link"
      className="spc-summary-accept-a"
      href={URLS.TERMS_OF_SERVICE}
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Service
    </a>
    &nbsp;and understand the contents of the kit and 23andMe Personal Genetic Service are provided
    only in English.
  </div>
);

export default TermsOfServiceEnEu;

import React from 'react';
import cookie from 'react-cookies';
import Checkbox from '../common/Checkbox';
import { COUNTRY_TO_REGION_MAP } from '../../regions';
import { GDPR_COOKIE, GDPR_COOKIE_CHOICES } from '../../constants';

export const CaptureEmailDisclaimer = ({
  handleCheckBox,
  region,
  okayToSendEmail,
}: {
  handleCheckBox: (e: boolean) => void;
  region: string;
  okayToSendEmail: boolean;
}) => {
  const handleCheck = () => {
    handleCheckBox(!okayToSendEmail);
  };
  const isOkayToTrackUser = () => {
    if (region === COUNTRY_TO_REGION_MAP.GB) {
      const gdprCookie = cookie.load(GDPR_COOKIE);
      return GDPR_COOKIE_CHOICES.includes(gdprCookie);
    }
    return true;
  };
  const isUS = region === COUNTRY_TO_REGION_MAP.US;
  const disclaimerText =
    '*By entering your email address, you consent to the transfer and validation of your email address to 23andMe in order to receive exclusive offers and news. You can unsubscribe at any time.';
  const gdprCheckbox = isOkayToTrackUser() ? (
    <Checkbox
      checked={okayToSendEmail}
      handleCheck={handleCheck}
      text={disclaimerText}
      ariaLabel="I accept the Terms of Service"
    />
  ) : (
    ''
  );
  return <div className="spc-email-disclaimer">{isUS ? disclaimerText : gdprCheckbox}</div>;
};

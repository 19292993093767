/* eslint-disable jsx-a11y/no-onchange */
import * as React from 'react';

interface Props {
  name: string;
  focusLabel: string;
  placeholder: string;
  options: any[];
  selectedOption: string;
  onChange?: (e: any) => void;
  id?: string;
  showError?: boolean;
  isRequired?: boolean;
  disableCountrySelector?: boolean;
}

const SelectInput = ({
  name,
  focusLabel,
  placeholder,
  options,
  selectedOption = '',
  onChange = () => {},
  id = '',
  showError = false,
  isRequired = false,
  disableCountrySelector = false,
}: Props) => {
  const selectId: { id?: string } = {};
  if (id !== '') {
    selectId.id = id;
  }

  let showErrClass;
  let placeholdrTxt;
  if (showError) {
    showErrClass = ' mod-error';
    placeholdrTxt = `Invalid ${name}`;
  } else {
    showErrClass = '';
    placeholdrTxt = placeholder;
  }

  const disabled = !!disableCountrySelector;

  return (
    <div
      className={`spc-select spc-input-container${showErrClass}`}
      data-jest-id="spc-select-container"
    >
      <label htmlFor={selectId.id} className="spc-input-label">
        {focusLabel}
      </label>
      <select
        {...selectId}
        name={name}
        value={selectedOption}
        onChange={onChange}
        className="spc-select-input"
        data-stor-id="spc-select-state-field"
        data-jest-id={`spc-${name}-input-field`}
        disabled={disabled}
        required={isRequired}
        aria-required={isRequired}
      >
        <option value="" disabled>
          {placeholdrTxt}
        </option>
        {options.map(opt => (
          <option key={opt.text} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;

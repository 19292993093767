import { useEffect, useState } from 'react';
import { REGION } from '../../../../regions';
import { defaultFieldInfo, parseIsoCountryCode } from '../../../../utils/i18nFields';
import { fetchLabels, fetchStates } from '../../../../utils/storeApi';
import { useStoreContext } from '../../../../utils/storeUtils';

/**
 * Manages state for a billing address form
 *
 * @param initialBillingAddress - initial values for billing address form inputs
 * @returns - billing address form input values/props, and a setter to update billing address
 *
 */
export const useBillingAddressForm = (initialBillingAddress: Address) => {
  /**
   * State
   */

  const {
    storeState: { billingStates: initialStateOptions },
  } = useStoreContext();

  const defaultInitialBillingAddress = {
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    country: parseIsoCountryCode(REGION),
    city: '',
    state: '',
    postalCode: '',
  };

  const initialStateForBillingAddress =
    initialBillingAddress && Object.keys(initialBillingAddress).length > 0 // if initialBillingAddress defined and not empty
      ? initialBillingAddress
      : defaultInitialBillingAddress;

  // billing address field values; used for controlled form fields
  const [billingAddress, setBillingAddress] = useState<Address>(initialStateForBillingAddress);

  // country field disabled when user selects new country and i18n fields are fetched
  const [disableCountryField, setDisableCountryField] = useState(false);

  // current state options based on selected country
  const [stateOptions, setStateOptions] = useState<GeoState[]>(initialStateOptions);

  // i18n info for billing address fields
  const [billingAddressFieldInfo, setBillingAddressFieldInfo] = useState<FieldInfo>(
    defaultFieldInfo[REGION],
  );

  /**
   * Side Effects
   */

  /**
   * When country field changes, fetches state option values and i18n data for billing address fields
   * and saves values in React state
   */

  useEffect(() => {
    const updateI18nFields = async (country: string) => {
      setDisableCountryField(true);

      // kick off both fetches
      const fetchStatesPromise = fetchStates(country);
      const fetchLabelsPromise = fetchLabels(country);

      // wait for both promises to resolve
      const states: GeoState[] = await fetchStatesPromise;
      const fieldInfo: FieldInfo = await fetchLabelsPromise;

      setStateOptions(states);
      setBillingAddressFieldInfo(fieldInfo);
      setDisableCountryField(false);
    };

    updateI18nFields(billingAddress.country);
  }, [billingAddress.country]);

  /**
   * Utils
   */

  /**
   * Merges new field values into current billingAddress state object.
   *
   * @param newFields - new values for billing address fields
   */
  const updateBillingAddress = (newFields: Address) => {
    if (newFields.country) {
      newFields = { ...newFields, state: '' }; // reset state field if country changes
    }

    setBillingAddress(billingAddress => ({
      ...billingAddress,
      ...newFields,
    }));
  };

  return {
    billingAddress,
    updateBillingAddress,
    stateOptions,
    disableCountryField,
    billingAddressFieldInfo,
  };
};

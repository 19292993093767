declare global {
  interface Window {
    optimizely: any;
    optimizelyEdge: any;
    heap: any;
  }
}
type ExpData = { [expName: string]: string };
type CohortData = ExpData[];

const getObjectValues = (obj): Array<any> => {
  const values = [];
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      values.push(obj[prop]);
    }
  }
  return values;
};

const optimizely = {
  isLoaded: (): boolean => !!(window.optimizely && window.optimizely.get),
  getCohortData: (): CohortData => {
    if (!optimizely.isLoaded()) {
      return [];
    }

    const activeCampaigns = getObjectValues(
      window.optimizely.get('state').getCampaignStates() || {},
    ).filter((campaign: any) => campaign.isActive);

    const cohortData = activeCampaigns.map(
      (campaign: any): ExpData => {
        const key =
          campaign.experiment && campaign.experiment.name ? campaign.experiment.name : 'none';
        const value =
          campaign.variation && campaign.variation.name ? campaign.variation.name : 'none';

        return {
          [key]: value,
        };
      },
    );

    return cohortData;
  },
};
const optimizelyEdge = {
  isLoaded: (): boolean => !!(window.optimizelyEdge && window.optimizelyEdge.get),
  getCohortData: (): CohortData => {
    if (!optimizelyEdge.isLoaded()) {
      return [];
    }

    const activeCampaigns = getObjectValues(
      window.optimizelyEdge.get('state').getActiveExperiments() || {},
    );

    const cohortData = activeCampaigns.map(
      (campaign: any): ExpData => {
        const key = campaign.name ? campaign.name : 'none';
        const value =
          campaign.variation && campaign.variation.name ? campaign.variation.name : 'none';

        return {
          [key]: value,
        };
      },
    );

    return cohortData;
  },
};
const getCohortData = (): CohortData => {
  if (optimizely.isLoaded()) {
    return optimizely.getCohortData();
  }
  if (optimizelyEdge.isLoaded()) {
    return optimizelyEdge.getCohortData();
  }
  return [];
};

const trackCohortData = () => {
  if (!window.heap) return;
  const experiments = getCohortData();
  for (const exp of experiments) {
    window.heap.addUserProperties(exp);
  }
};

const identify = (email: string) => {
  if (!email) return;
  window.heap && window.heap.identify(email);
};

export { trackCohortData, identify };

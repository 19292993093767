import { errorActions } from './errorActions';
import { newPostShipMethod } from '../utils/storeApi';
import { updateGlobalStore } from './actions';
import { scroller } from '../utils/scroller';
import { ELEMENTS, SCROLL_TIME } from '../constants';
import { handleFocusTransition } from '../utils/scroller';

export const setShippingMethod = callback => async (state: StoreState, dispatch: Dispatch) => {
  dispatch({ type: 'PROCESSING' });
  let res: any;
  try {
    res = await newPostShipMethod({ shipMethod: state.shipMethod });
    if (res.error) {
      if (res.rejectCart) {
        dispatch(errorActions['EMPTY_CART_ERROR']);
      } else {
        dispatch(errorActions['SHIPPING_ERROR']);
      }
      return;
    }
  } catch {
    dispatch(errorActions['SHIPPING_ERROR']);
    return;
  }

  dispatch({ type: 'SET_SHIPPING_METHOD', data: res });
  callback && callback();
};

export const goNextStep = (query, offBy, last = false) => async (
  state: StoreState,
  dispatch: Dispatch,
) => {
  // TODO replace usage of this with goToStep , delete scroller code etc
  const start = state.step;
  const end = state.step + 1;
  dispatch(updateGlobalStore({ step: end - 0.1 }));
  scroller(query, SCROLL_TIME, offBy);
  window.setTimeout(() => {
    dispatch(updateGlobalStore({ step: end }));
    if (last) {
      scroller(query, SCROLL_TIME, offBy);
    }
    handleFocusTransition(start, end);
  }, SCROLL_TIME);
};

export const goToStep = (stepNum: number, element?: string) => async (
  state: StoreState,
  dispatch: Dispatch,
) => {
  dispatch(updateGlobalStore({ step: stepNum }));
  if (element) {
    const el = document.getElementById(element);
    el.scrollIntoView({ behavior: 'smooth' });
  } else {
    handleFocusTransition(state.step, stepNum);
  }
};

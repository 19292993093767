import styled from 'styled-components';
import { media } from './common';

const linkColor = '#fff';
const textColor = '#fff';
const Div = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(192, 193, 194, 0.97);
  color: ${textColor};
  -webkit-font-smoothing: antialiased;
  font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Roboto, sans-serif;
  font-size: 14px;
  ${media.nonMobile`
    flex-direction: row;
    justify-content: center;
  `};
`;
const Paragraph = styled.p`
  max-width: 230px;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 21px;
  color: #fff;
  font-size: 14px;
  ${media.desk`
    max-width: 520px;
    font-size: 18px;
  `};
`;
const Link = styled.a`
  color: ${linkColor};
  text-decoration: underline;
  cursor: pointer;
  color: ${linkColor};
  font-size: 14px;
  ${media.desk`
    font-size: 18px;
  `};
  &:hover {
    color: ${linkColor};
  }
`;

const ButtonAccept = styled.button`
  font-weight: 400;
  padding: 8px 65px;
  color: ${textColor};
  text-transform: none;
  background: transparent !important;
  border: 2px solid white;
  border-radius: 47px;
  font-size: 18px;
  margin: 15px 0;
  line-height: 1;
  @extend %font;
  cursor: pointer;
  &:hover,
  &:active  {
    background-color: transparent:
    font-weight: 400;
  }
  ${media.nonMobile`
    margin: 0 30px;
    padding: 6px 35px;
  `};
`;
export { Div, Paragraph, Link, ButtonAccept };

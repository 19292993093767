import React from 'react';
import { BILLING_METHODS, BILLING_ADDRESS } from '../../constants';
import { useStoreContext } from '../../utils/storeUtils';

interface Props {
  addressSource: number;
  isCustomAddressValidated: boolean;
  isEditHostedFields: boolean;
  isPayPalReady: boolean;
  method: string;
  isExpiredCard: boolean;
}

export const CONTINUE_TO_ORDER_REVIEW = 'continue to order review';
export const CONTINUE_TO_PAYPAL = 'continue to paypal';

export default function BillingSubmitButton(props: Props) {
  const { storeState } = useStoreContext();
  const label = {
    [BILLING_METHODS.CREDIT_CARD]: CONTINUE_TO_ORDER_REVIEW,
    [BILLING_METHODS.PAYPAL]: CONTINUE_TO_PAYPAL,
    [BILLING_METHODS.SAVED_PAYMENT]: CONTINUE_TO_ORDER_REVIEW,
    [BILLING_METHODS.VENMO]: CONTINUE_TO_ORDER_REVIEW,
  };
  const { areHostedFieldsPopulated } = storeState;

  const {
    addressSource,
    isCustomAddressValidated,
    isEditHostedFields,
    isPayPalReady,
    method,
    isExpiredCard,
  } = props;

  const isReadyAsCreditCard =
    method === BILLING_METHODS.CREDIT_CARD &&
    areHostedFieldsPopulated &&
    (addressSource === BILLING_ADDRESS.SHIPPING || isCustomAddressValidated);
  const isReadyAsPaypal = method === BILLING_METHODS.PAYPAL && isPayPalReady;
  const isReadyAsSavedPayment =
    method === BILLING_METHODS.SAVED_PAYMENT &&
    (!isExpiredCard || isEditHostedFields) &&
    (!isEditHostedFields || areHostedFieldsPopulated) &&
    (addressSource === BILLING_ADDRESS.SAVED || isCustomAddressValidated);
  const isReadyAsVenmo = method === BILLING_METHODS.VENMO;

  const isGreen = isReadyAsCreditCard || isReadyAsPaypal || isReadyAsSavedPayment || isReadyAsVenmo;

  const submitButtonClass = `spc-next-button${isGreen ? ' mod-ok' : ''}`;

  if (method === BILLING_METHODS.APPLEPAY) {
    return null;
  }

  return (
    <div className="spc-next spc-billing-next spc-next-no-border">
      <button
        id="submit-order-button"
        className={`${submitButtonClass}`}
        data-stor-id="spc-billing-continue-button"
        data-track-click="checkout_billing_button"
        data-payment-method={method}
        type="submit"
      >
        {label[method]}
      </button>
    </div>
  );
}

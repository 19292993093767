/* globals window */
import React from 'react';
import Modal from './withModalv2';
import { ELEMENTS, STEP, BILLING_METHODS } from '../../constants';
import { URLS } from '../../regions';
import ApplePayErrorPopup from './ApplePayErrorPopup';
import PayPalWrongCountryPopup from './PayPalWrongCountryPopup';
import { useStore } from '../../utils/storeUtils';

interface Props {
  errStep?: (a: any, b: any, c: any) => void;
}

const goToCart = () => {
  window.location.href = URLS.CART;
};

function ErrorPopup(props: Props) {
  const [storeState, setStoreState] = useStore();
  const { error } = storeState;
  const {
    backTo = 'address',
    body,
    button,
    countryCode,
    method,
    step = STEP.ADDRESS,
    title,
    startOver,
    ctaUrl,
  }: ErrorType = error;
  const { errStep } = props;
  const handleClick = () => {
    if (startOver) {
      return goToCart();
    }
    if (ctaUrl) {
      window.location.assign(ctaUrl);
      return;
    }
    const offset = step === STEP.BILLING ? -280 : -200;
    setStoreState({ modalOpen: '' });
    errStep && errStep(`#${ELEMENTS[backTo]}`, step, offset);
  };

  if (method === BILLING_METHODS.PAYPAL) {
    return <PayPalWrongCountryPopup handleClose={handleClick} {...{ body, countryCode, title }} />;
  }
  if (method === BILLING_METHODS.APPLEPAY) {
    return <ApplePayErrorPopup goToCart={goToCart} {...{ button, title }} />;
  }
  return (
    <Modal modalName="ERROR">
      <div role="alert">
        <h2 className="spc-verification-h2 mod-font" id="js-error-modal" tabIndex={-1}>
          {title}
        </h2>
        <p className="spc-verification-text">{body}</p>
        <div className="spc-verification-edit">
          <button
            onClick={e => {
              e.stopPropagation();
              handleClick();
            }}
            type="button"
            className="spc-verification-div-button"
          >
            {button}
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default ErrorPopup;

import toCamel from './toCamel';

const getContext = () => {
  const contextElement = document.getElementById('context_data');
  let context;

  if (contextElement) {
    context = toCamel(JSON.parse(contextElement.textContent));
  }
  return context;
};

export default getContext;

import React from 'react';
import { useStore } from '../../utils/storeUtils';
import { PremiumSubscriptionActivationConfirmation } from './PremiumSubscriptionActivationConfirmation';
import { TotalHealthSubscriptionActivationConfirmation } from './TotalHealthSubscriptionActivation';

const SubscriptionActivationConfirmation = () => {
  const [{ hasTotalHealth }] = useStore();

  return hasTotalHealth ? (
    <TotalHealthSubscriptionActivationConfirmation />
  ) : (
    <PremiumSubscriptionActivationConfirmation />
  );
};

export { SubscriptionActivationConfirmation };

import React from 'react';

interface Props {
  children: React.ReactNode;
  id: string;
  className?: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClickEdit?: React.PointerEventHandler<HTMLButtonElement>;
}

/**
 * Generic radio button that is used to compose radio buttons for saved payments and billing addresses
 */
export const RadioOption = ({
  id,
  className,
  name,
  value,
  checked,
  children,
  onChange,
  onClickEdit,
}: Props) => {
  return (
    <div className={className}>
      <label htmlFor={id}>
        <input
          id={id}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span>{children}</span>
      </label>
      {onClickEdit && (
        <button
          className="sd-button-anchor"
          type="button"
          onClick={onClickEdit}
          data-stor-id="clinic-edit-saved-payment-button"
        >
          Edit
        </button>
      )}
    </div>
  );
};

import React from 'react';
import { BILLING_METHODS } from '../../../constants';
import HostedFields from '../PaymentMethodBody/HostedFields';
import handleKeyDown from '../../../utils/handleKeyDown';

interface Props {
  removeSavePayment: any;
  savedPayment: SavedPayment;
  method: string;
  setupHostedFields: any;
  handleEditSavedPayment: any;
}

interface State {
  isEdit: boolean;
}

class SavedPayment extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.method !== BILLING_METHODS.SAVED_PAYMENT) {
      this.setState({ isEdit: false });
    }
  }

  onChange = () => {
    this.setState({ isEdit: true });
    this.props.handleEditSavedPayment(true);
  };

  onRevert = () => {
    this.setState({ isEdit: false });
    this.props.handleEditSavedPayment(false);
  };

  render() {
    const { method, savedPayment, setupHostedFields } = this.props;
    const hasCreditCard = savedPayment && savedPayment.isCreditCard;
    if (!hasCreditCard || method !== BILLING_METHODS.SAVED_PAYMENT) {
      return null;
    }

    if (this.state.isEdit) {
      return (
        <div className={`spc-input-wrap spc-billing-saved-payment-wrap`}>
          <div
            className={`spc-billing-use-saved-payment`}
            onClick={this.onRevert}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            Use {savedPayment.cardType} ending in {savedPayment.lastFour}
          </div>
          <HostedFields setupHostedFields={setupHostedFields} isHosted={true} />
        </div>
      );
    }

    const isExpiredCard = hasCreditCard && savedPayment.expired;

    return (
      <div className={`spc-input-wrap spc-input-wrap-change spc-billing-saved-payment-wrap`}>
        <div
          className="spc-billing-change-saved-payment"
          data-stor-id="spc-update-saved-payment"
          onClick={this.onChange}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          {isExpiredCard && (
            <span className="spc-billing-expired-saved-payment">
              Your payment method has expired. Please update before submitting your purchase.
            </span>
          )}
          <span className="spc-billing-update-saved-payment">Update</span>
        </div>
      </div>
    );
  }
}

export default SavedPayment;

import React from 'react';
import PropTypes from 'prop-types';
import getDisplayName from '../../utils/getDisplayName';
import handleKeyDown from '../../utils/handleKeyDown';

const propTypes = {
  authClass: PropTypes.string,
  clickBackground: PropTypes.func,
};

const defaultProps = {
  authClass: '',
  clickBackground() {},
};

export default function withModal(WrappedComponent) {
  class WithModal extends React.Component {
    constructor(props) {
      super(props);
      this.hideModal = this.hideModal.bind(this);
      this.state = {
        hide: false,
      };

      this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
    }

    componentWillMount() {
      this.hideModal(true);
    }

    hideModal(isHidden) {
      this.setState({
        hide: isHidden,
      });
    }

    handleBackgroundClick(e) {
      if (this.props.clickBackground) {
        this.props.clickBackground(e);
      }
    }

    render() {
      const hidden = this.state.hide ? 'mod-hidden' : '';

      return (
        <div
          className={`spc-modal ${hidden}`}
          onClick={this.handleBackgroundClick}
          onKeyDown={handleKeyDown}
          role="dialog"
        >
          <div className={`spc-modal-content${this.props.authClass}`}>
            <WrappedComponent hideModal={this.hideModal} {...this.props} />
          </div>
        </div>
      );
    }
  }

  WithModal.displayName = `withModal(${getDisplayName(WrappedComponent)})`;
  WithModal.propTypes = propTypes;
  WithModal.defaultProps = defaultProps;

  return WithModal;
}

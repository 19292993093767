import React from 'react';
import { BILLING_METHODS } from '../../../constants';

export default function Paypal({ method }: { method: string }) {
  return (
    <div
      className={`spc-input-wrap spc-billing-paypal-wrap
      ${method === BILLING_METHODS.PAYPAL ? '' : ' mod-hide-paypal mod-hidden'}`}
    >
      When paying with PayPal, you will be temporarily redirected to PayPal&#39;s website to access
      your account and submit your payment. Your balance will be paid in full with PayPal. You will
      then return to 23andMe to review and complete your order.
    </div>
  );
}

import React from 'react';
import { useStore } from '../../utils/storeUtils';
import subscriptionWelcomeDesktop from '../../images/subscriptionWelcomeDesktop.svg';
import subscriptionWelcomeIcon from '../../images/subscriptionWelcomeIcon.svg';
import subscriptionWelcomeIcon2 from '../../images/subscriptionWelcomeIcon2.svg';
import subscriptionWelcomeMobile from '../../images/subscriptionWelcomeMobile.svg';
import iconSubsCheck from '../../images/iconSubsCheck.svg';

import { redirectToYouDot } from '../../containers/SubscriptionRenewalOptIn/subscriptionRenewalOptInActions';

const PremiumSubscriptionActivationConfirmation = () => {
  const [
    {
      youdotUrl,
      account: { currentProfileId },
    },
  ] = useStore();

  return (
    <div className="spc-optin-activation-confirmation">
      <div className="spc-optin-activation-confirmation-content">
        <img
          className="desktop-header"
          src={subscriptionWelcomeDesktop}
          alt="Welcome to 23andMe plus"
          width="480"
        />
        <img
          className="mobile-header"
          src={subscriptionWelcomeMobile}
          alt="Welcome to 23andMe plus"
          width="170"
        />
        <p>Enjoy your premium benefits, including:</p>
        <div className="row">
          <img src={iconSubsCheck} alt="" width="20" />
          <p className="column-right">Premium Heart health reports</p>
        </div>
        <div className="row">
          <img src={iconSubsCheck} alt="" width="20" />
          <p className="column-right">Pharmacogenetics reports</p>
        </div>
        <div className="row">
          <img src={iconSubsCheck} alt="" width="20" />
          <p className="column-right">Advanced DNA Relatives features</p>
        </div>
        <div className="row">
          <img src={iconSubsCheck} alt="" width="20" />
          <p className="column-right">
            New premium reports and features delivered throughout the year
          </p>
        </div>
        <p>Cancel anytime. You&apos;ll get a reminder before renewal.</p>
        <div className="spc-optin-activation-confirmation-button">
          <button
            className="spc-subs-button"
            type="button"
            onClick={() =>
              redirectToYouDot({
                youdotUrl: youdotUrl,
                currentProfileId: currentProfileId,
              })
            }
          >
            Continue
          </button>
        </div>
        <img className="background-right" src={subscriptionWelcomeIcon2} alt="" />
        <img className="background-left" src={subscriptionWelcomeIcon} alt="" />
      </div>
    </div>
  );
};

export { PremiumSubscriptionActivationConfirmation };

import React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useStoreContext } from '../../utils/storeUtils';
import { Payment } from '../common/Payment';

export const PaymentInfoSection = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();

  // Use global state as initial payment form data if it exists.
  // Used to populate form fields when user navigates back from review screen.
  const {
    storeState: {
      billing: { customAddress: initialBillingAddress },
      labs: { expirationMonths },
    },
  } = useStoreContext();

  return (
    <section>
      <h2 className="spc-labs-h2">Payment information</h2>
      <p className="spc-labs-disclaimer">
        Your selected payment method will be charged once your lab test request is approved and the
        order is sent to the lab. You will then have {expirationMonths} months to provide your blood
        sample.
      </p>
      <Payment
        initialFormData={{ billingAddress: initialBillingAddress }}
        onSubmitSuccess={() => {
          window.scrollTo(0, 0);
          history.push(`${url}review/${search}`);
        }}
      />
    </section>
  );
};

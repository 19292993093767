/* eslint jsx-a11y/anchor-is-valid:0 */
/* eslint react/forbid-prop-types:0 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Consumer } from './Provider';

const propTypes = {
  className: PropTypes.string,
  el: PropTypes.any.isRequired,
};
const defaultProps = {
  className: '',
};
class LinkToPopup extends Component {
  render() {
    return ReactDOM.createPortal(
      <Consumer>
        {({ show }) => (
          <a
            href={null}
            role="button"
            tabIndex={0}
            onClick={show}
            onKeyDown={show}
            className={this.props.className}
            style={{ cursor: 'pointer', outline: 'none' }}
          >
            Cookie Settings
          </a>
        )}
      </Consumer>,
      this.props.el,
    );
  }
}
LinkToPopup.propTypes = propTypes;
LinkToPopup.defaultProps = defaultProps;
export default LinkToPopup;

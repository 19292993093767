import React from 'react';
import PropTypes from 'prop-types';

import {
  DescHdr,
  SpanHdr,
  SpanSubHdr,
  DescInput,
  DescUl,
  DescLi,
  DescLabel,
} from '../Styled/common';

const propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.string,
      domain: PropTypes.string,
    }),
  ).isRequired,
};

const AdsTable = ({ ads }) => (
  <div className="gdpr-ads-table">
    <DescHdr>
      <SpanHdr>Company</SpanHdr>
      <SpanSubHdr>Domain</SpanSubHdr>
    </DescHdr>

    {ads.map(({ id, company, domain }) => (
      <div className="gdpr-ad" key={id}>
        <DescInput id={`gdpr-${id}`} type="checkbox" />
        <DescLabel htmlFor={`gdpr-${id}`}>{company}</DescLabel>
        <DescUl>
          <DescLi>{domain}</DescLi>
        </DescUl>
      </div>
    ))}
  </div>
);

AdsTable.propTypes = propTypes;

export default AdsTable;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const _defaultSuccess = (data?: object) => data;
const _defaultFail = e => {
  console.log(e);
};

const apiCall = (method: API_METHODS, url: string, overrides: Record<string, any> = {}) => {
  const credentials: RequestCredentials = 'same-origin';
  const headers = new Headers({ 'Content-Type': 'application/json' });
  const defaultConfig = { credentials, headers, method };
  const config = { ...defaultConfig, ...overrides };
  return fetch(url, config).then(response => {
    if (!response.ok) {
      return response.json().then(res => {
        return Promise.reject(res.error);
      });
    }
    return response.json();
  });
};

const get = (url: string, onSuccess = _defaultSuccess, onFail = _defaultFail) => {
  return apiCall('GET', url)
    .then(data => onSuccess(data))
    .catch(e => {
      console.error('GET e is ', e);
      onFail(e);
    });
};

const post = (url: string, data: object, onSuccess = _defaultSuccess, onFail = _defaultFail) => {
  const config = { body: JSON.stringify(data) };
  return apiCall('POST', url, config)
    .then(data => onSuccess(data))
    .catch(e => {
      console.error('POST e is ', e);
      onFail(e);
    });
};

const del = (url: string, onSuccess = _defaultSuccess, onFail = _defaultFail) => {
  return apiCall('DELETE', url)
    .then(data => onSuccess(data))
    .catch(e => {
      console.error('DEL e is ', e);
      onFail(e);
    });
};

const put = (url: string, data, onSuccess = _defaultSuccess, onFail = _defaultFail) => {
  const config = { body: JSON.stringify(data) };
  return apiCall('PUT', url, config)
    .then(data => onSuccess(data))
    .catch(e => {
      console.error('PUT e is ', e);
      onFail(e);
    });
};

export { apiCall, get, post, del, put };

/* globals window */
import * as React from 'react';
import OrderKit from './OrderKit';
import { URLS } from '../../regions';
import { useStore } from '../../utils/storeUtils';

const Order = () => {
  const [state] = useStore();
  const { cart, isBookPurchase } = state;
  let utilSavings = null;
  if (
    cart.totals.itemsDiscount !== 0 &&
    !(state.hideUkPromoPricing || state.hasDiscountedPrepaidSubscription)
  ) {
    utilSavings = (
      <div className="spc-order-total-text">
        <div>You saved</div>
        <div>{cart.totals.displayItemsDiscount}</div>
      </div>
    );
  }
  const giftWrapCount = cart.kits.reduce((count, kit) => (kit.giftWrap ? count + 1 : count), 0);
  const orderKits = cart.kits.map(kit => <OrderKit key={kit.kitId} {...kit} />);
  const orderTotal = (
    <div className="spc-order-total mod-bottom">
      <div className="spc-order-total-text" role="row">
        <div role="cell">Subtotal ({cart.kits.length})</div>
        <div role="cell">{cart.totals.displayItemsTotalWithDiscount}</div>
      </div>
    </div>
  );

  const serviceOrProduct = isBookPurchase ? 'PRODUCT' : 'SERVICE';
  const isReadOnlyCart = state.isChipUpgrade || state.hasTotalHealthUpgrade || state.isBookPurchase;

  return (
    <div>
      <div className="spc-order mod-border">
        <header className="spc-order-header" role="presentation">
          <h2 className="spc-align-h2" data-stor-id="spc-order-summary-header">
            Order Summary
          </h2>
          {!isReadOnlyCart && (
            <span>
              <button
                className={`spc-button-edit${state.editableClass}`}
                onClick={e => {
                  e.stopPropagation();
                  window.location.href = URLS.CART;
                }}
                type="button"
              >
                EDIT
              </button>
            </span>
          )}
        </header>
      </div>
      <div className="spc-order">
        <div className="spc-order-text" role="table">
          <div className="spc-order-title spc-align" role="row">
            <div role="columnheader">{serviceOrProduct}</div>
            <div role="columnheader">PRICE</div>
          </div>
          {orderKits}
          {!!giftWrapCount && (
            <>
              <div className="spc-order-price">
                <div className="spc-order-price-name">Gift or privacy option ({giftWrapCount})</div>
                <div className="spc-order-price-text mod-discount">FREE</div>
              </div>
              <button
                className="spc-order-button-giftwrap"
                onClick={e => {
                  e.stopPropagation();
                  window.location.href = URLS.GIFTWRAP;
                }}
                type="button"
              >
                View selected gift options
              </button>
            </>
          )}
          {utilSavings && <div className="spc-order-total mod-saved">{utilSavings}</div>}
        </div>
        <div className="spc-order-text" role="table">
          {orderTotal}
        </div>
      </div>
    </div>
  );
};

Order.displayName = 'Order';
export default Order;

import React from 'react';
import iconpgt from '../../images/iconpgt.svg';
import icondnarmorerelatives from '../../images/icondnarmorerelatives.svg';
import iconhealthinsights from '../../images/iconhealthinsights.svg';
import icondoctor from '../../images/doctor.svg';
import iconbiomakers from '../../images/biomarker.svg';

const SubscriptionCancellationReasonsTotalHealth = () => (
  <div className="confirm-section">
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={icondoctor} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">
          Unlimited access to genetics-trained clinicians
        </div>
        <div className="confirm-section-content-description">
          Virtual visits and unlimited direct messaging with clinicians specially trained in
          genetics-based care.
        </div>
      </div>
    </div>
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={iconbiomakers} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">Biannual blood testing</div>
        <div className="confirm-section-content-description">
          Advanced cardiometabolic biomarkers plus insights into your thyroid, liver, kidneys, and
          more - all initiated by a clinician.
        </div>
      </div>
    </div>
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={iconpgt} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">Pharmacogenetics reports</div>
        <div className="confirm-section-content-description">
          Information about how your DNA may affect the way your body processes certain medications.
        </div>
      </div>
    </div>
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={icondnarmorerelatives} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">Member-only DNA Relative discovery</div>
        <div className="confirm-section-content-description">
          Find relatives based on their Ancestry Composition, haplogroup, and 23andMe activity.
        </div>
      </div>
    </div>
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={iconhealthinsights} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">Exclusive health insights</div>
        <div className="confirm-section-content-description">
          Access to breakthrough reports powered by 23andMe research about how your genetics can
          impact your heart health and more.
        </div>
      </div>
    </div>
  </div>
);
export default SubscriptionCancellationReasonsTotalHealth;

/* globals document */
import 'babel-polyfill';
import React from 'react';
import Routes from './routes';
import { ELEMENTS } from './constants';
import { StoreProvider, setupPage } from './pageSetup';
import { VariationProvider } from './providers/variationProvider/VariationProvider';
import { createRoot } from 'react-dom/client';

require('./index.css');
setupPage();

const root = createRoot(document.getElementById(ELEMENTS.root));

root.render(
  <VariationProvider>
    <StoreProvider>
      <Routes />
    </StoreProvider>
  </VariationProvider>,
);

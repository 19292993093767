import React from 'react';
import { URLS } from '../../regions';

interface Props {
  height?: string;
  width?: string;
  className?: string;
  url?: string;
}

const Logo = ({ height = '100px', width = '88px', className = '', url = URLS.HOME }: Props) => (
  <div className="spc-header-logo">
    <div className="hide-for-mobile">
      <a href={url} aria-label="23andMe Home">
        <svg
          width={width}
          height={height}
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 223.2 152.8"
        >
          <title>23andMe Home</title>
          <path
            fill="#7BC143"
            d="M1.3 112.5c-3 6.1-.5 13.4 5.6 16.4 6.1 3 13.4.5 16.4-5.6l4.9-9.9-10.5-34.3-16.4 33.4zM69.5 1.3c-6.1-3-13.4-.5-16.4 5.6l-19.6 40L44 81.2l31.2-63.6c2.9-6 .4-13.4-5.7-16.3z"
          />
          <path
            fill="#DA1963"
            d="M37.5 144.1c2 6.5 8.8 10.1 15.3 8.1s10.1-8.8 8.1-15.3l-17-55.8-15.8 32.2 9.4 30.8zM26.6 24.4c-2-6.5-8.8-10.1-15.3-8.1S1.2 25.1 3.2 31.6l14.5 47.6L33.5 47l-6.9-22.6z"
          />
          <path fill="#49A948" d="M17.7 79.1l10.4 34.3 15.8-32.2-10.4-34.3" />
          <path
            d="M78.6 89.4H59.8v-1.1c0-4.7 1.9-7.8 6.4-10.8 2.4-1.6 3.8-2.5 4.4-2.9.6-.5 1.1-1 1.5-1.7.4-.7.6-1.5.6-2.3 0-2.1-1.4-3.5-3.4-3.5-2.5 0-3.9 1.7-4 5h-5.5c.1-3.6.9-5.6 3-7.3 1.7-1.4 3.9-2.1 6.5-2.1 5.7 0 9.3 3 9.3 7.7 0 3.7-1.9 6.3-7.1 9.7-3.9 2.5-4.4 3-4.8 4.8h11.9v4.5zm7.8-8.1c0 2.6 1.7 4.2 4.3 4.2 2.4 0 4-1.4 4-3.5 0-2.5-1.6-3.5-5.6-3.5h-.6V74h.5c3.9 0 5.4-1 5.4-3.4 0-2-1.5-3.4-3.7-3.4-2.6 0-4 1.5-4 4.4H81c.2-3.4 1-5.2 3-6.8 1.7-1.4 4-2.1 6.8-2.1 5.5 0 9.2 2.9 9.2 7.2 0 3.1-1.8 5.4-4.9 6.2 1.1.3 1.5.4 2.1.7 2.1 1.1 3.1 3 3.1 5.5 0 4.7-3.7 7.7-9.6 7.7-6.4 0-10.1-3.4-10.1-9.3h5.7c.1.2.1.4.1.6zm25.7-12.5c2.9 0 5.2.8 6.7 2.3 1.4 1.5 1.9 3.1 1.9 6.6 0 .3 0 1.2-.1 2.9v1.9c0 3.1.2 5.2.5 6.9h-4.7c-.2-1.1-.2-1.5-.2-2.2v-.8c-1.4 2.5-3.5 3.5-6.8 3.5-4.3 0-7.2-2.5-7.2-6.1 0-1.5.6-2.9 1.5-3.9 1.2-1.2 2.6-1.8 5.3-2.4 2.1-.4 4.4-.7 6.3-.8 0-2.8-1-4-3.3-4-2.2 0-3.3 1-3.4 2.9H103c.3-4.2 3.7-6.8 9.1-6.8zm2.6 11.7l-1.9.3c-3.5.5-4.8 1.3-4.8 2.9 0 1.4 1.2 2.4 3 2.4 2.9 0 4.4-1.9 4.4-5.6h-.7zm15-8.6c1.4-1.9 3.4-2.9 5.8-2.9 2.9 0 5.4 1.5 6.2 3.9.5 1.2.6 2.2.6 5.2v11.4h-5.5V77.7c0-3.2-.9-4.5-3.2-4.5-2.5 0-3.7 1.9-3.7 5.9v10.4h-5.5v-20h5.2v2.4zm34.6 17.5h-5.2v-2.6c-1.3 2.1-3.3 3.1-5.8 3.1-4.8 0-8.2-4.3-8.2-10.4 0-6.3 3.2-10.5 8-10.5 2.7 0 4.5.9 5.8 3V61.8h5.5v27.6zm-9.7-4c2.5 0 4.2-2.4 4.2-6 0-3.7-1.6-5.9-4.1-5.9-2.6 0-4.2 2.3-4.2 5.9 0 3.5 1.8 6 4.1 6zm45.1 4h-5.5V67.2l-6.7 22.2h-6l-6.8-22.2v22.2h-5.5V61.8h9.2l6.3 20.9 6.2-20.9h8.8v27.6zm13.8.5c-6.2 0-10.2-4.1-10.2-10.6 0-6.2 4-10.4 10-10.4s9.9 4.1 9.9 10.9v1.3H209c.1 2.9 1.8 4.6 4.6 4.6 1.9 0 3.1-.8 3.6-2.4h5.9c-1.2 4.3-4.6 6.6-9.6 6.6zm4-12.8c-.1-2.4-1.7-4.1-4.2-4.1-2.4 0-3.9 1.4-4.3 4.1h8.5z"
            fill="#9C9EA1"
          />
        </svg>
      </a>
    </div>
    <div className="hide-for-desktop">
      <a href={url} aria-label="23andMe Home">
        <svg
          className={className}
          fill="none"
          height="30"
          viewBox="0 0 88 30"
          width="88"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipRule="evenodd" fillRule="evenodd">
            <g fill="#9c9ea1">
              <path d="m23.9921 21.4351h-7.6161c-.0168-.1651-.0168-.3139-.0168-.4295 0-1.8811.7574-3.1198 2.6116-4.3081.9598-.6262 1.5499-1.0074 1.786-1.1711.2521-.1816.4546-.4148.6228-.6791.1687-.2788.2527-.6104.2527-.9228 0-.8269-.5729-1.3871-1.3986-1.3871-1.0109 0-1.5833.6934-1.617 1.9804h-2.2575c.0327-1.4202.3697-2.2439 1.2127-2.9212.7075-.5608 1.5673-.8426 2.6618-.8426 2.3089 0 3.7752 1.1888 3.7752 3.0694 0 1.4869-.775 2.5107-2.8817 3.8479-1.6008 1.0066-1.8033 1.2054-1.9717 1.9315h4.8366z" />
              <path d="m27.1764 18.1996c0 1.0232.6902 1.6671 1.7521 1.6671.9943 0 1.6345-.5449 1.6345-1.3862 0-1.0068-.6402-1.4032-2.2919-1.4032h-.2517v-1.7986h.2358c1.5831 0 2.2069-.3799 2.2069-1.3542 0-.7922-.6238-1.3701-1.5002-1.3701-1.0448 0-1.617.6112-1.617 1.7335v.015h-2.3427c.0674-1.354.3878-2.0621 1.2302-2.7219.691-.545 1.6182-.8271 2.78-.8271 2.2587 0 3.741 1.1394 3.741 2.89 0 1.2371-.7248 2.1446-2.0048 2.4585.4549.0996.6227.165.8585.2979.843.4294 1.2813 1.2048 1.2813 2.1956 0 1.8828-1.5171 3.0539-3.9097 3.0539-2.6116 0-4.1113-1.3365-4.1113-3.7152h2.3258c0 .1165-.0168.1989-.0168.265z" />
              <path d="m38.7024 17.8701-.7585.1148c-1.4151.2146-1.9723.5285-1.9723 1.1394 0 .5614.5067.9413 1.2308.9413 1.1636 0 1.7698-.7439 1.8035-2.2457zm-1.0786-4.6391c1.1802 0 2.1237.3137 2.7304.9247.5904.5767.7925 1.2207.7925 2.641-.0175.1148-.0175.4939-.0343 1.1553v.7429c0 1.254.068 2.0795.219 2.7402h-1.9049c-.0843-.4459-.0843-.5947-.0843-.8906v-.3309c-.5893.9902-1.4145 1.4031-2.7467 1.4031-1.7352 0-2.9491-.9908-2.9491-2.4111 0-.5932.2362-1.1546.6243-1.551.4717-.4789 1.0614-.7266 2.1739-.941.8582-.1489 1.7857-.2804 2.5613-.3305 0-1.1231-.404-1.5999-1.3484-1.5999-.8929 0-1.3478.3964-1.3995 1.1724h-2.3077c.1179-1.7008 1.4819-2.7246 3.6735-2.7246z" />
              <path d="m44.7858 14.4517c.5888-.7748 1.3815-1.1712 2.3755-1.1712 1.1795 0 2.1907.611 2.5442 1.5511.203.4948.2361.8915.2361 2.0801v4.5232h-2.2409v-4.6714c0-1.2886-.3706-1.7987-1.2972-1.7987-1.0289 0-1.4997.7433-1.4997 2.343v4.1271h-2.2419v-7.9728h2.1239z" />
              <path d="m54.9462 19.8174c1.0273 0 1.7193-.9407 1.7193-2.3781-.0175-1.4691-.6411-2.3435-1.6856-2.3435-1.0452 0-1.7019.9238-1.7019 2.3607 0 1.3871.6904 2.3609 1.6682 2.3609zm3.9262 1.6177h-2.124v-1.0569c-.5219.8258-1.3302 1.2384-2.3754 1.2384-1.9723 0-3.3528-1.6996-3.3528-4.1427 0-2.4936 1.3138-4.1936 3.2521-4.1936 1.0953 0 1.8201.3635 2.3594 1.1871v-4.026h2.2407z" />
              <path d="m73.2632 21.4351h-2.2406v-8.8482l-2.747 8.8482h-2.4444l-2.78-8.8482v8.8482h-2.2416v-10.9937h3.7578l2.5785 8.3198 2.528-8.3198h3.5893z" />
              <path d="m80.5084 16.5318c-.0337-.9738-.7078-1.633-1.7012-1.633-.9778 0-1.5858.5596-1.7692 1.633zm-1.6182 5.085c-2.5267 0-4.145-1.6348-4.145-4.2091 0-2.4759 1.6354-4.1272 4.0776-4.1272 2.4612 0 4.0105 1.6513 4.0105 4.3246 0 .1321-.0159.2314-.0159.5129h-5.7626c.0506 1.1553.7409 1.8151 1.8859 1.8151.7588 0 1.2645-.3133 1.4835-.9407h2.3932c-.5225 1.7161-1.9043 2.6244-3.9272 2.6244z" />
            </g>
            <path
              d="m.259054 22.0058c-.60496 1.1845-.114506 2.6286 1.097786 3.2216 1.21021.5932 2.68214.1133 3.28834-1.0752l.99203-1.9411-2.04313-6.7375z"
              fill="#7ac242"
            />
            <path
              d="m14.0917.278641c-1.2104-.593199-2.6827-.113665-3.2894 1.073109l-4.00024 7.83568 2.04437 6.73657 6.34097-12.42343c.605-1.18584.1152-2.627706-1.0957-3.221929z"
              fill="#7ac242"
            />
            <path
              d="m7.46961 28.257c.38594 1.2708 1.74992 1.9964 3.04619 1.6186 1.2997-.3781 2.039-1.7152 1.6541-2.9869l-3.32356-10.9646-3.20901 6.287z"
              fill="#d91a63"
            />
            <path
              d="m5.45916 4.75352c-.38565-1.27258-1.74992-1.99724-3.04782-1.61888-1.29874.37742-2.038084 1.714-1.652816 2.98537l2.835536 9.35339 3.20778-6.28612z"
              fill="#d91a63"
            />
            <path
              d="m3.59406 15.4735 2.04312 6.7375 3.2094-6.287-2.04475-6.73662z"
              fill="#4aa847"
            />
          </g>
        </svg>
      </a>
    </div>
  </div>
);

Logo.displayName = 'Logo';

export default Logo;

import React from 'react';
import StateFieldSelect from './StateFieldSelect';
import StateFieldText from './StateFieldText';

interface Props {
  state?: string;
  states?: GeoState[];
  handleChange: (e: any) => void;
  errors: FormError[];
  placeHolder: string;
  isRequired?: boolean;
  isPresent?: boolean;
}

const StateField = ({
  state = '',
  states = [],
  handleChange,
  errors,
  placeHolder,
  isRequired = true,
  isPresent = true,
}: Props) => {
  const stateField =
    states.length > 1 ? (
      <StateFieldSelect
        state={state}
        states={states}
        handleChange={handleChange}
        errors={errors}
        placeHolder={placeHolder}
      />
    ) : (
      <StateFieldText
        state={state}
        handleChange={handleChange}
        errors={errors}
        isRequired={isRequired}
        placeHolder={placeHolder}
      />
    );

  return isPresent ? stateField : <span />;
};

export default StateField;

import React, { useState } from 'react';
import { useStore } from '../../utils/storeUtils';
import iconSubsCheck from '../../images/iconSubsCheck.svg';
import WithToggleDrawer from '../common/withToggleDrawer';
import SubscriptionName from './SubscriptionName';

const SubscriptionRenewalInfo = () => {
  const [storeState] = useStore();
  const currentProfile = storeState.profiles.filter(
    profile => profile.id === storeState.account.currentProfileId,
  )[0];
  const endDate = currentProfile?.subscriptionInfo?.endDate;
  const { price, discountedPrice, isSubFree, isTotalHealth } = storeState.subscriptionInfo;

  let renewalPrice = price;
  if (discountedPrice) {
    renewalPrice = isSubFree ? '0' : discountedPrice;
  }
  const priceString = `${renewalPrice}`;

  const subsCheckIcon = <img src={iconSubsCheck} alt="" width="20" />;

  const drawerContentExome = (
    <div className="drawer-content">
      <span>Included with your membership:</span>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Clinicians trained in genetics-informed care, with unlimited direct messaging and a
          dedicated virtual consultation annually.{' '}
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Biannual blood testing of advanced cardiometabolic biomarkers plus insights into your
          thyroid, liver, kidneys, and more - all initiated by a clinician.
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Biological age results twice a year to track and take steps to change the trajectory of
          your health by comparing your calendar age and biological age.
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Personalized recommendations through a health plan based on your genetics and lifestyle
          with a focus on ongoing prevention and early detection.
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Access to breakthrough reports powered by 23andMe research and how your genetics can
          impact your heart health and more.
        </p>
      </div>
      <p>
        Cancel anytime before your next renewal to avoid being charged. We’ll send you a reminder
        before your renewal date.
      </p>
    </div>
  );

  const drawerContentPremium = (
    <div className="drawer-content">
      <span>Included with your membership:</span>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">Premium reports on heart health, sleep, and more</p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Historical matches<sup>SM</sup>
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">Migraine and other premium health reports</p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Pharmacogenetics reports to help you understand how your body processes certain
          medications
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">Advanced DNA Relatives filters</p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Plus new reports and features as more discoveries are made
        </p>
      </div>
      <p>
        Cancel anytime before your next renewal to avoid being charged. We’ll send you a reminder
        before your renewal date.
      </p>
    </div>
  );

  return (
    <div className="spc-subs-info">
      <div className="spc-subs-renewal-container">
        <div className="spc-subs-info-title">
          <h1 className="spc-subs-info-h1" data-mdv-id="subscription-payment-header">
            Thank you for being a{' '}
            <SubscriptionName useServiceMark={true} iconSize={18} isTotalHealth={isTotalHealth} />{' '}
            member
          </h1>
        </div>
        <div className="spc-subs-info-content">
          <p>
            Your annual membership will expire{' '}
            {endDate ? `on ${endDate}` : 'a year from its start date'}.
            <br />
            <br />
            Confirm your payment details now to <strong>automatically renew</strong> your membership
            at {priceString} when your first year is up.
          </p>
          <div className="spc-subs-info-desktop">
            {isTotalHealth ? drawerContentExome : drawerContentPremium}
          </div>
          <div className="spc-subs-info-mobile">
            <WithToggleDrawer drawerLabel="Learn more" seeLessText="See less" useButton={false}>
              {isTotalHealth ? drawerContentExome : drawerContentPremium}
            </WithToggleDrawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRenewalInfo;

import React from 'react';
import withArrayDisplay from '../../common/withArrayDisplay';
import { BILLING_METHODS, BILLING_ADDRESS } from '../../../constants';
import handleKeyDown from '../../../utils/handleKeyDown';

interface RadioProps {
  checked: boolean;
  switchToCustomAddress: (e) => void;
  id: string;
  label: string;
  value: string;
}

const AddressOptionRadioBox = (props: RadioProps) => {
  const { checked, id, label, value, switchToCustomAddress } = props;

  const handleChange = e => {
    e.stopPropagation();
    e.target.parentElement.querySelector('input').checked = true;
    switchToCustomAddress(e.target.parentElement.querySelector('input').value);
  };
  return (
    <div className="spc-billing-text">
      <input tabIndex={0} type="radio" onChange={handleChange} {...{ checked, id, value }} />
      <label
        className="spc-billing-text-label spc-pointer"
        htmlFor={id}
        onClick={handleChange}
        onKeyDown={handleKeyDown}
      >
        {label}
      </label>
    </div>
  );
};

interface Props {
  addressSource: number;
  switchToCustomAddress: (e) => void;
  method: string;
}

const UseSameAddress = (props: Props) => {
  const { addressSource, switchToCustomAddress, method } = props;
  const checked =
    addressSource === BILLING_ADDRESS.SHIPPING || addressSource === BILLING_ADDRESS.SAVED;
  const label =
    method === BILLING_METHODS.CREDIT_CARD ? 'Use same as shipping' : 'Use saved address';
  return (
    <AddressOptionRadioBox
      id="address-same"
      value="default"
      {...{ checked, switchToCustomAddress, label }}
    />
  );
};

const UseNewAddress = ({ addressSource, switchToCustomAddress }: Props) => {
  const checked =
    addressSource === BILLING_ADDRESS.CUSTOM || addressSource === BILLING_ADDRESS.SAVED_CUSTOM;
  const label = 'Enter a new address';
  return (
    <AddressOptionRadioBox
      id="address-new"
      value="custom"
      {...{ checked, switchToCustomAddress, label }}
    />
  );
};

const BillingAddressOption = () => {};
BillingAddressOption.componentArray = [UseSameAddress, UseNewAddress];

export default withArrayDisplay(BillingAddressOption);

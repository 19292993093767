import React from 'react';

interface Props {
  height?: string;
  width?: string;
  className?: string;
}

const CloseIcon = ({ height = '13px', width = '13px', className = '' }: Props) => (
  <div>
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-10.5 110.5 226.5 229"
    >
      <title>Close</title>
      <path
        fill="#333435"
        d="M135.107 223.6l70.055-70.054c8.757-8.757 8.757-23.352 0-32.108-8.757-8.757-23.352-8.757-32.107 0L103 194.411l-70.054-72.973c-8.757-8.757-23.351-8.757-32.108 0-8.757 8.756-8.757 23.351 0 32.108L70.892 223.6.838 296.572c-8.757 8.758-8.757 23.352 0 32.109 2.919 2.918 8.757 5.838 14.595 5.838s11.675-2.92 14.594-5.838l70.054-70.055 70.054 70.055c2.92 2.918 8.758 5.838 14.595 5.838 5.838 0 11.676-2.92 14.595-5.838 8.757-8.758 8.757-23.352 0-32.109L135.107 223.6z"
      />
    </svg>
  </div>
);

CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;

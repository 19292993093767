import React, { useState } from 'react';
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { ReviewPaymentMethod } from './ReviewPaymentMethod';
import { YourOrderSection } from './YourOrderSection';
import { ReviewPersonalInfo } from './ReviewPersonalInfo';
import { showError, useStoreContext } from '../../utils/storeUtils';
import { postValidation } from '../../utils/storeApi';
import { ERROR_MESSAGES, MAGENTO_ERROR_KEYWORDS } from '../../constants';
import { updateGlobalStore } from '../../actions/actions';
import classNames from 'classnames';

import Checkbox from '../common/Checkbox';

interface Props {
  updatePass?: () => void;
}

export const ReviewOrderScreen = ({ updatePass = () => {} }: Props) => {
  const {
    storeState: { billing, labs, cart },
    dispatch,
  } = useStoreContext();
  const { search } = useLocation();
  const {
    params: { lang },
  } = useRouteMatch();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [showCheckRequiredMessage, setShowCheckRequiredMessage] = useState(false);
  const { editUrl: editPersonalInfoUrl } = labs;

  /**
   * Gets error message based off data received from postValidation API call
   *
   * @param data - data passed into postValidation error callback
   * @returns error message for error popup
   */
  const getErrorData = (data: any = {}): ErrorType => {
    const defaults = {
      button: 'continue',
      body: ERROR_MESSAGES.BILLING_PROCESSING,
    };
    let error = {};
    if (data.rejectCart) {
      error = {
        body: ERROR_MESSAGES.EMPTY_CART,
        ctaUrl: editPersonalInfoUrl,
        button: 'return to your order',
      };
    }
    if (data.error?.includes(MAGENTO_ERROR_KEYWORDS.AVS)) {
      error = { body: ERROR_MESSAGES.ADDRESS };
    }
    if (data.error?.toLowerCase().includes(MAGENTO_ERROR_KEYWORDS.DECLINE)) {
      error = { body: ERROR_MESSAGES.DECLINE };
    }

    return {
      ...defaults,
      ...error,
    };
  };

  return (
    <div>
      <h1 className="spc-labs-h1">Review your order</h1>
      <h2 className="spc-labs-eyebrow">Your order</h2>
      <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
        <YourOrderSection />
      </div>
      <form
        onSubmit={event => {
          event.preventDefault();
          if (!isChecked) {
            setShowCheckRequiredMessage(true);
            return;
          } else {
            setShowCheckRequiredMessage(false);
          }
          setIsProcessing(true);

          const body = {
            billing,
            deviceData: window.deviceData,
          };

          // send the data!
          postValidation(
            body,
            function handlePostValidationSuccess({ receiptId, taxes, orderCreationDate }) {
              // route user to receipt before updating global state to avoid react router redirect
              history.push(`/en-${lang}/labs/receipt/${receiptId}/`);
              window.scrollTo(0, 0);

              // update receiptId so user is routed back to receipt page if they attempt to navigate back
              dispatch(
                updateGlobalStore({
                  labs: {
                    ...labs,
                    receiptId,
                  },
                  cart: {
                    ...cart,
                    taxes: taxes,
                  },
                  orderCreationDate,
                }),
              );
            },
            function handlePostValidationError(data) {
              showError(dispatch, getErrorData(data));
              setIsProcessing(false);
            },
          );
        }}
      >
        <h2 className="spc-labs-h2">Review your information</h2>
        <p>Please ensure the following information is correct before checking out.</p>
        <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
          <ReviewPersonalInfo
            editLink={
              editPersonalInfoUrl && (
                <a className="no-external-icon spc-labs-edit-link" href={editPersonalInfoUrl}>
                  Edit
                </a>
              )
            }
          />
        </div>
        <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
          <ReviewPaymentMethod
            editLink={
              <Link
                to={`/en-${lang}/labs/checkout/${search}`}
                className="spc-labs-edit-link"
                onClick={() => updatePass()}
                data-stor-id="clinic-change-payment-method"
              >
                Edit
              </Link>
            }
          />
        </div>
        {showCheckRequiredMessage && (
          <h3 className="spc-billing-err spc-pl-0">
            To continue with this order, check the box below.
          </h3>
        )}
        <Checkbox
          dataStorId="clinic-tos-agreement-checkbox"
          checked={isChecked}
          handleCheck={() => {
            setChecked(!isChecked);
            setShowCheckRequiredMessage(false);
          }}
          darkBorder
          text="I acknowledge that this purchase is non-refundable, and that I will be charged for any applicable sales tax."
          ariaLabel="I acknowledge that this purchase is non-refundable, and that I will be charged for any applicable sales tax."
        />
        {/* NOTE: the mod-disabled shows a spinny thing because we are making a request to the backend and actually-disabled is a greyed-out button */}
        <div className="card-button-wrapper">
          <button
            className={classNames('spc-labs-submit-button', 'card-button', {
              'spc-next-button': isProcessing,
              'mod-disabled': isProcessing,
              'actually-disabled': !isChecked,
            })}
            type="submit"
            aria-disabled={isProcessing || !isChecked}
            data-stor-id="clinic-submit-order-button"
          >
            Place order
          </button>
        </div>
      </form>
    </div>
  );
};

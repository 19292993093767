import { SHIPPING_CODES, BILLING_ADDRESS } from '../constants';

const kit: Kit = {
  alias: 'health',
  kitId: '1',
  description: 'Health + Ancestry Service',
  discount: 20,
  discounted: true,
  discountPercent: 0,
  discountPrice: 0,
  displayDiscount: '$20.00',
  displayDiscountPrice: '$179.00',
  displayPrice: '$199.00',
  discountLabel: '',
  discountProgram: '',
  human: '',
  project: '',
  giftMessage: 'hello',
  giftWrap: 'gift',
  name: 'Joe',
  price: 199,
  sku: 'US-KIT-PGS',
  url: '',
  itemId: 0,
  itemName: '',
  itemCategory2: '',
  itemCategory3: '',
};

const cart: Cart = {
  id: 'abc123',
  totals: {
    ancestryTotal: 0,
    ancestryTotalWithDiscount: 0,
    discount: 20,
    discounted: true,
    displayAncestryTotal: '$0.00',
    displayAncestryTotalWithDiscount: '$0.00',
    displayHealthTotal: '$0.00',
    displayHealthTotalWithDiscount: '$0.00',
    displayItemsDiscount: '$20.00',
    displayItemsTotal: '$199.00',
    displayItemsTotalWithDiscount: '$179.00',
    displayShipping: '$9.95',
    displayShippingDiscount: '$0.00',
    displayShippingWithDiscount: '$9.95',
    displayTaxAmount: '$0.00',
    displayTotal: '$188.95',
    healthTotal: 0,
    healthTotalWithDiscount: 0,
    itemsCount: 1,
    itemsDiscount: 20,
    itemsTotal: 199,
    itemsTotalWithDiscount: 188.95,
    shipping: 9.95,
    shippingDiscount: 0,
    shippingTaxAmount: 0,
    shippingWithDiscount: 9.95,
    subtotal: '',
    subtotalWithDiscount: '',
    taxAmount: 0,
    taxRate: 0,
    total: '188.95',
  },
  kits: [kit],
  taxes: [],
  isFree: false,
};

const defaultCountries: Country[] = [
  {
    text: 'United States',
    value: 'US',
  },
];

const payment: Billing = {
  method: 'credit_card',
  lastFour: '1111',
  cardType: 'Visa',
  addressSource: BILLING_ADDRESS.SHIPPING,
  customAddress: { country: 'US' },
  nonce: '',
  enableSavedPayment: false,
  saveCreditCardOptIn: false,
};

const shipMethod: ShipMethod = {
  code: SHIPPING_CODES.STANDARD,
  displayPrice: '$9.95',
  title: 'Standard',
};

const shippingAddress: Address = {
  address: '12 Shorebird Lane',
  country: 'US',
};

const shippingMethods: ShipMethod[] = [
  {
    code: SHIPPING_CODES.STANDARD,
    displayPrice: '$9.95',
    title: 'Standard',
  },
  {
    code: SHIPPING_CODES.EXPEDITED,
    displayPrice: '$14.95',
    title: 'Expedited',
  },
  {
    code: SHIPPING_CODES.EXPRESS,
    displayPrice: '$24.95',
    title: 'Express',
  },
];

const states: GeoState[] = [
  {
    text: 'CA',
    value: 'California',
  },
  {
    text: 'NY',
    value: 'New York',
  },
];

const account: IAccount = {
  firstName: 'Dillon',
  lastName: 'Leon',
  currentProfileId: 'f015c50a72d8608b',
  isAuthenticated: true,
  email: 'test@test.com',
  paymentMethods: [
    {
      firstName: 'Chris',
      lastName: 'Dabney',

      isPaypalAccount: false,
      isApplepay: false,
      isCreditCard: true,
      cardType: 'Visa',
      lastFour: '1111',
      imageUrl:
        'https://assets.braintreegateway.com/payment_method_logo/visa.png?environment=sandbox',

      address: '899 W EVELYN AVE',
      city: 'MOUNTAIN VIEW',
      state: 'CA',
      country: 'USA',
      postalCode: '94041-1225',
      expired: false,
      hasSubscriptions: false,
      default: false,
    },
  ],
  addresses: [],
};

const isMinicart = true;
const isChipUpgrade = false;
const isBookPurchase = false;
const hideUkPromoPricing = false;
const showUkShippingUpdate = false;
const showIntShippingUpdate = false;
const hasTotalHealth = false;
const hasTotalHealthUpgrade = false;
const isEmptyCart = false;
const hasPendingTotalHealth = false;
const isApiUp = true;
const isPrepaidSubscription = true;
const hasDiscountedPrepaidSubscription = true;
const hasSubscribedProfile = true;
const braintree_token = '';
const deviceData = '';
const youdotUrl = '';
const pubUrl = '';
const isSubsEnabled = true;
const planId = '123';
const openSubsTab = false;
const hasSurveyResults = false;
const successUrl = '';
const isMobileWebView = false;
const membership = false;
const orderCreationDate = 'Sep 20, 2023';
const preserveDefaultPayment = false;
const showHistoricalMatches = false;
const enableApplepay = false;
const enablePaypal = false;
const enableVenmo = false;
const gtmNullPayload = false;
const isMultiProfileAccount = false;

const subscriptionInfo: SubscriptionInfo = {
  receiptId: 'B1234567890',
  activatedAt: 'Jan 1, 2020',
  createdAt: 'Jan 1, 2020',
  endDate: 'Dec 31, 2020',
  renewDate: 'Jan 1, 2021',
  currentPrice: '$29',
  price: '$29',
  frequency: 'year',
  discountedPrice: '$20',
  discountedCycles: 0,
  discountAmount: '$9',
  isHealthUpgrade: true,
  isSubscriptionRenewal: false,
  totalDiscountedPrice: '0',
  totalAmountSaved: '$9',
  isSubFree: false,
  isRenewable: false,
  activePrepaid: false,
  newPrepaid: false,
  daysToRenewal: 365,
  status: 'Active',
  priceNumber: 29,
  discountedPriceNumber: 29,
  discountAmountNumber: 0,
  storePlanId: 'plan-4',
  planName: 'US Premium Annual 29',
  sampleShippingCountry: 'US',
};

const subBundleInfo = {
  price: '$99.00',
  receiptId: 'M1307871441587576',
  itemName: 'Health + Ancestry Personal Genetic Service Upgrade',
  isGenotyped: true,
  discountedPrice: '$29.00',
};

const profile: Profile = {
  lastName: 'Mitchell',
  genotypeStatus: 'claimed',
  legalRegion: 'US',
  platformLabels: ['v5'],
  id: 'f015c50a72d8608b',
  firstName: 'Gary',
  genotyped: false,
  phenotype: {},
  isRetailLocked: false,
  sampleShippingCountry: 'US',
  isAncestryOnly: true,
  hasPurchasedHealthUpgrade: false,
  region: 'US',
  hasServicesToConfer: true,
  isEligibleForHealthUpgrade: true,
  initials: 'GM',
  subscriptionInfo: subscriptionInfo,
  hasPendingTotalHealthUpgrade: false,
};

const expressStatesBlacklist = [];
const profiles = [
  {
    id: '237a3e57b5468398',
    firstName: 'Test',
    lastName: 'Tester',
    isSubscriber: true,
    hasCancelledSub: false,
    initials: 'TT',
    subscriptionInfo: subscriptionInfo,
  },
  {
    id: 'ea59345f35bd6902',
    firstName: 'Test2',
    lastName: 'Tester2',
    isSubscriber: false,
    isEligibleForSubscription: true,
    initials: 'TT',
    subscriptionInfo: subscriptionInfo,
  },
  {
    id: '3cb02f05191ea1eb',
    firstName: 'Test3',
    lastName: 'Tester3',
    isSubscriber: true,
    hasCancelledSub: true,
    initials: 'TT',
    subscriptionInfo: subscriptionInfo,
  },
];

const billingInfo = {
  lastFour: '1111',
  cardType: 'MasterCard',
  expirationMonth: '02',
  expirationYear: '2020',
  firstName: 'Chris',
  lastName: 'Dabney',
  lineOne: '223 N Mathilda Ave',
  lineTwo: '',
  city: 'Sunnyvale',
  state: 'CA',
  zip: '95123',
  country: 'US',
};

const placeholderData = {
  account,
  billingCountries: defaultCountries,
  braintree_token,
  cart,
  kit,
  deviceData,
  expressStatesBlacklist,
  hasSubscribedProfile,
  hideUkPromoPricing,
  showUkShippingUpdate,
  showIntShippingUpdate,
  isChipUpgrade,
  isBookPurchase,
  isMinicart,
  isSubsEnabled,
  hasTotalHealth,
  hasTotalHealthUpgrade,
  isEmptyCart,
  hasPendingTotalHealth,
  isPrepaidSubscription,
  hasDiscountedPrepaidSubscription,
  isApiUp,
  payment,
  planId,
  profile,
  profiles,
  isMultiProfileAccount,
  pubUrl,
  shipMethod,
  shippingAddress,
  shippingCountries: defaultCountries,
  shippingMethods,
  states,
  subscriptionInfo,
  subBundleInfo,
  youdotUrl,
  billingInfo,
  openSubsTab,
  hasSurveyResults,
  successUrl,
  isMobileWebView,
  membership,
  orderCreationDate,
  preserveDefaultPayment,
  showHistoricalMatches,
  enableApplepay,
  enablePaypal,
  enableVenmo,
  gtmNullPayload,
};

export default placeholderData;

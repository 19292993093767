import React from 'react';
import handleKeyDown from '../../../../utils/handleKeyDown';

interface Props {
  handleGoToRegion: (e: any) => void;
  handleClose: (e: any) => void;
}
const CountrySuggestionEnCa = (props: Props) => (
  <div>
    <h2 className="spc-verification-h2 mod-font">Notice: Canada-bound Kits</h2>
    <p className="spc-verification-text">
      If you want to ship to Canada, please switch over to our Canadian site.
    </p>
    <div className="spc-verification-edit">
      <button
        onClick={props.handleGoToRegion}
        className="spc-verification-div-button"
        type="button"
      >
        23andMe.ca
      </button>
      <p>
        <button
          className="spc-button-link"
          onClick={props.handleClose}
          onKeyDown={handleKeyDown}
          type="button"
          tabIndex={0}
        >
          Ship to another country
        </button>
      </p>
    </div>
  </div>
);

const cartPathEnCa = '/en-ca/cart/';

export { CountrySuggestionEnCa, cartPathEnCa };

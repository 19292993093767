import { CountrySuggestionEnUs, cartPathEnUs } from './CountrySuggestionEnUs';
import { CountrySuggestionEnCa, cartPathEnCa } from './CountrySuggestionEnCa';
import { CountrySuggestionEnGb, cartPathEnGb } from './CountrySuggestionEnGb';
import { CountrySuggestionEnEu, cartPathEnEu } from './CountrySuggestionEnEu';

const CountrySuggestionGroup = {
  enus: CountrySuggestionEnUs,
  enca: CountrySuggestionEnCa,
  engb: CountrySuggestionEnGb,
  eneu: CountrySuggestionEnEu,
};

const cartPathGroup = {
  enus: cartPathEnUs,
  enca: cartPathEnCa,
  engb: cartPathEnGb,
  eneu: cartPathEnEu,
};

export { CountrySuggestionGroup, cartPathGroup };

import PaymentMethodsAll from './PaymentMethodsAll';
import PaymentMethodsEnUs from './PaymentMethodsEnUs';

const PaymentMethodsGroup = {
  enus: PaymentMethodsEnUs,
  enca: PaymentMethodsAll,
  engb: PaymentMethodsAll,
  eneu: PaymentMethodsAll,
  enint: PaymentMethodsEnUs,
};

export default PaymentMethodsGroup;

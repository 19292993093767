import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubmitDiv, SubmitP } from './Styled/Submitted';
import { Header, ButtonHollow } from '../Styled/common';

const propTypes = {
  hide: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

class Submitted extends Component {
  componentDidMount() {}

  render() {
    const { hide, text } = this.props;
    return (
      <SubmitDiv>
        <Header>Cookies choice submitted</Header>
        <SubmitP>You have successfully submitted your cookie preferences.</SubmitP>
        <ButtonHollow short onClick={hide}>
          {text}
        </ButtonHollow>
      </SubmitDiv>
    );
  }
}

Submitted.propTypes = propTypes;

export default Submitted;

import { KEY_CODE, KEY_NAME } from '../constants';

// Add this function to onKeyDown for any element that functions as a button
// or link to make it accessible to screen readers.

function handleKeyDown(event) {
  if ([KEY_CODE.ENTER, KEY_CODE.SPACE].includes(event.keyCode)) {
    event.target.click();
  }
  if ([KEY_NAME.ENTER].includes(event.key)) {
    event.target.click();
  }
}

export default handleKeyDown;

import React from 'react';
import Modal from '../common/withModalv2';
import { useStore } from '../../utils/storeUtils';
import handleKeyDown from '../../utils/handleKeyDown';

interface Props {
  confirmCallback?: () => void;
}

function ConfirmationModal(props: Props) {
  const [storeState, setStoreState] = useStore();
  const { confirmation, isProcessing } = storeState;
  const { body, title } = confirmation;
  const handleClick = () => {
    setStoreState({ modalOpen: '' });
  };

  return (
    <Modal modalName="CONFIRM">
      <div>
        <h2 className="spc-verification-h2 mod-font">{title}</h2>
        <p className="spc-verification-text">{body}</p>
        <div className="spc-verification-container">
          <div className="spc-verification-edit">
            <button
              onClick={e => {
                e.stopPropagation();
                setStoreState({ isProcessing: true });
                props.confirmCallback();
              }}
              type="button"
              className={`spc-verification-div-button ${isProcessing && 'mod-disabled'}`}
              data-stor-id="spc-confirm-button"
            >
              Confirm
            </button>
          </div>
          <div
            className="spc-spi-paymentmethod-link mod-padding"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-label="cancel confirmation"
          >
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;

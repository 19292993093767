import styled from 'styled-components';
import { font, media } from '../../Styled/common';

const P = styled.p`
  ${font};
  margin: 0 0 15px;
  padding: 0;
  font-size: 14px;
  ${media.nonMobile`
    font-size: 16px;
  `};
`;

export default P;

/* globals window */
// like https://github.com/airbnb/react-dates/blob/master/src/components/DateInput.jsx
import React, { Component } from 'react';

import Address from '../components/Address';
import CookieBanner from '../components/CookieBanner';
import ErrorPopup from '../components/common/ErrorPopup';
import PriceModal from '../components/common/PriceModal';
import Footer from '../components/Footer';
import GdprPortal from '../components/Gdpr/indexPortal';
import Header from '../components/Header';
import Order from '../components/Order';
import OrderDetails from '../components/common/OrderDetails';
import Review from '../components/Review';
import Shipping from '../components/Shipping';
import Verification from '../components/Verification';
import Authentication from '../components/Authentication';
import { showError } from '../utils/storeUtils';
import { ELEMENTS, ERROR_MESSAGES, STEP, NON_CC, MAGENTO_ERROR_KEYWORDS } from '../constants';
import { REGION, PRICE_MODAL_REGIONS, PRICE_MODAL_KIT_SKUS, URLS } from '../regions';
import { parseIsoCountryCode } from '../utils/i18nFields';
import { postShippingAddress, postValidation } from '../utils/storeApi';
import { Store } from '../pageSetup';
import { updateStore, UseStoreCallback } from '../utils/storeUtils';
import { trackOrderSuccess as trackOrderSuccessForEmailCapture } from '../utils/captureEmail';
import { goNextStep } from '../actions/thunks';
import { guideFocusToElement } from '../utils/scroller';
import SpcBillingContainer from '../components/Billing/SpcBillingContainer';
import { EligibilityModals } from '../components/EligibilityModals/EligibilityModals';

class CheckoutContainer extends Component<{}> {
  static contextType = Store;
  errorPop: any;
  context: { storeState: StoreState; dispatch: any };
  updateStore: UseStoreCallback;

  constructor(props, context) {
    super(props);
    this.updateStore = updateStore.bind(this, context.dispatch);
  }

  componentDidMount() {
    if (this.context.storeState.nonCC) {
      this.handleNonCCFlow();
    }
  }

  showError = (errInfo: ErrorType) => {
    this.context.dispatch({
      type: 'SHOW_ERROR',
      data: errInfo,
    });
  };

  // TODO: consolidate error handling and popup raising in SPC
  handleSubmitAddressError = (data: any = {}) => {
    if (data.rejectCart) {
      this.showEmptyCartError();
    } else if (data.wrongShippingCountry && this.context.storeState.nonCC) {
      const countryCode = this.context.storeState.address.country;
      const addressData = Object.assign({}, this.context.storeState.address);
      addressData.country = parseIsoCountryCode(REGION);
      this.showShippingAddressChangeNeededError(countryCode, data);
      this.updateStore({ address: addressData });
    } else {
      this.showAddressError();
    }
  };

  handleSubmitShipMethodError = () => {
    this.showError({
      title: 'Shipping Error',
      body: 'There was a problem processing your shipping information. Please try again.',
      button: 'edit shipping',
      element: 'shipping',
      step: STEP.SHIP_METHOD,
    });
  };

  showShippingAddressChangeNeededError = (countryCode, data) => {
    this.updateStore({ nonCC: false });
    const { paymentMethod } = this.context.storeState;
    this.showError({
      countryCode,
      body: data.error,
      button: 'edit shipping destination',
      element: 'address',
      method: paymentMethod,
      step: STEP.ADDRESS,
      title: `Notice: ${NON_CC[paymentMethod]} address change needed`,
    });
  };

  showAddressError = () => {
    this.showError({
      title: 'Address Error',
      body: ERROR_MESSAGES.ADDRESS,
      button: 'edit address',
      element: 'address',
      step: STEP.ADDRESS,
    });
  };

  showEmptyCartError = (body = ERROR_MESSAGES.EMPTY_CART) => {
    this.showError({
      title: 'Error: Empty Cart',
      body,
      button: 'return to order page',
      startOver: true,
    });
  };

  errStep = (query, step, offBy = 0) => {
    this.updateStore(
      {
        step: step - 1,
      },
      () => {
        this.context.dispatch(goNextStep(query, offBy));
      },
    );
  };

  redirectToReceiptPage = receiptId => {
    if (receiptId) window.location.href = `${URLS.RECEIPT}${receiptId}`;
  };

  trackOrderSuccess = receiptId => {
    if (this.context.storeState.gdprAvailable && receiptId) {
      trackOrderSuccessForEmailCapture({
        kits: this.context.storeState.cart.kits,
        email: this.context.storeState.address.email,
      });
    }
  };

  handleSubmitError = (data: any = {}) => {
    if (data.rejectCart) {
      this.showEmptyCartError(data.error);
    } else {
      if (
        data.error &&
        (data.error.toLowerCase().includes(MAGENTO_ERROR_KEYWORDS.AVS) ||
          data.error.toLowerCase().includes(MAGENTO_ERROR_KEYWORDS.ADDRESS) ||
          data.error.toLowerCase().includes(MAGENTO_ERROR_KEYWORDS.POSTAL_CODE))
      ) {
        data.body = ERROR_MESSAGES.ADDRESS;
      } else {
        data.body = ERROR_MESSAGES.BILLING_PROCESSING;
      }

      showError(this.context.dispatch, data);
      this.updateStore({ editableClass: '' });
      guideFocusToElement('js-error-modal'); // TODO remove
    }
  };

  submit = async event => {
    this.updateStore({
      nonCC: false,
      editableClass: ' mod-hide', // Does this need to exist? It's paired to isProcessing anyways
      isProcessing: true,
    });
    event.preventDefault();
    const { paymentMethod, billing } = this.context.storeState;
    const billingInfo = Object.assign({}, billing, {
      method: paymentMethod,
    });
    const deviceData = window.deviceData;
    const address = { ...this.context.storeState.address };
    address.email = address.email.trim().toLowerCase();
    const validate = postValidation.bind(this);
    await validate(
      {
        address,
        billing: billingInfo,
        deviceData,
        paymentMethod,
      },
      data => {
        this.trackOrderSuccess(data.receiptId);
        this.redirectToReceiptPage(data.receiptId);
      },
      e => this.handleSubmitError(e),
    );
  };

  handleNonCCFlow = () => {
    postShippingAddress(
      this.context.storeState.address,
      data => {
        if (data.error) {
          this.handleSubmitAddressError(data);
          return;
        }

        if (this.context.storeState.paymentMethod === 'paypal') {
          this.handlePaypalSettings(data);
        }

        if (this.context.storeState.paymentMethod === 'venmo') {
          this.handleVenmoSettings(data);
        }

        this.updateStore({ isProcessing: false, loading: false });
        this.context.dispatch(goNextStep(`#${ELEMENTS.review}`, -250, true));
      },
      this.handleSubmitAddressError,
    );
  };

  handlePaypalSettings = data => {
    const { cart } = this.context.storeState;
    this.updateStore({ isBillingPromo: cart.isFree && data.freeShipping });
  };

  handleVenmoSettings = data => {
    const { cart } = this.context.storeState;
    this.updateStore({ isBillingPromo: cart.isFree && data.freeShipping });
  };

  render() {
    const {
      address,
      step,
      account,
      showGDPR,
      cart,
      isProcessing,
      loading,
      isChipUpgrade,
      hasTotalHealthUpgrade,
      youdotUrl,
      pubUrl,
      verifiedAddress,
    } = this.context.storeState;
    const showPriceModal =
      PRICE_MODAL_REGIONS.includes(REGION) &&
      cart.kits.some(kit => PRICE_MODAL_KIT_SKUS.includes(kit.sku)) &&
      window.optimizelyShowPriceModal;
    const spinner = loading ? ' mod-spinner' : '';
    const isLoading = loading ? ' spc-hide' : '';
    const gdprBanner = showGDPR ? <GdprPortal /> : '';
    const priceModal = showPriceModal ? <PriceModal /> : '';
    const disabledBtnClass = isProcessing ? ' mod-disabled' : '';
    const logoUrl =
      (isChipUpgrade || hasTotalHealthUpgrade) && account.isAuthenticated ? youdotUrl : pubUrl;

    return (
      <div className={`spc${spinner}`}>
        <div className={`spc-container${isLoading}`}>
          <main role="main">
            <Header logoUrl={logoUrl} />
            <CookieBanner />
            <Authentication />
            <Order />
            <Address
              address={address}
              showEmptyCartError={this.showEmptyCartError}
              showError={this.showError}
            />
            <Verification {...{ address, verifiedAddress, step }} />
            <Shipping street={address.address} geoState={address.state} />
            <SpcBillingContainer />
            <Review
              country={address ? address.country : null}
              submit={this.submit}
              {...{ cart, disabledBtnClass }}
            />
            {this.context.storeState.showOrderDetails && <OrderDetails />}
          </main>
          <Footer />
          <EligibilityModals />
          <div className="spc-error-wrap">
            <ErrorPopup errStep={this.errStep} />
          </div>
          <div className="spc-error-wrap">{priceModal}</div>
        </div>
        {gdprBanner}
      </div>
    );
  }
}

export default CheckoutContainer;

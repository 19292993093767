import React from 'react';
import iconPlus from '../../images/iconPlus.svg';
import { REGION } from '../../regions';

interface Props {
  useServiceMark?: boolean;
  iconSize?: number;
  useIconOffset?: boolean;
  serviceMarkClass?: string;
  isTotalHealth?: boolean;
  region?: SampleShippingCountry;
}

const SubscriptionName = ({
  useServiceMark = false,
  iconSize = null,
  useIconOffset = false,
  serviceMarkClass = '',
  isTotalHealth = false,
  region,
}: Props) => {
  const trademark = <>&trade;</>;
  const symbols = {
    enus: trademark,
  };
  const regionIso = region ? 'en' + region.toLowerCase() : REGION;
  const branding = regionIso in symbols ? symbols[regionIso] : <></>;

  const shouldUseServiceMark = useServiceMark || isTotalHealth;

  return (
    <span className={`spc-subs-brand-wrap${useIconOffset ? ' brand-icon-offset' : ''}`}>
      23andMe{iconSize ? <img alt="Plus" src={iconPlus} width={iconSize} height={iconSize} /> : '+'}{' '}
      {isTotalHealth ? <span data-test-id="total-health">Total Health</span> : <>Premium</>}
      {shouldUseServiceMark && <span className={`${serviceMarkClass}`}>{branding}</span>}
    </span>
  );
};

const subscriptionName = (content = '23andMe+', isTotalHealth = false) => {
  if (!content) return '';
  const token = '23andMe+';
  if (!content.includes(token)) {
    return content;
  }

  const subscriptionName = isTotalHealth ? '23andMe+ Total Health' : '23andMe+ Premium';
  const fragments = content.split(token);
  fragments.splice(fragments.length - 1, 0, subscriptionName);
  return fragments.join('');
};

const injectSubscriptionName = (text: string, params = {}) => {
  /*
    Takes a sentence of text containing the token '23andMe+' and inserts the
    membership name, configured to the params.
   */
  const membershipToken = '23andMe+';

  if (text === '' || !text.includes(membershipToken)) return <>{text}</>;

  return (
    <>
      {text
        .split(membershipToken)
        .map((fragment, index) => <React.Fragment key={`${index}-f`}>{fragment}</React.Fragment>)
        .reduce((prev: Array<JSX.Element>, curr, index) => {
          return !prev.length
            ? [curr]
            : prev.concat([<SubscriptionName key={index} {...params} />, curr]);
        }, [])}
    </>
  );
};

export default SubscriptionName;
export { subscriptionName, injectSubscriptionName };

import React, { useState } from 'react';
import { URLS } from '../../regions';
import SubscriptionRenewalTerms from '../../components/SubscriptionPayment/SubscriptionRenewalTerms';
import SubscriptionPricing from '../../components/SubscriptionPayment/SubscriptionPricing';
import SubscriptionName from './SubscriptionName';
import SavedPaymentMethod from '../common/SavedPaymentMethod';
import handleKeyDown from '../../utils/handleKeyDown';

interface Props {
  profile: Profile;
  startSubCancellationFlow: (
    subscriptionId: string,
    sampleShippingCountry: SampleShippingCountry,
    isTotalHealth: boolean,
  ) => void;
  savedPayments: SavedPayment[];
  confirmChangePayment: (
    token: string,
    subscriptionId: string,
    lastFour: string,
    confirmActionType: ConfirmationActions,
  ) => void;
}

const SubscriptionProfile = ({
  profile,
  startSubCancellationFlow,
  savedPayments,
  confirmChangePayment,
}: Props) => {
  const initialState = { showChangePayment: false };

  const [state, setState] = useState(initialState);

  const startChangePaymentFlow = () => {
    setState({ ...state, showChangePayment: true });
  };

  const getProfileBillingMessage = (profile: Profile) => {
    if (!profile.subscriptionInfo) {
      return null;
    }
    if (profile.subscriptionInfo.newPrepaid || profile.subscriptionInfo.isRenewable) {
      const prepaid = profile.subscriptionInfo.newPrepaid || profile.subscriptionInfo.activePrepaid;
      const endDateString = profile.subscriptionInfo.endDate;
      const endDate = new Date(endDateString);
      const today = new Date();
      const expiredSub = endDate < today;
      const applicableDatesText = profile.subscriptionInfo.newPrepaid
        ? ' will begin when you receive your reports.'
        : expiredSub
        ? ` ended on ${endDateString}.`
        : ` will end on ${endDateString} if not renewed.`;
      return (
        <p className="spc-spi-subs-renew">
          Your {prepaid && 'prepaid '}membership and access to{' '}
          <SubscriptionName isTotalHealth={profile.subscriptionInfo.isTotalHealth} />
          {applicableDatesText}
        </p>
      );
    }
    return (
      <SubscriptionRenewalTerms
        subscriptionInfo={profile.subscriptionInfo}
        isDelayedBilling={!profile.genotyped}
        className="spc-spi-subs-renew"
        showCancelTerms={false}
      />
    );
  };

  const getProfileCta = (profile: Profile) => {
    let profileButton;
    if (profile.hasPendingTotalHealthUpgrade) {
      profileButton = <span></span>;
    } else if (!profile.subscriptionInfo || profile.subscriptionInfo.newPrepaid) {
      profileButton = <span></span>;
    } else if (profile.subscriptionInfo.isRenewable) {
      profileButton = (
        <button
          className="spc-button-link spc-spi-subs-button nowrap"
          onClick={() => {
            window.location.href = `${URLS.SUBSCRIPTION_RENEWAL}?human=${profile.id}`;
          }}
          data-stor-id="cancel-subscription-button"
        >
          Renew Membership
        </button>
      );
    } else {
      const receiptid = profile.subscriptionInfo.receiptId;
      const region = profile.sampleShippingCountry;
      const isTotalHealth = profile.subscriptionInfo.isTotalHealth;
      profileButton = (
        <button
          className="spc-button-link spc-spi-subs-button nowrap cancel-button"
          onClick={() => startSubCancellationFlow(receiptid, region, isTotalHealth)}
          data-stor-id="cancel-subscription-button"
        >
          Cancel auto-renewal
        </button>
      );
    }

    return profileButton;
  };

  const getSubscriptionProfileInfo = (profile: Profile) => {
    const profileBillingMessage = getProfileBillingMessage(profile);
    const isTotalHealth = profile.isSubscriber && profile.subscriptionInfo.isTotalHealth;
    const displayPremiumForTotalHealthUpgrade =
      profile.hasPendingTotalHealthUpgrade && profile.isSubscriber;
    const hidePremiumForTotalHealthUpgrade =
      profile.hasPendingTotalHealthUpgrade && !profile.isSubscriber;
    return (
      <div className="spc-spi-subs-profile">
        <div className="spc-spi-subs-image">
          {profile.image ? (
            <img
              className="spc-spi-subs-image-initials mod-img"
              src={profile.image.url}
              alt={profile.initials}
            ></img>
          ) : (
            <div className="spc-spi-subs-image-initials">{profile.initials}</div>
          )}
        </div>
        <div className="spc-spi-subs-name">
          <div>
            <span>
              {profile.firstName} {profile.lastName}
            </span>
            {!isTotalHealth && (
              <span className="spc-spi-subs-badge">
                <span className="spc-spi-subs-badge-content">
                  <SubscriptionName
                    iconSize={8}
                    isTotalHealth={
                      profile.subscriptionInfo?.isTotalHealth || hidePremiumForTotalHealthUpgrade
                    }
                  />
                </span>
              </span>
            )}
          </div>
          <p className="spc-spi-subs-type">
            <span className="spc-spi-subs-subheader">Membership: </span>
            <SubscriptionName
              useServiceMark={true}
              region={profile.sampleShippingCountry}
              isTotalHealth={
                profile.subscriptionInfo?.isTotalHealth || hidePremiumForTotalHealthUpgrade
              }
            />
          </p>
          {profile.subscriptionInfo && (
            <p className="spc-spi-subs-price">
              <span className="spc-spi-subs-subheader">Price: </span>
              <SubscriptionPricing subscriptionInfo={profile.subscriptionInfo} />
            </p>
          )}
          {profileBillingMessage}
          {displayPremiumForTotalHealthUpgrade && (
            <p className="total-health-upgrade-notice">
              Hold tight! Your <SubscriptionName /> membership will upgrade into the{' '}
              <SubscriptionName isTotalHealth={true} /> membership here once you register your kit
              and fill out information to get set up for your virtual consultation. You can change
              your payment method at any time, and can update or cancel your membership after your
              membership begins.
            </p>
          )}
          {hidePremiumForTotalHealthUpgrade && (
            <p className="total-health-upgrade-notice">
              Hold tight! Your <SubscriptionName isTotalHealth={true} /> membership will appear here
              once you register your kit and fill out information to get set up for your virtual
              consultation. You can change your payment method at any time, and can update or cancel
              your membership after your membership begins.
            </p>
          )}
        </div>
      </div>
    );
  };

  const getPaymentMethod = (profile: Profile) => {
    if (!profile.isSubscriber || !profile.subscriptionInfo.token) return;
    const paymentMethod = savedPayments.find(
      payment => payment.token === profile.subscriptionInfo.token,
    );
    const showChangePayment =
      !state.showChangePayment &&
      !profile.subscriptionInfo.isRenewable &&
      savedPayments.some(
        payment => payment.isCreditCard && payment.token !== profile.subscriptionInfo.token,
      );
    return (
      <>
        <div className="spc-spi-subs-payment-wrap">
          {paymentMethod && <SavedPaymentMethod savedPayment={paymentMethod} />}
        </div>
        {showChangePayment && (
          <div className="spc-spi-subs-button-container">
            <button
              className="spc-button-link spc-spi-subs-button nowrap"
              data-stor-id="cancel-subscription-button"
              onClick={() => startChangePaymentFlow()}
            >
              Change payment method
            </button>
          </div>
        )}
      </>
    );
  };

  const getPaymentMethodOptions = (profile: Profile) => {
    const paymentOptions = savedPayments.filter(payment => payment.isCreditCard);
    const paymentMethods = paymentOptions.map(savedPayment => (
      <div
        key={savedPayment.token}
        className="spc-spi-subs-payment-wrap mod-select"
        onClick={() =>
          confirmChangePayment(
            profile.subscriptionInfo.receiptId,
            savedPayment.token,
            savedPayment.lastFour,
            'UPDATE_SUBSCRIPTION',
          )
        }
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-label="select saved payment for subscription"
      >
        <SavedPaymentMethod savedPayment={savedPayment} />
      </div>
    ));
    return (
      <div className="spc-spi-subs-payment-details">
        <div>Select Payment</div>
        {paymentMethods}
      </div>
    );
  };

  return (
    <li className="spc-spi-subs-li">
      <div className="spc-spi-subs-li-container">
        {getSubscriptionProfileInfo(profile)}
        <br />
        <div className="spc-spi-subs-payment-container">
          {getPaymentMethod(profile)}
          <div className="spc-spi-subs-button-container">{getProfileCta(profile)}</div>
        </div>
      </div>
      {state.showChangePayment && getPaymentMethodOptions(profile)}
    </li>
  );
};

export default SubscriptionProfile;

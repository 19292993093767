import * as React from 'react';
import Checkbox from '../common/Checkbox';
import TermsOfServiceGroup from '../common/intl/TermsOfServiceGroup/TermsOfServiceGroup';
import OrderButtonGroup from '../common/intl/OrderButtonGroup/OrderButtonGroup';
import { SHIPPING_CODES, STEP, ELEMENTS } from '../../constants';
import { URLS, REGION } from '../../regions';
import ItemizedTaxes from '../common/ItemizedTaxes';
import { useStore } from '../../utils/storeUtils';
import { useState } from 'react';
import SubscriptionName from '../SubscriptionPayment/SubscriptionName';
import dataLayer from '../../utils/dataLayer';

interface Props {
  country?: string;
  disabledBtnClass: string;
  cart: Cart;
  submit: (e: Event) => void;
}

export default function ReviewContainer({ country = 'US', disabledBtnClass, cart, submit }: Props) {
  const [ok, setOk] = useState(false);
  const [errTxt, setErrTxt] = useState(false);
  const [storeState] = useStore();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!ok) {
      setErrTxt(true);
      return;
    }
    submit((e as unknown) as Event);
  };

  const acceptTerms = () => {
    const newOk = !ok;
    setOk(newOk);
    if (newOk && errTxt) {
      setErrTxt(false);
    }
    const { hasTotalHealthUpgrade, cart } = storeState;
    if (newOk && hasTotalHealthUpgrade) {
      dataLayer.totalHealthAcceptTos(cart.kits[0].alias, cart.kits[0].discountPrice);
    }
  };

  const {
    shipMethod,
    step,
    showGDPR,
    isChipUpgrade,
    isPrepaidSubscription,
    hasTotalHealth,
    hasTotalHealthUpgrade,
    isEmptyCart,
  } = storeState;

  if (isEmptyCart) {
    return null;
  }

  const { totals, taxes, kits } = cart;

  const shipMethodNames = {
    [SHIPPING_CODES.STANDARD]: 'Standard',
    [SHIPPING_CODES.EXPEDITED]: 'Expedited',
    [SHIPPING_CODES.EXPRESS]: 'Express',
    [SHIPPING_CODES.BOOK_STANDARD]: 'Standard',
    [SHIPPING_CODES.BOOK_EXPEDITED]: 'Expedited',
    [SHIPPING_CODES.BOOK_EXPRESS]: 'Express',
  };

  const shipMethodShort = shipMethodNames[shipMethod.code];
  const amountUnavailable = '-';

  const acceptErrMsg = (
    <h3 role="alert" className="spc-billing-err mod-terms" data-stor-id="spc-tos-error-message">
      Please accept the terms of service.
    </h3>
  );
  const prepaidBundleMsg =
    !hasTotalHealth && isPrepaidSubscription ? (
      <li className="spc-summary-prepaid-msg">
        The one year <SubscriptionName /> Membership will begin when the person who uses the Health
        + Ancestry Service associated with the membership receives reports. The payment method used
        for this purchase will not be associated with the renewal of the membership.
      </li>
    ) : null;

  const TermsOfService = TermsOfServiceGroup[REGION];
  const OrderButton = OrderButtonGroup[REGION];

  const totalHealthUpgradeTerms = hasTotalHealthUpgrade && step >= STEP.REVIEW && (
    <div
      className="spc-totalhealth-upgrade-billing-terms-wrap"
      data-stor-id="total-health-disclosure"
    >
      <div className="spc-totalhealth-upgrade-billing-terms">
        Your saved payment method will be charged {kits[0].displayFirstYearPlanPrice}/first year,
        renewing at {kits[0].displayRenewalPlanYearlyPrice}/yr. Your{' '}
        <SubscriptionName isTotalHealth={true} /> membership will begin after you receive and
        register your kit and complete your Clinic profile. You can change your payment method at
        any time, and can update or cancel your membership after your membership begins.
      </div>
    </div>
  );

  // TODO: maybe need a check to ensure all info is complete before submit?
  const acceptForm =
    step > STEP.BILLING ? (
      <form
        className={`spc-summary-accept ${step === STEP.REVIEW ? 'mod-fade-in' : ''}`}
        data-testid="acceptForm"
        onSubmit={handleSubmit}
      >
        <div className="spc-form-content-wrap-">
          <div className="spc-payment-submit-wrap">
            {errTxt ? acceptErrMsg : ''}
            <div className={`spc-checkbox-outer ${errTxt ? 'mod-err' : ''}`}>
              <TermsOfService
                {...{
                  isChipUpgrade,
                  isPrepaidSubscription,
                  hasTotalHealth,
                  hasTotalHealthUpgrade,
                }}
              />
              <Checkbox
                handleCheck={acceptTerms}
                checked={ok}
                dataStorId="spc-accept-tos-checkbox"
                ariaLabel="I accept the Terms of Service"
              />
            </div>
            {totalHealthUpgradeTerms}
          </div>
          <OrderButton disabledBtnClass={disabledBtnClass} accepted={ok} ariaDisabled={true} />
        </div>
      </form>
    ) : null;

  const gdprLink = showGDPR ? (
    <li>
      <a
        className="spc-summary-links-a"
        href={URLS.DATA_PROTECTION}
        target="_blank"
        rel="noopener noreferrer"
      >
        Data Protection
      </a>
    </li>
  ) : null;

  const totalHealthDisclaimer = hasTotalHealth && !hasTotalHealthUpgrade && step >= STEP.REVIEW && (
    <div className="spc-totalhealth" data-stor-id="total-health-disclosure">
      By clicking SUBMIT ORDER you acknowledge that the Total Health membership fee includes lab
      processing fees charged by third-party laboratories and the healthcare providers&apos;
      professional fees. The payment method used for this purchase will not be associated with the
      renewal of the membership. Valid payment method required at kit registration. NOTE: only users
      who are 18+ years of age are eligible. 23andMe+ Total Health services are not available in HI,
      NJ, NY, RI, and US territories. The annual 23andMe+ Total Health membership will begin when
      the member receives their Health + Ancestry Service reports.
    </div>
  );

  return (
    <div className="spc-outer">
      <div className="spc-wrap spc-summary">
        <div className="spc-align mod-border">
          <h2
            id={ELEMENTS.review}
            className="spc-align-h2"
            data-stor-id="spc-order-review-header"
            tabIndex={-1}
          >
            Order Review
          </h2>
        </div>
        <div className="spc-summary-wrap">
          <ul className="spc-summary-links">
            {gdprLink}
            {!isChipUpgrade && (
              <li>
                <a
                  className="spc-summary-links-a"
                  href={URLS.REFUND_POLICY[REGION]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Refund Policy
                </a>
              </li>
            )}
            {prepaidBundleMsg}
          </ul>
          <div className="spc-summary-totals">
            <div className="spc-summary-text mod-top">
              <div>Subtotal ({totals.itemsCount})</div>
              <div>{totals.displayItemsTotalWithDiscount}</div>
            </div>
            <div className="spc-summary-text">
              <div>Shipping {step <= STEP.SHIP_METHOD ? '' : `(${shipMethodShort})`}</div>
              <div>
                {step <= STEP.SHIP_METHOD ? amountUnavailable : totals.displayShippingWithDiscount}
              </div>
            </div>
            <ItemizedTaxes
              displayTaxAmount={totals.displayTaxAmount}
              taxAmount={totals.taxAmount}
              {...{ country, step, taxes }}
            />
            <div className="spc-summary-text mod-total">
              <div>Total</div>
              <div>{step <= STEP.SHIP_METHOD ? amountUnavailable : totals.displayTotal}</div>
            </div>
          </div>
        </div>
        {totalHealthDisclaimer}
        {acceptForm}
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import OptIn from './OptIn';

const propTypes = {
  gdprOptInShow: PropTypes.bool,
};
const defaultProps = {
  gdprOptInShow: true,
};

const startingEl = document.createElement('div');
startingEl.id = 'js-gdpr-optin';
document.body.appendChild(startingEl);

class LinkToPopup extends Component {
  render() {
    const { gdprOptInShow } = this.props;
    return gdprOptInShow ? ReactDOM.createPortal(<OptIn />, startingEl) : null;
  }
}
LinkToPopup.propTypes = propTypes;
LinkToPopup.defaultProps = defaultProps;
export default LinkToPopup;

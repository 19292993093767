/* eslint-disable @typescript-eslint/indent */
/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { media, fader, font } from '../../Styled/common';

const blue = '#3595d6';
const DivFadeIn = styled.div`
  ${fader};
`;
const InfoWrap = styled.div`
  padding: 5px 0 20px;
  border-bottom: 1px solid #9c9ea1;
  ${font};
`;

const LinkToggle = styled.a`
  font-size: 12px;
  font-weight: normal;
  line-height: 1.75;
  ${font};
  color: ${blue};
  display: inline-block;
  outline: none;
  cursor: pointer;
  height: auto;
  border-radius: 0 !important;
  background: transparent !important;
  padding: 0;
  &:focus {
    text-decoration: underline;
  }
  &::before {
    content: '\\2227';
    transform: rotate(180deg) scale(1.6, 1) translateY(-2px);
    font-weight: 600;
    display: inline-block;
    font-size: 13px;
    margin-right: 8px;
    margin-left: 2px;
    ${props =>
      props.active &&
      css`
        transform: scale(1.6, 1);
      `};
  }
`;

const AdvancedAds = styled.div`
  border-bottom: 1px solid #9c9ea1;
  padding-bottom: 20px;
  ${media.mobile`
    margin-bottom: 20px;
  `};
`;

const FlexRow = styled.div`
  ${media.nonMobile`
    display: flex;
    flex-wrap: wrap;
  `};
`;

export { DivFadeIn, InfoWrap, LinkToggle, AdvancedAds, FlexRow };

import React from 'react';
import { useCreditCard, useSavedPayments } from './hooks';
import { RadioOption } from './RadioOption';

export type ClickEditSavedPaymentEventHandler = (
  event: React.PointerEvent<HTMLButtonElement>,
  args: {
    token: string;
  },
) => void;

export interface Props {
  selectedMethod: MethodOptions;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    args: {
      method: MethodOptions;
    },
  ) => void;
  onClickEditSavedPayment?: ClickEditSavedPaymentEventHandler;
}

/**
 * Radio buttons for selecting payment method, including saved payments, paypal, and applepay
 */
export const PaymentMethodRadioGroup = ({
  selectedMethod,
  onChange,
  onClickEditSavedPayment,
}: Props) => {
  // Store API only supports checking out with default saved payment, so only render default
  // saved payment option for now. Use savedPayments from useSavedPayments hook when other
  // saved payments can be used for checkout.
  const { savedPayments } = useSavedPayments();
  const { hasCreditCardData, creditCardData } = useCreditCard();

  return (
    <fieldset>
      <legend>Payment Method</legend>
      {hasCreditCardData && (
        <RadioOption
          key={creditCardData.nonce}
          id={`credit-card`}
          className="spc-payment-method-option"
          name="method"
          value="saved_payment"
          checked={selectedMethod.type === 'credit_card'}
          onChange={function handleSelectSavedPayment(event) {
            onChange(event, {
              method: {
                type: 'credit_card',
                token: creditCardData.nonce,
              },
            });
          }}
        >
          {creditCardData.cardType} ****{creditCardData.lastFour}
        </RadioOption>
      )}
      {savedPayments.map(savedPayment => (
        <RadioOption
          key={savedPayment.token}
          id={`saved-payment-${savedPayment.token}`}
          className="spc-payment-method-option"
          name="method"
          value="saved_payment"
          checked={
            selectedMethod.type === 'saved_payment' && savedPayment.token === selectedMethod.token
          }
          onChange={function handleSelectSavedPayment(event) {
            onChange(event, {
              method: {
                type: 'saved_payment',
                token: savedPayment.token,
              },
            });
          }}
          onClickEdit={
            onClickEditSavedPayment &&
            function handleClickEditSavedPayment(event) {
              onClickEditSavedPayment(event, {
                token: savedPayment.token,
              });
            }
          }
        >
          <img
            src={savedPayment.imageUrl}
            alt={`${savedPayment.cardType} ending in ${savedPayment.lastFour}`}
          />{' '}
          ending in ({savedPayment.lastFour})
        </RadioOption>
      ))}
    </fieldset>
  );
};

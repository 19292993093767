import React from 'react';
import { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import back from '../../images/back.svg';

import SubscriptionCancellationConfirm from './SubscriptionCancellationConfirm';
import SubscriptionCancellationSurvey from './SubscriptionCancellationSurvey';
import SubscriptionCancellationComplete from './SubscriptionCancellationComplete';
import { cancelSubscription, postSurveyResults } from '../../utils/storeApi';
import GaEventHandler from '../../utils/dataLayer';
import { showError, useStoreContext } from '../../utils/storeUtils';
import ErrorPopup from '../common/ErrorPopup';

interface Props {
  subscriptionId: string;
  sampleShippingCountry: SampleShippingCountry;
  emailAddress: string;
  youdotUrl: string;
  isTotalHealth: boolean;
}

export default function SubscriptionCancellationFlow({
  subscriptionId,
  sampleShippingCountry,
  emailAddress,
  youdotUrl,
  isTotalHealth,
}: Props) {
  const SCREEN_CANCELLATION_CONFIRM = 0;
  const SCREEN_CANCELLATION_SURVEY = 1;
  const SCREEN_CANCELLATION_COMPLETE = 2;
  const { storeState, dispatch } = useStoreContext();

  const [current, setCurrent] = useState(SCREEN_CANCELLATION_CONFIRM);
  if (current === SCREEN_CANCELLATION_CONFIRM) {
    window.scrollTo(0, 0);
  }

  const handleConfirm = () => {
    setCurrent(SCREEN_CANCELLATION_SURVEY);
  };

  const handleSubmit = (checkboxPayload, textareaPayload, setSubmitted) => {
    cancelSubscription(
      subscriptionId,
      () => {
        postSurveyResults(checkboxPayload);
        if (textareaPayload !== null) {
          postSurveyResults(textareaPayload);
        }
        setCurrent(SCREEN_CANCELLATION_COMPLETE);
      },
      () => {
        const style = {
          fontSize: 'unset',
        };
        const errorMessage = (
          <>
            It looks like there was an issue. Please try a different payment method or try again
            later. Reach out to{' '}
            <a
              href="https://customercare.23andme.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
              style={style}
            >
              Customer Care
            </a>{' '}
            with any questions
          </>
        );
        const errData = {
          body: errorMessage,
          button: 'Try Again',
        };
        showError(dispatch, errData);
        setSubmitted(false);
      },
    );
    GaEventHandler.pushToDataLayer({
      event: 'gaEvent',
      gaEventCategory: 'store_subscription',
      gaEventAction: 'link_click',
      gaEventLabel: 'cancel_subscription',
      gaEventValue: 0,
      gaEventNonInteractionBool: false,
    });
  };

  let screen = (
    <SubscriptionCancellationConfirm
      handleConfirm={handleConfirm}
      sampleShippingCountry={sampleShippingCountry}
      youdotUrl={youdotUrl}
      isTotalHealth={isTotalHealth}
    />
  );
  if (current === SCREEN_CANCELLATION_SURVEY) {
    screen = (
      <SubscriptionCancellationSurvey
        subscriptionId={subscriptionId}
        sampleShippingCountry={sampleShippingCountry}
        handleSubmit={handleSubmit}
        youdotUrl={youdotUrl}
        isTotalHealth={isTotalHealth}
      />
    );
  } else if (current === SCREEN_CANCELLATION_COMPLETE) {
    screen = (
      <SubscriptionCancellationComplete
        emailAddress={emailAddress}
        sampleShippingCountry={sampleShippingCountry}
        youdotUrl={youdotUrl}
      />
    );
  }

  return (
    <div className="spc-subs-cancel" data-stor-id="subscription-cancellation-text">
      <Header />
      <div className="spc-subs-cancel-container">
        <div className="spc-subs-cancel-back-btn-wrapper">
          <a className="spc-subs-cancel-back-btn" href={`${youdotUrl}user`}>
            <span className="spc-subs-cancel-back-btn-container">
              <img
                className="spc-subs-cancel-back-btn-icon"
                src={back}
                alt="Back to settings"
                width="24"
                height="24"
              />
              <span className="spc-subs-cancel-back-btn-text">Back to settings</span>
            </span>
          </a>
        </div>
        {screen}
      </div>
      <Footer />
      <div className="spc-error-wrap">
        <ErrorPopup />
      </div>
    </div>
  );
}

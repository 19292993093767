import * as React from 'react';
import handleKeyDown from '../../utils/handleKeyDown';
import classNames from 'classnames';

interface Props {
  savedPaymentCallback: () => void;
  ariaLabel: string;
  ctaText: string;
  dataStorId?: string;
  disableAction?: boolean;
  toolTipText?: string;
}

export default function SavedPaymentCta({
  savedPaymentCallback,
  ariaLabel,
  ctaText,
  dataStorId,
  disableAction = false,
  toolTipText = '',
}: Props) {
  const savedPaymentButtonClassNames = classNames('spc-spi-paymentmethod-link', 'tooltip', {
    'mod-disabled': disableAction,
  });

  const toolTip = toolTipText && (
    <div className="tooltip-data-saved-payment" role="tooltip">
      {toolTipText}
    </div>
  );

  return (
    <div
      className={savedPaymentButtonClassNames}
      onClick={() => !disableAction && savedPaymentCallback()}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-label={ariaLabel}
      data-stor-id={dataStorId}
    >
      <p>{ctaText}</p>
      {toolTip}
    </div>
  );
}

import React from 'react';
import add from '../../../images/ic_add.svg';
import {
  SavedBillingAddressRadioGroup,
  Props as SavedBillingAddressRadioGroupProps,
} from './SavedBillingAddressRadioGroup';

export type ClickAddAddressEventHandler = React.PointerEventHandler<HTMLButtonElement>;

interface Props extends SavedBillingAddressRadioGroupProps {
  showError?: boolean;

  // event handler for "Add a new address" button
  onClickAddAddress?: ClickAddAddressEventHandler;
}

/**
 * Wrapper component for saved billing address fields (primarily for consistent structure)
 */
export const SavedBillingAddressSection = ({
  selectedAddressId,
  showError = false,
  onChange,
  onClickEditAddress,
  onClickAddAddress,
}: Props) => {
  return (
    <section className="spc-saved-address-selector">
      <SavedBillingAddressRadioGroup {...{ selectedAddressId, onChange, onClickEditAddress }} />
      {showError && <p>Please select an addresss</p>}
      {onClickAddAddress && (
        <button
          className="sd-button-anchor spc-add-to-list"
          data-stor-id="clinic-enter-address-button"
          onClick={onClickAddAddress}
        >
          <img className="spc-subs-back-btn-icon" src={add} width="24" height="24" alt="" />
          Add a new address
        </button>
      )}
    </section>
  );
};

import React from 'react';
import Modal from '../Modal';
import { Consumer } from '../Provider';
import Choices from './Choices';
import Submitted from './Submitted';

const Popup = () => (
  <Consumer>
    {({ state, handleSwitch, submit, hide }) => {
      switch (state.step) {
        case 1:
          return (
            <Modal>
              <Choices
                handleSwitch={handleSwitch}
                hide={hide}
                submit={submit}
                choice={state.choice}
              />
            </Modal>
          );
        default:
          return (
            <Modal>
              <Submitted hide={hide} text={state.closeTxt} />
            </Modal>
          );
      }
    }}
  </Consumer>
);

export default Popup;

import React from 'react';

interface Props {
  height?: string;
  width?: string;
}

const ClinicHeartHealthCheckup = ({ height = '48px', width = '48px' }: Props) => (
  <div className="spc-no-events spc-float-right" tabIndex={-1}>
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Essential Heart Health Checkup</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4436 4.5C29.6824 4.5 25.8597 7.14673 24 11.0141V11.013C22.1413 7.14673 18.3175 4.5 13.5574 4.5C6.77313 4.5 2.5 9.87251 2.5 16.5004C2.5 31.5955 23.9871 43.4937 24 43.5C24 43.5 45.5 31.5997 45.5 16.5004C45.5 9.87251 41.229 4.5 34.4436 4.5Z"
        fill="#AF1111"
      />
      <path
        d="M33.5 42C35.7091 42 37.5 40.2091 37.5 38L37.5 34.5L41 34.5C43.2091 34.5 45 32.7091 45 30.5C45 28.2909 43.2091 26.5 41 26.5L37.5 26.5V23C37.5 20.7909 35.7091 19 33.5 19C31.2909 19 29.5 20.7909 29.5 23V26.5L26 26.5C23.7909 26.5 22 28.2909 22 30.5C22 32.7091 23.7909 34.5 26 34.5L29.5 34.5L29.5 38C29.5 40.2091 31.2909 42 33.5 42Z"
        fill="white"
        stroke="#00B5B5"
        strokeWidth="3"
      />
    </svg>
  </div>
);

ClinicHeartHealthCheckup.displayName = 'ClinicHeartHealthCheckup';

export default ClinicHeartHealthCheckup;

import React from 'react';

interface Props {
  countryText: string;
}
const CountryFieldStatic = (props: Props) => (
  <div className="spc-select">
    <div className="spc-select-input mod-border">{props.countryText}</div>
    <div className="label mod-not-empty">
      <label htmlFor="country">country</label>
    </div>
  </div>
);

export default CountryFieldStatic;

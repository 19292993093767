// This is more or less copied from nextpub VariationProvider.tsx, although I removed a couple of
// features that aren't relevant to our use case right now

import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { VariationContextInterface } from './VariationContextInterface';

declare global {
  interface Window {
    TTAM: any;
  }
}

const defaultContextValue: VariationContextInterface = {};

export const TTAM_VARIATION_PROVIDER_DID_MOUNT_EVENT = 'ttamVariationProviderDidMount';

export const VariationContext = createContext(defaultContextValue);

interface VariationProviderProps {
  children: ReactNode;
}

export const VariationProvider = ({ children }: VariationProviderProps) => {
  const [variations, setVariations] = useState(defaultContextValue);

  useEffect(() => {
    window.TTAM = window.TTAM || {};
    window.TTAM.setVariation = (newVariations: VariationContextInterface) => {
      setVariations(prevVariations => ({
        ...prevVariations,
        ...newVariations,
      }));
    };

    // dispatch event for Optimizely to hook into
    document.dispatchEvent(new CustomEvent(TTAM_VARIATION_PROVIDER_DID_MOUNT_EVENT));
  }, []);

  return <VariationContext.Provider value={variations}>{children}</VariationContext.Provider>;
};

import * as React from 'react';
import hasPOBox from '../../utils/hasPOBox';
import handleKeyDown from '../../utils/handleKeyDown';
import { useStore } from '../../utils/storeUtils';
import { SHIPPING_CODES, ELEMENTS } from '../../constants';

interface Props {
  title: string;
  street: string;
  geoState: string;
  displayPrice: string;
  code: string;
}

export const UNAVAILABLE_MESSAGE_PO_BOX = 'Express delivery is not available for PO Box addresses.';
export const UNAVAILABLE_MESSAGE_STATE = 'Express delivery is not available for this address.';

const ShippingMethod = ({ code, title, street, geoState, displayPrice }: Props) => {
  let utilMethod;
  const [storeState, setStoreState] = useStore();
  const { isProcessing, expressStatesBlacklist, shipMethod } = storeState;
  const isExpressMethod = code === SHIPPING_CODES.EXPRESS;
  const isPOBox = isExpressMethod && hasPOBox(street);
  const isExcludedState =
    isExpressMethod && expressStatesBlacklist && expressStatesBlacklist.includes(geoState);

  let shippingUnavailableMessage = null;

  const handleChange = event => {
    if (isProcessing) {
      return;
    }
    const code = event.target.value;
    const shipMethod = storeState.shippingMethods.find(method => method.code === code);
    setStoreState({ shipMethod });
  };

  if (isPOBox) {
    shippingUnavailableMessage = UNAVAILABLE_MESSAGE_PO_BOX;
  } else if (isExcludedState) {
    shippingUnavailableMessage = UNAVAILABLE_MESSAGE_STATE;
  }

  if (shippingUnavailableMessage) {
    utilMethod = (
      <div>
        <div className="spc-shipping-method mod-express" data-jest-id="spc-shipping-method">
          <input
            id={ELEMENTS.shippingMethod}
            type="radio"
            name="shippingMethod"
            className="spc-shipping-method-radio"
            disabled
          />{' '}
          {shippingUnavailableMessage}
        </div>
      </div>
    );
  } else {
    const ariaLabel = title.split('-').join(' to ');
    utilMethod = (
      <div
        className="spc-shipping-method spc-pointer"
        onClick={e => {
          e.stopPropagation();
          const element = e.target as HTMLElement;
          if (element.firstElementChild === null) {
            return;
          }
          const firstChild = element.firstElementChild as HTMLInputElement;
          firstChild.checked = true;
          handleChange({ target: { value: firstChild.value } });
        }}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-label={ariaLabel}
      >
        <input
          id={ELEMENTS.shippingMethod + code}
          type="radio"
          name="shippingMethod"
          className="spc-shipping-method-radio"
          data-stor-id={`spc-${code}-radio`}
          checked={shipMethod.code === code}
          value={code}
          onChange={handleChange}
          aria-hidden={true}
          tabIndex={-1}
        />{' '}
        {title} - {displayPrice}
      </div>
    );
  }

  return <div>{utilMethod}</div>;
};

export default ShippingMethod;

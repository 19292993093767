import React, { useState } from 'react';
import { useStore } from '../utils/storeUtils';
import Header from '../components/Header';
import SavedPayment from '../components/SavedPayment';
import Footer from '../components/Footer';
import { BILLING_METHODS, ERROR_MESSAGES } from '../constants';
import {
  removeSavedPayment,
  updateSavedPayment,
  updateSubscriptionPayment,
} from '../utils/storeApi';
import PaymentForm from '../components/common/PaymentForm';
import SubscriptionCancellationFlow from '../components/SubscriptionPayment/SubscriptionCancellationFlow';
import back from '../images/back.svg';
import { URLS } from '../regions';
import GaEventHandler from '../utils/dataLayer';
import SubscriptionProfiles from '../components/SubscriptionPayment/SubscriptionProfiles';
import SubscriptionName from './../components/SubscriptionPayment/SubscriptionName';
import { SavedPaymentBanner } from './SavedPaymentBanner';
import ConfirmationModal from '../components/SavedPayment/ConfirmationModal';
import ErrorPopup from '../components/common/ErrorPopup';

export default function SavedPaymentInfo() {
  const [storeState, updateStoreState] = useStore();
  const initialState = {
    displayAddForm: false,
    isSubscribed: storeState.hasSubscribedProfile,
    showSubCancellationFlow: false,
    showSubs: storeState.openSubsTab,
    showSpi: !storeState.openSubsTab,
    isUpdatingPayment: false,
    tokenToUpdate: '',
    confirmActionType: '',
    addressToUpdate: '',
  };

  const [state, setState] = useState(initialState);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [sampleShippingCountry, setSampleShippingCountry] = useState(null);
  const [isTotalHealth, setIsTotalHealth] = useState(null);

  const displayAddPaymentForm = () => {
    setState({
      ...state,
      displayAddForm: true,
      isUpdatingPayment: false,
      tokenToUpdate: '',
      addressToUpdate: '',
    });
  };

  const displayUpdatePaymentForm = (token: string, address: string) => {
    setState({
      ...state,
      tokenToUpdate: token,
      displayAddForm: true,
      isUpdatingPayment: true,
      addressToUpdate: address,
    });
  };

  const clearAddPaymentForm = () => {
    setState({ ...state, displayAddForm: false });
    updateStoreState({ isProcessing: false });
  };

  const showError = () => {
    const error = storeState.error;
    error.body = ERROR_MESSAGES.BILLING_PROCESSING;
    error.button = 'continue';
    updateStoreState({ error, modalOpen: 'ERROR', isProcessing: false });
  };

  const confirmQuickAction = (
    token: string,
    lastFour: string,
    confirmActionType: ConfirmationActions,
  ) => {
    setState({ ...state, tokenToUpdate: token, confirmActionType });
    const confirmation = storeState.confirmation;
    if (confirmActionType === 'DELETE_PAYMENT') {
      confirmation.title = '';
      confirmation.body = `Remove card ending in ${lastFour}?`;
    } else {
      confirmation.title = 'Set as default payment';
      confirmation.body = `Set card ending in ${lastFour} as default?`;
    }

    updateStoreState({ confirmation, modalOpen: 'CONFIRM' });
  };

  const confirmChangePayment = (
    subscriptionId: string,
    token: string,
    lastFour: string,
    confirmActionType: ConfirmationActions,
  ) => {
    setState({ ...state, tokenToUpdate: token, confirmActionType });
    setSubscriptionId(subscriptionId);
    const confirmation = storeState.confirmation;
    confirmation.title = 'Select Payment';
    confirmation.body = `Change subscription payment to card ending in ${lastFour}?`;
    updateStoreState({ confirmation, modalOpen: 'CONFIRM' });
  };

  const confirmAction = () => {
    if (state.confirmActionType === 'DELETE_PAYMENT') {
      removeSavedPayment(
        state.tokenToUpdate,
        event => {
          if (event['success']) {
            const account = Object.assign({}, storeState.account, event.account);
            updateStoreState({ account, modalOpen: '', isProcessing: false });
          }
        },
        showError,
      );
    } else if (state.confirmActionType === 'UPDATE_PAYMENT') {
      const payload = { paymentMethodToken: state.tokenToUpdate, makeDefault: true };
      updateSavedPayment(
        payload,
        e => updateStoreState({ account: e.account, isProcessing: false, modalOpen: '' }),
        showError,
      );
    } else if (state.confirmActionType === 'UPDATE_SUBSCRIPTION') {
      const url = `${URLS.SAVED_PAYMENTS}?subscription`;
      const body = { paymentMethodToken: state.tokenToUpdate, subscriptionId };
      updateSubscriptionPayment(body, () => (window.location.href = url), showError);
    }
  };

  const startSubCancellationFlow = (subscriptionId, sampleShippingCountry, totalHealth) => {
    setState({ ...state, showSubCancellationFlow: true });
    setSubscriptionId(subscriptionId);
    setSampleShippingCountry(sampleShippingCountry);
    setIsTotalHealth(totalHealth);
  };

  const addPaymentForm = state.displayAddForm ? (
    <div className="spc-payment">
      <div id="add-spi-form" className="spc-spi-addform">
        <PaymentForm
          total={0}
          method={BILLING_METHODS.CREDIT_CARD}
          savePaymentCallback={clearAddPaymentForm}
          isUpdatingPayment={state.isUpdatingPayment}
          isSub={false}
          cancel={clearAddPaymentForm}
          isAuthorized={true}
          tokenToUpdate={state.tokenToUpdate}
          addressToUpdate={state.addressToUpdate}
        />
      </div>
    </div>
  ) : null;

  const switchToSubsTab = () => {
    setState({ ...state, showSubs: true, showSpi: false });
    GaEventHandler.pushToDataLayer({
      event: 'gaEvent',
      gaEventCategory: 'store_subscription',
      gaEventAction: 'tab_click',
      gaEventLabel: 'manage_subscription',
      gaEventValue: 0,
      gaEventNonInteractionBool: false,
    });
  };

  const switchToPaymentTab = () => {
    setState({ ...state, showSpi: true, showSubs: false });
    GaEventHandler.pushToDataLayer({
      event: 'gaEvent',
      gaEventCategory: 'store_subscription',
      gaEventAction: 'tab_click',
      gaEventLabel: 'manage_payments',
      gaEventValue: 0,
      gaEventNonInteractionBool: false,
    });
  };

  const renderSavedPayments = () => {
    const { preserveDefaultPayment } = storeState;
    const { paymentMethods } = storeState.account;
    const savedCreditCards = paymentMethods.filter(payment => payment.isCreditCard);
    if (!savedCreditCards.length) return <p>You currently have no saved credit cards.</p>;

    return (
      <SavedPayment
        savedPayments={savedCreditCards}
        preserveDefaultPayment={preserveDefaultPayment}
        updatePaymentCallback={displayUpdatePaymentForm}
        confirmActionCallback={confirmQuickAction}
      />
    );
  };

  const anyProfileIsTotalHealth =
    storeState.hasPendingTotalHealth ||
    storeState.profiles.some(profile => profile?.subscriptionInfo?.isTotalHealth);

  const bodyClass = state.displayAddForm ? '' : 'non-addform';
  const logoUrl = storeState.account.isAuthenticated ? storeState.youdotUrl : storeState.pubUrl;
  if (state.showSubCancellationFlow) {
    return (
      <SubscriptionCancellationFlow
        subscriptionId={subscriptionId}
        sampleShippingCountry={sampleShippingCountry}
        emailAddress={storeState.account.email}
        youdotUrl={storeState.youdotUrl}
        isTotalHealth={isTotalHealth}
      />
    );
  } else {
    return (
      <div>
        <div className={`spc-spi ${bodyClass}`} data-stor-id="spi-header">
          <Header logoUrl={logoUrl} />
          <div className="spc-spi-container">
            <h2 className="spc-spi-container-title">
              <div className="spc-spi-container-title-wrapper">
                <a className="spc-spi-back-btn" href={`${URLS.YOUDOT}user`}>
                  <span className="spc-spi-back-btn-container">
                    <img
                      className="spc-subs-back-btn-icon"
                      src={back}
                      alt="subscription icon"
                      width="24"
                      height="24"
                    />
                    <span className="spc-subs-back-btn-text">Back to settings</span>
                  </span>
                </a>
                <p className="spc-spi-container-title-header">
                  Manage payment{storeState.isSubsEnabled ? ' and membership' : ' method'}
                </p>
              </div>
              <div className="spc-spi-toggle-container">
                <div
                  className={`spc-spi-toggle-saved-payments ${state.showSpi && 'mod-blue'}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => switchToPaymentTab()}
                  onKeyDown={() => switchToPaymentTab()}
                >
                  Manage payment
                </div>
                {storeState.isSubsEnabled && (
                  <div
                    className={`spc-spi-toggle-subs ${state.showSubs && 'mod-blue'}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => switchToSubsTab()}
                    onKeyDown={() => switchToSubsTab()}
                    data-stor-id="manage-sub-button"
                  >
                    Manage membership
                  </div>
                )}
              </div>
            </h2>

            <SavedPaymentBanner
              showSpi={state.showSpi}
              account={storeState.account}
              profiles={storeState.profiles}
            />

            {state.showSpi && (
              <div className="spc-spi-saved-payments">
                {!state.displayAddForm && (
                  <>
                    {renderSavedPayments()}
                    <div>
                      <button
                        className="spc-next-button spc-spi-button"
                        onClick={displayAddPaymentForm}
                        type="button"
                        aria-label="add a new credit card"
                        data-stor-id="spi-add-button"
                      >
                        add a new credit card
                      </button>
                    </div>
                  </>
                )}
                {addPaymentForm}
              </div>
            )}
            {state.showSubs && storeState.isSubsEnabled && (
              <div className="spc-spi-subs">
                <SubscriptionProfiles
                  profiles={storeState.profiles}
                  startSubCancellationFlow={startSubCancellationFlow}
                  savedPayments={storeState.account.paymentMethods}
                  confirmChangePayment={confirmChangePayment}
                />
              </div>
            )}
          </div>
        </div>
        <div className="spc-spi-footer-container">
          {!state.showSubs &&
            (storeState.hasSubscribedProfile || storeState.hasPendingTotalHealth) && (
              <p className="spc-spi-disclaimer">
                We must have a valid form of payment to provide your{' '}
                <SubscriptionName isTotalHealth={anyProfileIsTotalHealth} /> membership(s). If
                you&apos;d like to manage your{' '}
                <SubscriptionName isTotalHealth={anyProfileIsTotalHealth} /> membership(s), go to{' '}
                <button
                  className="spc-button-link"
                  onClick={() => setState({ ...state, showSubs: true, showSpi: false })}
                >
                  Manage subscription
                </button>
                . 23andMe does not store your payment information. It is stored by a third party
                payment processor. You may update your payment information at any time.
              </p>
            )}
          <Footer />
          <div className="spc-error-wrap">
            <ConfirmationModal confirmCallback={confirmAction} />
            <ErrorPopup />
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import SubscriptionName from './SubscriptionName';
import { URLS } from '../../regions';

interface Props {
  isOptIn?: boolean;
}

const SubscriptionDisclaimer = ({ isOptIn = false }: Props) => {
  if (!isOptIn) {
    return (
      <div className="spc-subs-disclaimer-card">
        We save your card details to ensure that your access to <SubscriptionName /> membership
        stays uninterrupted. You can edit your payment information at any time through your account
        settings. 23andMe does not store your payment information. It is stored by a third party
        processor.
      </div>
    );
  } else {
    return (
      <div className="spc-optin-disclaimer">
        <div className="spc-subs-disclaimer-card">
          <p>
            Membership fees are non-refundable. See{' '}
            <a
              className="spc-optin-disclaimer-tos"
              href={URLS.SUBSCRIPTION_TERMS_OF_SERVICE}
              target="_blank"
              rel="noopener noreferrer"
            >
              Membership Terms
            </a>
            .
          </p>
          <p>
            Your first year of <SubscriptionName /> membership has already been paid for. Your
            annual membership billing cycle will begin one year after you receive your genetic
            reports and features. After the first year, it will auto-renew at $29.00 each year. You
            can cancel anytime.
          </p>
        </div>
      </div>
    );
  }
};

export default SubscriptionDisclaimer;

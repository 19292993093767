/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import SubscriptionName from './../components/SubscriptionPayment/SubscriptionName';
import { URLS } from '../regions';
import x from '../images/x.svg';
import whiteX from '../images/whiteX.svg';
import cookie from 'react-cookies';

interface Props {
  showSpi: boolean;
  account: IAccount;
  profiles: Profile[];
}

interface ProfileBannerProps {
  profile: Profile;
}

interface DismissableSavedPaymentBannerProps {
  profile?: Profile;
  content: object;
  cookieName: string;
  optIn?: boolean;
}

const DismissablePaymentBanner = ({
  profile,
  optIn,
  content,
  cookieName,
}: DismissableSavedPaymentBannerProps) => {
  const [fading, setFading] = useState(false);
  const [faded, setFaded] = useState(false);
  if (!optIn && !profile) return null;

  if (!(faded || fading)) {
    const dismiss = !!cookie.load(cookieName);
    if (dismiss) return null;
  }

  return (
    !faded && (
      <>
        <div className="spc-spi-warning-banner-wrapper">
          <div
            className={`spc-spi-warning-banner ${fading ? `spc-spi-warning-banner-fading` : ``}${
              optIn ? ' mod-optin' : ''
            }`}
            onTransitionEnd={() => {
              setFaded(true);
              cookie.save(cookieName, true, { path: '/', domain: 'store.23andme.com' });
            }}
          >
            {content}

            <div className="spc-spi-warning-banner-close">
              <a
                onClick={() => {
                  setFading(true);
                }}
              >
                <img src={!profile ? whiteX : x} alt="close" width="14" height="14" />
              </a>
            </div>
          </div>
        </div>
      </>
    )
  );
};

const expiredPaymentBannerFilter = (savedPayment, profiles) => {
  const isExpiredCard = savedPayment ? savedPayment.expired : false;
  if (!isExpiredCard) return null;

  const BANNER_MAX_DAYS = 30;
  const SUBSCRIPTION_STATUS_ACTIVE = 'Active';

  const impactedProfiles = profiles
    .filter(
      profile =>
        profile.isSubscriber &&
        profile.subscriptionInfo.status == SUBSCRIPTION_STATUS_ACTIVE &&
        profile.subscriptionInfo.daysToRenewal < BANNER_MAX_DAYS,
    )
    .sort((p1, p2) => p1.subscriptionInfo.daysToRenewal - p2.subscriptionInfo.daysToRenewal);
  return impactedProfiles.length ? impactedProfiles[0] : null;
};

const ExpiredPaymentBannerContent = ({ profile }: ProfileBannerProps) => {
  return (
    <div className="spc-spi-warning-banner-text">
      Your payment information needs attention. Update your payment information before{' '}
      {profile.subscriptionInfo.endDate} to continue your <SubscriptionName useServiceMark={true} />{' '}
      membership without interruption.
    </div>
  );
};

const membershipRenewalBannerFilter = profiles => {
  const renewableProfiles = profiles
    .filter(
      profile =>
        profile.isSubscriber &&
        profile.subscriptionInfo.isRenewable &&
        profile.subscriptionInfo.daysToRenewal >= 0,
    )
    .sort((p1, p2) => p1.subscriptionInfo.daysToRenewal - p2.subscriptionInfo.daysToRenewal);
  return renewableProfiles.length ? renewableProfiles[0] : null;
};

const MembershipRenewalBannerContent = ({ profile }: ProfileBannerProps) => {
  const isTotalHealth = !!profile?.subscriptionInfo?.isTotalHealth;

  return (
    <div className="spc-spi-warning-banner-text">
      Your{' '}
      <SubscriptionName
        useServiceMark={true}
        region={profile.sampleShippingCountry}
        isTotalHealth={isTotalHealth}
      />{' '}
      membership will end on {profile.subscriptionInfo.endDate}. To continue your membership without{' '}
      interruption,{' '}
      <a href={`${URLS.SUBSCRIPTION_RENEWAL}?human=${profile.id}`}>renew your membership</a> before{' '}
      {profile.subscriptionInfo.endDate}.
    </div>
  );
};

const SubscriptionRenewalOptInBannerContent = () => {
  return (
    <div className="spc-spi-warning-banner-text">
      Success! Your <SubscriptionName useServiceMark={true} /> membership will renew one year after{' '}
      you receive your results.
    </div>
  );
};

const SavedPaymentBanner = ({ showSpi, account, profiles }: Props) => {
  if (!showSpi) return null;

  const { paymentMethods } = account;
  const savedPayment = paymentMethods.length ? paymentMethods[0] : null;
  const prepaidProfiles = membershipRenewalBannerFilter(profiles);
  const renewalProfiles = expiredPaymentBannerFilter(savedPayment, profiles);

  return (
    <div>
      <DismissablePaymentBanner
        profile={prepaidProfiles}
        content={<MembershipRenewalBannerContent profile={prepaidProfiles} />}
        cookieName="spi_membership_renewal_warning_banner_cookie"
      />
      <DismissablePaymentBanner
        profile={renewalProfiles}
        content={<ExpiredPaymentBannerContent profile={renewalProfiles} />}
        cookieName="spi_expired_payment_warning_banner_cookie"
      />
    </div>
  );
};

const SubscriptionRenewalOptInBanner = () => {
  return (
    <div>
      <DismissablePaymentBanner
        content={<SubscriptionRenewalOptInBannerContent />}
        cookieName="spi_subscription_renewal_opt_in_banner_cookie"
        optIn={true}
      />
    </div>
  );
};

export { SavedPaymentBanner, SubscriptionRenewalOptInBanner };

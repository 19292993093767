/* eslint-disable no-console */
/* eslint no-console: 0 */
import braintreeAuthorization from './braintreeAuthorization';
import { ELEMENTS } from '../constants';
import * as braintree from 'braintree-web';
import getContext from './getContext';
import { PayPal } from 'braintree-web';
import { Venmo } from 'braintree-web';

let applePaySessionIsActive: boolean = false;

function parseCurrency(amountString: string): { amount: string; currencyCode: CurrencyCode } {
  // Mapping of currency symbols to ISO currency codes
  const currencySymbols = {
    C$: 'CAD',
    '£': 'GBP',
    '€': 'EUR',
    $: 'USD',
  };

  // Find the currency symbol in the string
  const matchedSymbol = Object.keys(currencySymbols).find(symbol => amountString.includes(symbol));

  // Default to 'USD' if no matching symbol is found
  const currencyCode = matchedSymbol ? currencySymbols[matchedSymbol] : 'USD';

  // Remove the currency symbol and any non-numeric characters to extract the amount
  const numericAmount = amountString.replace(/[^0-9.]/g, '');

  return { amount: numericAmount, currencyCode };
}

function createDataCollector(clientInstance: braintree.Client) {
  braintree.dataCollector.create(
    {
      client: clientInstance,
      paypal: true,
    },
    (err, dataCollectorInstance: braintree.DataCollector) => {
      if (err) {
        console.log(err);
        return;
      }
      // Collect device data for fraud detection
      window.deviceData = dataCollectorInstance.deviceData;
    },
  );
}

function setupBraintree(clientReady: braintree.callback) {
  const context = getContext();
  const authorization = context ? context.braintreeToken : braintreeAuthorization;
  braintree.client.create(
    {
      authorization,
    },
    (err, clientInstance) => {
      if (err) {
        clientReady(null);
        return;
      }
      clientReady(clientInstance);
    },
  );
}

function createHostedFields(clientInstance: braintree.Client, hostedFieldsReady) {
  braintree.hostedFields.create(
    {
      client: clientInstance,
      styles: {
        input: {
          'font-size': '16px',
          'font-weight': '400',
          'font-family': 'Atkinson Hyperlegible, Helvetica Neue, Helvetica, Roboto,sans-serif',
          'border-radius': '3px',
          padding: '10px 5px',
        },
        ':focus': {},
        '.invalid': {},
      },
      fields: {
        number: {
          selector: `#${ELEMENTS.ccNum}`,
          placeholder: '1111 1111 1111 1111',
        },
        cvv: {
          selector: `#${ELEMENTS.ccCVV}`,
          placeholder: '123',
        },
        expirationDate: {
          selector: `#${ELEMENTS.ccExp}`,
          placeholder: 'MM/YY',
        },
      },
    },
    (err, hostedFieldsInstance) => {
      if (err) {
        console.log('withBraintree::createHostedFields err in create callback ', err);
        hostedFieldsReady(null);
        return;
      }
      hostedFieldsReady(hostedFieldsInstance);
    },
  );
}

function createPaypal(clientInstance: braintree.Client, paypalReady: (PayPal) => void) {
  braintree.paypal.create(
    {
      client: clientInstance,
    },
    (err, paypalInstance: PayPal) => {
      if (err) {
        console.error('Error creating PayPal:', err);
        paypalReady(null);
        return;
      }
      paypalReady(paypalInstance);
    },
  );
}

function createVenmo(clientInstance: braintree.Client, venmoReady: (Venmo) => void) {
  braintree.venmo.create(
    {
      client: clientInstance,
    },
    (err, venmoInstance: Venmo) => {
      if (err) {
        console.error('Error creating Venmo:', err);
        venmoReady(null);
        return;
      }

      venmoReady(venmoInstance);
    },
  );
}

function createApplePayRequest(applePayInstance, callback, amount: string) {
  const parsedAmount = parseCurrency(amount);
  const paymentRequest = applePayInstance.createPaymentRequest({
    total: {
      label: '23andMe',
      amount: parsedAmount.amount,
    },
    currencyCode: parsedAmount.currencyCode,
    requiredBillingContactFields: ['postalAddress'],
  });
  // make sure that we don't have an already active applepay session
  if (!applePaySessionIsActive) {
    const session = new window.ApplePaySession(3, paymentRequest);
    applePaySessionIsActive = true;

    setupApplePaySession(session, applePayInstance, callback);
    session.begin();
    session.oncancel = function() {
      applePaySessionIsActive = false;
    };
  }
}

function onValidateMerchant(instance, session, event) {
  instance.performValidation(
    {
      validationURL: event.validationURL,
      displayName: '23andMe',
    },
    function(validationErr, validationData) {
      if (validationErr) {
        console.error(validationErr);
        session.abort();
        return;
      }

      session.completeMerchantValidation(validationData);
    },
  );
}

function onPaymentAuthorized(instance, session, callback, event) {
  instance.tokenize(
    {
      token: event.payment.token,
    },
    function(tokenizeErr, payload) {
      if (tokenizeErr) {
        console.error('Error tokenizing Aple pay:', tokenizeErr);
        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
        return;
      }
      callback({ nonce: payload.nonce, ...event.payment });
      session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
    },
  );
}

function setupApplePaySession(session, instance, callback) {
  session.onvalidatemerchant = onValidateMerchant.bind(this, instance, session);
  session.onpaymentauthorized = onPaymentAuthorized.bind(this, instance, session, callback);
}

function createApplePay(clientInstance: braintree.Client, setApplePayInstance) {
  if (!window.ApplePaySession.canMakePayments()) {
    console.error('This device is not capable of making Apple Pay payments');
  }
  braintree.applePay.create(
    {
      client: clientInstance,
    },
    (err, applePayInstance) => {
      if (err) {
        console.error('Could not create applepay instance because of:', err);
      }
      setApplePayInstance(applePayInstance);
    },
  );
}

export {
  setupBraintree,
  createHostedFields,
  createPaypal,
  createVenmo,
  createDataCollector,
  createApplePay,
  createApplePayRequest,
  parseCurrency,
};

import React from 'react';
import ttamPlusNotRegisteredLogo from '../../images/23andMePlusNotRegisteredLogoWhite.svg';
import totalHealthTMWhite from '../../images/totalHealthTMWhite.svg';
import ttamPlusPremium from '../../images/23andMePlusPremium.svg';
import ttamPlusPremiumNoTrademark from '../../images/23andMePlusPremiumNoTrademark.svg';
import { useStore } from '../../utils/storeUtils';
import { REGION, COUNTRY_TO_REGION_MAP } from '../../regions';

const ActivationCard = () => {
  const [storeState] = useStore();
  const { hasTotalHealth } = storeState;
  const premium =
    REGION === COUNTRY_TO_REGION_MAP.US ? (
      <img src={ttamPlusPremium} alt="Premium" className="spc-display-block premium-branding" />
    ) : (
      <img
        src={ttamPlusPremiumNoTrademark}
        alt="Premium"
        className="spc-display-block premium-branding"
      />
    );

  return (
    <div className="spc-optin-activation-card">
      <img src={ttamPlusNotRegisteredLogo} alt="23andMe plus" width="90" />
      {hasTotalHealth ? (
        <img
          src={totalHealthTMWhite}
          alt="Total Health™"
          width="90"
          height="10"
          className="spc-display-block spc-mb-10"
        />
      ) : (
        premium
      )}
      <h1>Activate your membership</h1>
      <p>
        Great news! Your first year is already paid. Please provide a payment method to continue
        your membership without interruption beyond your first year.
        <br />
        <br />
        You will not be charged today. Cancel at any time.
        <br />
      </p>
    </div>
  );
};

export { ActivationCard };

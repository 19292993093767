import * as React from 'react';
import { useStore } from '../../utils/storeUtils';
import { PREPAID_SUBSCRIPTION_KIT_ALIAS } from '../../constantsv2';
import SubscriptionName from '../SubscriptionPayment/SubscriptionName';

const OrderKit = ({
  alias,
  description,
  discount,
  discounted,
  discountPercent,
  displayDiscount,
  displayDiscountPrice,
  displayPrice,
  name,
}: Kit) => {
  const [state] = useStore();

  // Indicate if kit is discounted by a certain percentage, or a certain $$ amount (not both)
  let utilDiscount = '';
  if (discountPercent !== 0) {
    utilDiscount = `${discountPercent}%`;
  } else if (discount !== 0) {
    utilDiscount = displayDiscount;
  }

  const personalization = name ? ` - ${name}` : '';
  const priceToDisplay = displayPrice;
  const discountedPriceToDisplay = displayDiscountPrice;
  const prepaidSubKit = alias === PREPAID_SUBSCRIPTION_KIT_ALIAS;
  let kitDescription = <span>{description}</span>;
  if (state.membership && prepaidSubKit) {
    kitDescription = (
      <>
        Kit with <SubscriptionName useServiceMark={true} /> Membership
      </>
    );
  } else if (prepaidSubKit) {
    kitDescription = (
      <>
        <SubscriptionName useServiceMark={true} /> Annual Membership
      </>
    );
  }

  let utilKit;
  if (discounted) {
    utilKit = (
      <div>
        <div className="spc-order-price" role="row">
          <div
            className="spc-order-price-name"
            data-stor-id="spc-order-kit-name"
            data-jest-id="spc-order-kit-name"
            role="cell"
          >
            {kitDescription}
            {personalization}
          </div>
          {!state.hideUkPromoPricing && (
            <div className="spc-order-price-discount" id="discount-amount">
              <span
                className="spc-order-line-through"
                data-jest-id="spc-order-line-through"
                aria-hidden="true"
              >
                {priceToDisplay}
              </span>
              <span className="spc-visually-hidden">Original price was {priceToDisplay}</span>
              <span> / </span>
              <span className="spc-order-off" data-jest-id="spc-order-off">
                {utilDiscount} Off
              </span>
            </div>
          )}
          <div
            className="spc-order-price-text mod-discount"
            data-jest-id="spc-order-price-text"
            role="cell"
          >
            {discountedPriceToDisplay}
            <span aria-labelledby="discount-amount" />
          </div>
        </div>
      </div>
    );
  } else {
    utilKit = (
      <div className="spc-order-price" role="row">
        <div
          className="spc-order-price-name"
          data-stor-id="spc-order-kit-name"
          role="cell"
          data-jest-id="spc-order-kit-name"
        >
          {kitDescription}
          {personalization}
        </div>
        <div className="spc-order-price-text" role="cell" data-jest-id="spc-order-price-text">
          {priceToDisplay}
        </div>
      </div>
    );
  }
  return utilKit;
};

export default OrderKit;

import * as React from 'react';
import { useStore } from '../../utils/storeUtils';
import Logo from '../Svgs/Logo';
import { STEP } from '../../constants';
import { URLS, REGION, getPgtPermalink, getFdaInfo } from '../../regions';
import CustomerCareContactGroup from '../common/intl/CustomerCareContactGroup/CustomerCareContactGroup';
import FDALinkEnUs from '../common/intl/FDALinkGroup/FDALinkEnUs';
import SubTOSLink from '../common/intl/TermsOfServiceGroup/SubTOSLink';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const secure = require('../../images/secure.png');

interface Props {
  showSubDisclaimer?: boolean;
  forSubscription?: boolean;
}

const Footer = ({ showSubDisclaimer = false, forSubscription = false }: Props) => {
  const [state] = useStore();
  const { step, gdprAvailable, subscriptionInfo, region } = state;
  let asterisks = '*';
  if (subscriptionInfo && subscriptionInfo.isHealthUpgrade) {
    asterisks = '**';
  }
  let hideAddress: string;
  if (step === STEP.REVIEW) {
    hideAddress = '';
  } else {
    hideAddress = ' mod-hidden';
  }
  const tooltipMoreTxt = (
    <div className="tooltip-data mod-right">
      We take your security seriously. Your information is encrypted on our servers and is protected
      with the industry standard 128-bit TLS (Transport Layer Security) encryption.
    </div>
  );

  const CustomerCareContact = CustomerCareContactGroup[REGION];

  const gdprLinkDesktop = gdprAvailable ? (
    <a
      className="spc-footer-big-div-a"
      href={URLS.DATA_PROTECTION}
      target="_blank"
      rel="noopener noreferrer"
    >
      Data Protection
      <br />
    </a>
  ) : null;

  const gdprLinkMobile = gdprAvailable ? (
    <a
      className="spc-footer-links-a"
      href={URLS.DATA_PROTECTION}
      target="_blank"
      rel="noopener noreferrer"
    >
      Data Protection
    </a>
  ) : null;

  let disclaimer = null;
  if (showSubDisclaimer) {
    disclaimer = forSubscription ? (
      <p className="spc-footer-disclaimer">
        The 23andMe PGS test uses qualitative genotyping to detect 6 variants in 3 genes in the
        genomic DNA of adults from saliva for the purpose of reporting and interpreting information
        about the processing of certain therapeutics to inform discussions with a healthcare
        professional.It does not describe if a person will or will not respond to a particular
        therapeutic and does not describe the association between detected variants and any specific
        therapeutic.Results should be confirmed in a clinical setting with independent genetic
        testing before taking any medical action.Warning: Test information should not be used to
        start, stop, or change any course of treatment and does not test for all possible variants
        that may affect metabolism or protein function.The PGS test is not a substitute for visits
        to a healthcare professional.Making changes to your current regimen can lead to harmful side
        effects or reduced intended benefits of your medication, therefore consult with your
        healthcare professional before taking any medical action.For a complete list of the 6
        variants tested, visit Pharmacognentics Important Test Information.
      </p>
    ) : (
      <p className="spc-footer-disclaimer">
        {subscriptionInfo && subscriptionInfo.isHealthUpgrade && (
          <>
            *The 23andMe PGS test uses qualitative genotyping to detect clinically relevant variants
            in the genomic DNA of adults from saliva collected using an FDA-cleared collection
            device (Oragene&middot;DX model OGD-500.001) for the purpose of reporting and
            interpreting genetic health risks and reporting carrier status. It is not intended to
            diagnose any disease. The relevance of each report may vary based on ethnicity. Each
            genetic health risk report describes if a person has variants associated with a higher
            risk of developing a disease, but does not describe a person&apos;s overall risk of
            developing the disease. These reports are not intended to tell you anything about your
            current state of health, or to be used to make medical decisions, including whether or
            not you should take a medication or how much of a medication you should take. Our
            carrier status reports can be used to determine carrier status, but cannot determine if
            you have two copies of any genetic variant. These carrier reports are not intended to
            tell you anything about your risk for developing a disease in the future or anything
            about the health of your fetus, or your newborn child&apos;s risk of developing a
            particular disease later in life. For certain conditions, we provide a single report
            that includes information on both carrier status and genetic health risk. For important
            information and limitations regarding carrier status and genetic health risk reports,
            visit&nbsp;
            <a
              className="spc-footer-disclaimer-link"
              href={getFdaInfo(region)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getFdaInfo(region)}
            </a>
            .
            <br />
          </>
        )}
        {asterisks}23andMe PGS Pharmacogenetics reports: The 23andMe test uses qualitative
        genotyping to detect 3 variants in the CYP2C19 gene, 2 variants in the DPYD gene and 1
        variant in the SLCO1B1 gene in the genomic DNA of adults from saliva for the purpose of
        reporting and interpreting information about the processing of certain therapeutics to
        inform discussions with a healthcare professional. It does not describe if a person will or
        will not respond to a particular therapeutic and does not describe the association between
        detected variants and any specific therapeutic. Our CYP2C19 Pharmacogenetics report provides
        certain information about variants associated with metabolism of some therapeutics and
        provides interpretive drug information regarding the potential effect of citalopram and
        clopidogrel therapy. Results for SLCO1B1 and DPYD and certain CYP2C19 results should be
        confirmed by an independent genetic test prescribed by your own healthcare provider before
        taking any medical action. <strong>Warning</strong>: Test information should not be used to
        start, stop, or change any course of treatment and does not test for all possible variants
        that may affect metabolism or protein function. The PGS test is not a substitute for visits
        to a healthcare professional. Making changes to your current regimen can lead to harmful
        side effects or reduced intended benefits of your medication, therefore consult with your
        healthcare professional before taking any medical action. For important information and
        limitations regarding Pharmacogenetic reports, visit&nbsp;
        <a
          className="spc-footer-disclaimer-link"
          href={getPgtPermalink(region)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getPgtPermalink(region)}
        </a>
        .
      </p>
    );
  }

  return (
    <footer>
      <div className="spc-footer">
        <div className="spc-footer-info">
          <div className="spc-footer-info-secure">
            <img src={secure} width="26" alt="visa" />
            <div className="spc-footer-info-secure-text">
              Secure Server
              <button
                data-placement="right"
                data-trigger="hover"
                className="tooltip spc-button-link spc-footer-info-secure-p mod-right"
                type="button"
              >
                <span className="tooltip-span mod-right" />
                <p className="spc-footer-info-secure-p">Tell me more</p>
                {tooltipMoreTxt}
              </button>
            </div>
          </div>
          <div className={`spc-footer-info-address${hideAddress}`}>
            <div className="spc-footer-info-address-div mod-bottom">
              <span>349 Oyster Point Blvd,</span>
              <span>&nbsp;South San Francisco,</span>
              <span>&nbsp;CA 94080 USA</span>
            </div>
            <div className="spc-footer-info-address-div">
              <CustomerCareContact />
              <span>&nbsp;M-F: 9am-12pm</span>
              <span>&nbsp;and</span>
              <span>&nbsp;1pm-4pm PT</span>
            </div>
          </div>
        </div>

        <div className="spc-footer-links">
          <FDALinkEnUs isDesktop={false} />
          <a
            data-test-id="footer-tos-link"
            className="spc-footer-links-a"
            href={URLS.TERMS_OF_SERVICE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <SubTOSLink isDesktop={false} />
          <a
            className="spc-footer-links-a"
            href={URLS.CUSTOMER_CARE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Customer Care
          </a>
          <a
            data-test-id="footer-privacy-link"
            className="spc-footer-links-a"
            href={URLS.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>
          <a
            data-test-id="footer-telehealth-tos-link"
            className="spc-footer-links-a"
            href={URLS.TELEHEALTH_TOS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Telehealth Terms of Service
          </a>
          {gdprLinkMobile}
          <span data-gdpr-link="spc-gdpr-link" />
          <a
            className="spc-footer-links-a"
            href={URLS.FAMILY_CONSIDERATIONS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Family Considerations
          </a>
          <a
            className="spc-footer-links-a"
            href={URLS.CONSUMER_HEALTH_DATA_PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Consumer Health Data Privacy Policy
          </a>
          {['en-us', 'en-ca'].includes(region) && (
            <>
              <a
                className="spc-footer-links-a"
                href={URLS.COOKIES}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>
              <a className="spc-footer-links-a" href={URLS.COOKIE_CHOICES}>
                Cookie Choices
              </a>
            </>
          )}
        </div>
        {disclaimer}
        <div className="spc-footer-copyright">
          <div>
            &copy;{new Date().getFullYear()} 23andMe, Inc.
            <br />
            All rights reserved
          </div>
        </div>
      </div>
      <div className="spc-footer-big">
        <div className="spc-footer-big-info">
          <div className="spc-footer-info-secure">
            <img src={secure} width="26" alt="visa" />
            <div className="spc-footer-info-secure-text">
              Secure Server
              <button
                data-placement="right"
                data-trigger="hover"
                className="spc-button-link tooltip mod-right spc-footer-info-secure-p"
                type="button"
              >
                <span className="tooltip-span mod-right" />
                <p className="spc-footer-info-secure-p">Tell me more</p>
                {tooltipMoreTxt}
              </button>
            </div>
          </div>
          <div
            className={`spc-footer-big-info-address${hideAddress}`}
            data-jest-id="footer-big-address"
          >
            349 Oyster Point Blvd, South San Francisco, CA 94080 USA
            <br />
            <CustomerCareContact /> M-F: 9am-12pm and 1pm-4pm PT
          </div>
        </div>
        <div className="spc-footer-big-img">
          <Logo />
        </div>
        <div className="spc-footer-big-div mod-left">
          <h3 className="spc-footer-big-h1">ABOUT</h3>
          <a
            className="spc-footer-big-div-a"
            href={URLS.SITE_MAP}
            target="_blank"
            rel="noopener noreferrer"
          >
            Site Map
          </a>
          <a
            className="spc-footer-big-div-a"
            href={URLS.CUSTOMER_CARE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Customer Care
          </a>
        </div>
        <div className="spc-footer-big-div">
          <h3 className="spc-footer-big-h1">LEGAL</h3>
          <FDALinkEnUs isDesktop />
          <a
            data-test-id="footer-tos-link"
            className="spc-footer-big-div-a"
            href={URLS.TERMS_OF_SERVICE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <SubTOSLink isDesktop />
          <a
            data-test-id="footer-privacy-link"
            className="spc-footer-big-div-a"
            href={URLS.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Statement
          </a>
          <a
            data-test-id="footer-telehealth-tos-link"
            className="spc-footer-big-div-a"
            href={URLS.TELEHEALTH_TOS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Telehealth Terms of Service
          </a>
          {gdprLinkDesktop}
          <a
            className="spc-footer-big-div-a"
            href={URLS.FAMILY_CONSIDERATIONS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Family Considerations
          </a>
          <a
            className="spc-footer-big-div-a"
            href={URLS.CONSUMER_HEALTH_DATA_PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Consumer Health Data Privacy Policy
          </a>
        </div>
        <div className="spc-footer-big-div mod-last">
          <a
            className="spc-footer-big-div-a"
            href={URLS.CONSENT_DOC}
            target="_blank"
            rel="noopener noreferrer"
          >
            Consent Document
          </a>
          <a
            className="spc-footer-big-div-a"
            href={URLS.COOKIES}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
          <span data-gdpr-link="spc-gdpr-link" />
          {/* The #cookie-choices link opens up the TrustArc cookie choices modal.
          The click event handler is defined in GTM in the Consent Manager Assets tag. */}
          {['en-us', 'en-ca'].includes(region) && (
            <a className="spc-footer-big-div-a" href={URLS.COOKIE_CHOICES}>
              Cookie Choices
            </a>
          )}
        </div>
        {disclaimer}
        <div className="spc-footer-big-copyright">
          <div>&copy;{new Date().getFullYear()} 23andMe, Inc. All rights reserved</div>
        </div>
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;

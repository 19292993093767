/* globals dataLayer */

import { BILLING_METHODS } from '../constants';

class GaEventHandler {
  static GTM_EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
  static GTM_EVENT_ADD_PAYMENT_INFO = 'add_payment_info';
  static GTM_EVENT_UPGRADE_IN_CART = 'upgrade_in_cart';
  static GTM_EVENT_DOWNGRADE_IN_CART = 'downgrade_in_cart';
  static GTM_EVENT_TRACK_TOTAL_HEALTH = 'track_th';
  static GTM_EVENT_TOTAL_HEALTH_UPGRADE = 'checkout_total_health';
  static GTM_EVENT_CHECKOUT_UPGRADE_SUBSCRIPTION = 'checkout_upgrade_subscription';
  static GTM_EVENT_CHECKOUT_SUBSCRIPTION = 'checkout_subscription';

  static pushToDataLayer(eventDetails = {}) {
    if (!window.dataLayer) {
      return;
    }
    dataLayer.push(eventDetails);
  }

  static parseKit(currency, kit, index) {
    return {
      index: index,
      item_id: String(kit.itemId),
      item_name: kit.itemName,
      currency: currency,
      price: kit.discountPrice,
      coupon: kit.discountProgram,
      discount: kit.discount,
      item_brand: '23andMe',
      item_category: 'DNA Kits',
      item_category2: kit.itemCategory2,
      item_category3: kit.itemCategory3,
      quantity: 1,
    };
  }

  static parseSubscription(subscriptionInfo) {
    const category2 = {
      US: 'US Health',
      CA: 'CA Health',
      GB: 'UK Health',
      DK: 'EU Health',
      FL: 'EU Health',
      IE: 'EU Health',
      NL: 'EU Health',
      SE: 'EU Health',
    };
    const itemCategory2 = category2.hasOwnProperty(subscriptionInfo.sampleShippingCountry)
      ? category2[subscriptionInfo.sampleShippingCountry]
      : 'US Health';
    const hasDiscount = subscriptionInfo.discountAmountNumber > 0;
    const discountedPrice = hasDiscount
      ? subscriptionInfo.discountedPriceNumber
      : subscriptionInfo.priceNumber;
    return {
      index: 0,
      item_id: 'plan-' + subscriptionInfo.storePlanId,
      item_name: subscriptionInfo.planName,
      currency: subscriptionInfo.currency,
      price: discountedPrice,
      coupon: subscriptionInfo.coupon,
      discount: subscriptionInfo.discountAmountNumber,
      item_brand: '23andMe',
      item_category: 'DNA Kits',
      item_category2: itemCategory2,
      item_category3: 'Sub',
      quantity: 1,
    };
  }

  static trackEvent(payload, gtmNullPayload = false) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    if (gtmNullPayload) {
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    }
    dataLayer.push(payload);
  }

  static addShippingInfo(data, shipMethod, gtmNullPayload) {
    const payload = {
      event: this.GTM_EVENT_ADD_SHIPPING_INFO,
      ecommerce: {
        currency: data.currency,
        value: data.totals.itemsTotal,
        shippingTier: shipMethod.code,
        items: data.kits.map((item, index) => this.parseKit(data.currency, item, index)),
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static paymentMethod(data) {
    let method = BILLING_METHODS.CREDIT_CARD;
    if (data.isApplepay) {
      method = BILLING_METHODS.APPLEPAY;
    } else if (data.isPaypalAccount) {
      method = BILLING_METHODS.PAYPAL;
    }
    return method;
  }

  static addPaymentInfo(data, billing, gtmNullPayload) {
    let method = billing.method;
    if (method === BILLING_METHODS.SAVED_PAYMENT) {
      // TODO - why are saved payment flags tucked away in customAddress?
      method = this.paymentMethod(billing.customAddress);
    }
    const payload = {
      event: this.GTM_EVENT_ADD_PAYMENT_INFO,
      ecommerce: {
        currency: data.currency,
        value: data.totals.itemsTotal,
        paymentType: method,
        items: data.kits.map((item, index) => this.parseKit(data.currency, item, index)),
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static addPaymentInfoSubs(subscriptionInfo, savedPayment, kit, gtmNullPayload) {
    let method = this.paymentMethod(savedPayment);
    let items = [this.parseSubscription(subscriptionInfo)];
    let value = subscriptionInfo.priceNumber;
    if (subscriptionInfo.isHealthUpgrade) {
      items.push(this.parseKit(subscriptionInfo.currency, kit, 1));
      value = value + kit.discountPrice + kit.discount;
    }

    const payload = {
      event: this.GTM_EVENT_ADD_PAYMENT_INFO,
      ecommerce: {
        currency: subscriptionInfo.currency,
        value: value,
        paymentType: method,
        items: items,
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static trackTotalHealth(identifier, totalKits, gtmNullPayload) {
    const payload = {
      event: this.GTM_EVENT_TRACK_TOTAL_HEALTH,
      ecommerce: {
        identifier: identifier,
        total_kits: totalKits,
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static totalHealthAcceptSavePayment(alias, price) {
    const payload = {
      event: this.GTM_EVENT_TOTAL_HEALTH_UPGRADE,
      ecommerce: {
        event_category: 'youdot_total_health_checkout',
        event_action: 'box_checked',
        event_label: 'payment_saved',
        alias: alias,
        price: price,
      },
    };
    this.trackEvent(payload);
  }

  static totalHealthAcceptTos(alias, price) {
    const payload = {
      event: this.GTM_EVENT_TOTAL_HEALTH_UPGRADE,
      ecommerce: {
        event_category: 'youdot_total_health_checkout',
        event_action: 'box_checked',
        event_label: 'terms_of_service',
        alias: alias,
        price: price,
      },
    };
    this.trackEvent(payload);
  }

  static trackFamilyRecordsSubscriptionInfoPageView() {
    const payload = {
      event: this.GTM_EVENT_CHECKOUT_UPGRADE_SUBSCRIPTION,
      ecommerce: {
        event_category: 'store_upgrade_subscription_payment_page',
        event_action: 'page_view',
        event_label: 'family_tree_hint',
      },
    };
    this.trackEvent(payload);
  }

  static trackFamilyRecordsUpgradeSubscriptionPageView() {
    const payload = {
      event: this.GTM_EVENT_CHECKOUT_SUBSCRIPTION,
      ecommerce: {
        event_category: 'store_subscription_payment_page',
        event_action: 'page_view',
        event_label: 'family_tree_hint',
      },
    };
    this.trackEvent(payload);
  }
}

export default GaEventHandler;

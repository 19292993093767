import React from 'react';
import Visa from '../../../Svgs/Visa';
import Amex from '../../../Svgs/Amex';
import Mastercard from '../../../Svgs/Mastercard';

const PaymentMethodsAll = () => (
  <div className="spc-billing-text-payment-methods">
    <Visa height="46px" className="spc-billing-radio" />
    <Amex height="46" className="spc-billing-radio" />
    <Mastercard height="46px" className="spc-billing-radio" />
  </div>
);

export default PaymentMethodsAll;

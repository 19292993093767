import React from 'react';
import { useStore } from '../../../../utils/storeUtils';
import { REGION } from '../../../../regions';

const ShippingDetailsAll = () => {
  const [state] = useStore();
  return (
    <div>
      <p className="spc-title-div">
        {REGION == 'engb' && state.showUkShippingUpdate && (
          <div className="spc-mail-delay-notice">
            Please expect significant delays in standard shipping due to the Royal Mail strike.{' '}
            <span className="spc-mail-delay-notice-bold-text">
              Select express shipping to receive by 25 Dec.
            </span>
          </div>
        )}
        Shipping costs include shipping to each recipient and return shipping from each recipient to
        our lab.{' '}
        {state.showIntShippingUpdate ? (
          <>
            PLEASE NOTE: Due to changes in transportation and fulfillment costs by our distribution
            service providers, effective immediately shipping rates in certain countries have
            increased. Thank you for your understanding.
          </>
        ) : (
          ''
        )}
      </p>
      <p className="spc-title-div">Express Shipments cannot be delivered to P.O. Boxes.</p>
      <p className="spc-title-div">Ships from warehouse in 1-2 business days.</p>
    </div>
  );
};

export default ShippingDetailsAll;

/* eslint jsx-a11y/anchor-is-valid:0 */
import React from 'react';
import { Div, Paragraph, Link, ButtonAccept } from './Styled/Banner';
import { Consumer } from './Provider';
import { cookiePolicyLink } from './common/utils';

const Banner = () => (
  <Consumer>
    {({ show, state, accept }) =>
      state.step === 3 || state.noBanner ? null : (
        <Div>
          <Paragraph>
            By continuing to use our website, you accept our{' '}
            <Link href={cookiePolicyLink()} target="_blank" rel="noopener noreferrer">
              Cookie Policy
            </Link>
          </Paragraph>
          <ButtonAccept onClick={accept}>I accept</ButtonAccept>
          <Link onClick={show} onKeyPress={show} role="link" tabIndex={0}>
            More Cookie Choices
          </Link>
        </Div>
      )
    }
  </Consumer>
);

export default Banner;

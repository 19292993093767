/* globals window */
import cookie from 'react-cookies';
import { GDPR_COOKIE, GDPR_VISITED_COOKIE, REQUIRED_COOKIES } from './common/constants';

const useDomain = window.location.href.indexOf('.23andme.com') > -1;

function saveCookie(name, value, years = 1) {
  const expires = new Date(new Date().setFullYear(new Date().getFullYear() + years));
  const opts = {
    path: '/',
    expires,
    ...(useDomain ? { domain: '.23andme.com' } : {}),
  };

  cookie.save(name, value, opts);
}

function saveChoice(choice) {
  saveCookie(GDPR_COOKIE, choice);
}

function reload() {
  window.location.reload();
}

function accepted(choice) {
  saveChoice(choice);
  reload();
}

function cleanCookiesAndStorage(cookies) {
  cookies.forEach(name => {
    if (typeof cookie.load(name) !== 'undefined') {
      cookie.remove(name, {
        path: '/',
        ...(useDomain ? { domain: '.23andme.com' } : {}),
      });
    }
  });
  window.localStorage.clear();
}

function cookieClense() {
  const allCookies = Object.keys(cookie.loadAll());
  const cookiesToDel = [];

  if (allCookies.length === 0) {
    return;
  }
  allCookies.forEach(c => {
    if (REQUIRED_COOKIES.indexOf(c) === -1) {
      cookiesToDel.push(c);
    }
  });
  cleanCookiesAndStorage(cookiesToDel);
}

function strictClear() {
  cookieClense();
}

function loadVisitorChoice() {
  return cookie.load(GDPR_COOKIE);
}

function cleanIfFirstTime() {
  if (typeof cookie.load(GDPR_VISITED_COOKIE) === 'undefined') {
    cookieClense();
    saveCookie(GDPR_VISITED_COOKIE, 'true');
  }
}

export { accepted, saveChoice, loadVisitorChoice, strictClear, reload, cleanIfFirstTime };

import * as React from 'react';
import withModal from './withModal';
import CloseIcon from '../Svgs/CloseIcon';
import handleKeyDown from '../../utils/handleKeyDown';

interface Props {
  hideModal: (hide: boolean) => {};
}

class PriceModal extends React.Component<Props> {
  componentDidMount() {
    this.props.hideModal(false);
  }

  handleClick() {
    this.props.hideModal(true);
  }

  render() {
    return (
      <div>
        <span
          onClick={e => {
            e.stopPropagation();
            this.handleClick();
          }}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <CloseIcon height="46px" className="spc-verification-close" />
        </span>
        <h2 className="spc-verification-h2 mod-font mod-caps">Great News!</h2>
        <p className="spc-verification-text">
          The price of your 23andMe Health + Ancestry Kit has been updated to $199.
        </p>
        <div className="spc-verification-edit">
          <button
            onClick={e => {
              e.stopPropagation();
              this.handleClick();
            }}
            className="spc-verification-div-button"
            type="button"
          >
            continue
          </button>
        </div>
      </div>
    );
  }
}

export default withModal(PriceModal);

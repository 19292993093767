/* globals window */
import React from 'react';
import PropTypes from 'prop-types';
import withModal from './withModal';
import {
  CountrySuggestionGroup,
  cartPathGroup,
} from './intl/CountrySuggestionGroup/CountrySuggestionGroup';
import { COUNTRY_TO_REGION_MAP } from '../../regions';

const propTypes = {
  hideModal: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
};

class CountrySuggestionPopup extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoToRegion = this.handleGoToRegion.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.hideModal(true);
  }

  handleGoToRegion() {
    this.props.hideModal(true);
    const region = COUNTRY_TO_REGION_MAP[this.props.country];
    window.location.replace(cartPathGroup[region]);
  }

  handleClose() {
    this.props.hideModal(true);
  }

  render() {
    const region = COUNTRY_TO_REGION_MAP[this.props.country];
    const CountrySuggestion = CountrySuggestionGroup[region];
    return (
      <CountrySuggestion handleGoToRegion={this.handleGoToRegion} handleClose={this.handleClose} />
    );
  }
}

CountrySuggestionPopup.propTypes = propTypes;

export default withModal(CountrySuggestionPopup);

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import handleKeyDown from '../../utils/handleKeyDown';
import { useStore } from '../../utils/storeUtils';
import { handleHealthUpgradeUpdateHuman } from '../../utils/storeApi';

const SubscriptionProfileHeader = () => {
  const [storeState, updateStoreState] = useStore();
  const [showProfileSelector, setShowProfileSelector] = useState(false);

  const handleProfileClick = async profile => {
    const account = storeState.account;
    account.currentProfileId = profile.id;
    account.firstName = profile.firstName;
    account.lastName = profile.lastName;
    updateStoreState({ account });
    if (storeState.subscriptionInfo.isHealthUpgrade) {
      await handleHealthUpgradeUpdateHuman(profile.id);
    }
    setShowProfileSelector(false);
  };

  const { firstName, lastName, currentProfileId } = storeState.account;

  const renderProfiles = profiles => {
    return (
      <div className={`spc-subs-profile-selector-div${showProfileSelector ? '' : ' spc-hide'}`}>
        <ul className="spc-subs-profile-selector-list">
          {profiles.map(profile => (
            <li
              className="spc-subs-profile-selector-li"
              key={profile.id}
              data-stor-id={`spc-subs-profile-${profile.id}`}
              onClick={() => handleProfileClick(profile)}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
            >
              <div className="spc-subs-profile-selector-container">
                <label
                  htmlFor={profile.id}
                  className={`spc-subs-profile-selector-label${
                    profile.id === currentProfileId ? '-checked' : ''
                  }`}
                >
                  {profile.image ? (
                    <img
                      className="spc-subs-profile-selector-img"
                      src={profile.image.url}
                      alt={profile.initials}
                    />
                  ) : (
                    <span className="spc-subs-profile-selector-icon">{profile.initials}</span>
                  )}
                  <div className="spc-subs-profile-selector-name">
                    {profile.firstName} {profile.lastName}
                  </div>
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="spc-subs-profile">
      <div className="spc-subs-profile-header">
        <div>
          Purchasing for {firstName} {lastName}
        </div>
        {storeState.profiles.length > 1 && (
          <div
            className="spc-subs-profile-cta"
            data-stor-id="spc-subs-switch-profile"
            aria-pressed={showProfileSelector}
            onClick={() => setShowProfileSelector(!showProfileSelector)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            Switch Profile
          </div>
        )}
      </div>
      {storeState.profiles.length > 1 && renderProfiles(storeState.profiles)}
    </div>
  );
};

export default SubscriptionProfileHeader;

import React from 'react';
import { useStore } from '../../../../utils/storeUtils';
import { ELEMENTS } from '../../../../constants';
import { getSubscriptionTermsOfService } from '../../../../regions';

const SubscriptionTermsOfServiceAll = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storeState, _] = useStore();
  const { region } = storeState;

  const getTosLink = () => {
    return (
      <a
        data-test-id="checkout-accept-tos-link"
        data-jest-id="checkout-accept-tos-link"
        className="spc-summary-accept-a"
        href={getSubscriptionTermsOfService(region)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Membership Terms
      </a>
    );
  };

  return (
    <div id={ELEMENTS.accept}>
      I agree to the&nbsp;
      {getTosLink()}
      &nbsp;and understand that this purchase is non-refundable.
    </div>
  );
};

export default SubscriptionTermsOfServiceAll;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, SpanOpt, SwitchLabel, SwitchInput, SliderSpan } from './Styled/Switch';

const propTypes = {
  handleSwitch: PropTypes.func.isRequired,
  choice: PropTypes.string.isRequired,
};

const Switch = ({ choice, handleSwitch }) => (
  <Form>
    <SpanOpt active={choice === 'ads'} onClick={handleSwitch}>
      opt in
    </SpanOpt>
    <SwitchLabel htmlFor="optin">
      <SwitchInput
        name="optin"
        type="checkbox"
        checked={choice === 'strict'}
        onChange={handleSwitch}
      />
      <SliderSpan onClick={handleSwitch} onKeyDown={handleSwitch} role="button" tabIndex={0} />
    </SwitchLabel>
    <SpanOpt active={choice === 'strict'} onClick={handleSwitch}>
      opt out
    </SpanOpt>
  </Form>
);
Switch.propTypes = propTypes;
export default Switch;

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import handleKeyDown from '../utils/handleKeyDown';

interface Props {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  text: string;
  handleChange: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  ariaLabel: string;
}

export default function SurveyRadio({
  id,
  name,
  value,
  checked,
  text,
  handleChange,
  ariaLabel,
}: Props) {
  return (
    <div className="spc-radio-wrap" onKeyPress={handleKeyDown}>
      <div className="spc-radio">
        <input
          id={id}
          name={name}
          type="radio"
          value={value}
          checked={checked}
          onChange={handleChange}
          aria-checked={checked}
          aria-label={ariaLabel}
          tabIndex={0}
        />
      </div>
      <label className="radio-label" htmlFor={id} aria-hidden={true}>
        {text}
      </label>
    </div>
  );
}

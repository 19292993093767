import React from 'react';

interface WithDisplayArrayProps {
  rootClass: string;
}

export default function withArrayDisplay(WrappedComponent) {
  const WithDisplayArray = (props: WithDisplayArrayProps) => {
    const { rootClass = '' } = props;
    return (
      <div className={`${rootClass}`}>
        {WrappedComponent.componentArray.map(ArrayDisplayComponent => (
          <ArrayDisplayComponent key={ArrayDisplayComponent.name} {...props} />
        ))}
      </div>
    );
  };

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithDisplayArray.displayName = `WithDisplayArray(${wrappedComponentName})`;

  return WithDisplayArray;
}

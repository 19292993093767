import React, { Component } from 'react';

import { getLocale } from '../common/utils';
import AdsTable from './AdsTable';

class AllAds extends Component {
  constructor(props) {
    super(props);

    this.ads = [
      {
        id: 'doubleclick',
        company: 'DoubleClick',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'clicktale',
        company: 'Clicktale',
        domain: 'All 23andMe owned and operated websites and domains.',
        locales: ['en-gb'],
      },
      {
        id: 'fb',
        company: 'Facebook',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'ga',
        company: 'Google Adwords',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'gtm',
        company: 'Google Tag Manager',
        domain: 'All 23andMe owned and operated websites and domains.',
      },
      {
        id: 'cooladata',
        company: 'CoolaData',
        domain: 'All 23andMe owned and operated websites and domains.',
        locales: ['en-gb'],
      },
    ];
  }

  getFilteredAds() {
    const currentLocale = getLocale();

    return this.ads.filter(({ locales = null }) => !locales || locales.includes(currentLocale));
  }

  render() {
    return <AdsTable ads={this.getFilteredAds()} />;
  }
}

export default AllAds;

import React from 'react';

interface Props {
  forShipping?: boolean;
  readOnly?: boolean;
}

const ShippingCountryTooltipEnCa = ({ forShipping = true, readOnly = false }: Props) =>
  forShipping && !readOnly ? (
    <div className="spc-tooltip mod-select">
      <span
        data-placement="right"
        data-trigger="hover"
        className="tooltip mod-select spc-tooltip-a"
        aria-disabled="true"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <span className="tooltip-span" />?
        <div className="tooltip-data" role="tooltip">
          Canadian kits are for Canada customers only. If you want to ship to the US, visit the{' '}
          <a className="spc-tooltip-link" href="https://www.23andme.com/en-us/">
            US site
          </a>
          . If you want to ship a kit to another country, visit the{' '}
          <a className="spc-tooltip-link" href="https://www.23andme.com/en-int/">
            International site
          </a>
          .
        </div>
      </span>
    </div>
  ) : (
    <span />
  );

export default ShippingCountryTooltipEnCa;

/* Function to translate python snake_case object keys to javascript camelCase, after receiving
 object from back end. Counterpart to toSnake.js */

function camelizeDigit(key) {
  const map = {
    '0': 'Zero',
    '1': 'One',
    '2': 'Two',
    '3': 'Three',
    '4': 'Four',
    '5': 'Five',
    '6': 'Six',
    '7': 'Seven',
    '8': 'Eight',
    '9': 'Nine',
  };
  return map[key];
}

function toCamel(o) {
  if (typeof o === 'undefined') {
    return null;
  }
  let newO;
  let newKey;
  let value;
  if (Array.isArray(o)) {
    newO = [];
    Object.keys(o).forEach(origKey => {
      value = o[origKey];
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      newO.push(value);
    });
  } else {
    newO = {};
    Object.keys(o).forEach(origKey => {
      // handle alpha replacements, eg. last_four => lastFour
      newKey = origKey.replace(/_([a-z])/g, g => g[1].toUpperCase());
      // handle single-digit numerican replacements, eg. last_4 => lastFour
      newKey = newKey.replace(/_([0-9])/g, g => g[1].replace(g[1], camelizeDigit(g[1])));
      value = o[origKey];
      if (value !== null && value.constructor === Object) {
        value = toCamel(value);
      } else if (Array.isArray(value)) {
        value = toCamel(value);
      }
      newO[newKey] = value;
    });
  }
  return newO;
}

export default toCamel;

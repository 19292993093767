import React from 'react';
import simplesuccess from '../../images/simplesuccess.svg';
import success from '../../images/success.svg';
import SubscriptionName from './SubscriptionName';

interface Props {
  emailAddress: string;
  sampleShippingCountry: SampleShippingCountry;
  youdotUrl: string;
}

export default function SubscriptionCancellationComplete({
  emailAddress,
  sampleShippingCountry,
  youdotUrl,
}: Props) {
  const branding = <SubscriptionName useServiceMark={true} region={sampleShippingCountry} />;
  return (
    <div className="spc-subs-cancel-complete">
      <div className="spc-subs-cancel-complete-header-wrapper for-complete">
        <div>
          <img src={success} alt="" width="30" height="30" className="" />
        </div>
        <h2>Your cancellation is confirmed</h2>
      </div>
      <div className="complete-section-wrapper">
        <div className="complete-section-row">
          <div>
            <img src={simplesuccess} alt="" width="30" height="30" />
          </div>
          <div className="complete-section-row-description">
            You still have access to your subscription features until your billing cycle ends.
          </div>
        </div>
        <div className="complete-section-row">
          <div>
            <img src={simplesuccess} alt="" width="30" height="30" />
          </div>
          <div className="complete-section-row-description">
            To keep a record of your {branding} health reports, be sure to{' '}
            <a className="complete-section-row-link" href={`${youdotUrl}reports/print`}>
              download
            </a>{' '}
            them before your access ends.
          </div>
        </div>
        <div className="complete-section-row">
          <div>
            <img src={simplesuccess} alt="" width="30" height="30" />
          </div>
          <div className="complete-section-row-description">
            A confirmation email has been sent to{' '}
            <span className="complete-section-row-email">{emailAddress}</span>. Please allow 24
            hours for this email to arrive.
          </div>
        </div>
      </div>
      <div className="buttons">
        <a className="button back-button" href={`${youdotUrl}user`}>
          Back to settings
        </a>
      </div>
    </div>
  );
}

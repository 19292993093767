import React, { useEffect } from 'react';
import withModal from '../common/withModal';
import { ELEMENTS } from '../../constants';
import GaEventHandler from '../../utils/dataLayer';
import { goNextStep } from '../../actions/thunks';
import { useStore } from '../../utils/storeUtils';
import { useStoreContext } from '../../utils/storeUtils';

interface Props {
  address: Address;
  edit: () => void;
  hideModal: (bool: boolean) => void;
  verifiedAddress: Address;
}

const VerificationWithModal = ({
  address,
  edit = null,
  hideModal,
  verifiedAddress = null,
}: Props) => {
  const [_, updateStoreState] = useStore();
  const { storeState, dispatch } = useStoreContext();

  useEffect(() => {
    hideModal(false);
  }, []);

  const handleClick = (isVerified: boolean) => {
    hideModal(true);
    if (isVerified) {
      updateStoreState({ address: verifiedAddress });
    }
    dispatch(goNextStep(`#${ELEMENTS.shipping}`, -120));
    GaEventHandler.pushToDataLayer({
      event: 'AnalyticsEvent',
      eventCategory: 'store',
      eventAction: 'spc_section_transition',
      eventLabel: 'shipping_method',
    });
  };

  const utilUnverified = (
    <div className="spc-verification-div">
      <span className="spc-verification-div-span" id={ELEMENTS.verification} tabIndex={-1}>
        UNVERIFIED ADDRESS
      </span>
      <div>
        {address.firstName} {address.lastName}
        <br />
        {address.address} {address.address2}
        <br />
        {address.city}, {address.state} {address.postalCode}
        <br />
        {address.country}
      </div>
      <button
        onClick={e => {
          e.stopPropagation();
          handleClick(false);
        }}
        type="button"
        className="spc-verification-div-button mod-other"
        data-stor-id="spc-ship-unverified-address-button"
      >
        ship to unverified
      </button>
    </div>
  );

  let utilVerifyAddress;
  if (verifiedAddress) {
    utilVerifyAddress = (
      <div>
        <p className="spc-verification-p">
          We verified your shipping address and found an exact match. Using the verified address
          below will help us ship your order faster. If you ship to the unverified address, the
          delivery of your order may be delayed or unsuccessful.
        </p>
        <div className="spc-verification-div">
          <span className="spc-verification-div-span">VERIFIED ADDRESS</span>
          <div className="spc-modal-address">
            {verifiedAddress.firstName} {verifiedAddress.lastName}
            <br />
            {verifiedAddress.address} {verifiedAddress.address2}
            <br />
            {verifiedAddress.city}, {verifiedAddress.state} {verifiedAddress.postalCode}
            <br />
            {verifiedAddress.country}
          </div>
          <button
            className="spc-verification-div-button"
            data-stor-id="spc-ship-verified-address-button"
            type="button"
            onClick={e => {
              e.stopPropagation();
              handleClick(true);
            }}
          >
            ship to verified
          </button>
        </div>
        {utilUnverified}
      </div>
    );
  } else {
    utilVerifyAddress = (
      <div>
        <p className="spc-verification-p">
          We tried to verify your shipping address, but couldn&lsquo;t find a match. You can edit it
          to make corrections, or you can proceed using the address you provided. If you ship to an
          unverified address, the delivery of your order may be delayed or unsuccessful.
        </p>
        {utilUnverified}
      </div>
    );
  }
  return (
    <div>
      <h2 className="spc-verification-h2" data-stor-id="spc-address-verification-modal-header">
        ADDRESS VERIFICATION
      </h2>
      {utilVerifyAddress}
      <div className="spc-verification-edit">
        <button
          onClick={e => {
            e.stopPropagation();
            edit();
          }}
          type="button"
          className="spc-verification-edit-button"
        >
          edit shipping destination
        </button>
      </div>
    </div>
  );
};

export default withModal(VerificationWithModal);

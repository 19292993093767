import React from 'react';

interface Props {
  disabledBtnClass?: string;
  accepted?: boolean;
  ariaDisabled?: boolean;
}

const OrderButtonEnInt = ({
  disabledBtnClass = '',
  accepted = false,
  ariaDisabled = false,
}: Props) => {
  const acceptedClassNames = accepted
    ? 'spc-summary-accept-button spc-next-button mod-ok'
    : 'spc-summary-accept-button spc-next-button';

  return (
    <div className="spc-summary-accept-button-wrap">
      <button
        className={`${acceptedClassNames} ${disabledBtnClass}`}
        data-stor-id="spc-submit-order-button"
        data-track-click="checkout_order_button"
        type="submit"
        aria-label="place order and pay"
        aria-disabled={ariaDisabled}
      >
        {disabledBtnClass ? 'please wait' : 'place order and pay'}
      </button>
    </div>
  );
};

export default OrderButtonEnInt;

import React, { useState } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useStoreContext } from '../../utils/storeUtils';
import ErrorPopup from '../common/ErrorPopup';
import { PaymentInfoScreen } from './PaymentInfoScreen';
import { ReceiptScreen } from './ReceiptScreen';
import { ReviewOrderScreen } from './ReviewOrderScreen';
import Footer from '../../components/Footer';
import Header from '../Header';
import { useSavedPayments } from '../common/Payment/hooks';

const LabsCheckoutPage = () => {
  const { path, url } = useRouteMatch();
  const { hasSavedPayment } = useSavedPayments();
  const { storeState } = useStoreContext();
  const {
    labs: { receiptId },
    youdotUrl,
  } = storeState;
  const [firstPass, updatePass] = useState(hasSavedPayment);
  const initialLandingScreen =
    hasSavedPayment && firstPass ? (
      <ReviewOrderScreen updatePass={() => updatePass(false)} />
    ) : (
      <PaymentInfoScreen />
    );
  const receiptRedirect = <Redirect to={`${url}receipt/${receiptId}/`} />;

  return (
    <>
      <Header logoUrl={youdotUrl} />
      <div className="spc-labs-page">
        <main
          className="spc-labs-main"
          data-stor-id="clinic-checkout-page-header"
          aria-live="assertive"
        >
          <Switch>
            <Route exact strict path={`${path}checkout/`}>
              {/* redirect to receipt page if receiptId is set */}
              {receiptId ? receiptRedirect : initialLandingScreen}
            </Route>
            <Route strict path={`${path}checkout/review/`}>
              {receiptId ? receiptRedirect : <ReviewOrderScreen />}
            </Route>
            <Route strict path={`${path}receipt/:receiptId/`}>
              <ReceiptScreen />
            </Route>
          </Switch>
        </main>
      </div>
      <Footer />
      <div className="spc-error-wrap">
        <ErrorPopup />
      </div>
    </>
  );
};

export default LabsCheckoutPage;

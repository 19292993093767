import React from 'react';
import { useStoreContext } from '../../utils/storeUtils';
import { ReviewPaymentMethod } from './ReviewPaymentMethod';
import { ReviewPersonalInfo } from './ReviewPersonalInfo';
import { YourOrderSection } from './YourOrderSection';
import { sendToNativeLabOrderReceiptNextSteps } from '../../utils/nativeMobile';

export const ReceiptScreen = () => {
  const {
    storeState: {
      labs: { nextStepsUrl, clinicalOrderSourceId },
    },
  } = useStoreContext();

  return (
    <div>
      <h1 className="spc-labs-h1">Order confirmed</h1>
      <YourOrderSection />
      {nextStepsUrl && (
        <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
          <h3 className="spc-labs-h3" data-stor-id="clinic-order-confirmed-header">
            Your order has been placed!
          </h3>
          <p>
            Your order will now be reviewed by a member from the clinical team. We will notify you
            when your order has been approved.
          </p>
          <a
            href={nextStepsUrl}
            className="sd-button mod-button-wide spc-labs-edit-next-steps"
            onClick={() => {
              sendToNativeLabOrderReceiptNextSteps(clinicalOrderSourceId);
            }}
          >
            View next steps
          </a>
        </div>
      )}
      <section>
        <h2 className="spc-labs-eyebrow">Order receipt</h2>
        <ReviewPersonalInfo />
        <ReviewPaymentMethod />
      </section>
    </div>
  );
};

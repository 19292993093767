import * as React from 'react';
import { URLS, REGION } from '../../../../regions';

interface Props {
  isDesktop: boolean;
}

const FDALinkEnUs = (props: Props) => {
  const hideTestInfo = REGION === 'enint' ? 'mod-hidden' : '';
  const linkClass = props.isDesktop ? 'spc-footer-big-div-a' : 'spc-footer-links-a';
  return (
    <a
      data-test-id="footer-iti-link"
      className={`${linkClass} ${hideTestInfo}`}
      href={URLS.FDA_INFO}
      target="_blank"
      rel="noopener noreferrer"
    >
      Important Test Info
    </a>
  );
};

export default FDALinkEnUs;

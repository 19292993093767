import * as React from 'react';

import handleKeyDown from '../../utils/handleKeyDown';
import { useStore } from '../../utils/storeUtils';

interface Props {
  klass?: string;
  modalName: ModalNames;
  children: React.ReactNode;
  hideOnBackgroundClick?: boolean;
}

const Modal = ({ klass = '', modalName, children, hideOnBackgroundClick }: Props) => {
  const [storeState, updateStoreState] = useStore();
  const hideModal = storeState.modalOpen !== modalName;

  const hidden = hideModal ? 'mod-hidden' : '';

  return (
    <div
      className={`spc-modal ${hidden}`}
      onClick={() => {
        hideOnBackgroundClick && updateStoreState({ modalOpen: '' });
      }}
      onKeyDown={handleKeyDown}
      role="dialog"
    >
      <div className={`spc-modal-content ${klass}`}>{children}</div>
    </div>
  );
};

export default Modal;

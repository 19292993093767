import React from 'react';
import withBraintree, { BraintreeInjectedProps } from '../../Billing/withBraintree';
import { PayPal as PayPalInstance } from 'braintree-web';
import PayPal from '../../Billing/PayPal';
import { useStoreContext } from '../../../utils/storeUtils';
import { BILLING_METHODS } from '../../../constants';

declare interface PayPalBillingAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
}

declare interface PayPalPayment {
  details: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    billingAddress: PayPalBillingAddress;
  };
  nonce: string;
}

type SubmitPaypalEventHandler = (nonce: string, address: Address, method: string) => void;

interface PayPalButtonProps extends BraintreeInjectedProps {
  onReceivePayPalPayment: SubmitPaypalEventHandler;
  paypalInstance: PayPalInstance;
}

const extractAddressFromPaypal = (paypalPayment: PayPalPayment): Address => {
  const {
    details: { firstName, lastName, email, phone, billingAddress },
  } = paypalPayment;
  const { line1, line2, city, state, countryCode, postalCode } = billingAddress;
  return {
    firstName,
    lastName,
    address: line1,
    address2: line2,
    city,
    state,
    country: countryCode,
    postalCode,
    phone,
    email,
  };
};

const _PayPalButton = ({
  onReceivePayPalPayment,
  paypalInstance,

  // injected props from withBraintree
  handleSubmitPaypal,
}: PayPalButtonProps) => {
  const { storeState } = useStoreContext();
  const {
    billing: { method, nonce, customAddress: address },
  } = storeState;
  return (
    paypalInstance && (
      <PayPal
        handleClick={() => {
          // has the user been through this flow?
          // if so, short circuit!
          if (method === BILLING_METHODS.PAYPAL && !!nonce) {
            onReceivePayPalPayment(nonce, address, BILLING_METHODS.PAYPAL);
            return;
          }
          handleSubmitPaypal(payment => {
            const address: Address = extractAddressFromPaypal(payment);
            const { nonce } = payment;
            onReceivePayPalPayment(nonce, address, BILLING_METHODS.PAYPAL);
          });
        }}
      />
    )
  );
};

export const PayPalButton = withBraintree(_PayPalButton);

import React from 'react';
import Logo from '../Svgs/Logo';
import { URLS } from '../../regions';

interface Props {
  logoUrl?: string;
}

const Header = ({ logoUrl = URLS.HOME }: Props) => {
  return (
    <div className="spc-header" data-test-id="spc-header">
      <Logo height="75px" width="110px" url={logoUrl} />
    </div>
  );
};

export default Header;

import React from 'react';

interface Props {
  forShipping?: boolean;
  readOnly?: boolean;
  isBookPurchase?: boolean;
}

const ShippingCountryTooltipEnUs = ({
  forShipping = true,
  readOnly = false,
  isBookPurchase = false,
}: Props) => {
  const toolTipInfo = isBookPurchase ? (
    <div className="tooltip-data" role="tooltip">
      NOTE: Ancestry Books are currently unavailable for shipment outside the US.
    </div>
  ) : (
    <div className="tooltip-data" role="tooltip">
      US kits are for US customers only. If you want to ship to Canada, visit the{' '}
      <a className="spc-tooltip-link" href="https://www.23andme.com/en-ca/">
        Canada site
      </a>
      . If you are a UK or EU resident, please visit the{' '}
      <a className="spc-tooltip-link" href="https://www.23andme.com/en-gb/">
        UK site
      </a>{' '}
      or{' '}
      <a className="spc-tooltip-link" href="https://www.23andme.com/en-eu/">
        EU site
      </a>
      . If you want to ship a kit to another country, visit the{' '}
      <a className="spc-tooltip-link" href="https://www.23andme.com/en-int/">
        International site
      </a>
      .
    </div>
  );
  return forShipping && !readOnly ? (
    <div className="spc-tooltip mod-select">
      <span
        data-placement="right"
        data-trigger="hover"
        className="tooltip mod-select spc-tooltip-a"
        aria-disabled="true"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <span className="tooltip-span" />?{toolTipInfo}
      </span>
    </div>
  ) : (
    <span />
  );
};

export default ShippingCountryTooltipEnUs;

import React from 'react';

import { AddressDisplayProps } from '../common/AddressDisplay';

const BillingAddressInfo = ({
  firstName,
  lastName,
  company,
  address,
  address2,
  city,
  state,
  postalCode,
  country,
}: AddressDisplayProps) => (
  <div className="spc-billing-address">
    <div className="spc-billing-address-div" data-stor-id="spc-billing-address">
      <div>
        {firstName} {lastName}
      </div>
      <div>{company}</div>
      <div>
        {address} {address2}
      </div>
      <div>
        {city}, {state} {postalCode}
      </div>
      <div>{country}</div>
    </div>
  </div>
);

BillingAddressInfo.displayName = 'BillingAddress';

export default BillingAddressInfo;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { RevealIn } from '../Styled/common';
import { LinkToggle } from './Styled/Choices';

const propTypes = {
  renderToggleText: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

class Collapsible extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();

    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const { renderToggleText, children } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        <LinkToggle onClick={this.toggle} active={isOpen}>
          {renderToggleText(isOpen)}
        </LinkToggle>
        {isOpen && <RevealIn>{children}</RevealIn>}
      </Fragment>
    );
  }
}

Collapsible.propTypes = propTypes;

export default Collapsible;

export const EVENTS_TO_MOBILE = {
  STORE_SUBSCRIPTION_TRANSACTION_COMPLETE: 'STORE_SUBSCRIPTION_TRANSACTION_COMPLETE',
  STORE_HEALTH_SUBSCRIPTION_TRANSACTION_COMPLETE: 'STORE_HEALTH_SUBSCRIPTION_TRANSACTION_COMPLETE',
  EXIT_RENEWAL_OPT_IN: 'EXIT_RENEWAL_OPT_IN',
};

const MODEL_TYPES = {
  LAB_ORDER_RECEIPT_NEXT_STEPS: 'lab_order_receipt_next_steps',
  STORE_ABANDON_INELIGIBLE_TRANSACTION: 'store_abandon_ineligible_transaction',
};

interface Window {
  webkit?: any;
  newrelic?: any;
  androidInterface?: any;
  androidInterfaceExt?: any;
}

declare const window: Window;

/**
 * send special event to native mobile so the app can catch it
 * @param message: the name of the message defined in
 */
export const sendToNative = message => {
  // If neither the js iOS webkit nor Android interface is present, then we're not
  // in a webview and can just short-circuit.
  if (window.webkit == undefined && window.androidInterface == undefined) {
    return;
  }

  // iOS
  if (window.webkit !== undefined) {
    try {
      const native = window.webkit.messageHandlers.nativeProcess;
      native.postMessage(message);
    } catch (error) {
      if (window.newrelic && window.newrelic.noticeError) {
        const failure = new Error(`iOSWebkit failure: ${error}`);
        window.newrelic.noticeError(failure);
      }
    }
  }

  // Android
  if (window.androidInterface !== undefined) {
    try {
      window.androidInterface.postMessage(message);
    } catch (error) {
      if (window.newrelic && window.newrelic.noticeError) {
        const failure = new Error(`AndroidInterface failure: ${error}`);
        window.newrelic.noticeError(failure);
      }
    }
  }
};

export const sendToNativeLabOrderReceiptNextSteps = (orderId: string) => {
  sendToNativeExt({
    type: MODEL_TYPES.LAB_ORDER_RECEIPT_NEXT_STEPS,
    order_id: orderId,
  });
};

export const sendToNativeTotalHealthPurchaseAbandonment = () => {
  sendToNativeExt({
    type: MODEL_TYPES.STORE_ABANDON_INELIGIBLE_TRANSACTION,
  });
};

// Do not expose this function.  This function is meant to be private.
// All objects passed to this function must have a type parameter in the root object.  See MODEL_TYPES
const sendToNativeExt = (message: object) => {
  const eventData = JSON.stringify(message);
  // iOS
  if (window.webkit !== undefined) {
    try {
      const native = window.webkit.messageHandlers.nativeProcessExt;
      native.postMessage(eventData);
    } catch (error) {
      if (window.newrelic && window.newrelic.noticeError) {
        const failure = new Error(`iOSWebkit nativeProcessExt failure: ${error}`);
        window.newrelic.noticeError(failure);
      }
    }
  }

  // Android
  if (window.androidInterfaceExt !== undefined) {
    try {
      const native = window.androidInterfaceExt;
      native.postMessage(eventData);
    } catch (error) {
      if (window.newrelic && window.newrelic.noticeError) {
        const failure = new Error(`AndroidInterfaceExt failure: ${error}`);
        window.newrelic.noticeError(failure);
      }
    }
  }
};

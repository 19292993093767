import React from 'react';
import withBraintree from '../../Billing/withBraintree';
import ApplePay from '../../Billing/BraintreeComponents/ApplePay';
import { BILLING_METHODS } from '../../../constants';
import { useStoreContext } from '../../../utils/storeUtils';

export type SubmitApplePayEventHandler = (nonce: string, address: Address, method: string) => void;

interface ApplePayButtonProps {
  onReceiveApplePayPayment: SubmitApplePayEventHandler;
}

export const _ApplePayButton = ({ onReceiveApplePayPayment }: ApplePayButtonProps) => {
  const {
    storeState: {
      cart: { totals },
    },
  } = useStoreContext();
  return (
    <ApplePay
      klass={`spc-next-button spc-applepay-button}`}
      canSubmit={() => true}
      disabled={false}
      savePaymentCallback={(address: Address, payload) => {
        const { nonce } = payload;
        onReceiveApplePayPayment(nonce, address, BILLING_METHODS.APPLEPAY);
      }}
      amount={String(totals.itemsTotalWithDiscount)}
    />
  );
};

export const ApplePayButton = withBraintree(_ApplePayButton);

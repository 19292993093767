import React, { ReactNode } from 'react';
import { Store } from '../../pageSetup';
import { defaultFieldInfo, refineAddressValidation } from '../../utils/i18nFields';
import { REGION } from '../../regions';
import {
  basicAddressFieldRequirements,
  getListOfInvalidFields,
} from '../../utils/getListOfInvalidFields';
import withBraintree from '../Billing/withBraintree';
import {
  fetchStates,
  fetchLabels,
  updateSavedPayment,
  addSavedPayment,
} from '../../utils/storeApi';
import { scroller } from '../../utils/scroller';
import { ELEMENTS, BILLING_ADDRESS, BILLING_METHODS, ERROR_MESSAGES } from '../../constants';
import ApplePay from '../Billing/BraintreeComponents/ApplePay';
import BillingAddress from '../Billing/BillingAddress';
import HostedFields from '../Billing/PaymentMethodBody/HostedFields';
import ErrorPopup from './ErrorPopup';
import SubscriptionDisclaimer from '../SubscriptionPayment/SubscriptionDisclaimer';
import SubscriptionRenewalTerms from '../SubscriptionPayment/SubscriptionRenewalTerms';
import { updateStore } from '../../utils/storeUtils';
import SavedPaymentMethod from './SavedPaymentMethod';
import PaymentMethodsGroup from './intl/PaymentMethodsGroup/PaymentMethodsGroup';
import SubmitButton from './SubmitButton';
import handleKeyDown from '../../utils/handleKeyDown';
import Checkbox from './Checkbox';
import SavedBillingAddress from '../Billing/SavedBillingAddress';
import PayPal from '../Billing/PayPal';

interface Props {
  creditCardCopy: string;
  total: number;
  method: string;
  setHostedFieldsPopulated;
  errors: [string];
  returnSubmitHostedFields: any;
  handleSubmitPaypal: (callbackSuccess: any, useCheckout?: boolean) => any;
  savePaymentCallback: (token?: string, billingAddress?: object, isPaypalPayment?: boolean) => void;
  isUpdatingPayment?: boolean;
  isSub: boolean;
  setupHostedFields: any;
  cancel?: boolean | (() => void);
  isAuthorized: boolean;
  aboveSubmitEl?: () => ReactNode;
  setValidationError?: (bool: boolean) => void;
  allowApplepay?: boolean;
  allowPaypal?: boolean;
  paypalLabel?: string;
  tokenToUpdate?: string;
  addressToUpdate?: string;
  addingPaymentDetailsCallback?: (bool: boolean) => void;
  showSavedPayment?: boolean;
  trackPaymentInformationCallback?: (isApplepayPayment: Boolean, isPaypalPayment: Boolean) => {};
}

interface State {
  customAddress: Address;
  errors: any[];
  fieldInfo;
  isCustomAddressValidated: boolean;
  states: GeoState[];
  disableCountrySelector: boolean;
  useSavedPayment: boolean;
  isApplepayPayment: boolean;
  makeDefault: boolean;
  isPaypalPayment: boolean;
  showPaymentOptions: boolean;
  showAddressFields: boolean;
  savedAddressId: string;
  savedPayment: SavedPayment;
}

class PaymentForm extends React.Component<Props, State> {
  static contextType = Store;
  context: { storeState: StoreState; dispatch: any };
  constructor(props, context) {
    super(props);
    const { paymentMethods } = context.storeState.account;
    const savedPayment = paymentMethods.length ? paymentMethods[0] : null;
    const showAddressFields = context.storeState.account.addresses.length == 0;
    const savedAddressId = showAddressFields
      ? ''
      : props.addressToUpdate || context.storeState.account.addresses[0].id;

    this.state = {
      customAddress: context.storeState.billing.customAddress,
      errors: [],
      fieldInfo: defaultFieldInfo[REGION],
      isCustomAddressValidated: false,
      states: context.storeState.billingStates,
      disableCountrySelector: false,
      addressValidation: Object.assign({}, basicAddressFieldRequirements),
      useSavedPayment: !!savedPayment,
      savedPayment: savedPayment,
      isApplepayPayment: false,
      makeDefault: false,
      isPaypalPayment: false,
      showPaymentOptions: true,
      showAddressFields: showAddressFields,
      savedAddressId: savedAddressId,
    };
  }

  handleMakeDefault = () => {
    this.setState({ makeDefault: !this.state.makeDefault });
  };

  clearErr = errName => {
    const { errors } = this.state;
    if (errors.length > 0) {
      this.setState({ errors: errors.filter(name => name !== errName) });
    }
  };

  showError = () => {
    const errInfo = {
      body: ERROR_MESSAGES.BILLING_PROCESSING,
      button: 'continue',
    };
    this.context.dispatch({
      type: 'SHOW_ERROR',
      data: errInfo,
    });
    return;
  };

  canSubmit = () => {
    if (!this.props.isAuthorized) {
      updateStore(this.context.dispatch, { isProcessing: false });
      if (this.props.setValidationError) {
        this.props.setValidationError(true);
      }
      return false;
    }
    return true;
  };

  handleFetchedStates = states => {
    this.setState({ states, disableCountrySelector: false });
  };

  handleFetchedLabels = fieldInfo => {
    this.setState({ fieldInfo });
  };

  handleFieldChange = event => {
    const customAddress = Object.assign({}, this.state.customAddress, {
      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'country') {
      this.setState({ disableCountrySelector: true });
      fetchStates(event.target.value, this.handleFetchedStates);
      fetchLabels(event.target.value, this.handleFetchedLabels);
      customAddress.state = '';
    }

    this.setState({ customAddress });
  };

  checkCustomAddressValid = () => {
    if (!this.state.showAddressFields) {
      return !!this.state.savedAddressId;
    }
    const issues = getListOfInvalidFields(
      this.state.customAddress,
      refineAddressValidation(this.state.fieldInfo),
    );
    const isCustomAddressValidated = issues.length === 0;

    if (!isCustomAddressValidated) {
      this.setState({ errors: issues });
      return false;
    }
    this.setState({ isCustomAddressValidated });
    return true;
  };

  getUpdateSavedPaymentData = (cardInfo, isUpdate: Boolean) => {
    if (!cardInfo) return null;
    let paymentMethod = BILLING_METHODS.CREDIT_CARD;
    if (this.state.isApplepayPayment) {
      paymentMethod = BILLING_METHODS.APPLEPAY;
    } else if (this.state.isPaypalPayment) {
      paymentMethod = BILLING_METHODS.PAYPAL;
    } else if (this.state.isCreditCard) {
      paymentMethod = BILLING_METHODS.CREDIT_CARD;
    }
    const payload = {
      paymentMethodNonce: cardInfo.nonce,
      billingAddress: this.state.customAddress,
      makeDefault: this.state.makeDefault,
      billingAddressId: this.state.savedAddressId,
      method: paymentMethod,
    };
    if (isUpdate) {
      const { tokenToUpdate } = this.props;
      const { savedPayment } = this.state;
      const tokenFromState = savedPayment ? savedPayment.token : null;
      payload['paymentMethodToken'] = tokenToUpdate || tokenFromState;
    }
    return payload;
  };

  createOrUpdateSavedPayment = (cardInfo: BTData) => {
    const hasSavedCreditCard = this.state.savedPayment && this.state.savedPayment.isCreditCard;
    const isUpdate =
      !(this.state.isApplepayPayment || this.state.isPaypalPayment) &&
      ((this.props.isSub && hasSavedCreditCard) || this.props.isUpdatingPayment);
    const handler = isUpdate ? updateSavedPayment : addSavedPayment;
    const payload = this.getUpdateSavedPaymentData(cardInfo, isUpdate);
    if (!payload) return;
    handler(
      payload,
      this.handleAddPayment.bind(this, true, cardInfo, this.state.customAddress),
      this.showError,
    );
  };

  handleAddPayment = (
    isSuccessful?: boolean,
    cardInfo?: { cardType: string; lastFour: string },
    billingAddress?,
    paymentMethodData?: { token: string; imageUrl: string; account: IAccount },
  ) => {
    if (!isSuccessful) return;
    const { token } = paymentMethodData;
    const account = Object.assign(paymentMethodData.account, {
      currentProfileId: this.context.storeState.account.currentProfileId, // in case the profile was switched in react
    });
    updateStore(this.context.dispatch, { account });

    this.props.trackPaymentInformationCallback?.(
      this.state.isApplepayPayment,
      this.state.isPaypalPayment,
    );
    this.props.savePaymentCallback(token, billingAddress, this.state.isPaypalPayment);
  };

  handleAddPaymentSubmit = async e => {
    e.preventDefault();
    if (!this.checkCustomAddressValid()) {
      updateStore(this.context.dispatch, { isProcessing: false });
      scroller(`#${ELEMENTS[this.state.errors[0]]}`, 200, -150);
      return;
    }

    if (!this.canSubmit()) return;
    const cardData = await this.props.returnSubmitHostedFields(() =>
      updateStore(this.context.dispatch, { isProcessing: false }),
    );
    this.handleSubscriptionPayment(cardData);
  };

  handleSubscriptionPayment = (cardData: BTData) => {
    this.createOrUpdateSavedPayment(cardData);
  };

  getSubscriptionRenewalTerms = () => {
    if (!this.props.isSub) return null;

    const profiles = this.context.storeState.profiles.filter(
      profile => profile.id === this.context.storeState.account.currentProfileId,
    );
    const isDelayedBilling = profiles.length
      ? !profiles[0].genotyped
      : this.context.storeState.subscriptionInfo.isDelayedBilling;
    return (
      <SubscriptionRenewalTerms
        subscriptionInfo={this.context.storeState.subscriptionInfo}
        currentProfile={profiles[0]}
        isDelayedBilling={isDelayedBilling}
        className="spc-sub-billing-info"
        showCancelTerms={true}
      />
    );
  };

  getSubscriptionDisclaimer = () => {
    if (this.props.isSub) {
      return <SubscriptionDisclaimer />;
    }
  };

  getSubmitButtonText = (subscriptionInfo: SubscriptionInfo, subBundleInfo: SubBundleInfo) => {
    let subButtonText = 'save';
    if (subscriptionInfo) {
      const { frequency, isSubscriptionRenewal, isHealthUpgrade } = subscriptionInfo;
      let buttonPriceText = this.getTotalAmount(subscriptionInfo, subBundleInfo);
      if (!isHealthUpgrade) {
        buttonPriceText = `${buttonPriceText} / ${frequency}`;
      }
      subButtonText = isSubscriptionRenewal
        ? 'Confirm auto-renewal'
        : `Confirm purchase for ${buttonPriceText}`;
    }
    return subButtonText;
  };

  getTotalAmount = (subscriptionInfo: SubscriptionInfo, subBundleInfo: SubBundleInfo) => {
    if (!subscriptionInfo) return null;

    if (!subscriptionInfo.isHealthUpgrade) {
      return subscriptionInfo.discountAmountNumber
        ? subscriptionInfo.discountedPrice
        : subscriptionInfo.price;
    }

    return subBundleInfo.discountedPrice || subBundleInfo.price;
  };

  renderError = () => {
    return (
      <div className="spc-error-wrap">
        <ErrorPopup />
      </div>
    );
  };

  openPaymentOptions = () => {
    this.setState({ showPaymentOptions: true });
    this.props.addingPaymentDetailsCallback?.(false);
  };

  closePaymentOptions = () => {
    this.setState({ showPaymentOptions: false });
    this.props.addingPaymentDetailsCallback?.(true);
  };

  renderCancelButton = () => {
    if (!this.props.cancel) return null;
    const cancelMethod = this.props.cancel === true ? this.openPaymentOptions : this.props.cancel;
    return (
      <button className="spc-button-link spc-spi-cancel-button" onClick={cancelMethod}>
        Cancel
      </button>
    );
  };

  handleAddAddress = () => {
    this.setState({ showAddressFields: true });
  };

  handleAddressSelect = (addressId: string) => {
    this.setState({ savedAddressId: addressId });
  };

  handlePaypal = () => {
    if (!this.canSubmit()) return;

    this.props.handleSubmitPaypal(data => {
      updateStore(this.context.dispatch, { isProcessing: true });
      data.details.billingAddress.firstName = data.details.firstName;
      data.details.billingAddress.lastName = data.details.lastName;
      data.details.billingAddress.country = data.details.countryCode;
      data.details.billingAddress.address = data.details.billingAddress.line1;
      this.setState({ customAddress: data.details.billingAddress, isPaypalPayment: true });
      this.handleSubscriptionPayment(data);
    }, false);
  };

  render() {
    const { isSub, savePaymentCallback, setupHostedFields } = this.props;
    const classPrefix = isSub ? 'subs' : 'spi';
    const { subscriptionInfo, subBundleInfo, isProcessing } = this.context.storeState;
    const { savedPayment } = this.state;
    const hasSavedCreditCard = savedPayment && savedPayment.isCreditCard;
    const isExpiredCard = hasSavedCreditCard && savedPayment.expired;
    const PaymentMethods = PaymentMethodsGroup[REGION];
    const showSavedPayment =
      this.state.useSavedPayment && hasSavedCreditCard && (isSub || this.props.showSavedPayment);
    const processingClass = isProcessing && 'mod-disabled';
    const subButtonText = this.getSubmitButtonText(subscriptionInfo, subBundleInfo);
    const totalAmount = this.getTotalAmount(subscriptionInfo, subBundleInfo);

    let updateButtonText = 'Save';
    if (this.props.isUpdatingPayment) {
      updateButtonText = 'Update';
    }

    if (this.state.showPaymentOptions && (this.props.allowApplepay || this.props.allowPaypal)) {
      return (
        <div className="spc-payment-submit-wrap">
          {this.props.aboveSubmitEl && this.props.aboveSubmitEl()}
          <div className="spc-spi-button-container">
            <span className="spc-spi-button-container-padding">&nbsp;</span>
            <ApplePay
              klass={`spc-next-button spc-${classPrefix}-button ${subscriptionInfo?.isHealthUpgrade &&
                ' mod-upgrade'}`}
              canSubmit={this.canSubmit}
              disabled={isProcessing}
              savePaymentCallback={(address: Address, payload) => {
                updateStore(this.context.dispatch, { isProcessing: true });
                // this.handleSubscriptionPayment must be called *after* this.setState resolves
                // because handleSubscriptionPayment() -> createOrUpdateSavedPayment() -> getUpdateSavedPaymentData()
                // and getUpdateSavedPaymentData() expects {customAddress} to already be set
                this.setState(
                  { customAddress: address, isApplepayPayment: true },
                  this.handleSubscriptionPayment.bind(this, payload),
                );
              }}
              amount={totalAmount}
            />
            <PayPal handleClick={this.handlePaypal} label={this.props.paypalLabel} />
            <button
              className={`${processingClass} spc-next-button spc-${classPrefix}-button spc-${classPrefix}-save-button`}
              disabled={isProcessing}
              data-stor-id={`${classPrefix}-save-button`} // TODO dont duplicate from submit
              onClick={this.closePaymentOptions}
            >
              {this.props.creditCardCopy}
            </button>
          </div>
          {this.getSubscriptionRenewalTerms()}
        </div>
      );
    }
    return (
      <>
        <div id={ELEMENTS.ccForm} className={`spc-${classPrefix}-input spc-input`}>
          <div className={`spc-${classPrefix}-addform-subheader`}>Payment details</div>
          <div className={`spc-subs-payment-method${showSavedPayment ? '' : ' spc-hide'}`}>
            <div className="spc-subs-payment-method-saved">
              {hasSavedCreditCard && <SavedPaymentMethod savedPayment={savedPayment} />}
              <div className="spc-subs-payment-method-update-container">
                {isExpiredCard && (
                  <span className="spc-subs-payment-method-expired">
                    Your payment method has expired. Please update it.
                  </span>
                )}
                <button
                  className="spc-button-link spc-subs-payment-method-button"
                  type="button"
                  onClick={() => this.setState({ useSavedPayment: false })}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
          <div
            className={`spc-${classPrefix}-input-wrapper${
              (!isSub && !this.props.showSavedPayment) ||
              !hasSavedCreditCard ||
              !this.state.useSavedPayment
                ? ''
                : ' spc-hide'
            }`}
          >
            <div className="">
              {isSub && (
                <div className="spc-subs-payment-method-new">
                  <PaymentMethods />
                  {hasSavedCreditCard && (
                    <button
                      className="spc-button-link"
                      type="button"
                      onClick={() => this.setState({ useSavedPayment: true })}
                    >
                      Use card ending in {savedPayment.lastFour}
                    </button>
                  )}
                </div>
              )}
              <HostedFields setupHostedFields={setupHostedFields} isHosted={true} />
              {!isSub && this.state.useSavedPayment && (
                <div
                  className="spc-spi-address-container"
                  onClick={this.handleMakeDefault}
                  onKeyDown={handleKeyDown}
                  role="button"
                  tabIndex={0}
                >
                  <div className="spc-spi-address-radio-container">
                    <Checkbox
                      checked={this.state.makeDefault}
                      dataStorId="spc-set-default-payment"
                      handleCheck={this.handleMakeDefault}
                      ariaLabel="Set as default payment"
                    />
                  </div>
                  <div>Save as default payment card</div>
                </div>
              )}
            </div>
          </div>
          {(!isSub || (isSub && !this.state.useSavedPayment)) && (
            <div className={`spc-${classPrefix}-addform-subheader${' mod-top'}`}>
              Billing Address
            </div>
          )}
          <div
            className={`spc-${classPrefix}-input-wrapper${
              !isSub || !hasSavedCreditCard || !this.state.useSavedPayment ? '' : ' spc-hide'
            }`}
          >
            {!isSub && !this.state.showAddressFields && (
              <SavedBillingAddress
                addresses={this.context.storeState.account.addresses}
                handleAddAddress={this.handleAddAddress}
                handleAddressSelect={this.handleAddressSelect}
                savedAddressId={this.state.savedAddressId}
              />
            )}
            {(isSub || this.state.showAddressFields) && (
              <BillingAddress
                clearErr={this.clearErr}
                errors={this.state.errors}
                addressSource={BILLING_ADDRESS.SAVED_CUSTOM}
                countries={this.context.storeState.billingCountries}
                customAddress={this.state.customAddress}
                disableCountrySelector={this.state.disableCountrySelector}
                fieldInfo={this.state.fieldInfo}
                handleFieldChange={this.handleFieldChange}
                method={'credit_card'}
                savedPayment={savedPayment}
                savedPaymentInfoClass={` mod-${classPrefix}`}
                shippingAddress={this.context.storeState.shippingAddress}
                states={this.state.states}
              />
            )}
            {this.getSubscriptionDisclaimer()}
          </div>
        </div>
        <div className="spc-payment-submit-wrap">
          {this.props.aboveSubmitEl && this.props.aboveSubmitEl()}
          <div className="spc-spi-button-container">
            <span className="spc-spi-button-container-padding">&nbsp;</span>

            {this.props.allowApplepay && (
              <ApplePay
                klass={`spc-next-button spc-${classPrefix}-button ${subscriptionInfo?.isHealthUpgrade &&
                  ' mod-upgrade'}`}
                canSubmit={this.canSubmit}
                disabled={isProcessing}
                savePaymentCallback={(address: Address, payload) => {
                  updateStore(this.context.dispatch, { isProcessing: true });
                  this.setState({ customAddress: address, isApplepayPayment: true });
                  this.handleSubscriptionPayment(payload);
                }}
                amount={totalAmount}
              />
            )}

            {isSub && !subscriptionInfo?.isHealthUpgrade && (
              <PayPal handleClick={this.handlePaypal} label={this.props.paypalLabel} />
            )}

            <SubmitButton
              klass={`spc-next-button spc-${classPrefix}-button`}
              buttonText={isSub ? `${subButtonText}` : `${updateButtonText}`}
              data-payment-method={BILLING_METHODS.CREDIT_CARD}
              data-stor-id={`${classPrefix}-save-button`}
              expiredCard={isExpiredCard && showSavedPayment}
              onSubmit={e => {
                if (!this.canSubmit()) return;
                this.setState({ isApplepayPayment: false, isPaypalPayment: false });
                showSavedPayment ? savePaymentCallback() : this.handleAddPaymentSubmit(e);
              }}
            />
            {this.renderCancelButton()}
          </div>
          {this.getSubscriptionRenewalTerms()}
        </div>
        {this.renderError()}
      </>
    );
  }
}

export default withBraintree(PaymentForm);

import React from 'react';
import { URLS } from '../../../../regions';

const ContactPrivacyAll = () => (
  <div>
    <p>
      This contact information will only be used as described in our{' '}
      <a
        data-test-id="shipping-section-privacy-statement-link"
        className="spc-contact-link"
        target="_blank"
        rel="noopener noreferrer"
        href={URLS.PRIVACY_POLICY}
      >
        Privacy Statement
      </a>
      .
    </p>
  </div>
);

export default ContactPrivacyAll;

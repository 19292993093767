import * as React from 'react';
import Modal from '../../common/withModalv2';
import { useStore } from '../../../utils/storeUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import IC_ERROR_SVG from '../../../images/icons/24px/ic_error.svg';
import { DISALLOWED_STATES_FOR_TOTAL_HEALTH_KITS } from '../../../constantsv2';
import { STEP } from '../../../constants';

export const STATE_BASED_ELIGIBILITY_MODAL = 'STATE_BASED_ELIGIBILITY';
export const isStateAllowedForTotalHealth = (state: string): boolean =>
  !DISALLOWED_STATES_FOR_TOTAL_HEALTH_KITS.includes(state);

interface GetShouldShowTotalHealthStateBasedEligibilityDisclaimerModalParams {
  hasTotalHealth: boolean;
  hasTotalHealthUpgrade: boolean;
  state: string;
  modalHasBeenClosed: boolean;
  inStepShipMethodOrLater: boolean;
}

export const getShouldShowTotalHealthStateBasedEligibilityDisclaimerModal = ({
  state,
  hasTotalHealth,
  hasTotalHealthUpgrade,
  modalHasBeenClosed,
  inStepShipMethodOrLater,
}: GetShouldShowTotalHealthStateBasedEligibilityDisclaimerModalParams): boolean => {
  return (
    (hasTotalHealth || hasTotalHealthUpgrade) &&
    !modalHasBeenClosed &&
    !isStateAllowedForTotalHealth(state) &&
    inStepShipMethodOrLater
  );
};

const TotalHealthStateBasedEligibilityDisclaimer = () => {
  const [storeState, updateStoreState] = useStore();
  const {
    step,
    address: { state },
    hasTotalHealth,
    hasTotalHealthUpgrade,
  } = storeState;
  const [modalWasLastClosedForState, setModalWasLastClosedForState] = useState('');

  const inStepShipMethodOrLater = step >= STEP.SHIP_METHOD;

  const shouldShowTotalHealthStateBasedEligibilityDisclaimerModal = useMemo(() => {
    return getShouldShowTotalHealthStateBasedEligibilityDisclaimerModal({
      hasTotalHealth,
      hasTotalHealthUpgrade,
      state,
      modalHasBeenClosed: modalWasLastClosedForState === state,
      inStepShipMethodOrLater,
    });
  }, [hasTotalHealth, state, modalWasLastClosedForState, inStepShipMethodOrLater]);

  useEffect(() => {
    if (shouldShowTotalHealthStateBasedEligibilityDisclaimerModal) {
      updateStoreState({ modalOpen: STATE_BASED_ELIGIBILITY_MODAL });
    }
  }, [shouldShowTotalHealthStateBasedEligibilityDisclaimerModal]);

  const closeModal = useCallback(() => {
    updateStoreState({ modalOpen: '' });
    setModalWasLastClosedForState(state);
  }, [setModalWasLastClosedForState, state]);

  return shouldShowTotalHealthStateBasedEligibilityDisclaimerModal ? (
    <Modal modalName={STATE_BASED_ELIGIBILITY_MODAL}>
      <div className="spc-mx-50 spc-my-30">
        <div className="spc-txtC">
          <img src={IC_ERROR_SVG} width="48" height="48" alt="Error Icon" />
          <h2 className="sd-title-small spc-txtC spc-mt-15">Geographic restrictions may apply</h2>
          {hasTotalHealthUpgrade ? (
            <p>
              Total Health services are not available in HI, NJ, NY, RI, or US territories. You can
              continue purchasing Total Health if you currently live in another state.
            </p>
          ) : (
            <p>
              Total Health services are not available in HI, NJ, NY, RI, or US territories. You can
              continue purchasing Total Health if you currently live in another state or if you are
              purchasing Total Health as a gift for someone else.
            </p>
          )}
          <button className="sd-button" onClick={closeModal}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { TotalHealthStateBasedEligibilityDisclaimer };

import React from 'react';
import VerificationWithModal from './VerificationWithModal';
import { useStoreContext } from '../../utils/storeUtils';
import { STEP } from '../../constants';
import { goToStep } from '../../actions/thunks';

interface Props {
  address: Address;
  verifiedAddress: Address;
  step: number;
}

export const VerificationContainer = (props: Props) => {
  const { storeState, dispatch } = useStoreContext();

  const edit = () => {
    dispatch(goToStep(STEP.ADDRESS));
  };

  const showComponent = props.step === STEP.VERIFICATION;

  if (!showComponent) return null;

  return (
    <VerificationWithModal
      edit={edit}
      address={props.address}
      verifiedAddress={props.verifiedAddress}
    />
  );
};

export default VerificationContainer;

import styled from 'styled-components';
import { media, fader, font } from '../../Styled/common';

const SubmitDiv = styled.div`
  ${fader};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SubmitP = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #0d0e0e;
  margin: -10px 0 30px;
  text-align: center;
  ${font};
  ${media.nonMobile`
    font-size: 16px;
    line-height: 1.31;
  `};
`;
export { SubmitDiv, SubmitP };

import React from 'react';
import { useStoreContext } from '../../utils/storeUtils';
import ClinicHeartCheckup from '../Svgs/ClinicHeartCheckup';
import ClinicCardiometabolicScreening from '../Svgs/ClinicCardiometabolicScreening';

export const YourOrderSection = () => {
  const {
    storeState: {
      cart: { kits: labItems, taxes: taxItems },
      clinic: { firstName, lastName, labels },
    },
  } = useStoreContext();

  const clinicIcons = {
    'US-QUEST-ESSENTIAL-HEART-CHECKUP': <ClinicHeartCheckup />,
    'US-QUEST-ADVANCED-CARDIOMETABOLIC-SCREENING': <ClinicCardiometabolicScreening />,
  };

  const items = labItems.map((kit: Kit) => {
    const clinicIcon = clinicIcons[kit.sku] || null;
    return (
      <div key={kit.kitId} data-stor-id="clinic-cart-item-card">
        <h3 className="spc-labs-h2 spc-labs-h2-icon">
          <div className="wide-header">{kit.description}</div>
          <div>{clinicIcon}</div>
        </h3>
        <p>{labels}</p>
        <p>Clinical analysis and consultation</p>
        <p>
          {firstName} {lastName}
        </p>
        <div className="spc-labs-price">{kit.displayPrice}</div>
      </div>
    );
  });

  const taxes = taxItems.map((tax, idx) => (
    <div key={idx} className="spc-labs-price">
      {tax['name']}: {tax['price']}
    </div>
  ));

  return (
    <div className="spc-labs-your-order-section">
      {items}
      {taxes}
    </div>
  );
};

import * as React from 'react';
import withAnim from '../common/withAnim';
import { STEP } from '../../constants';
import { useStoreContext } from '../../utils/storeUtils';
import { goToStep } from '../../actions/thunks';

interface Props {
  animClass: string;
  className: string;
}

function ShippingInfo(props: Props) {
  const { storeState, dispatch } = useStoreContext();

  const { animClass, className } = props;

  const { shipMethod } = storeState;
  return (
    <div className="spc-wrap">
      <div className="spc-align mod-border">
        <h2 className="spc-align-h2 spc-count" data-stor-id="spc-shipping-method-header">
          2. Shipping Method
        </h2>
        <button
          className={`spc-button-edit${className}`}
          type="button"
          onClick={() => {
            dispatch(goToStep(STEP.SHIP_METHOD));
          }}
        >
          EDIT
        </button>
      </div>
      <div className="spc-info">
        <div className={`spc-info-details ${animClass}`}>
          <div className="spc-info-details-title">SELECTED SHIPPING</div>
          <div className="spc-info-details-wrap">
            {shipMethod.title}
            <span className="spc-info-details-wrap-span">{shipMethod.displayPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAnim(ShippingInfo);

import OrderButtonAll from './OrderButtonAll';
import OrderButtonEnInt from './OrderButtonEnInt';

const OrderButtonGroup = {
  enus: OrderButtonAll,
  enca: OrderButtonAll,
  engb: OrderButtonEnInt,
  eneu: OrderButtonEnInt,
  enint: OrderButtonEnInt,
};

export default OrderButtonGroup;

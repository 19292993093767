/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import SurveyQuestion from '../../containers/SurveyQuestion';
import SubscriptionName from './SubscriptionName';

interface Props {
  subscriptionId: string;
  sampleShippingCountry: SampleShippingCountry;
  handleSubmit: (checkboxPayload: any, textareaPayload: any, setSubmitted: any) => void;
  youdotUrl: string;
  isTotalHealth: boolean;
}

export default function SubscriptionCancellationSurvey({
  subscriptionId,
  sampleShippingCountry,
  handleSubmit,
  youdotUrl,
  isTotalHealth,
}: Props) {
  const premium_answers = [
    {
      text: 'I don’t use the 23andMe+ features',
      value: 'premium_subscription_features_not_used',
    },
    { text: 'I didn’t learn anything new', value: 'learned_nothing_new' },
    {
      text: '23andMe+ genealogy features aren’t relevant to me',
      value: 'premium_genealogy_feature_irrelevant',
    },
    {
      text: '23andMe+ Health reports aren’t relevant to me',
      value: 'premium_health_reports_irrelevant',
    },
    { text: 'I prefer to manually renew subscriptions', value: 'prefer_manual_renewals' },
    {
      text: 'It’s not clear what I get in my 23andMe+ membership',
      value: 'premium_subscription_value_unclear',
    },
    { text: 'Customer Service was less than expected', value: 'customer_service_insufficient' },
    { text: 'Other', value: 'other' },
  ];

  const total_health_answers = [
    {
      text: 'I no longer need it',
      value: 'total_health_no_longer_need',
    },
    {
      text: 'Total Health™ features aren’t relevant or useful to me',
      value: 'total_health_irrelevant',
    },
    {
      text: 'Customer Care and/or the clinical team were not as helpful as I expected',
      value: 'total_health_customer_care_not_helpful',
    },
    {
      text: 'It’s too expensive',
      value: 'total_health_too_expensive',
    },
    {
      text: 'I prefer to use a different medical provider',
      value: 'total_health_prefer_different_provider',
    },
    { text: 'Other', value: 'other' },
  ];
  const answers = isTotalHealth ? total_health_answers : premium_answers;

  const [checkboxResponse, setCheckboxResponse] = useState([]);
  const [textareaResponse, setTextAreaResponse] = useState('');

  const [showTextArea, setShowTextArea] = useState(false);
  const [showError, setShowError] = useState(false);

  const [isSubmitEnabled, setSubmitEnabled] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const setMultipleChoiceResponse = (questionId, value, isReadyToSubmit) => {
    setShowTextArea(value.includes('other'));
    setCheckboxResponse(value);
    if (isReadyToSubmit) {
      setShowError(false);
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setFreeTextResponse = (questionId, value, _) => {
    setTextAreaResponse(value);
  };

  const submitResponse = () => {
    if (isSubmitEnabled) {
      setSubmitted(true);
      const checkboxPayload = {
        survey_id: 'subscription_cancellation',
        question_id: 'subscription_features',
        instance_id: subscriptionId,
        value: checkboxResponse,
      };
      const textareaPayload =
        textareaResponse === ''
          ? null
          : {
              survey_id: 'subscription_cancellation',
              question_id: 'subscription_cancellation_reason',
              instance_id: subscriptionId,
              value: textareaResponse,
            };
      handleSubmit(checkboxPayload, textareaPayload, setSubmitted);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="spc-subs-cancel-survey survey-for-cancel">
      <div className="question-section-wrapper">
        <h1>
          What didn’t you love about{' '}
          <SubscriptionName
            useServiceMark={true}
            region={sampleShippingCountry}
            isTotalHealth={isTotalHealth}
          />
          ?
        </h1>
        <SurveyQuestion
          key="subscription_features"
          questionId="subscription_cancellation_features"
          fieldType="checkbox"
          question="Before you cancel your subscription, help us understand why you’d like to cancel."
          answers={answers}
          active={true}
          setCurrentResponse={setMultipleChoiceResponse}
          hasError={showError}
          disabled={isSubmitted}
        />
        <SurveyQuestion
          fieldType="textarea"
          questionId="subscription_cancellation_reason"
          question=""
          answers={null}
          active={showTextArea}
          setCurrentResponse={setFreeTextResponse}
          disabled={isSubmitted}
        />
        <div className={`question-section-wrapper-error${showError ? ` has-error` : ``}`}>
          Select at least one reason.
        </div>
      </div>
      <div className="buttons">
        <a className="button back-button" href={`${youdotUrl}user`}>
          Keep <SubscriptionName isTotalHealth={isTotalHealth} />
        </a>
        <div
          className={`button next-button${isSubmitEnabled ? `` : ` disabled`}${
            isSubmitted ? ` mod-disabled` : ``
          }`}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (!isSubmitted) submitResponse();
          }}
        >
          Cancel <SubscriptionName isTotalHealth={isTotalHealth} />
        </div>
      </div>
    </div>
  );
}

import { ERROR_MESSAGES } from '../constants';

export const errorActions: Record<ErrorActions, Action> = {
  SHIPPING_ERROR: {
    type: 'SHOW_ERROR',
    data: {
      title: 'Shipping Error',
      body: ERROR_MESSAGES.SHIP_METHOD,
      button: 'edit shipping',
      element: 'shipping',
    },
  },
  EMPTY_CART_ERROR: {
    type: 'SHOW_ERROR',
    data: {
      title: 'Error: Empty Cart',
      body: ERROR_MESSAGES.EMPTY_CART,
      button: 'return to order page',
      startOver: true,
    },
  },
};

import React, { Fragment } from 'react';
import { H4, Ul, Li, AdvancedDiv } from '../Styled/common';

const StrictInfo = () => (
  <Fragment>
    <H4 required>STRICTLY NECESSARY COOKIES</H4>
    <AdvancedDiv>
      These cookies are essential to make our website work, and consist of both first party and
      third party cookies. Strictly necessary cookies cannot be removed or turned off. These cookies
      enable:
      <Ul>
        <Li>Functionality settings</Li>
        <Li>Remembering items in your shopping cart</Li>
        <Li>Accessing secure areas of our website</Li>
        <Li>Aligning our service offerings to your location</Li>
      </Ul>
    </AdvancedDiv>
  </Fragment>
);

export default StrictInfo;

// Default braintree token (to be used during development only)

const braintreeAuthorization =
  'eyJ2ZXJzaW9uIjoyLCJhdXRob3JpemF0aW9uRmluZ2VycHJpbnQiOiJkMDIzMTJkZ' +
  'TBlNDVlMWUzNzE2YmY4MWNiMjE5ZmIwY2RlY2U1MDg4NzYyNmFlYzMzMjJmYzBiZGMyN2M1MjAyfGNyZWF0ZWRfYXQ9MjA' +
  'xNy0wMy0zMFQwMDowODowNy4xMTA1NDU4NzUrMDAwMFx1MDAyNm1lcmNoYW50X2lkPXY0YmM3NGp3djdwbW15bnBcdTAwM' +
  'jZwdWJsaWNfa2V5PWg4eHprY2ZraGtqa2I2cjUiLCJjb25maWdVcmwiOiJodHRwczovL2FwaS5zYW5kYm94LmJyYWludHJ' +
  'lZWdhdGV3YXkuY29tOjQ0My9tZXJjaGFudHMvdjRiYzc0and2N3BtbXlucC9jbGllbnRfYXBpL3YxL2NvbmZpZ3VyYXRpb' +
  '24iLCJjaGFsbGVuZ2VzIjpbInBvc3RhbF9jb2RlIl0sImVudmlyb25tZW50Ijoic2FuZGJveCIsImNsaWVudEFwaVVybCI' +
  '6Imh0dHBzOi8vYXBpLnNhbmRib3guYnJhaW50cmVlZ2F0ZXdheS5jb206NDQzL21lcmNoYW50cy92NGJjNzRqd3Y3cG1te' +
  'W5wL2NsaWVudF9hcGkiLCJhc3NldHNVcmwiOiJodHRwczovL2Fzc2V0cy5icmFpbnRyZWVnYXRld2F5LmNvbSIsImF1dGh' +
  'VcmwiOiJodHRwczovL2F1dGgudmVubW8uc2FuZGJveC5icmFpbnRyZWVnYXRld2F5LmNvbSIsImFuYWx5dGljcyI6eyJ1c' +
  'mwiOiJodHRwczovL2NsaWVudC1hbmFseXRpY3Muc2FuZGJveC5icmFpbnRyZWVnYXRld2F5LmNvbS92NGJjNzRqd3Y3cG1' +
  'teW5wIn0sInRocmVlRFNlY3VyZUVuYWJsZWQiOmZhbHNlLCJwYXlwYWxFbmFibGVkIjp0cnVlLCJwYXlwYWwiOnsiZGlzc' +
  'GxheU5hbWUiOiIyM2FuZE1lIiwiY2xpZW50SWQiOm51bGwsInByaXZhY3lVcmwiOiJodHRwOi8vZXhhbXBsZS5jb20vcHA' +
  'iLCJ1c2VyQWdyZWVtZW50VXJsIjoiaHR0cDovL2V4YW1wbGUuY29tL3RvcyIsImJhc2VVcmwiOiJodHRwczovL2Fzc2V0c' +
  'y5icmFpbnRyZWVnYXRld2F5LmNvbSIsImFzc2V0c1VybCI6Imh0dHBzOi8vY2hlY2tvdXQucGF5cGFsLmNvbSIsImRpcmV' +
  'jdEJhc2VVcmwiOm51bGwsImFsbG93SHR0cCI6dHJ1ZSwiZW52aXJvbm1lbnROb05ldHdvcmsiOnRydWUsImVudmlyb25tZ' +
  'W50Ijoib2ZmbGluZSIsInVudmV0dGVkTWVyY2hhbnQiOmZhbHNlLCJicmFpbnRyZWVDbGllbnRJZCI6Im1hc3RlcmNsaWV' +
  'udDMiLCJiaWxsaW5nQWdyZWVtZW50c0VuYWJsZWQiOnRydWUsIm1lcmNoYW50QWNjb3VudElkIjoicXY0YzczZG40eGN0Y' +
  'zk1ZyIsImN1cnJlbmN5SXNvQ29kZSI6IlVTRCJ9LCJjb2luYmFzZUVuYWJsZWQiOmZhbHNlLCJtZXJjaGFudElkIjoidjR' +
  'iYzc0and2N3BtbXlucCIsInZlbm1vIjoib2ZmIn0=';

export default braintreeAuthorization;

/* Function to translate javascript camelCase object keys to python snake_case, prior to sending
 object to back end. Counterpart to toCamel.js */

function toSnake(obj) {
  if (typeof obj === 'undefined') {
    return null;
  }
  let newObj;
  let newKey;
  let value;
  if (Array.isArray(obj)) {
    newObj = [];
    Object.keys(obj).forEach(origKey => {
      value = obj[origKey];
      if (typeof value === 'object') {
        value = toSnake(value);
      }
      newObj.push(value);
    });
  } else {
    newObj = {};
    Object.keys(obj).forEach(origKey => {
      newKey = origKey.replace(/([A-Z])/g, g => `_${g.toLowerCase()}`);
      value = obj[origKey];
      if (!!value && value.constructor === Object) {
        value = toSnake(value);
      } else if (Array.isArray(value)) {
        value = toSnake(value);
      }
      newObj[newKey] = value;
    });
  }
  return newObj;
}

export default toSnake;

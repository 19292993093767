import * as React from 'react';

import { useStore } from '../../../utils/storeUtils';
import { useEffect, useMemo, useState, useCallback } from 'react';

import Modal from '../../common/withModalv2';

import { URLS } from '../../../regions';
import SubscriptionName from '../../SubscriptionPayment/SubscriptionName';
import { sendToNativeTotalHealthPurchaseAbandonment } from '../../../utils/nativeMobile';

const GENERIC_ELIGIBILITY_MODAL = 'GENERIC_ELIGIBILITY';

interface GetShouldShowTotalHealthGenericEligibilityDisclaimerModalProps {
  isAuthenticated: boolean;
  modalHasBeenClosed: boolean;
  hasTotalHealthUpgrade: boolean;
  isEmptyCart: boolean;
}

const getShouldShowTotalHealthGenericEligibilityDisclaimerModal = ({
  isAuthenticated,
  modalHasBeenClosed,
  hasTotalHealthUpgrade,
  isEmptyCart,
}: GetShouldShowTotalHealthGenericEligibilityDisclaimerModalProps): boolean => {
  return isAuthenticated && hasTotalHealthUpgrade && isEmptyCart && !modalHasBeenClosed;
};

const TotalHealthGenericEligibilityDisclaimer = () => {
  const [storeState, updateStoreState] = useStore();
  const {
    account: { isAuthenticated },
    hasTotalHealth,
    hasTotalHealthUpgrade,
    isEmptyCart,
  } = storeState;

  const [modalHasBeenClosed] = useState(false);
  const shouldShowTotalHealthEligibilityDisclaimerModal = useMemo(
    () =>
      getShouldShowTotalHealthGenericEligibilityDisclaimerModal({
        isAuthenticated,
        modalHasBeenClosed,
        hasTotalHealthUpgrade,
        isEmptyCart,
      }),
    [modalHasBeenClosed, hasTotalHealth, hasTotalHealthUpgrade, isAuthenticated, isEmptyCart],
  );

  useEffect(() => {
    if (shouldShowTotalHealthEligibilityDisclaimerModal) {
      updateStoreState({ modalOpen: GENERIC_ELIGIBILITY_MODAL });
    }
  }, [shouldShowTotalHealthEligibilityDisclaimerModal]);

  const handleAbandon = useCallback(() => {
    sendToNativeTotalHealthPurchaseAbandonment();
  });

  return shouldShowTotalHealthEligibilityDisclaimerModal ? (
    <Modal modalName={GENERIC_ELIGIBILITY_MODAL} klass="th-eligibility">
      <div
        className="spc-mx-50 spc-my-30"
        data-test-id="generic-eligibility-modal-purchase-is-blocked"
      >
        <h2 className="sd-title-small spc-txtC spc-mt-15">
          Unfortunately, you're not eligible for
          <br />
          <SubscriptionName isTotalHealth={true} />
        </h2>
        <p>
          There are several reasons why someone might be ineligible to join Total Health at this
          time, including age, location, or having multiple profiles associated with your account.
          We apologize for the inconvenience. If you have any questions, please contact
          <a
            className="spc-th-popup-anchor"
            href={URLS.CUSTOMER_CARE}
            target="_blank"
            rel="noopener noreferrer"
          >
            Customer Care
          </a>
          .
        </p>
        <div className="spc-txtC">
          <a className="sd-button spc-mt-15" href={URLS.YOUDOT} onClick={handleAbandon}>
            Return home
          </a>
        </div>
      </div>
    </Modal>
  ) : null;
};

export {
  getShouldShowTotalHealthGenericEligibilityDisclaimerModal,
  TotalHealthGenericEligibilityDisclaimer,
};

import React from 'react';
import handleKeyDown from '../../../../utils/handleKeyDown';

interface Props {
  handleGoToRegion: (e: any) => void;
  handleClose: (e: any) => void;
}
const CountrySuggestionEnGb = (props: Props) => (
  <div>
    <h2 className="spc-verification-h2 mod-font">Notice: UK-bound Kits</h2>
    <p className="spc-verification-text">
      If you want to ship to England, Scotland, Wales or Northern Ireland,
      <br />
      please switch over to our UK site.
    </p>
    <div className="spc-verification-edit">
      <button
        onClick={props.handleGoToRegion}
        className="spc-verification-div-button"
        type="button"
      >
        23andMe.co.uk
      </button>
      <p>
        <button
          className="spc-button-link"
          onClick={props.handleClose}
          onKeyDown={handleKeyDown}
          type="button"
          tabIndex={0}
        >
          Ship to another country
        </button>
      </p>
    </div>
  </div>
);

const cartPathEnGb = '/en-gb/cart/';

export { CountrySuggestionEnGb, cartPathEnGb };

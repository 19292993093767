import { useEffect } from 'react';
import { useStoreContext } from '../../../../utils/storeUtils';
import { validateBillingAddress } from '../utils/validation';
import { useErrors } from './useErrors';

interface Options {
  /**
   * initial i18n field info retrieved from either `defaultFieldInfo` the `fetchLabels` call
   */
  initialBillingAddressFieldInfo: FieldInfo;
}

/**
 * Error state for the PaymentCardForm
 *
 * @param initialFormData - the initial form field values for PaymentCardForm (i.e. payment card and billing address)
 * @param options - additional arguments; see Options type for details
 *
 * @returns - object with list of fields that have errors and a state setter
 */
export const usePaymentCardFormErrors = (
  initialFormData: PaymentFormData,
  { initialBillingAddressFieldInfo }: Options,
) => {
  const { billingAddress: initialBillingAddress } = initialFormData;
  const {
    storeState: { areHostedFieldsPopulated },
  } = useStoreContext();
  const initialErrors = [
    ...(areHostedFieldsPopulated ? [] : ['paymentCard']),
    ...validateBillingAddress(initialBillingAddress, initialBillingAddressFieldInfo),
  ];
  const { errors, setErrors, clearError } = useErrors(initialErrors);

  /**
   * Clears the payment card error when all hosted/card fields are filled out
   */
  useEffect(() => {
    if (areHostedFieldsPopulated && errors.includes('paymentCard')) {
      clearError('paymentCard');
    } else if (!areHostedFieldsPopulated && !errors.includes('paymentCard')) {
      setErrors([...errors, 'paymentCard']);
    }
  }, [areHostedFieldsPopulated, errors, clearError]);

  return { errors, setErrors, clearError };
};

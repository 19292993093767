import { useState } from 'react';

export const useErrors = (initialErrors: string[] = []) => {
  const [errors, setErrors] = useState(initialErrors);
  const clearError = (fieldName: string) => {
    setErrors(errors => errors.filter(error => error !== fieldName));
  };

  return { errors, setErrors, clearError };
};

import React from 'react';
import { useStoreContext } from '../../../utils/storeUtils';
import { toCapitalize } from '../../../utils/toCapitalize';
import { BillingAddressFields } from './BillingAddressFields';

interface Props {
  action: 'add' | 'edit';
  inputValues: Address;
  onChangeInput: React.ChangeEventHandler<HTMLInputElement>;
  inputErrors: string[];
  stateOptions: GeoState[];
  fieldInfo: FieldInfo;
  disableCountryField?: boolean;
}

/**
 * Wrapper component for billing address fields (primarily for consistent structure)
 */
export const BillingAddressSection = ({
  action,
  inputValues: values,
  onChangeInput: onChange,
  inputErrors: errors = [],
  stateOptions,
  fieldInfo,
  disableCountryField = false,
}: Props) => {
  const {
    storeState: { billingCountries: countryOptions },
  } = useStoreContext();

  return (
    <section>
      <h3 className="spc-labs-h3">{toCapitalize(action)} billing address</h3>
      <BillingAddressFields
        values={values}
        onChange={onChange}
        errors={errors}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        disableCountryField={disableCountryField}
        fieldInfo={fieldInfo}
      />
    </section>
  );
};

import ShippingDetailsAll from './ShippingDetailsAll';
import ShippingDetailsEnUs from './ShippingDetailsEnUs';

const ShippingDetailsGroup = {
  enus: ShippingDetailsEnUs,
  enca: ShippingDetailsAll,
  engb: ShippingDetailsAll,
  eneu: ShippingDetailsAll,
  enint: ShippingDetailsAll,
};

export default ShippingDetailsGroup;

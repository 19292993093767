import React from 'react';
import PropTypes from 'prop-types';
import handleKeyDown from '../../utils/handleKeyDown';
import { COUNTRY_TO_REGION_MAP, WRONG_COUNTRY_ERROR } from '../../regions';
import Modal from './withModalv2';

const propTypes = {
  body: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const PayPalWrongCountryPopup = props => {
  const { body, countryCode, title, handleClose } = props;
  const region = COUNTRY_TO_REGION_MAP[countryCode] || 'enint';
  const { urlSuffix } = WRONG_COUNTRY_ERROR[region];

  return (
    <Modal modalName="ERROR">
      <div className="spc-paypal-error-modal">
        <h2 className="spc-verification-h2 mod-font">{title}</h2>
        <p className="spc-paypal-error-modal-body">{body}</p>
        <div className="spc-verification-edit">
          <button
            onClick={() => window.location.replace(`/en-${region.slice(2)}/cart/`)}
            className="spc-verification-div-button"
            type="button"
          >
            23andMe.{urlSuffix}
          </button>
          <p>
            <button
              className="spc-button-link"
              onClick={handleClose}
              onKeyDown={handleKeyDown}
              type="button"
              tabIndex="0"
            >
              Change shipping country
            </button>
          </p>
        </div>
      </div>
    </Modal>
  );
};

PayPalWrongCountryPopup.propTypes = propTypes;

export default PayPalWrongCountryPopup;

import { useStore } from '../../utils/storeUtils';
import goldGradientCheckmarkBullet from '../../images/goldGradientCheckmarkBullet.svg';
import TTAMPlusTotalHealthGoldGradient from '../../images/23AndMePlusTotalHealthGoldGradient.svg';
import { redirectToYouDot } from '../../containers/SubscriptionRenewalOptIn/subscriptionRenewalOptInActions';
import SubscriptionName from '../SubscriptionPayment/SubscriptionName';
import React from 'react';

const TotalHealthSubscriptionActivationConfirmation = () => {
  const [
    {
      youdotUrl,
      account: { currentProfileId },
    },
  ] = useStore();

  // With webpack I couldn't get it to work with a normal CSS class for some reason so I had to revert to inline style
  const goldGradientCheckmarkStyle = {
    listStyleImage: `url('${goldGradientCheckmarkBullet}')`,
  };

  return (
    <div className="spc-optin-welcome-to-total-health-container spc-centered spc-px-30">
      <h1 className="spc-optin-welcome-to-header spc-mb-10">Welcome to</h1>
      <div>
        <img
          src={TTAMPlusTotalHealthGoldGradient}
          width="238"
          height="79"
          alt="23andMe Plus® Total Health™"
        />
      </div>
      <p>
        Get ready for the most advanced and comprehensive insights and recommendations we offer.
      </p>
      <ul className="spc-optin-welcome-list" style={goldGradientCheckmarkStyle}>
        <li className="spc-mb-10">
          <span className="spc-optin-welcome-list-item">Exome sequencing</span>
        </li>
        <li className="spc-mb-10">
          <span className="spc-optin-welcome-list-item">Genetics-based clinical care</span>
        </li>
        <li className="spc-mb-10">
          <span className="spc-optin-welcome-list-item">Biannual blood testing</span>
        </li>
        <li className="spc-mb-10">
          <span className="spc-optin-welcome-list-item">
            Everything that comes with <SubscriptionName />, including 190+ genotyped reports on
            your ancestry, health, wellness, traits and more.{' '}
          </span>
        </li>
      </ul>
      <p>Cancel anytime. You’ll get a reminder before renewal.</p>
      <div className="spc-mt-40 spc-txtC">
        <button
          className="sd-button-subs-gradient spc-pointer"
          type="button"
          onClick={() =>
            redirectToYouDot({
              youdotUrl,
              currentProfileId,
            })
          }
        >
          Continue
        </button>
      </div>
    </div>
  );
};
export { TotalHealthSubscriptionActivationConfirmation };

import styled from 'styled-components';
import { media, font } from '../../Styled/common';

const BrMobi = styled.br`
  ${media.nonMobile`
    display: none;
  `};
`;

const HdrParagraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #0d0e0e;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  ${font};
  ${media.nonMobile`
    font-size: 16px;
    line-height: 1.31;display: none;
  `};
`;

export { BrMobi, HdrParagraph };

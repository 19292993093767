import React, { useState } from 'react';
import { useStore } from '../../utils/storeUtils';
import SubscriptionName from './SubscriptionName';
import { getPgtPermalink } from '../../regions';
import WithToggleDrawer from '../common/withToggleDrawer';
import iconSubsCheck from '../../images/iconSubsCheck.svg';

interface Props {
  isSubscriptionRenewal?: boolean;
}

const SubscriptionPaymentHeader = ({ isSubscriptionRenewal }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storeState, _] = useStore();
  const { region } = storeState;
  const {
    price,
    discountedPrice,
    discountAmountNumber,
    frequency,
    isSubFree,
    isTotalHealth,
  } = storeState.subscriptionInfo;

  let displayPrice = `${price} / ${frequency}`;
  if (discountAmountNumber) {
    if (isSubscriptionRenewal) {
      displayPrice = `${discountedPrice}*`;
    } else if (isSubFree) {
      displayPrice = 'FREE FIRST YEAR';
    } else {
      displayPrice = `${discountedPrice} / first ${frequency}`;
    }
  }

  const subsCheckIcon = <img src={iconSubsCheck} alt="" width="20" />;

  const drawerContent = (
    <div className="drawer-content">
      <span>Included with your membership:</span>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">Premium reports on heart health, sleep, and more</p>
      </div>
      {storeState.showHistoricalMatches && (
        <div className="renewal-row">
          {subsCheckIcon}
          <p className="renewal-column-right">
            Historical matches<sup>SM</sup>
          </p>
        </div>
      )}
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">Migraine and other premium health reports</p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Pharmacogenetics reports to help you understand how your body processes certain
          medications
        </p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">Advanced DNA Relatives filters</p>
      </div>
      <div className="renewal-row">
        {subsCheckIcon}
        <p className="renewal-column-right">
          Plus new reports and features as more discoveries are made
        </p>
      </div>
      <p>
        Cancel anytime before your next renewal to avoid being charged. We’ll send you a reminder
        before your renewal date.
      </p>
    </div>
  );

  return (
    <>
      <div className="spc-subs-info-mobile">
        <WithToggleDrawer drawerLabel="Learn more" seeLessText="See less" useButton={false}>
          {drawerContent}
        </WithToggleDrawer>
      </div>
      <div className="spc-subs-header">
        <h2 className="spc-subs-header-title">Your order</h2>
        <div className="spc-subs-header-container">
          <div className="spc-subs-header-text">
            <div>
              <SubscriptionName
                useServiceMark={true}
                serviceMarkClass="for-mobile-only"
                isTotalHealth={isTotalHealth}
              />
              &nbsp;Membership
            </div>
            <div className="spc-subs-header-test-info-mobile">
              <a
                href={getPgtPermalink(region)}
                rel="noopener noreferrer"
                target="_blank"
                className="spc-subs-info-test-info"
                data-mdv-id="subscription-payment-test-info-link"
              >
                Important Test Info
              </a>
            </div>
          </div>
          <div className="spc-subs-header-price">
            {discountAmountNumber ? (
              <>
                <span className="spc-subs-header-price-strikethrough">&nbsp;{price}&nbsp;</span>
                &nbsp;&nbsp;
                <span className="spc-subs-header-price-discount">{displayPrice}</span>
              </>
            ) : (
              <>{displayPrice}</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPaymentHeader;

import React from 'react';
import AuthenticationWithModal from './AuthenticationWithModal';
import handleKeyDown from '../../utils/handleKeyDown';
import toCamel from '../../utils/toCamel';
import { useStore } from '../../utils/storeUtils';
import { STEP, WEBPACK_SIGNAL, BILLING_METHODS } from '../../constants';

function AuthenticationContainer() {
  const [storeState, updateStoreState] = useStore();
  const {
    step,
    isApiUp,
    account: { isAuthenticated, firstName },
  } = storeState;

  const listenForAuthenticationCompletion = React.useCallback(
    e => {
      // All iFrame messages trigger this callback indiscriminately.
      // Ignore everything but store and webpack signal
      if (e.origin === window.location.origin && e.data.type !== WEBPACK_SIGNAL) {
        const account: IAccount = toCamel(e.data);
        // All iFrame messages from store will get through, make sure this one originates with authentication
        if (account.isAuthenticated) {
          window.removeEventListener('message', listenForAuthenticationCompletion);
          let { paymentMethod } = storeState;
          if (account.paymentMethods.length) {
            const isPaymentSet =
              paymentMethod === BILLING_METHODS.PAYPAL ||
              paymentMethod === BILLING_METHODS.APPLEPAY ||
              paymentMethod === BILLING_METHODS.VENMO ||
              step > STEP.BILLING;
            if (!isPaymentSet) {
              paymentMethod = BILLING_METHODS.SAVED_PAYMENT;
            }
          }
          updateStoreState({
            account,
            modalOpen: '',
            paymentMethod,
          });
        }
      }
    },
    [step, storeState, updateStoreState],
  );
  React.useEffect(() => {
    window.addEventListener('message', listenForAuthenticationCompletion);
    return () => {
      window.removeEventListener('message', listenForAuthenticationCompletion);
    };
  }, [listenForAuthenticationCompletion]);

  let content = <span className="spc-authentication" />;
  if (!isApiUp) {
    return <div>{content}</div>;
  } else if (isAuthenticated) {
    content = (
      <div className="spc-authentication mod-border" data-stor-id="spc-authentication">
        <header className="spc-authentication-text" data-stor-id="spc-authenticate-greeting">
          Welcome back, {firstName}!
        </header>
      </div>
    );
  } else if (step < STEP.REVIEW) {
    content = (
      <>
        <AuthenticationWithModal authClass=" spc-modal-auth" />
        <div className="spc-authentication mod-border" data-stor-id="spc-authentication">
          <div className="spc-authentication-text">
            <div>
              <span>Want faster checkout?</span>
              <span
                className="spc-authentication-signin"
                data-stor-id="spc-authenticate-signin"
                onClick={() => {
                  updateStoreState({ modalOpen: 'AUTH' });
                }}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                role="button"
              >
                sign in
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <div>{content}</div>;
}

export default AuthenticationContainer;

import AddressFieldsEnUs from './AddressFieldsEnUs';
import AddressFieldsi18nSingle from './AddressFieldsi18nSingle';
import AddressFieldsi18nMulti from './AddressFieldsi18nMulti';

const AddressFieldsGroup = {
  enus: AddressFieldsEnUs,
  enca: AddressFieldsi18nSingle,
  engb: AddressFieldsi18nSingle,
  eneu: AddressFieldsi18nMulti,
  enint: AddressFieldsi18nMulti,
};

export default AddressFieldsGroup;

import React from 'react';
import { PaymentCardForm } from './PaymentCardForm';
import { saveThePayment } from '../../../utils/storeApi';
import { useCreditCard, useSavedPayments } from './hooks';
import { toCapitalize } from '../../../utils/toCapitalize';

type Props = PaymentFormProps;

/**
 * Form for adding a new saved payment card
 */
export const AddPaymentCardForm = ({
  onSubmitSuccess,
  onCancelOperation,
  redirectsToOrderReview = false,
  paymentMethodToken = null,
}: Props) => {
  const { setStateForCreditCard } = useCreditCard();
  const {
    setStateForSavedPayment,
    buildSavedPaymentPayload,
    getSavedPaymentByToken,
  } = useSavedPayments();

  let action = 'Add credit card';
  if (paymentMethodToken) {
    const savedPayment = getSavedPaymentByToken(paymentMethodToken);
    const cardType = toCapitalize(savedPayment.cardType);
    action = `You are editing the ${cardType} ending in ${savedPayment.lastFour}`;
  }

  return (
    <PaymentCardForm
      submitButtonText="Save payment"
      onSubmitSuccess={(event, { data }) => {
        const { paymentCard, billingAddress, saveCreditCardOptIn, token } = data;
        if (saveCreditCardOptIn || token) {
          const payload = buildSavedPaymentPayload(paymentCard.nonce, billingAddress);
          // save or update the saved payment (based on the token)
          saveThePayment(
            payload,
            successResult => {
              const { token } = successResult;
              setStateForSavedPayment(paymentCard, token, successResult.account);
              onSubmitSuccess(event, { data: null });
            },
            failResult => {
              // TODO - manage error
            },
            !!token ? token : null,
          );
        } else {
          // use case puts credit card method into billing object
          setStateForCreditCard(paymentCard, billingAddress);
          onSubmitSuccess(event, { data: null });
        }
      }}
      {...{
        action,
        onCancelOperation,
        redirectsToOrderReview,
        paymentMethodToken,
      }}
    />
  );
};

import React from 'react';
import iconpgt from '../../images/iconpgt.svg';
import icondnarmorerelatives from '../../images/icondnarmorerelatives.svg';
import iconhealthinsights from '../../images/iconhealthinsights.svg';

const SubscriptionCancellationReasonsPremium = () => (
  <div className="confirm-section">
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={iconpgt} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">Pharmacogenetics reports</div>
        <div className="confirm-section-content-description">
          Explore how your DNA may affect how you process certain medications with our
          Pharmacogenetics reports.
        </div>
      </div>
    </div>
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={icondnarmorerelatives} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">More DNA Relatives discoveries</div>
        <div className="confirm-section-content-description">
          Filter relatives based on their Ancestry Composition, Haplogroup, and sign-in recency.
        </div>
      </div>
    </div>
    <div className="confirm-section-content">
      <div className="confirm-section-content-image">
        <img src={iconhealthinsights} alt="" width="40" height="40" />
      </div>
      <div className="confirm-section-content-container">
        <div className="confirm-section-content-feature">Exclusive health insights</div>
        <div className="confirm-section-content-description">
          Learn how your genetics can influence your heart health and more with breakthrough reports
          powered by 23andMe research.
        </div>
      </div>
    </div>
  </div>
);
export default SubscriptionCancellationReasonsPremium;

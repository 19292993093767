interface Window {
  webkit?: any;
}

/**
 * Detects whether the page is executing inside a iOS Webview
 */
export const isiOSWebView = (): boolean => {
  return !!(
    window &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.nativeProcess
  );
};

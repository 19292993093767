/* eslint react/no-unused-state:0 */
import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  accepted,
  loadVisitorChoice,
  saveChoice,
  strictClear,
  reload,
  cleanIfFirstTime,
} from './sticky';

const propTypes = {
  children: PropTypes.node.isRequired,
};
const Context = createContext();
const { Provider, Consumer } = Context;

class ShowProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      noBanner: false,
      choice: 'ads',
      step: 1,
      closeTxt: 'close',
    };
    this.body = document.querySelector('body');
  }

  componentWillMount() {
    const alreadyChosenValue = loadVisitorChoice();
    if (alreadyChosenValue === 'ads' || alreadyChosenValue === 'strict') {
      this.setState({ noBanner: true });
      this.setState({ choice: alreadyChosenValue });
      return;
    }

    cleanIfFirstTime();
  }

  render() {
    return (
      <Provider
        value={{
          state: this.state,
          show: () => {
            this.setState({ isShown: true });
            this.body.style.overflowY = 'hidden';
          },
          hide: () => {
            this.body.style.overflowY = '';
            if (this.state.step === 3) {
              this.setState({ closeTxt: 'reloading' });
              reload();
              return;
            }
            this.setState({ isShown: false });
          },
          handleSwitch: () =>
            this.setState(prevState => {
              const chosen = prevState.choice === 'ads' ? 'strict' : 'ads';
              return { choice: chosen };
            }),
          accept: () => {
            this.setState({ noBanner: true });
            accepted('ads');
          },
          submit: () => {
            this.setState({ step: 3 });
            saveChoice(this.state.choice);
            if (this.state.choice === 'strict') {
              strictClear();
            }
          },
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}
ShowProvider.propTypes = propTypes;
export { ShowProvider, Consumer };

/* globals window, document */
import cookie from 'react-cookies';

const getLocale = () => {
  const gdprLink = document.getElementById('#js-gdpr-link');
  const countryCode = cookie.load('country_code');
  const countries = {
    US: '',
    CA: '/en-ca',
    GB: '/en-gb',
    EU: '/en-eu',
    INT: '/en-int',
  };
  let url;
  if (countryCode) {
    url = countries[countryCode.toUpperCase()];
  } else if (gdprLink) {
    url = gdprLink.href;
  } else {
    url = window.location.href;
  }
  let locale = 'en-us';

  if (!url) {
    return locale;
  }

  if (url.indexOf('/en-gb') !== -1) {
    locale = 'en-gb';
  } else if (url.indexOf('/en-ca') !== -1) {
    locale = 'en-ca';
  } else if (url.indexOf('/en-eu') !== -1) {
    locale = 'en-eu';
  } else if (url.indexOf('/en-int') !== -1) {
    locale = 'en-int';
  }

  return locale;
};

const cookiePolicyLink = () => {
  const locale = getLocale();
  const localePath = locale === 'en-us' ? '' : `/${locale}`;

  return `https://www.23andme.com${localePath}/about/cookies/`;
};

export { cookiePolicyLink, getLocale };

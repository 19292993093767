import React from 'react';
import { useStore } from '../../utils/storeUtils';

interface Props {
  klass: string;
  buttonText: string;
  onSubmit: (e) => void;
  disabled?: boolean;
  expiredCard?: boolean;
}

const SubmitButton = (props: Props) => {
  const { klass, buttonText, onSubmit, disabled, expiredCard, ...dataProps } = props;
  const [storeState, updateStoreState] = useStore();
  const disabledClass = disabled || storeState.isProcessing ? 'mod-disabled' : '';

  return (
    <button
      className={`submit-button ${klass} ${disabledClass} ${
        expiredCard ? ` mod-disabled-static` : ``
      }`}
      type="submit"
      aria-label={buttonText}
      {...dataProps}
      onClick={e => {
        updateStoreState({ isProcessing: true });
        onSubmit(e);
      }}
      disabled={disabled || storeState.isProcessing}
    >
      {buttonText}
    </button>
  );
};

export default SubmitButton;

import React from 'react';
import HostedFields from '../PaymentMethodBody/HostedFields';
import { BILLING_METHODS } from '../../../constants';

interface Props {
  method: string;
  setupHostedFields: any;
}

export default function CreditCard({ method, setupHostedFields }: Props) {
  if (method !== BILLING_METHODS.CREDIT_CARD) {
    return null;
  }
  return <HostedFields setupHostedFields={setupHostedFields} isHosted={true} />;
}

import React from 'react';
import { useStoreContext } from '../../utils/storeUtils';

interface Props {
  editLink?: React.ReactNode;
}

export const ReviewPersonalInfo = ({ editLink }: Props) => {
  const {
    storeState: {
      labs: { contactInfo },
    },
  } = useStoreContext();

  // do not render if personal info not available
  if (!contactInfo) return null;

  const { firstName, lastName, dob, birthSex, state, phone } = contactInfo;

  return (
    <>
      {editLink}
      <h3 className="spc-labs-h3">Personal Information</h3>
      <div>
        {firstName} {lastName}
        <br />
        {dob}
        <br />
        {birthSex}
        <br />
        {state}
        <br />
        {phone}
      </div>
    </>
  );
};

import * as React from 'react';

interface Props {
  savedPayment: SavedPayment;
}

const SavedPaymentMethod = (props: Props): React.ReactElement<Props> => {
  const { cardType, imageUrl, lastFour } = props.savedPayment;
  return (
    <div className="spc-billing-text-payment-methods spc-billing-saved-payment-method-wrapper">
      <img
        src={imageUrl}
        alt={`Pay with saved ${cardType}`}
        width="46"
        height="30"
        className="spc-billing-saved-payment-method-image"
      />
      {cardType && lastFour && (
        <span className="spc-billing-saved-payment-method-label">
          {cardType} ending in {lastFour}
        </span>
      )}
    </div>
  );
};

SavedPaymentMethod.displayName = 'SavedPaymentMethod';

export default SavedPaymentMethod;

import getContext from './utils/getContext';

const context = getContext();
const CONTEXT_REGION = context ? context.region : 'en-us';
const URL_REGION = CONTEXT_REGION === 'en-us' ? '' : `/${CONTEXT_REGION}`; // Some URLs for US region don't require /en-us/ in them
export const BASE_URL = 'https://www.23andme.com';
const CUSTOMER_CARE = 'customercare.23andme.com/hc/en-us';

export const REGIONS = {
  US: 'en-us',
  CA: 'en-ca',
  GB: 'en-gb',
  EU: 'en-eu',
  INT: 'en-int',
};

export const REGION_CODE = `${CONTEXT_REGION}`;
export const REGION = `${CONTEXT_REGION}`.replace('-', '');
export const SINGLE_COUNTRY_REGIONS = ['enus', 'enca', 'engb'];
export const COUNTRY_TO_REGION_MAP = {
  US: 'enus',
  CA: 'enca',
  GB: 'engb',
  AS: 'enus',
  MP: 'enus',
  PR: 'enus',
  VI: 'enus',
  GU: 'enus',
  DK: 'eneu',
  FI: 'eneu',
  IE: 'eneu',
  NL: 'eneu',
  SE: 'eneu',
};
export const COOKIE_BANNER_REGIONS = []; /* originally ['engb', 'eneu'] - may re-enable if necessary */
export const GDPR_REGIONS = ['engb', 'eneu', 'enint'];
export const PRICE_MODAL_REGIONS = ['enus'];
export const PRICE_MODAL_KIT_SKUS = ['US-KIT-PGS'];
export const HEAP_REGIONS = ['enca', 'enus', 'engb'];
export const SUBSCRIPTION_REGIONS = ['enca', 'enus', 'engb'];
export const HEAP_SKUS = {
  ANCESTRY: ['CA-KIT-ANC', 'US-KIT-V4-ANC-2016'],
  HEALTH: ['CA-KIT-PGS', 'US-KIT-PGS'],
};
export const TOTAL_HEALTH_ALIAS = 'us_exome_health_subs_12_mo_prepaid';
export const PERMITTED_REGIONS = {
  ALL: 'en-:lang(us|ca|gb|eu|int)',
  US: 'en-:lang(us)',
};
export const COUNTRIES = {
  US: 'US',
  GDPR_REGIONS: ['US', 'CA', 'GB'],
};
export const getPgtPermalink = region =>
  region == 'en-us'
    ? `${BASE_URL}/test-info/pharmacogenetics`
    : `${BASE_URL}/${region}/test-info/pharmacogenetics`;
export const getFdaInfo = region =>
  region == 'en-us' ? `${BASE_URL}/test-info/` : `${BASE_URL}/${region}/test-info/`;
export const getSubscriptionTermsOfService = region =>
  region == 'en-us'
    ? `${BASE_URL}/about/tos/subscription`
    : `${BASE_URL}/${region}/about/tos/subscription`;
export const URLS = {
  AD_CHOICES: `${BASE_URL}/${URL_REGION}/about/cookies/#first-third-party-cookies`,
  AUTHENTICATION: `/${CONTEXT_REGION}/authenticate/`,
  CART: `/${CONTEXT_REGION}/cart/`,
  CONSUMER_HEALTH_DATA_PRIVACY_POLICY: `${BASE_URL}/legal/us-privacy/#washington-consumer-health-data-privacy-policy`,
  CUSTOMER_CARE: `https://${CUSTOMER_CARE}`,
  CONSENT_DOC: `${BASE_URL}${URL_REGION}/about/consent/`,
  COOKIES: `${BASE_URL}${URL_REGION}/about/cookies/`,
  COOKIE_CHOICES: `#cookie-choices`,
  DATA_PROTECTION: `${BASE_URL}${URL_REGION}/gdpr`,
  FAMILY_CONSIDERATIONS: `https://${CUSTOMER_CARE}/articles/202907980`,
  FDA_INFO: getFdaInfo(CONTEXT_REGION),
  FETCH_LABELS: `/${CONTEXT_REGION}/labels/`,
  FETCH_SHIPPING_METHODS: `/${CONTEXT_REGION}/shipping_methods/`,
  FETCH_STATES: `/${CONTEXT_REGION}/states/`,
  FSA_HSA: `/${BASE_URL}/fsa-hsa/`,
  GIFTWRAP: `/${CONTEXT_REGION}/giftwrap`,
  HOME: `${BASE_URL}${URL_REGION}`,
  PGT_PERMALINK: getPgtPermalink(CONTEXT_REGION),
  PRIVACY_POLICY: `${BASE_URL}${URL_REGION}/legal/privacy`,
  TELEHEALTH_TOS: `${BASE_URL}${URL_REGION}/legal/telehealth-tos`,
  RECEIPT: `/${CONTEXT_REGION}/receipt/`,
  LABS_RECEIPT: `/${CONTEXT_REGION}/labs/receipt/`,
  TOTAL_HEALTH_EXISTING_USER_SIGN_UP: `${BASE_URL}/total-health/#sign-up`,
  REFUND_POLICY: {
    enca: `https://ca.${CUSTOMER_CARE}/articles/115000268708`,
    eneu: `https://eu.${CUSTOMER_CARE}/articles/204704750`,
    engb: `https://eu.${CUSTOMER_CARE}/articles/204704750`,
    enint: `https://int.${CUSTOMER_CARE}/articles/214810808`,
    enus: `https://${CUSTOMER_CARE}/articles/202907780`,
  },
  SAVED_PAYMENT: `/${CONTEXT_REGION}/saved-payments/`,
  SURVEY: `/${CONTEXT_REGION}/checkout/survey/`,
  SHIPMETHOD: `/${CONTEXT_REGION}/checkout/shipmethod/`,
  SHIPPING: `/${CONTEXT_REGION}/checkout/shipping/`,
  SITE_MAP: `${BASE_URL}${URL_REGION}/sitemap`,
  SUBSCRIPTION_RECEIPT: `/${CONTEXT_REGION}/subscription/receipt/`,
  SAVED_PAYMENTS: `/${CONTEXT_REGION}/saved-payments/`,
  SUBSCRIPTION_RENEWAL: `/${CONTEXT_REGION}/renew-subscription/payment/`,
  TERMS_OF_SERVICE: `${BASE_URL}${URL_REGION}/legal/terms-of-service`,
  SUBSCRIPTION_TERMS_OF_SERVICE: `${BASE_URL}${URL_REGION}/about/tos/subscription`,
  VALIDATION: `/${CONTEXT_REGION}/checkout/validation/`,
  YOUDOT: `https://you.23andme.com/`,
};
export const SALE_AND_USE_COUNTRY = {
  enca: 'Canada',
  eneu: 'Denmark, Finland, Ireland, Sweden and the Netherlands',
  engb: 'the United Kingdom',
  enint: 'the country to which the kit is shipped from 23andMe',
  enus: 'the US',
};
export const WRONG_COUNTRY_ERROR = {
  enca: {
    desiredCountry: '',
    site: 'Canadian',
    urlSuffix: 'ca',
  },
  eneu: {
    desiredCountry: '',
    site: 'European Union',
    urlSuffix: 'eu',
  },
  engb: {
    desiredCountry: 'England, Scotland, Wales or Northern Ireland',
    site: 'UK',
    urlSuffix: 'co.uk',
  },
  enus: {
    desiredCountry: 'the United States',
    site: 'United States',
    urlSuffix: 'com',
  },
  enint: {
    desiredCountry: '',
    site: 'International',
    urlSuffix: 'int',
  },
};

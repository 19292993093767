/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import icondnarmorerelatives from '../../images/icondnarmorerelatives.svg';
import iconhealthinsights from '../../images/iconhealthinsights.svg';
import iconpgt from '../../images/iconpgt.svg';
import SubscriptionName from './SubscriptionName';
import SubscriptionCancellationReasonsPremium from './SubscriptionCancellationConfirmPremium';
import SubscriptionCancellationReasonsTotalHealth from './SubscriptionCancellationConfirmTotalHealth';

interface Props {
  handleConfirm: () => void;
  sampleShippingCountry: SampleShippingCountry;
  youdotUrl: string;
  isTotalHealth: boolean;
}

export default function SubscriptionCancellationConfirm({
  handleConfirm,
  sampleShippingCountry,
  youdotUrl,
  isTotalHealth,
}: Props) {
  return (
    <div className="spc-subs-cancel-confirm">
      <div className="spc-subs-cancel-confirm-header-wrapper">
        <h2>Are you sure you’d like to cancel your subscription?</h2>
      </div>
      <div className="confirm-section-wrapper">
        <div className="confirm-section-title">
          You will lose access to these subscription perks at the end of your billing cycle.
        </div>
        {isTotalHealth ? (
          <SubscriptionCancellationReasonsTotalHealth />
        ) : (
          <SubscriptionCancellationReasonsPremium />
        )}
      </div>
      {isTotalHealth && (
        <p className="spc-spi-subs-disclaimer">
          By canceling your 23andMe+ Total Health membership, you will not have access to additional
          Telehealth Services from Providers as part of the 23andMe+ Total Health membership
          services. To resume access to additional Telehealth Services, you may renew your 23andMe+
          Total Health membership at any time.
        </p>
      )}
      <div className="buttons">
        <a className="button back-button" href={`${youdotUrl}user`}>
          Keep{' '}
          <SubscriptionName
            useServiceMark={true}
            region={sampleShippingCountry}
            isTotalHealth={isTotalHealth}
          />
        </a>
        <div className="button next-button" tabIndex={0} role="button" onClick={handleConfirm}>
          Cancel <SubscriptionName isTotalHealth={isTotalHealth} />
        </div>
      </div>
    </div>
  );
}

import React from 'react';

interface Props {
  height?: string;
  width?: string;
  className?: string;
}

const Visa = ({ height = '46px', width = '46px', className = '' }: Props) => (
  <div className="spc-no-events" tabIndex={-1}>
    <svg
      height={height}
      width={width}
      className={className}
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 90 90"
    >
      <title>Visa payment method</title>
      <symbol id="Icon_visa" viewBox="-45 -27.5 90 55">
        <path
          className="spc-st0"
          d="M45-23.5c0-2.2-1.8-4-4-4h-82c-2.2 0-4 1.8-4 4v47c0 2.2 1.8 4 4 4h82c2.2 0 4-1.8 4-4v-47z"
        />
        <path
          className="spc-st1"
          d="M-8.7-8.7l3 17.4h4.8l-3-17.4zM13.3 8.3c-.9.3-2.4.7-4.2.7-4.7 0-8-2.4-8-5.8C1.1.7 3.5-.7 5.3-1.5 7-2.3 7.7-2.9 7.7-3.7c0-1.2-1.5-1.7-2.8-1.7-1.9 0-2.9.3-4.5.9l-.6.3-.7-3.9C.2-8.6 2.2-9 4.4-9c5 0 8.2 2.3 8.3 6 0 2-1.2 3.5-4 4.7C7 2.5 6 3 6 3.9c0 .7.9 1.5 2.7 1.5 1.6 0 2.7-.3 3.6-.7l.4-.2.6 3.8M25.5 8.7h-3.7c-1.1 0-2-.3-2.5-1.4l-7.1-16h5s.8 2.1 1 2.6h6.1c.1-.6.6-2.6.6-2.6h4.4L25.5 8.7M19.6-2.6c.4 1 1.9 4.9 1.9 4.9s.4 1 .6 1.7l.3-1.5s.9-4.2 1.1-5h-3.9zM-12.7 8.7l-4.7-11.9-.5 2.4c-.8 2.8-3.5 5.8-6.5 7.3l4.3-15.3h5l7.5 17.4h-5.1"
        />
        <path
          className="spc-st2"
          d="M-21.6 8.7h-7.7l-.1-.4c6-1.4 9.9-4.9 11.5-9.1l-1.7 8c-.2 1.1-1 1.5-2 1.5"
        />
      </symbol>
      <use
        xlinkHref="#Icon_visa"
        width="90"
        height="55"
        id="XMLID_73_"
        x="-45"
        y="-27.5"
        transform="matrix(1 0 0 -1 45 45)"
        overflow="visible"
      />
    </svg>
  </div>
);

Visa.displayName = 'Visa';

export default Visa;

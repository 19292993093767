import React from 'react';
import { redirectToCustomerCare } from './subscriptionRenewalOptInActions';
import { useStore } from '../../utils/storeUtils';

export const ActivationOptOut = () => {
  const [storeState] = useStore();
  const isTotalHealth = storeState?.hasTotalHealth;
  return (
    <button
      className="spc-optin-buttons-remind-me-later"
      onClick={() => {
        redirectToCustomerCare(isTotalHealth);
      }}
    >
      Customer Care
    </button>
  );
};

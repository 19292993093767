import React from 'react';

interface Props {
  name: string;
  text?: string;
  value: string;
  onChange: (e?: any) => void;
  id: string;
  showError?: boolean;
  type?: string;
  readOnly?: boolean;
  isRequired?: boolean;
  hasPaymentClass?: boolean;
  isPresent?: boolean;
}

const TextInput = ({
  name,
  id,
  text,
  value,
  onChange,
  showError,
  type = 'text',
  isRequired = true,
  readOnly,
  isPresent = true,
}: Props) => {
  const inputId: { id?: string } = {};
  if (id !== '') {
    inputId.id = id;
  }

  let showErrClass;
  let labelTxt;
  if (showError) {
    showErrClass = ' mod-error';
    labelTxt = <span className="spc-error-info">Invalid {text.toLowerCase()}</span>;
    value = '';
  } else {
    showErrClass = '';
  }

  const notEmptyClassName = readOnly ? 'label mod-not-empty' : 'label';
  if (!isPresent) return null;

  return (
    <div className={`spc-input${showErrClass}`}>
      <div className="spc-input-container">
        <label className="spc-input-label" htmlFor={inputId.id}>
          {text}
        </label>
        <input
          {...inputId}
          name={name}
          type={type}
          data-stor-id={`spc-${name}-text-field`}
          data-jest-id={`spc-${name}-input-field`}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          required={isRequired}
          aria-required={isRequired}
        />
      </div>
      <div className={notEmptyClassName}>{labelTxt}</div>
    </div>
  );
};

export default TextInput;

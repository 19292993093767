import React from 'react';
import { ELEMENTS } from '../../../../constants';
import { URLS } from '../../../../regions';

interface Props {
  isChipUpgrade?: boolean;
  isPrepaidSubscription?: boolean;
  hasTotalHealth?: boolean;
  hasTotalHealthUpgrade?: boolean;
}

const TermsOfServiceAll = ({
  isChipUpgrade = false,
  isPrepaidSubscription = false,
  hasTotalHealth = false,
  hasTotalHealthUpgrade = false,
}: Props) => (
  <div id={ELEMENTS.accept} data-jest-id="terms-of-service">
    I accept the&nbsp;
    {isPrepaidSubscription || hasTotalHealth || hasTotalHealthUpgrade ? (
      <>
        <a
          data-test-id="checkout-accept-sub-tos-link"
          data-jest-id="checkout-accept-sub-tos-link"
          className="spc-summary-accept-a"
          href={URLS.SUBSCRIPTION_TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Membership Terms of Service
        </a>
        ,{' '}
        <a
          data-test-id="checkout-accept-tos-link"
          data-jest-id="checkout-accept-tos-link"
          className="spc-summary-accept-a"
          href={URLS.TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        {(hasTotalHealth || hasTotalHealthUpgrade) && (
          <>
            ,{' '}
            <a
              data-test-id="checkout-accept-telehealth-tos-link"
              data-jest-id="checkout-accept-telehealth-tos-link"
              className="spc-summary-accept-a"
              href={URLS.TELEHEALTH_TOS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Telehealth Terms of Service
            </a>
          </>
        )}{' '}
        and{' '}
        <a
          data-test-id="checkout-accept-privacy-link"
          data-jest-id="checkout-accept-privacy-link"
          className="spc-summary-accept-a"
          href={URLS.PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Statement
        </a>
      </>
    ) : (
      <>
        <a
          data-test-id="checkout-accept-tos-link"
          data-jest-id="checkout-accept-tos-link"
          className="spc-summary-accept-a"
          href={URLS.TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
      </>
    )}
    {isChipUpgrade && ' and acknowledge that this purchase is non-refundable.'}
  </div>
);

export default TermsOfServiceAll;

import React from 'react';
import SelectInput from '../../SelectInput';
import { ELEMENTS } from '../../../../constants';

interface Props {
  country: string;
  countries: Country[];
  handleChange: (e: any) => void;
  errors?: FormError[];
  focusLabel?: string;
  disableCountrySelector?: boolean;
}
const CountryFieldSelect = ({
  country,
  countries,
  handleChange,
  errors = [],
  focusLabel = '',
  disableCountrySelector = false,
}: Props) => (
  <div className="spc-select">
    <SelectInput
      id={ELEMENTS.country}
      name="country"
      placeholder="Select Country"
      selectedOption={country}
      options={countries}
      onChange={handleChange}
      showError={errors.includes('country')}
      focusLabel={focusLabel}
      isRequired={true}
      disableCountrySelector={disableCountrySelector}
    />
  </div>
);

export default CountryFieldSelect;

/*
 * utilities for handling i18n address fields
 */

import { SINGLE_COUNTRY_REGIONS } from '../regions';
import { basicAddressFieldRequirements } from './getListOfInvalidFields';

export interface DefaultFieldInfo {
  [key: string]: FieldInfo;
}

const defaultFieldInfo: DefaultFieldInfo = {
  enus: {
    state: {
      label: 'State',
      optional: false,
    },
    postalCode: {
      label: 'Zip code',
      optional: false,
    },
  },
  enca: {
    state: {
      label: 'Province',
      optional: false,
    },
    postalCode: {
      label: 'Postal code',
      optional: false,
    },
  },
  engb: {
    postalCode: {
      label: 'Postal code',
      optional: false,
    },
  },
  eneu: {},
  enint: {},
};

function getFieldInfo(fieldInfo, field) {
  if (field in fieldInfo) {
    return fieldInfo[field];
  }
  return null;
}

function isFieldRequired(fieldInfo, field) {
  const fieldData = getFieldInfo(fieldInfo, field);
  const optional = fieldData ? fieldData.optional : false;
  return !optional;
}

function getFieldLabel(fieldInfo, field, defaultLabel?) {
  const info = getFieldInfo(fieldInfo, field);
  if (info === null) {
    return defaultLabel || field;
  }
  return info.label + (info.optional ? ' (optional)' : '');
}

function getFieldRestrictions(fieldInfo) {
  return { fieldRestrictions: fieldInfo['fieldRestrictions'] };
}

function isFieldPresent(fieldInfo, field) {
  return getFieldInfo(fieldInfo, field) !== null;
}

function unpackField(group, key, optional) {
  return {
    label: group[key],
    optional,
  };
}

function unpackFieldGroup(labelData, groupName, optional) {
  const unpackedGroup = {};
  const group = labelData[groupName];
  Object.keys(group).forEach(field => {
    unpackedGroup[field] = unpackField(group, field, optional);
  });
  return unpackedGroup;
}

function unpackLabelData(labelData): FieldInfo {
  /*
  Sample labelData responses (toCamel has been applied):
  {'requiredFields': {'state': 'State', 'postalCode': 'Zip code'}, 'optionalFields': {}}
  {'requiredFields': {'state': 'State'}, 'optionalFields': {'postalCode': 'Postal code'}}

  -- A breakdown follows --
  {
    'requiredFields':    <-- requiredFields is a field group
    {
      'state': 'State'   <-- state is a field
    },
    'optionalFields':
    {
      'postalCode': 'Postal code'
    }
  }
  */
  return Object.assign(
    {},
    unpackFieldGroup(labelData, 'requiredFields', false),
    unpackFieldGroup(labelData, 'optionalFields', true),
    getFieldRestrictions(labelData, 'fieldRestrictions'),
  );
}

function parseIsoCountryCode(region) {
  if (SINGLE_COUNTRY_REGIONS.includes(region)) {
    return region.substring(2, 4).toUpperCase();
  }
  return '';
}

function refineAddressValidation(fieldInfo, addressValidation = basicAddressFieldRequirements) {
  const refined = Object.assign({}, addressValidation, fieldInfo['fieldRestrictions']);
  const specialRegionFields = ['postalCode', 'state'];
  Object.keys(refined)
    .filter(key => {
      const info = getFieldInfo(fieldInfo, key);
      const isMissingAndSpecial = info === null && specialRegionFields.indexOf(key) > -1;
      const isPresentButOptional = info !== null && info.optional;
      return isPresentButOptional || isMissingAndSpecial;
    })
    .forEach(key => {
      delete refined[key];
    });
  return refined;
}

export {
  defaultFieldInfo,
  unpackLabelData,
  isFieldPresent,
  getFieldLabel,
  isFieldRequired,
  refineAddressValidation,
  parseIsoCountryCode,
};

import { BASE_URL } from '../regions';
import cookie from 'react-cookies';

declare global {
  interface Window {
    TTAM_GTM: any;
  }
}

type TrackedProduct = 'ha' | 'at' | 'sub' | 'th' | 'th_upgrade';

function is_total_health_upgrade(alias) {
  const total_health_upgrades = [
    'total_health_upgrade_ancestry',
    'total_health_upgrade_health',
    'total_health_upgrade_premium',
  ];
  return total_health_upgrades.includes(alias);
}

function is_total_health(alias) {
  const total_health = ['us_exome_health_subs_12_mo_prepaid'];
  return total_health.includes(alias);
}

function is_premium(alias) {
  const premium = ['prepaid_subscription_kit'];
  return premium.includes(alias);
}

/**
 * Returns the product type that customer should not receive abandonment emails on.
 * Prioritizes most expensive product.
 *
 * @param {Object} kitCounts
 * @returns {String} product type
 */
export const getTrackedProduct = (kits: Kit[]): TrackedProduct => {
  let product = null;

  const hasKit = {};
  kits.forEach(({ alias }) => {
    if (is_total_health(alias)) {
      alias = 'total_health';
    } else if (is_total_health_upgrade(alias)) {
      alias = 'total_health_upgrade';
    } else if (is_premium(alias)) {
      alias = 'prepaid_subscription_kit';
    }
    hasKit[alias] = true;
  });

  if (hasKit['total_health']) product = 'th';
  // th_upgrade will be effectively ignored until PUB-6355 is release.
  // TODO: remove this comment.
  else if (hasKit['total_health_upgrade']) product = 'th_upgrade';
  else if (hasKit['prepaid_subscription_kit']) product = 'sub';
  else if (hasKit['health']) product = 'ha';
  else if (hasKit['ancestry']) product = 'at';

  return product;
};

type TrackOrderSuccessProps = { kits: Kit[]; email: string };

export const trackOrderSuccess = ({ kits, email }: TrackOrderSuccessProps) => {
  const product = getTrackedProduct(kits);

  if (!window.TTAM_GTM || !window.TTAM_GTM.emailCapture || !product) {
    return;
  }

  window.TTAM_GTM.emailCapture.put({
    purchaseType: product,
    email: email,
  });
};

export const captureEmail = async ({
  email,
  locale,
  addToCartType,
}: {
  email: string;
  locale: string;
  addToCartType: TrackedProduct;
}) => {
  const EMAIL_ENDPOINT = '/api/email-capture';
  const EMAIL_COOKIE = 'ttam_email_capture_email';

  const data = {
    email: email,
    campaign: 'checkout',
    source: `/${locale}/checkout/`,
    locale: locale,
    addToCartType,
  };

  const prevEmail = cookie.load(EMAIL_COOKIE);
  const isSubmittedEmail = typeof prevEmail === 'string' && prevEmail.trim() === email.trim();

  try {
    const response = await fetch(`${BASE_URL}${EMAIL_ENDPOINT}`, {
      method: isSubmittedEmail ? 'PUT' : 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!isSubmittedEmail) {
      cookie.save(EMAIL_COOKIE, email, {
        path: '/',
        domain: '.23andme.com',
        secure: true,
      });
    }

    return response.json();
  } catch {
    return;
  }
};

import { useStoreContext } from '../../../../utils/storeUtils';
import { updateGlobalStore } from '../../../../actions/actions';
import { BILLING_ADDRESS, BILLING_METHODS } from '../../../../constants';

export const useCreditCard = () => {
  const { storeState, dispatch } = useStoreContext();
  const hasCreditCardData =
    !!storeState.billing.nonce && storeState.billing.method === 'credit_card';
  const creditCardData = {
    cardType: hasCreditCardData ? storeState.billing.cardType : '',
    lastFour: hasCreditCardData ? storeState.billing.lastFour : '',
    nonce: hasCreditCardData ? storeState.billing.nonce : '',
  };

  const setStateForCreditCard = (paymentCard, billingAddress) => {
    dispatch(
      updateGlobalStore({
        billing: {
          ...storeState.billing,
          ...paymentCard, // contains nonce
          token: '',
          method: BILLING_METHODS.CREDIT_CARD,
          customAddress: billingAddress,
          addressSource: BILLING_ADDRESS.CUSTOM,
        },
      }),
    );
  };

  return {
    hasCreditCardData,
    creditCardData,
    setStateForCreditCard,
  };
};

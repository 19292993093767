import React from 'react';
import { useState, useEffect } from 'react';
import iconsurvey from '../images/iconsurvey.svg';
import SurveyQuestion from './SurveyQuestion';
import { postSurveyResults } from '../utils/storeApi';
import SubscriptionName from './../components/SubscriptionPayment/SubscriptionName';
import { useStore } from '../utils/storeUtils';

interface Props {
  receiptId: string;
}

export default function Survey({ receiptId }: Props) {
  const [storeState, _] = useStore();
  const isTotalHealth = storeState?.subscriptionInfo?.isTotalHealth;
  const survey = [
    {
      type: 'checkbox',
      questionId: 'subscription_interests',
      required: true,
      question: 'What 23andMe+ feature(s) are you most excited about?',
      answers: [
        {
          text: 'Exclusive 23andMe+ health reports and insights',
          value: 'health_reports_and_insights',
        },
        { text: 'Advanced ancestry and genealogy features', value: 'advanced_ancestry_features' },
        { text: 'Lifestyle choice guidance', value: 'lifestyle_choice_guidance' },
        {
          text: 'New 23andMe+ reports and features as they are developed',
          value: 'new_reports_features_developed',
        },
        { text: 'Access to the full 23andMe experience.', value: 'full_23andme_experience' },
        { text: 'Other / prefer not to say', value: 'other_or_prefer_not_to_say' },
      ],
    },
    {
      type: 'radio',
      questionId: 'informed_purchase_satisfaction',
      required: true,
      question:
        'How satisfied are you with the amount of information you received about 23andMe+ before purchasing?',
      answers: [
        { text: 'Not at all satisfied', value: 'not_at_all_satisfied' },
        { text: 'Slightly satisfied', value: 'slightly_satisfied' },
        { text: 'Moderately satisfied', value: 'moderately_satisfied' },
        { text: 'Very satisfied', value: 'very_satisfied' },
        { text: 'Extremely satisfied', value: 'extremely_satisfied' },
      ],
    },
    {
      type: 'textarea',
      questionId: 'feedback_freetext',
      required: false,
      question: "Anything else you'd like to tell us?",
    },
  ];

  const [current, setCurrent] = useState(0);
  const [payload, setPayload] = useState({});
  const [answered, setAnswered] = useState(survey.map(() => false));

  useEffect(() => {
    if (current > 0) {
      const el = document.getElementById('survey-answer-section');
      if (el) {
        el.focus();
      }
    }
  }, [current]);

  const setCurrentResponse = (questionId, value, isReadyToSubmit) => {
    const newPayload = {
      ...payload,
      [questionId]: {
        survey_id: 'subscription_postpurchase_survey',
        question_id: questionId,
        instance_id: receiptId,
        value: value,
      },
    };
    setPayload(newPayload);
    answered[current] = isReadyToSubmit;
    setAnswered([...answered]);
  };

  if (current >= survey.length) {
    return (
      <div className="spc-subs-receipt-survey">
        <img
          src={iconsurvey}
          alt=""
          width="96"
          height="96"
          className="spc-subs-receipt-survey-image"
        />
        <h2 className="thankyou" aria-live="polite">
          Thanks for filling out our survey!
        </h2>
      </div>
    );
  }

  return (
    <div className="spc-subs-receipt-survey">
      <img src={iconsurvey} alt="" width="96" height="96" />
      <h2>23andMe Customer Survey</h2>
      <div>
        Thank you for joining <SubscriptionName isTotalHealth={isTotalHealth} />! If you have a
        moment, we&apos;d appreciate your feedback.
      </div>
      <div className="question-section-wrapper">
        {survey.map((item, index) => (
          <SurveyQuestion
            key={item['questionId']}
            questionId={item['questionId']}
            fieldType={item['type']}
            question={item['question']}
            answers={item['answers']}
            active={index === current}
            setCurrentResponse={setCurrentResponse}
          />
        ))}
        <div className="buttons">
          <button
            className={`survey-button back-button${current > 0 ? '' : ' disabled'}`}
            aria-disabled={current === 0}
            onClick={() => {
              if (current > 0) {
                setCurrent(current => current - 1);
              }
            }}
          >
            Back
          </button>
          <button
            className={`survey-button next-button${
              !survey[current]['required'] || answered[current] ? '' : ' disabled'
            }`}
            aria-disabled={survey[current]['required'] && !answered[current]}
            onClick={() => {
              if (survey[current]['required'] && !answered[current]) return;
              setCurrent(current => current + 1);
              if (current >= survey.length - 1) {
                Object.keys(payload).forEach(item => {
                  postSurveyResults(payload[item]);
                });
              }
            }}
          >
            {current >= survey.length - 1 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

interface Props {
  height?: string;
  width?: string;
  className?: string;
}

const Discover = ({ height = '29px', width = '46px', className = '' }: Props) => (
  <div className="spc-no-events" tabIndex={-1}>
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-828.1 758.2 90.3 55.4"
    >
      <title>Discover payment method</title>
      <defs>
        {' '}
        <path
          id="a"
          d="M-738.1 809.2c0 2.2-1.8 4-4 4H-824c-2.2 0-4-1.8-4-4v-46.9c0-2.2 1.8-4 4-4h81.9c2.2 0 4 1.8 4 4v46.9z"
        />{' '}
      </defs>{' '}
      <clipPath id="b">
        {' '}
        <use xlinkHref="#a" overflow="visible" />{' '}
      </clipPath>{' '}
      <g clipPath="url(#b)">
        {' '}
        <path fill="#F0F1F1" d="M-827.9 757.1h89.8v57.3h-89.8v-57.3" />{' '}
        <path fill="#F58220" d="M-738.1 789.7s-23.7 16.7-66.9 24.2h66.9v-24.2" />{' '}
      </g>{' '}
      <g>
        {' '}
        <defs>
          {' '}
          <path
            id="c"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="d">
          {' '}
          <use xlinkHref="#c" overflow="visible" />{' '}
        </clipPath>{' '}
        <path clipPath="url(#d)" fill="#FFEFE1" d="M-781.9 785.7" />{' '}
        <defs>
          {' '}
          <path
            id="e"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="f">
          {' '}
          <use xlinkHref="#e" overflow="visible" />{' '}
        </clipPath>{' '}
        <path clipPath="url(#f)" fill="#FFEFE1" d="M-781.9 785.7" />{' '}
        <defs>
          {' '}
          <path
            id="g"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="h">
          {' '}
          <use xlinkHref="#g" overflow="visible" />{' '}
        </clipPath>{' '}
        <path clipPath="url(#h)" fill="#FFEFE1" d="M-781.9 785.7s.1 0 0 0c.1 0 0 0 0 0" />{' '}
        <defs>
          {' '}
          <path
            id="i"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="j">
          {' '}
          <use xlinkHref="#i" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#j)"
          fill="#FFEFE1"
          d="M-781.9 785.7s0 .1 0 0c0 .1.1 0 0 0 .1 0 0 0 0 0"
        />{' '}
        <defs>
          {' '}
          <path
            id="k"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="l">
          {' '}
          <use xlinkHref="#k" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#l)"
          fill="#FFEFE1"
          d="M-781.9 785.7c.1 0 0 .1 0 0 0 .1 0 0 0 0-.1 0 0 .1 0 0 .1.1.1 0 0 0 .1 0 .1-.1 0 0 0-.1-.1 0 0 0"
        />{' '}
        <defs>
          {' '}
          <path
            id="m"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="n">
          {' '}
          <use xlinkHref="#m" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#n)"
          fill="#FFEFE1"
          d="M-782 785.7s.1-.1 0 0c.1-.1.1 0 0 0 .2 0 .2.1 0 0 .1.1 0 0 0 0 0 .1.1.1 0 0 .1.1.2 0 .2 0s0-.1-.2 0c.1-.1 0 0 0 0"
        />{' '}
        <defs>
          {' '}
          <path
            id="o"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="p">
          {' '}
          <use xlinkHref="#o" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#p)"
          fill="#FFEFE0"
          d="M-782 785.7s.1-.1 0 0c.1-.1.2 0 .2 0 0 .1 0 .1-.2 0 .1.1 0 .1 0 0 0 .1 0 .1 0 0 .2.1.2.1.2 0s0-.1-.2 0c.1-.1 0-.1 0 0"
        />{' '}
        <defs>
          {' '}
          <path
            id="q"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="r">
          {' '}
          <use xlinkHref="#q" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#r)"
          fill="#FFEFE0"
          d="M-782 785.7c0-.1 0-.1 0 0 .2-.1.2-.1.2 0s0 .1-.2 0c.1.1 0 .1 0 0 0 .1 0 .1 0 0 .2.1.2.1.2 0s0-.1-.2 0c0-.1 0-.1 0 0"
        />{' '}
        <defs>
          {' '}
          <path
            id="s"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="t">
          {' '}
          <use xlinkHref="#s" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#t)"
          fill="#FFEEE0"
          d="M-782 785.7c0-.1 0-.1 0 0 .2-.1.2 0 .2 0s0 .1-.2 0c0 .1 0 .1 0 0 0 .1 0 .1 0 0 .2.1.2 0 .2 0 0-.1-.1-.1-.2 0 0-.1 0-.1 0 0"
        />{' '}
        <defs>
          {' '}
          <path
            id="u"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="v">
          {' '}
          <use xlinkHref="#u" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#v)"
          fill="#FFEEDF"
          d="M-782 785.7c0-.1 0-.1 0 0 .2-.1.2 0 .2 0 0 .1-.1.1-.2 0 0 .1 0 .1 0 0 0 .1 0 .2.1.2s.2-.1.2-.2-.1-.2-.2-.2c-.1.1-.1.1-.1.2"
        />{' '}
        <defs>
          {' '}
          <path
            id="w"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="x">
          {' '}
          <use xlinkHref="#w" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#x)"
          fill="#FFEEDF"
          d="M-782 785.7c0-.1 0-.2.1-.2s.2.1.2.2-.1.2-.2.2-.1-.1-.1-.2c0 .1 0 .2.1.2s.2-.1.2-.2-.1-.2-.2-.2c-.1.1-.2.1-.1.2"
        />{' '}
        <defs>
          {' '}
          <path
            id="y"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="z">
          {' '}
          <use xlinkHref="#y" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#z)"
          fill="#FFEEDF"
          d="M-782.1 785.7c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2c0 .1.1.2.2.2s.2-.1.2-.2-.1-.2-.2-.2c-.1.1-.2.1-.2.2"
        />{' '}
        <defs>
          {' '}
          <path
            id="A"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="B">
          {' '}
          <use xlinkHref="#A" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#B)"
          fill="#FFEEDE"
          d="M-782.1 785.7c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2c0 .1.1.2.2.2s.2-.1.2-.2-.1-.2-.2-.2c-.1.1-.2.1-.2.2"
        />{' '}
        <defs>
          {' '}
          <path
            id="C"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="D">
          {' '}
          <use xlinkHref="#C" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#D)"
          fill="#FFEDDE"
          d="M-782.1 785.7c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2c0 .1.1.2.2.2s.2-.1.2-.2-.1-.2-.2-.2c-.1.1-.2.1-.2.2"
        />{' '}
        <defs>
          {' '}
          <path
            id="E"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="F">
          {' '}
          <use xlinkHref="#E" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#F)"
          fill="#FFEDDE"
          d="M-782.1 785.7c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2c0 .1.1.2.2.2s.3-.1.3-.2-.1-.2-.3-.2-.2.1-.2.2"
        />{' '}
        <defs>
          {' '}
          <path
            id="G"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="H">
          {' '}
          <use xlinkHref="#G" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#H)"
          fill="#FFEDDD"
          d="M-782.1 785.7c0-.1.1-.2.2-.2s.2.1.2.2-.1.3-.2.3-.2-.2-.2-.3c0 .1.1.3.3.3.2 0 .2-.2.2-.3s-.1-.3-.3-.3-.3.2-.2.3"
        />{' '}
        <defs>
          {' '}
          <path
            id="I"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="J">
          {' '}
          <use xlinkHref="#I" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#J)"
          fill="#FFEDDD"
          d="M-782.2 785.7c0-.1.1-.3.3-.3.1 0 .3.1.3.3 0 .1-.1.3-.3.3-.1 0-.3-.2-.3-.3 0 .2.1.3.3.3s.3-.1.3-.3-.1-.3-.3-.3c-.1.1-.3.2-.3.3"
        />{' '}
        <defs>
          {' '}
          <path
            id="K"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="L">
          {' '}
          <use xlinkHref="#K" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#L)"
          fill="#FFEDDC"
          d="M-782.2 785.7c0-.2.1-.3.3-.3s.3.1.3.3-.1.3-.3.3-.3-.1-.3-.3c0 .2.1.3.3.3s.3-.1.3-.3-.1-.3-.3-.3-.3.2-.3.3"
        />{' '}
        <defs>
          {' '}
          <path
            id="M"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="N">
          {' '}
          <use xlinkHref="#M" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#N)"
          fill="#FFEDDC"
          d="M-782.2 785.7c0-.2.1-.3.3-.3s.3.1.3.3-.1.3-.3.3-.3-.1-.3-.3c0 .2.1.3.3.3s.3-.1.3-.3-.1-.3-.3-.3-.3.2-.3.3"
        />{' '}
        <defs>
          {' '}
          <path
            id="O"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="P">
          {' '}
          <use xlinkHref="#O" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#P)"
          fill="#FFECDC"
          d="M-782.2 785.7c0-.2.1-.3.3-.3s.3.1.3.3-.1.3-.3.3-.3-.1-.3-.3c0 .2.1.3.3.3s.4-.1.4-.3-.2-.3-.4-.3-.3.2-.3.3"
        />{' '}
        <defs>
          {' '}
          <path
            id="Q"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="R">
          {' '}
          <use xlinkHref="#Q" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#R)"
          fill="#FFECDC"
          d="M-782.2 785.7c0-.2.2-.3.3-.3.2 0 .3.2.3.3 0 .2-.2.3-.3.3-.2 0-.3-.1-.3-.3 0 .2.1.4.3.4s.4-.2.4-.4-.2-.4-.4-.4-.3.3-.3.4"
        />{' '}
        <defs>
          {' '}
          <path
            id="S"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="T">
          {' '}
          <use xlinkHref="#S" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#T)"
          fill="#FFECDA"
          d="M-782.2 785.7c0-.2.2-.4.4-.4s.4.2.4.4-.2.4-.4.4c-.3 0-.4-.2-.4-.4 0 .2.2.4.4.4s.3-.2.3-.4-.2-.4-.4-.4-.4.3-.3.4"
        />{' '}
        <defs>
          {' '}
          <path
            id="U"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="V">
          {' '}
          <use xlinkHref="#U" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#V)"
          fill="#FFECDA"
          d="M-782.3 785.7c0-.2.2-.4.4-.4s.4.2.4.4-.2.4-.4.4-.4-.2-.4-.4c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4-.4.3-.4.4"
        />{' '}
        <defs>
          {' '}
          <path
            id="W"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="X">
          {' '}
          <use xlinkHref="#W" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#X)"
          fill="#FFECDA"
          d="M-782.3 785.7c0-.2.2-.4.4-.4s.4.2.4.4-.2.4-.4.4-.4-.2-.4-.4c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4-.4.3-.4.4"
        />{' '}
        <defs>
          {' '}
          <path
            id="Y"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="Z">
          {' '}
          <use xlinkHref="#Y" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#Z)"
          fill="#FFEBD9"
          d="M-782.3 785.7c0-.2.2-.4.4-.4s.4.2.4.4-.2.4-.4.4-.4-.2-.4-.4c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4-.4.3-.4.4"
        />{' '}
        <defs>
          {' '}
          <path
            id="aa"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="ab">
          <use xlinkHref="#aa" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#ab)"
          fill="#FFEBD9"
          d="M-782.3 785.7c0-.2.2-.4.4-.4s.4.2.4.4-.2.4-.4.4-.4-.2-.4-.4c0 .2.2.4.4.4s.5-.2.5-.4-.2-.4-.5-.4-.4.3-.4.4"
        />{' '}
        <defs>
          {' '}
          <path
            id="ac"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="ad">
          {' '}
          <use xlinkHref="#ac" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#ad)"
          fill="#FFEBD9"
          d="M-782.3 785.7c0-.2.2-.4.4-.4s.5.2.5.4-.2.4-.4.4-.5-.2-.5-.4c0 .3.2.5.4.5s.5-.2.5-.5-.2-.5-.5-.5-.4.3-.4.5"
        />{' '}
        <defs>
          {' '}
          <path
            id="ae"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="af">
          {' '}
          <use xlinkHref="#ae" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#af)"
          fill="#FFEAD7"
          d="M-782.3 785.7c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5c0 .3.2.5.5.5s.4-.2.4-.5-.2-.5-.5-.5-.5.3-.4.5"
        />{' '}
        <defs>
          {' '}
          <path
            id="ag"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="ah">
          {' '}
          <use xlinkHref="#ag" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#ah)"
          fill="#FFEAD7"
          d="M-782.3 785.7c0-.3.2-.5.5-.5s.4.2.4.5-.2.5-.5.5-.4-.2-.4-.5c0 .3.2.5.5.5s.4-.2.4-.5-.2-.5-.5-.5-.5.3-.4.5"
        />{' '}
        <defs>
          {' '}
          <path
            id="ai"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aj">
          {' '}
          <use xlinkHref="#ai" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aj)"
          fill="#FFEAD7"
          d="M-782.4 785.7c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.3-.5.5"
        />{' '}
        <defs>
          {' '}
          <path
            id="ak"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="al">
          {' '}
          <use xlinkHref="#ak" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#al)"
          fill="#FFE9D6"
          d="M-782.4 785.7c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.3-.5.5"
        />{' '}
        <defs>
          {' '}
          <path
            id="am"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="an">
          {' '}
          <use xlinkHref="#am" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#an)"
          fill="#FFE9D6"
          d="M-782.4 785.7c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5-.5-.2-.5-.5c0 .3.2.5.5.5s.6-.2.6-.5-.3-.5-.6-.5-.5.3-.5.5"
        />{' '}
        <defs>
          {' '}
          <path
            id="ao"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="ap">
          {' '}
          <use xlinkHref="#ao" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#ap)"
          fill="#FFE9D6"
          d="M-782.4 785.7c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5c-.3.1-.5-.2-.5-.5 0 .3.2.6.6.6.3 0 .6-.3.6-.6s-.3-.6-.6-.6c-.4.1-.7.4-.6.6"
        />{' '}
        <defs>
          {' '}
          <path
            id="aq"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="ar">
          {' '}
          <use xlinkHref="#aq" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#ar)"
          fill="#FFE8D5"
          d="M-782.5 785.7c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.3-.6-.6c0 .3.2.6.6.6.3 0 .6-.3.6-.6s-.3-.6-.6-.6-.6.4-.6.6"
        />{' '}
        <defs>
          {' '}
          <path
            id="as"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="at">
          {' '}
          <use xlinkHref="#as" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#at)"
          fill="#FFE8D4"
          d="M-782.5 785.7c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.3-.6-.6c0 .3.3.6.6.6s.6-.3.6-.6-.3-.6-.6-.6-.6.4-.6.6"
        />{' '}
        <defs>
          {' '}
          <path
            id="au"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="av">
          {' '}
          <use xlinkHref="#au" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#av)"
          fill="#FFE8D4"
          d="M-782.5 785.7c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.3-.6-.6c0 .3.2.6.6.6.4 0 .6-.3.6-.6s-.3-.6-.6-.6-.6.4-.6.6"
        />{' '}
        <defs>
          {' '}
          <path
            id="aw"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="ax">
          <use xlinkHref="#aw" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#ax)"
          fill="#FFE7D3"
          d="M-782.5 785.7c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.3-.6-.6c0 .4.3.6.6.6s.7-.2.7-.6-.3-.6-.7-.6c-.4.1-.6.4-.6.6"
        />{' '}
        <defs>
          {' '}
          <path
            id="ay"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="az">
          <use xlinkHref="#ay" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#az)"
          fill="#FFE7D3"
          d="M-782.5 785.7c0-.4.3-.6.6-.6s.7.3.7.6c0 .4-.3.6-.6.6s-.7-.2-.7-.6c0 .4.3.7.6.7s.7-.3.7-.7-.3-.7-.7-.7c-.4.1-.6.4-.6.7"
        />{' '}
        <defs>
          {' '}
          <path
            id="aA"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aB">
          {' '}
          <use xlinkHref="#aA" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aB)"
          fill="#FFE7D2"
          d="M-782.5 785.7c0-.4.3-.7.7-.7.4 0 .7.3.7.7s-.3.7-.7.7a.684.684 0 0 0 0 0c.4 0 .6-.3.6-.7s-.3-.7-.7-.7c-.4.1-.7.4-.6.7"
        />{' '}
        <defs>
          {' '}
          <path
            id="aC"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aD">
          <use xlinkHref="#aC" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aD)"
          fill="#FFE7D2"
          d="M-782.6 785.7c0-.4.3-.7.7-.7.4 0 .7.3.7.7s-.3.7-.7.7a.684.684 0 0 0 0 0c.4 0 .7-.3.7-.7s-.3-.7-.7-.7c-.4.1-.7.4-.7.7"
        />{' '}
        <defs>
          {' '}
          <path
            id="aE"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aF">
          {' '}
          <use xlinkHref="#aE" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aF)"
          fill="#FFE7D1"
          d="M-782.6 785.7c0-.4.3-.7.7-.7.4 0 .7.3.7.7s-.3.7-.7.7a.684.684 0 0 0 0 0c.4 0 .7-.3.7-.7s-.3-.7-.7-.7c-.4.1-.7.4-.7.7"
        />{' '}
        <defs>
          {' '}
          <path
            id="aG"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aH">
          {' '}
          <use xlinkHref="#aG" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aH)"
          fill="#FFE6D0"
          d="M-782.6 785.7c0-.4.3-.7.7-.7.4 0 .7.3.7.7s-.3.7-.7.7a.684.684 0 0 0 0 0c.4 0 .7-.3.7-.7s-.3-.7-.7-.7c-.4.1-.7.4-.7.7"
        />{' '}
        <defs>
          {' '}
          <path
            id="aI"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aJ">
          {' '}
          <use xlinkHref="#aI" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aJ)"
          fill="#FEE6D0"
          d="M-782.6 785.7c0-.4.3-.7.7-.7.4 0 .7.3.7.7s-.3.7-.7.7a.684.684 0 0 0 0 0c.4 0 .8-.3.8-.7s-.4-.7-.8-.7c-.4.1-.7.4-.7.7"
        />{' '}
        <defs>
          {' '}
          <path
            id="aK"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aL">
          {' '}
          <use xlinkHref="#aK" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aL)"
          fill="#FFE6CF"
          d="M-782.6 785.7c0-.4.3-.7.7-.7.4 0 .7.3.7.7s-.3.7-.7.7c-.4 0-.7-.3-.7-.7 0 .4.3.8.7.8.4 0 .8-.4.8-.8s-.3-.8-.8-.8-.7.5-.7.8"
        />{' '}
        <defs>
          {' '}
          <path
            id="aM"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aN">
          {' '}
          <use xlinkHref="#aM" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aN)"
          fill="#FEE5CE"
          d="M-782.6 785.7c0-.4.3-.8.8-.8s.7.4.7.8-.3.8-.8.8-.7-.4-.7-.8c0 .4.3.8.7.8.4 0 .8-.4.8-.8s-.3-.8-.8-.8-.8.5-.7.8"
        />{' '}
        <defs>
          {' '}
          <path
            id="aO"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aP">
          {' '}
          <use xlinkHref="#aO" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aP)"
          fill="#FEE5CE"
          d="M-782.7 785.7c0-.4.3-.8.8-.8.4 0 .8.3.8.8 0 .4-.3.8-.8.8-.4 0-.8-.4-.8-.8 0 .4.3.8.8.8s.8-.4.8-.8-.4-.8-.8-.8c-.4.1-.8.5-.8.8"
        />{' '}
        <defs>
          {' '}
          <path
            id="aQ"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aR">
          {' '}
          <use xlinkHref="#aQ" overflow="visible" />{' '}
        </clipPath>{' '}
        <path
          clipPath="url(#aR)"
          fill="#FEE5CD"
          d="M-782.7 785.7c0-.4.4-.8.8-.8s.8.4.8.8-.3.8-.8.8-.8-.4-.8-.8c0 .4.3.8.8.8.4 0 .8-.4.8-.8s-.4-.8-.8-.8c-.4.1-.8.5-.8.8"
        />{' '}
        <defs>
          {' '}
          <path
            id="aS"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />{' '}
        </defs>{' '}
        <clipPath id="aT">
          <use xlinkHref="#aS" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#aT)"
          fill="#FEE4CC"
          d="M-782.7 785.7c0-.4.4-.8.8-.8s.8.4.8.8-.4.8-.8.8-.8-.3-.8-.8c0 .5.4.8.8.8s.8-.3.8-.8-.4-.8-.8-.8c-.4.1-.8.4-.8.8"
        />
        <defs>
          <path
            id="aU"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="aV">
          <use xlinkHref="#aU" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#aV)"
          fill="#FEE4CC"
          d="M-782.7 785.7c0-.5.4-.8.8-.8.5 0 .8.4.8.8 0 .5-.4.8-.8.8s-.8-.3-.8-.8c0 .5.4.8.8.8s.9-.3.9-.8-.4-.8-.8-.8c-.4.1-.9.4-.9.8"
        />
        <defs>
          <path
            id="aW"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="aX">
          <use xlinkHref="#aW" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#aX)"
          fill="#FEE3CB"
          d="M-782.7 785.7c0-.5.4-.8.8-.8.5 0 .9.3.9.8s-.4.9-.9.9-.8-.4-.8-.9c0 .5.4.9.9.9.4 0 .8-.4.8-.9s-.4-.9-.9-.9-.9.5-.8.9"
        />
        <defs>
          <path
            id="aY"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="aZ">
          <use xlinkHref="#aY" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#aZ)"
          fill="#FEE3CB"
          d="M-782.8 785.7c0-.5.4-.9.9-.9s.9.4.9.9-.4.9-.9.9-.9-.4-.9-.9c0 .5.4.9.9.9s.9-.4.9-.9-.4-.9-.9-.9-.9.5-.9.9"
        />
        <defs>
          <path
            id="ba"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bb">
          <use xlinkHref="#ba" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bb)"
          fill="#FEE3CB"
          d="M-782.8 785.7c0-.5.4-.9.9-.9s.9.4.9.9-.4.9-.9.9-.9-.4-.9-.9c0 .5.4.9.9.9s.9-.4.9-.9-.4-.9-.9-.9-.9.5-.9.9"
        />
        <defs>
          <path
            id="bc"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bd">
          <use xlinkHref="#bc" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bd)"
          fill="#FEE2CA"
          d="M-782.8 785.7c0-.5.4-.9.9-.9s.9.4.9.9-.4.9-.9.9-.9-.4-.9-.9c0 .5.4.9.9.9s.9-.4.9-.9-.4-.9-.9-.9-.9.5-.9.9"
        />
        <defs>
          <path
            id="be"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bf">
          <use xlinkHref="#be" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bf)"
          fill="#FEE2C9"
          d="M-782.8 785.7c0-.5.4-.9.9-.9s.9.4.9.9-.4.9-.9.9-.9-.4-.9-.9c0 .5.4.9.9.9s.9-.4.9-.9-.4-.9-.9-.9-.9.5-.9.9"
        />
        <defs>
          <path
            id="bg"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bh">
          <use xlinkHref="#bg" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bh)"
          fill="#FEE2C8"
          d="M-782.8 785.7c0-.5.4-.9.9-.9s.9.4.9.9-.4.9-.9.9-.9-.4-.9-.9c0 .5.4 1 .9 1s1-.4 1-1c0-.5-.4-1-1-1-.6.1-.9.6-.9 1"
        />
        <defs>
          <path
            id="bi"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bj">
          <use xlinkHref="#bi" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bj)"
          fill="#FEE1C8"
          d="M-782.8 785.7c0-.5.4-1 1-1s1 .4 1 1c0 .5-.4 1-1 1s-1-.5-1-1c0 .5.4 1 .9 1s1-.4 1-1-.4-1-1-1c-.6.1-1 .6-.9 1"
        />
        <defs>
          <path
            id="bk"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bl">
          <use xlinkHref="#bk" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bl)"
          fill="#FEE1C7"
          d="M-782.9 785.7c0-.5.4-1 1-1 .5 0 1 .4 1 1 0 .5-.4 1-1 1-.5 0-1-.5-1-1 0 .5.4 1 1 1s1-.4 1-1c0-.5-.4-1-1-1-.6.1-1 .6-1 1"
        />
        <defs>
          <path
            id="bm"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bn">
          <use xlinkHref="#bm" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bn)"
          fill="#FEE1C7"
          d="M-782.9 785.7c0-.5.4-1 1-1 .5 0 1 .4 1 1 0 .5-.4 1-1 1-.5 0-1-.5-1-1 0 .6.4 1 1 1s1-.5 1-1c0-.6-.5-1-1-1s-1 .5-1 1"
        />
        <defs>
          <path
            id="bo"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bp">
          <use xlinkHref="#bo" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bp)"
          fill="#FEE1C6"
          d="M-782.9 785.7c0-.6.5-1 1-1 .6 0 1 .5 1 1s-.5 1-1 1-1-.4-1-1c0 .6.4 1 1 1s1-.5 1-1c0-.6-.5-1-1-1s-1 .5-1 1"
        />
        <defs>
          <path
            id="bq"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="br">
          <use xlinkHref="#bq" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#br)"
          fill="#FEE0C5"
          d="M-782.9 785.7c0-.6.5-1 1-1s1 .5 1 1c0 .6-.5 1-1 1s-1-.4-1-1c0 .6.5 1 1 1s1-.5 1-1c0-.6-.5-1-1-1s-1 .5-1 1"
        />
        <defs>
          <path
            id="bs"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bt">
          <use xlinkHref="#bs" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bt)"
          fill="#FEE0C4"
          d="M-782.9 785.7c0-.6.5-1 1-1 .6 0 1 .5 1 1 0 .6-.5 1-1 1-.6 0-1-.4-1-1 0 .6.5 1.1 1 1.1s1.1-.5 1.1-1.1-.5-1.1-1.1-1.1c-.6.1-1 .6-1 1.1"
        />
        <defs>
          <path
            id="bu"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bv">
          <use xlinkHref="#bu" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bv)"
          fill="#FEDFC4"
          d="M-782.9 785.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1c-.7 0-1.1-.5-1.1-1.1 0 .6.5 1.1 1.1 1.1.6 0 1-.5 1-1.1s-.5-1.1-1.1-1.1c-.6.1-1.1.6-1 1.1"
        />
        <defs>
          <path
            id="bw"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bx">
          <use xlinkHref="#bw" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bx)"
          fill="#FEDFC3"
          d="M-782.9 785.7c0-.6.5-1.1 1.1-1.1.6 0 1 .5 1 1.1s-.5 1.1-1.1 1.1c-.6 0-1-.5-1-1.1 0 .6.5 1.1 1.1 1.1.6 0 1-.5 1-1.1s-.5-1.1-1.1-1.1c-.6.1-1.1.6-1 1.1"
        />
        <defs>
          <path
            id="by"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bz">
          <use xlinkHref="#by" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bz)"
          fill="#FEDFC3"
          d="M-783 785.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1a1.11 1.11 0 0 0 0 0c.6 0 1.1-.5 1.1-1.1s-.5-1.1-1.1-1.1c-.6.1-1.1.6-1.1 1.1"
        />
        <defs>
          <path
            id="bA"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bB">
          <use xlinkHref="#bA" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bB)"
          fill="#FEDEC2"
          d="M-783 785.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1a1.11 1.11 0 0 0 0 0c.6 0 1.1-.5 1.1-1.1s-.5-1.1-1.1-1.1c-.6.1-1.1.6-1.1 1.1"
        />
        <defs>
          <path
            id="bC"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bD">
          <use xlinkHref="#bC" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bD)"
          fill="#FEDEC1"
          d="M-783 785.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1a1.11 1.11 0 0 0 0 0c.6 0 1.2-.5 1.2-1.1s-.5-1.1-1.1-1.1c-.6.1-1.2.6-1.2 1.1"
        />
        <defs>
          <path
            id="bE"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bF">
          <use xlinkHref="#bE" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bF)"
          fill="#FEDDC0"
          d="M-783 785.7c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1c-.6.1-1.1-.5-1.1-1.1 0 .6.5 1.2 1.2 1.2.6 0 1.2-.5 1.2-1.2s-.6-1.2-1.3-1.2c-.6.1-1.2.7-1.1 1.2"
        />
        <defs>
          <path
            id="bG"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bH">
          <use xlinkHref="#bG" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bH)"
          fill="#FEDDC0"
          d="M-783.1 785.7c0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2 0 .6-.5 1.2-1.2 1.2-.6 0-1.2-.6-1.2-1.2 0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2-.5-1.2-1.2-1.2c-.6.1-1.2.6-1.2 1.2"
        />
        <defs>
          <path
            id="bI"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bJ">
          <use xlinkHref="#bI" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bJ)"
          fill="#FEDDBF"
          d="M-783.1 785.7c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2c-.6 0-1.2-.5-1.2-1.2 0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2-.5-1.2-1.2-1.2c-.7.1-1.2.6-1.2 1.2"
        />
        <defs>
          <path
            id="bK"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bL">
          <use xlinkHref="#bK" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bL)"
          fill="#FEDDBF"
          d="M-783.1 785.7c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2-.5-1.2-1.2-1.2c-.7.1-1.2.6-1.2 1.2"
        />
        <defs>
          <path
            id="bM"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bN">
          <use xlinkHref="#bM" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bN)"
          fill="#FEDCBE"
          d="M-783.1 785.7c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2c0 .7.5 1.2 1.2 1.2s1.2-.6 1.2-1.2c0-.7-.6-1.2-1.2-1.2-.7.1-1.2.6-1.2 1.2"
        />
        <defs>
          <path
            id="bO"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bP">
          <use xlinkHref="#bO" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bP)"
          fill="#FEDCBD"
          d="M-783.1 785.7c0-.7.6-1.2 1.2-1.2.7 0 1.2.6 1.2 1.2 0 .7-.6 1.2-1.2 1.2-.7 0-1.2-.5-1.2-1.2 0 .7.5 1.3 1.2 1.3s1.3-.6 1.3-1.3-.6-1.3-1.3-1.3c-.7.1-1.2.7-1.2 1.3"
        />
        <defs>
          <path
            id="bQ"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bR">
          <use xlinkHref="#bQ" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bR)"
          fill="#FEDCBD"
          d="M-783.1 785.7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3c-.8 0-1.3-.6-1.3-1.3 0 .7.6 1.3 1.3 1.3.7 0 1.2-.6 1.2-1.3s-.6-1.3-1.3-1.3c-.7.1-1.3.7-1.2 1.3"
        />
        <defs>
          <path
            id="bS"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bT">
          <use xlinkHref="#bS" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bT)"
          fill="#FEDBBC"
          d="M-783.2 785.7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3a1.324 1.324 0 0 0 0 0c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3c-.7.1-1.3.7-1.3 1.3"
        />
        <defs>
          <path
            id="bU"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bV">
          <use xlinkHref="#bU" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bV)"
          fill="#FEDBBB"
          d="M-783.2 785.7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3a1.324 1.324 0 0 0 0 0c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3c-.7.1-1.3.7-1.3 1.3"
        />
        <defs>
          <path
            id="bW"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bX">
          <use xlinkHref="#bW" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bX)"
          fill="#FEDABA"
          d="M-783.2 785.7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3a1.324 1.324 0 0 0 0 0c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3c-.7.1-1.3.7-1.3 1.3"
        />
        <defs>
          <path
            id="bY"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="bZ">
          <use xlinkHref="#bY" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#bZ)"
          fill="#FEDABA"
          d="M-783.2 785.7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3a1.324 1.324 0 0 0 0 0c.7 0 1.4-.6 1.4-1.3s-.6-1.3-1.3-1.3c-.7.1-1.4.7-1.4 1.3"
        />
        <defs>
          <path
            id="ca"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cb">
          <use xlinkHref="#ca" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cb)"
          fill="#FED9B9"
          d="M-783.2 785.7c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3c-.7 0-1.3-.6-1.3-1.3 0 .8.6 1.4 1.3 1.4.7 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4-1.3.8-1.3 1.4"
        />
        <defs>
          <path
            id="cc"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cd">
          <use xlinkHref="#cc" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cd)"
          fill="#FED9B8"
          d="M-783.2 785.7c0-.8.6-1.4 1.4-1.4s1.3.7 1.3 1.4c0 .8-.6 1.4-1.4 1.4s-1.3-.6-1.3-1.4c0 .8.6 1.4 1.3 1.4.7 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4-1.4.7-1.3 1.4"
        />
        <defs>
          <path
            id="ce"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cf">
          <use xlinkHref="#ce" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cf)"
          fill="#FED8B7"
          d="M-783.3 785.7c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4-.6 1.4-1.4 1.4c-.7 0-1.4-.6-1.4-1.4 0 .8.6 1.4 1.4 1.4s1.4-.6 1.4-1.4-.6-1.4-1.4-1.4-1.4.7-1.4 1.4"
        />
        <defs>
          <path
            id="cg"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="ch">
          <use xlinkHref="#cg" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#ch)"
          fill="#FED8B7"
          d="M-783.3 785.7c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4c0 .8.6 1.4 1.4 1.4s1.4-.6 1.4-1.4-.6-1.4-1.4-1.4-1.4.7-1.4 1.4"
        />
        <defs>
          <path
            id="ci"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cj">
          <use xlinkHref="#ci" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cj)"
          fill="#FED8B6"
          d="M-783.3 785.7c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4c0 .8.6 1.4 1.4 1.4s1.4-.6 1.4-1.4-.6-1.4-1.4-1.4-1.4.7-1.4 1.4"
        />
        <defs>
          <path
            id="ck"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cl">
          <use xlinkHref="#ck" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cl)"
          fill="#FED8B6"
          d="M-783.3 785.7c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4c0 .8.6 1.4 1.4 1.4s1.5-.6 1.5-1.4-.6-1.4-1.4-1.4-1.5.7-1.5 1.4"
        />
        <defs>
          <path
            id="cm"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cn">
          <use xlinkHref="#cm" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cn)"
          fill="#FED7B5"
          d="M-783.3 785.7c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4-.6 1.5-1.4 1.5-1.4-.7-1.4-1.5c0 .8.6 1.5 1.5 1.5s1.4-.7 1.4-1.5-.7-1.5-1.5-1.5-1.5.8-1.4 1.5"
        />
        <defs>
          <path
            id="co"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cp">
          <use xlinkHref="#co" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cp)"
          fill="#FED7B4"
          d="M-783.4 785.7c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.8-1.5 1.5"
        />
        <defs>
          <path
            id="cq"
            d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
          />
        </defs>
        <clipPath id="cr">
          <use xlinkHref="#cq" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#cr)"
          fill="#FED6B3"
          d="M-783.4 785.7c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.8-1.5 1.5"
        />
        <g>
          <defs>
            <path
              id="cs"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ct">
            <use xlinkHref="#cs" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ct)"
            fill="#FED6B2"
            d="M-783.4 785.7c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.8-1.5 1.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="cu"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cv">
            <use xlinkHref="#cu" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cv)"
            fill="#FED5B2"
            d="M-783.4 785.7c0-.8.7-1.5 1.5-1.5s1.6.7 1.6 1.5-.7 1.5-1.5 1.5-1.6-.7-1.6-1.5c0 .8.7 1.5 1.5 1.5.9 0 1.6-.6 1.6-1.5 0-.8-.7-1.5-1.5-1.5-.9.1-1.6.8-1.6 1.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="cw"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cx">
            <use xlinkHref="#cw" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cx)"
            fill="#FED5B1"
            d="M-783.4 785.7c0-.8.7-1.5 1.5-1.5.9 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5c-.8 0-1.5-.6-1.5-1.5 0 .9.7 1.6 1.5 1.6s1.6-.7 1.6-1.6-.7-1.6-1.6-1.6c-.9.1-1.5.8-1.5 1.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="cy"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cz">
            <use xlinkHref="#cy" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cz)"
            fill="#FED4B0"
            d="M-783.4 785.7c0-.9.7-1.6 1.6-1.6.9 0 1.5.7 1.5 1.6s-.7 1.6-1.6 1.6c-.9 0-1.5-.7-1.5-1.6 0 .9.7 1.6 1.5 1.6s1.6-.7 1.6-1.6-.7-1.6-1.6-1.6c-.9.1-1.6.8-1.5 1.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="cA"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cB">
            <use xlinkHref="#cA" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cB)"
            fill="#FED4B0"
            d="M-783.5 785.7c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6a1.58 1.58 0 0 0 0 0c.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6c-.9.1-1.6.8-1.6 1.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="cC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cD">
            <use xlinkHref="#cC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cD)"
            fill="#FED4AF"
            d="M-783.5 785.7c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6a1.58 1.58 0 0 0 0 0c.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6c-.9.1-1.6.8-1.6 1.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="cE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cF">
            <use xlinkHref="#cE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cF)"
            fill="#FED3AE"
            d="M-783.5 785.7c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6a1.58 1.58 0 0 0 0 0c.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6c-.9.1-1.6.8-1.6 1.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="cG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cH">
            <use xlinkHref="#cG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cH)"
            fill="#FED3AE"
            d="M-783.5 785.7c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6a1.58 1.58 0 0 0 0 0c.9 0 1.7-.7 1.7-1.6s-.7-1.6-1.6-1.6c-.9.1-1.7.8-1.7 1.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="cI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cJ">
            <use xlinkHref="#cI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cJ)"
            fill="#FED3AD"
            d="M-783.5 785.7c0-.9.7-1.6 1.6-1.6.9 0 1.7.7 1.7 1.6s-.8 1.6-1.7 1.6c-.9 0-1.6-.7-1.6-1.6 0 .9.7 1.7 1.6 1.7.9 0 1.7-.8 1.7-1.7s-.7-1.7-1.7-1.7-1.6.9-1.6 1.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="cK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cL">
            <use xlinkHref="#cK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cL)"
            fill="#FDD2AC"
            d="M-783.5 785.7c0-.9.7-1.7 1.7-1.7.8 0 1.6.8 1.6 1.7s-.7 1.7-1.7 1.7c-.9 0-1.6-.8-1.6-1.7 0 .9.7 1.7 1.7 1.7s1.6-.8 1.6-1.7-.8-1.7-1.7-1.7c-.9.1-1.7.9-1.6 1.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="cM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cN">
            <use xlinkHref="#cM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cN)"
            fill="#FDD2AB"
            d="M-783.5 785.7c0-.9.8-1.7 1.7-1.7.9 0 1.6.8 1.6 1.7s-.8 1.7-1.7 1.7c-.9 0-1.6-.8-1.6-1.7 0 .9.7 1.7 1.7 1.7s1.6-.8 1.6-1.7-.8-1.7-1.7-1.7c-.9.1-1.7.9-1.6 1.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="cO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cP">
            <use xlinkHref="#cO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cP)"
            fill="#FDD1AA"
            d="M-783.6 785.7c0-.9.8-1.7 1.7-1.7.9 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7c-.9 0-1.7-.8-1.7-1.7 0 .9.8 1.7 1.7 1.7.9 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7c-.9.1-1.7.9-1.7 1.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="cQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cR">
            <use xlinkHref="#cQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cR)"
            fill="#FDD1A9"
            d="M-783.6 785.7c0-.9.8-1.7 1.7-1.7.9 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7c-.9 0-1.7-.8-1.7-1.7 0 1 .7 1.7 1.7 1.7s1.7-.7 1.7-1.7-.8-1.7-1.7-1.7c-.9.1-1.7.8-1.7 1.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="cS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cT">
            <use xlinkHref="#cS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cT)"
            fill="#FDD0A8"
            d="M-783.6 785.7c0-1 .8-1.7 1.7-1.7 1 0 1.7.8 1.7 1.7s-.7 1.7-1.7 1.7a1.685 1.685 0 0 0 0 0c.9 0 1.8-.7 1.8-1.7s-.8-1.7-1.7-1.7c-.9.1-1.8.8-1.8 1.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="cU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cV">
            <use xlinkHref="#cU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cV)"
            fill="#FDD0A8"
            d="M-783.6 785.7c0-1 .8-1.7 1.7-1.7.9 0 1.8.7 1.8 1.7s-.8 1.8-1.8 1.8-1.7-.8-1.7-1.8c0 1 .8 1.8 1.8 1.8s1.7-.8 1.7-1.8-.8-1.8-1.8-1.8-1.8.9-1.7 1.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="cW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cX">
            <use xlinkHref="#cW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cX)"
            fill="#FDCFA7"
            d="M-783.7 785.7c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.9-1.8 1.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="cY"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="cZ">
            <use xlinkHref="#cY" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#cZ)"
            fill="#FDCFA7"
            d="M-783.7 785.7c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.9-1.8 1.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="da"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="db">
            <use xlinkHref="#da" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#db)"
            fill="#FDCFA6"
            d="M-783.7 785.7c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.9-1.8 1.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="dc"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dd">
            <use xlinkHref="#dc" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dd)"
            fill="#FDCFA5"
            d="M-783.7 785.7c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8c0 1 .8 1.8 1.8 1.8s1.9-.8 1.9-1.8-.8-1.8-1.8-1.8c-1.1.1-1.9.9-1.9 1.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="de"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="df">
            <use xlinkHref="#de" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#df)"
            fill="#FDCEA4"
            d="M-783.7 785.7c0-1 .8-1.8 1.8-1.8s1.9.8 1.9 1.8-.9 1.8-1.9 1.8-1.8-.8-1.8-1.8c0 1 .8 1.9 1.8 1.9s1.9-.9 1.9-1.9-.8-1.9-1.9-1.9-1.8 1-1.8 1.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="dg"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dh">
            <use xlinkHref="#dg" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dh)"
            fill="#FDCEA3"
            d="M-783.7 785.7c0-1 .8-1.9 1.9-1.9.9 0 1.8.9 1.8 1.9s-.9 1.9-1.9 1.9-1.8-.9-1.8-1.9c0 1 .8 1.9 1.9 1.9 1.1 0 1.8-.9 1.8-1.9s-.8-1.9-1.9-1.9-1.9 1-1.8 1.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="di"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dj">
            <use xlinkHref="#di" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dj)"
            fill="#FDCDA2"
            d="M-783.7 785.7c0-1 .8-1.9 1.9-1.9 1.1 0 1.8.9 1.8 1.9s-.8 1.9-1.9 1.9-1.8-.9-1.8-1.9c0 1 .8 1.9 1.9 1.9 1 0 1.8-.9 1.8-1.9s-.8-1.9-1.9-1.9c-1 .1-1.9 1-1.8 1.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="dk"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dl">
            <use xlinkHref="#dk" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dl)"
            fill="#FDCCA1"
            d="M-783.8 785.7c0-1 .8-1.9 1.9-1.9s1.9.9 1.9 1.9-.8 1.9-1.9 1.9c-1 0-1.9-.9-1.9-1.9 0 1.1.8 1.9 1.9 1.9s1.9-.8 1.9-1.9-.8-1.9-1.9-1.9c-1 .1-1.9 1-1.9 1.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="dm"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dn">
            <use xlinkHref="#dm" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dn)"
            fill="#FDCCA1"
            d="M-783.8 785.7c0-1.1.9-1.9 1.9-1.9 1.1 0 1.9.9 1.9 1.9 0 1.1-.9 1.9-1.9 1.9s-1.9-.8-1.9-1.9c0 1.1.8 1.9 1.9 1.9s1.9-.8 1.9-1.9-.8-1.9-1.9-1.9-1.9.9-1.9 1.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="do"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dp">
            <use xlinkHref="#do" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dp)"
            fill="#FDCBA0"
            d="M-783.8 785.7c0-1.1.9-1.9 1.9-1.9 1.1 0 1.9.8 1.9 1.9s-.9 1.9-1.9 1.9c-1.1 0-1.9-.8-1.9-1.9 0 1.1.9 1.9 1.9 1.9s2-.8 2-1.9-.9-1.9-2-1.9-1.9.9-1.9 1.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="dq"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dr">
            <use xlinkHref="#dq" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dr)"
            fill="#FDCBA0"
            d="M-783.8 785.7c0-1.1.9-1.9 1.9-1.9 1.1 0 1.9.9 1.9 1.9s-.8 1.9-1.9 1.9-1.9-.8-1.9-1.9c0 1.1.9 2 1.9 2 1.1 0 2-.9 2-2s-.9-2-2-2-1.9 1-1.9 2"
          />
        </g>
        <g>
          <defs>
            <path
              id="ds"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dt">
            <use xlinkHref="#ds" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dt)"
            fill="#FDCB9F"
            d="M-783.8 785.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2c-1.2 0-2-.9-2-2 0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2c-1.1.1-2.1 1-2 2"
          />
        </g>
        <g>
          <defs>
            <path
              id="du"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dv">
            <use xlinkHref="#du" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dv)"
            fill="#FDCA9E"
            d="M-783.9 785.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 1-2 2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dw"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dx">
            <use xlinkHref="#dw" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dx)"
            fill="#FDCA9D"
            d="M-783.9 785.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 1-2 2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dy"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dz">
            <use xlinkHref="#dy" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dz)"
            fill="#FDCA9C"
            d="M-783.9 785.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 1-2 2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dA"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dB">
            <use xlinkHref="#dA" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dB)"
            fill="#FDC99B"
            d="M-783.9 785.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 1-2 2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dD">
            <use xlinkHref="#dC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dD)"
            fill="#FDC99B"
            d="M-783.9 785.7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2c-.1 1.1.9 2.1 2 2.1s2.1-1 2.1-2.1-.9-2.1-2.1-2.1c-1.2.1-2.1 1.1-2 2.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="dE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dF">
            <use xlinkHref="#dE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dF)"
            fill="#FDC89A"
            d="M-784 785.7c0-1.1 1-2.1 2.1-2.1s2.1.9 2.1 2.1-.9 2.1-2.1 2.1c-1.1 0-2.1-1-2.1-2.1 0 1.2 1 2.1 2.1 2.1s2.1-.9 2.1-2.1-.9-2.1-2.1-2.1c-1.2.1-2.1 1.1-2.1 2.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="dG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dH">
            <use xlinkHref="#dG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dH)"
            fill="#FDC799"
            d="M-784 785.7c0-1.1 1-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1c-1.1 0-2.1-.9-2.1-2.1 0 1.2 1 2.1 2.1 2.1s2.1-.9 2.1-2.1-.9-2.1-2.1-2.1c-1.2.1-2.1 1-2.1 2.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="dI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dJ">
            <use xlinkHref="#dI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dJ)"
            fill="#FDC799"
            d="M-784 785.7c0-1.2 1-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1c-1.1 0-2.1-.9-2.1-2.1 0 1.2.9 2.1 2.1 2.1 1.2 0 2.1-.9 2.1-2.1s-.9-2.1-2.1-2.1c-1.2.1-2.1 1-2.1 2.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="dK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dL">
            <use xlinkHref="#dK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dL)"
            fill="#FDC698"
            d="M-784 785.7c0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1c-1.2 0-2.1-.9-2.1-2.1 0 1.2.9 2.1 2.1 2.1 1.2 0 2.1-1 2.1-2.1 0-1.2-1-2.1-2.1-2.1s-2.1 1-2.1 2.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="dM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dN">
            <use xlinkHref="#dM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dN)"
            fill="#FDC697"
            d="M-784 785.7c0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-.9-2.1-2.1 0 1.2 1 2.2 2.1 2.2s2.2-1 2.2-2.2-1-2.2-2.2-2.2c-1.2.1-2.1 1.1-2.1 2.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dP">
            <use xlinkHref="#dO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dP)"
            fill="#FCC696"
            d="M-784 785.7c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2 0 1.2.9 2.2 2.1 2.2 1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2c-1.2.1-2.2 1.1-2.1 2.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dR">
            <use xlinkHref="#dQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dR)"
            fill="#FCC696"
            d="M-784.1 785.7c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2a2.22 2.22 0 0 0 0 0c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2c-1.2.1-2.2 1.1-2.2 2.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dT">
            <use xlinkHref="#dS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dT)"
            fill="#FCC595"
            d="M-784.1 785.7c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2a2.22 2.22 0 0 0 0 0c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2c-1.2.1-2.2 1.1-2.2 2.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dV">
            <use xlinkHref="#dU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dV)"
            fill="#FCC594"
            d="M-784.1 785.7c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2a2.22 2.22 0 0 0 0 0c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2c-1.2.1-2.2 1.1-2.2 2.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dX">
            <use xlinkHref="#dW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dX)"
            fill="#FCC493"
            d="M-784.1 785.7c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2a2.22 2.22 0 0 0 0 0c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2c-1.2.1-2.2 1.1-2.2 2.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="dY"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="dZ">
            <use xlinkHref="#dY" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#dZ)"
            fill="#FCC392"
            d="M-784.1 785.7c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2 0 1.2 1 2.3 2.2 2.3 1.2 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3-1.2.1-2.2 1.2-2.2 2.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="ea"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eb">
            <use xlinkHref="#ea" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eb)"
            fill="#FCC391"
            d="M-784.2 785.7c0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3c-1.2 0-2.3-1-2.3-2.3 0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3-1-2.3-2.3-2.3-2.3 1.1-2.3 2.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="ec"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ed">
            <use xlinkHref="#ec" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ed)"
            fill="#FCC290"
            d="M-784.2 785.7c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3-1 2.3-2.3 2.3c-1.2 0-2.3-1-2.3-2.3 0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3-1-2.3-2.3-2.3-2.3 1.1-2.3 2.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="ee"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ef">
            <use xlinkHref="#ee" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ef)"
            fill="#FCC290"
            d="M-784.2 785.7c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3-1 2.3-2.3 2.3-2.3-1-2.3-2.3c0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3-1-2.3-2.3-2.3-2.3 1.1-2.3 2.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="eg"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eh">
            <use xlinkHref="#eg" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eh)"
            fill="#FCC18F"
            d="M-784.2 785.7c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3-1 2.3-2.3 2.3-2.3-1-2.3-2.3c0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3-1-2.3-2.3-2.3-2.3 1.1-2.3 2.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="ei"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ej">
            <use xlinkHref="#ei" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ej)"
            fill="#FCC18E"
            d="M-784.2 785.7c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3-1 2.3-2.3 2.3-2.3-1-2.3-2.3c0 1.3 1 2.3 2.3 2.3s2.4-1 2.4-2.3-1.1-2.3-2.3-2.3c-1.2.1-2.4 1.1-2.4 2.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="ek"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="el">
            <use xlinkHref="#ek" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#el)"
            fill="#FCC18D"
            d="M-784.2 785.7c0-1.3 1.1-2.3 2.3-2.3 1.3 0 2.3 1.1 2.3 2.3 0 1.3-1.1 2.3-2.3 2.3-1.3.1-2.3-1-2.3-2.3 0 1.3 1 2.4 2.4 2.4s2.3-1.1 2.3-2.4-1.1-2.4-2.4-2.4-2.4 1.2-2.3 2.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="em"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="en">
            <use xlinkHref="#em" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#en)"
            fill="#FCC08C"
            d="M-784.3 785.7c0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4-2.4 1.2-2.4 2.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="eo"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ep">
            <use xlinkHref="#eo" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ep)"
            fill="#FCC08C"
            d="M-784.3 785.7c0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4-2.4 1.2-2.4 2.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="eq"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="er">
            <use xlinkHref="#eq" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#er)"
            fill="#FCBF8B"
            d="M-784.3 785.7c0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4-2.4 1.2-2.4 2.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="es"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="et">
            <use xlinkHref="#es" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#et)"
            fill="#FCBF8A"
            d="M-784.3 785.7c0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4c0 1.3 1.1 2.4 2.4 2.4s2.5-1.1 2.5-2.4-1.1-2.4-2.4-2.4-2.5 1.2-2.5 2.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="eu"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ev">
            <use xlinkHref="#eu" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ev)"
            fill="#FCBE89"
            d="M-784.3 785.7c0-1.3 1.1-2.4 2.4-2.4s2.5 1.1 2.5 2.4-1.1 2.4-2.4 2.4-2.5-1.1-2.5-2.4c0 1.4 1.1 2.5 2.4 2.5s2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5c-1.4.1-2.4 1.2-2.4 2.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="ew"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ex">
            <use xlinkHref="#ew" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ex)"
            fill="#FCBD88"
            d="M-784.3 785.7c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c0 1.4 1.1 2.5 2.5 2.5s2.4-1.1 2.4-2.5-1.1-2.5-2.5-2.5c-1.4.1-2.5 1.2-2.4 2.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="ey"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ez">
            <use xlinkHref="#ey" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ez)"
            fill="#FCBD88"
            d="M-784.3 785.7c0-1.4 1.1-2.5 2.5-2.5s2.4 1.1 2.4 2.5-1.1 2.5-2.5 2.5-2.4-1.1-2.4-2.5c0 1.4 1.1 2.5 2.5 2.5s2.4-1.1 2.4-2.5-1.1-2.5-2.5-2.5c-1.4.1-2.5 1.2-2.4 2.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="eA"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eB">
            <use xlinkHref="#eA" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eB)"
            fill="#FCBD87"
            d="M-784.4 785.7c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5c-1.4.1-2.5 1.2-2.5 2.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="eC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eD">
            <use xlinkHref="#eC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eD)"
            fill="#FCBD86"
            d="M-784.4 785.7c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5c-1.4.1-2.5 1.2-2.5 2.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="eE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eF">
            <use xlinkHref="#eE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eF)"
            fill="#FCBC85"
            d="M-784.4 785.7c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c0 1.4 1.1 2.5 2.5 2.5s2.6-1.1 2.6-2.5-1.2-2.5-2.6-2.5c-1.4.1-2.5 1.2-2.5 2.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="eG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eH">
            <use xlinkHref="#eG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eH)"
            fill="#FCBB84"
            d="M-784.4 785.7c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c0 1.4 1.1 2.6 2.5 2.6s2.6-1.1 2.6-2.6c0-1.4-1.1-2.6-2.6-2.6-1.4.1-2.5 1.3-2.5 2.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="eI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eJ">
            <use xlinkHref="#eI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eJ)"
            fill="#FCBB84"
            d="M-784.5 785.7c0-1.4 1.1-2.6 2.6-2.6 1.4 0 2.6 1.1 2.6 2.6 0 1.4-1.1 2.6-2.6 2.6-1.4 0-2.6-1.2-2.6-2.6 0 1.4 1.1 2.6 2.6 2.6s2.6-1.2 2.6-2.6-1.2-2.6-2.6-2.6c-1.4.1-2.6 1.3-2.6 2.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="eK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eL">
            <use xlinkHref="#eK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eL)"
            fill="#FBBA83"
            d="M-784.5 785.7c0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6c-1.4 0-2.6-1.2-2.6-2.6 0 1.4 1.1 2.6 2.6 2.6s2.6-1.2 2.6-2.6-1.2-2.6-2.6-2.6c-1.4.1-2.6 1.3-2.6 2.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="eM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eN">
            <use xlinkHref="#eM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eN)"
            fill="#FBBA83"
            d="M-784.5 785.7c0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6c-1.4 0-2.6-1.2-2.6-2.6 0 1.4 1.1 2.6 2.6 2.6s2.6-1.2 2.6-2.6-1.2-2.6-2.6-2.6c-1.4.1-2.6 1.3-2.6 2.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="eO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eP">
            <use xlinkHref="#eO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eP)"
            fill="#FBB981"
            d="M-784.5 785.7c0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6c-1.4 0-2.6-1.2-2.6-2.6 0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6s-1.1-2.6-2.6-2.6c-1.4.1-2.6 1.3-2.6 2.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="eQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eR">
            <use xlinkHref="#eQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eR)"
            fill="#FBB980"
            d="M-784.5 785.7c0-1.5 1.2-2.6 2.6-2.6 1.4 0 2.7 1.2 2.7 2.6 0 1.5-1.2 2.6-2.6 2.6-1.4 0-2.7-1.1-2.7-2.6 0 1.5 1.2 2.6 2.6 2.6 1.4 0 2.7-1.1 2.7-2.6s-1.2-2.6-2.7-2.6-2.6 1.2-2.6 2.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="eS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eT">
            <use xlinkHref="#eS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eT)"
            fill="#FBB980"
            d="M-784.5 785.7c0-1.5 1.2-2.6 2.6-2.6 1.5 0 2.6 1.2 2.6 2.6 0 1.5-1.2 2.6-2.6 2.6-1.5.1-2.6-1.1-2.6-2.6 0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.6-1.2 2.6-2.7s-1.2-2.7-2.7-2.7-2.7 1.3-2.6 2.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="eU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eV">
            <use xlinkHref="#eU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eV)"
            fill="#FBB87F"
            d="M-784.6 785.7c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7c0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7-2.7 1.3-2.7 2.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="eW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eX">
            <use xlinkHref="#eW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eX)"
            fill="#FBB87E"
            d="M-784.6 785.7c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7c0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7-2.7 1.3-2.7 2.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="eY"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="eZ">
            <use xlinkHref="#eY" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#eZ)"
            fill="#FBB77D"
            d="M-784.6 785.7c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7c0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7-2.7 1.3-2.7 2.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="fa"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fb">
            <use xlinkHref="#fa" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fb)"
            fill="#FBB67C"
            d="M-784.6 785.7c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7c0 1.5 1.2 2.7 2.7 2.7s2.8-1.2 2.8-2.7-1.3-2.7-2.8-2.7-2.7 1.3-2.7 2.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="fc"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fd">
            <use xlinkHref="#fc" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fd)"
            fill="#FBB67C"
            d="M-784.6 785.7c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7c0 1.5 1.2 2.8 2.7 2.8s2.8-1.3 2.8-2.8-1.2-2.8-2.8-2.8-2.7 1.4-2.7 2.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="fe"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ff">
            <use xlinkHref="#fe" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ff)"
            fill="#FBB57B"
            d="M-784.6 785.7c0-1.5 1.2-2.8 2.8-2.8 1.6 0 2.7 1.3 2.7 2.8s-1.2 2.8-2.8 2.8-2.7-1.3-2.7-2.8c0 1.5 1.2 2.8 2.7 2.8s2.8-1.3 2.8-2.8-1.3-2.8-2.8-2.8-2.8 1.4-2.7 2.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="fg"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fh">
            <use xlinkHref="#fg" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fh)"
            fill="#FBB57A"
            d="M-784.7 785.7c0-1.5 1.2-2.8 2.8-2.8s2.8 1.3 2.8 2.8-1.2 2.8-2.8 2.8-2.8-1.3-2.8-2.8c0 1.5 1.2 2.8 2.8 2.8s2.8-1.3 2.8-2.8-1.2-2.8-2.8-2.8-2.8 1.4-2.8 2.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="fi"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fj">
            <use xlinkHref="#fi" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fj)"
            fill="#FBB479"
            d="M-784.7 785.7c0-1.5 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8-1.2 2.8-2.8 2.8-2.8-1.3-2.8-2.8c0 1.6 1.2 2.8 2.8 2.8s2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8-2.8 1.4-2.8 2.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="fk"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fl">
            <use xlinkHref="#fk" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fl)"
            fill="#FBB479"
            d="M-784.7 785.7c0-1.6 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8 0 1.6-1.3 2.8-2.8 2.8s-2.8-1.2-2.8-2.8c0 1.6 1.3 2.8 2.8 2.8s2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8-2.8 1.3-2.8 2.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="fm"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fn">
            <use xlinkHref="#fm" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fn)"
            fill="#FBB478"
            d="M-784.7 785.7c0-1.6 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8 0 1.6-1.3 2.8-2.8 2.8s-2.8-1.2-2.8-2.8c0 1.6 1.3 2.8 2.8 2.8s2.9-1.2 2.9-2.8-1.3-2.8-2.9-2.8-2.8 1.3-2.8 2.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="fo"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fp">
            <use xlinkHref="#fo" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fp)"
            fill="#FBB377"
            d="M-784.7 785.7c0-1.6 1.3-2.8 2.8-2.8s2.9 1.2 2.9 2.8-1.3 2.8-2.8 2.8c-1.5 0-2.9-1.2-2.9-2.8 0 1.6 1.3 2.9 2.8 2.9 1.6 0 2.9-1.3 2.9-2.9s-1.3-2.9-2.9-2.9-2.8 1.4-2.8 2.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="fq"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fr">
            <use xlinkHref="#fq" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fr)"
            fill="#FBB376"
            d="M-784.8 785.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9c0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9-2.9 1.4-2.9 2.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="fs"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ft">
            <use xlinkHref="#fs" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ft)"
            fill="#FBB276"
            d="M-784.8 785.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9c0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9-2.9 1.4-2.9 2.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="fu"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fv">
            <use xlinkHref="#fu" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fv)"
            fill="#FBB175"
            d="M-784.8 785.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9c0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9-2.9 1.4-2.9 2.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="fw"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fx">
            <use xlinkHref="#fw" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fx)"
            fill="#FBB174"
            d="M-784.8 785.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9c0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9-2.9 1.4-2.9 2.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="fy"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fz">
            <use xlinkHref="#fy" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fz)"
            fill="#FAB073"
            d="M-784.8 785.7c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9c0 1.6 1.3 3 2.9 3s3-1.3 3-3-1.3-3-3-3c-1.7.1-2.9 1.5-2.9 3"
          />
        </g>
        <g>
          <defs>
            <path
              id="fA"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fB">
            <use xlinkHref="#fA" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fB)"
            fill="#FAB072"
            d="M-784.8 785.7c0-1.6 1.3-3 3-3s3 1.3 3 3c0 1.6-1.3 3-3 3s-3-1.4-3-3c0 1.6 1.3 3 2.9 3s3-1.3 3-3c0-1.6-1.3-3-3-3-1.7.1-3 1.5-2.9 3"
          />
        </g>
        <g>
          <defs>
            <path
              id="fC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fD">
            <use xlinkHref="#fC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fD)"
            fill="#FAB072"
            d="M-784.9 785.7c0-1.6 1.3-3 3-3 1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3-1.6 0-3-1.4-3-3 0 1.6 1.3 3 3 3 1.6 0 3-1.3 3-3 0-1.6-1.3-3-3-3-1.6.1-3 1.5-3 3"
          />
        </g>
        <g>
          <defs>
            <path
              id="fE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fF">
            <use xlinkHref="#fE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fF)"
            fill="#FAAF71"
            d="M-784.9 785.7c0-1.6 1.3-3 3-3 1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3c-1.7.1-3 1.4-3 3"
          />
        </g>
        <g>
          <defs>
            <path
              id="fG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fH">
            <use xlinkHref="#fG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fH)"
            fill="#FAAE70"
            d="M-784.9 785.7c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3c-1.6 0-3-1.3-3-3 0 1.7 1.3 3 3 3s3-1.4 3-3c0-1.7-1.4-3-3-3s-3 1.4-3 3"
          />
        </g>
        <g>
          <defs>
            <path
              id="fI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fJ">
            <use xlinkHref="#fI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fJ)"
            fill="#FAAE6F"
            d="M-784.9 785.7c0-1.7 1.4-3 3-3 1.7 0 3 1.4 3 3s-1.4 3-3 3c-1.7 0-3-1.3-3-3 0 1.7 1.3 3 3 3s3-1.4 3-3c0-1.7-1.4-3-3-3s-3 1.4-3 3"
          />
        </g>
        <g>
          <defs>
            <path
              id="fK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fL">
            <use xlinkHref="#fK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fL)"
            fill="#FAAD6E"
            d="M-784.9 785.7c0-1.7 1.4-3 3-3 1.7 0 3 1.4 3 3 0 1.7-1.4 3-3 3-1.7 0-3-1.3-3-3 0 1.7 1.4 3.1 3 3.1 1.7 0 3.1-1.4 3.1-3.1s-1.4-3.1-3.1-3.1c-1.7.1-3 1.5-3 3.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="fM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fN">
            <use xlinkHref="#fM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fN)"
            fill="#FAAD6E"
            d="M-784.9 785.7c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1c-1.8 0-3.1-1.4-3.1-3.1 0 1.7 1.4 3.1 3.1 3.1 1.7 0 3-1.4 3-3.1s-1.4-3.1-3.1-3.1c-1.7.1-3.1 1.5-3 3.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="fO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fP">
            <use xlinkHref="#fO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fP)"
            fill="#FAAC6D"
            d="M-784.9 785.7c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3 1.4 3 3.1s-1.4 3.1-3.1 3.1c-1.7 0-3-1.4-3-3.1 0 1.7 1.4 3.1 3.1 3.1 1.7 0 3-1.4 3-3.1s-1.4-3.1-3.1-3.1c-1.7.1-3.1 1.5-3 3.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="fQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fR">
            <use xlinkHref="#fQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fR)"
            fill="#FAAC6C"
            d="M-785 785.7c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1a3.116 3.116 0 0 0 0 0c1.7 0 3.1-1.4 3.1-3.1s-1.4-3.1-3.1-3.1c-1.7.1-3.1 1.5-3.1 3.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="fS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fT">
            <use xlinkHref="#fS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fT)"
            fill="#FAAC6B"
            d="M-785 785.7c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1a3.116 3.116 0 0 0 0 0c1.7 0 3.1-1.4 3.1-3.1s-1.4-3.1-3.1-3.1c-1.7.1-3.1 1.5-3.1 3.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="fU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fV">
            <use xlinkHref="#fU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fV)"
            fill="#FAAB6A"
            d="M-785 785.7c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1a3.116 3.116 0 0 0 0 0c1.7 0 3.2-1.4 3.2-3.1s-1.4-3.1-3.1-3.1c-1.7.1-3.2 1.5-3.2 3.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="fW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fX">
            <use xlinkHref="#fW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fX)"
            fill="#FAAA69"
            d="M-785 785.7c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.2 1.4 3.2 3.1s-1.5 3.1-3.2 3.1c-1.7 0-3.1-1.4-3.1-3.1 0 1.7 1.4 3.2 3.1 3.2 1.7 0 3.2-1.5 3.2-3.2s-1.4-3.2-3.2-3.2-3.1 1.6-3.1 3.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="fY"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="fZ">
            <use xlinkHref="#fY" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#fZ)"
            fill="#FAAA68"
            d="M-785.1 785.7c0-1.7 1.4-3.2 3.2-3.2 1.7 0 3.2 1.4 3.2 3.2 0 1.7-1.4 3.2-3.2 3.2-1.7 0-3.2-1.5-3.2-3.2 0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2-3.2 1.5-3.2 3.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="gaa"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gb">
            <use xlinkHref="#gaa" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gb)"
            fill="#FAA967"
            d="M-785.1 785.7c0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2-1.4 3.2-3.2 3.2c-1.7 0-3.2-1.4-3.2-3.2 0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2-3.2 1.5-3.2 3.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="gc"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gd">
            <use xlinkHref="#gc" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gd)"
            fill="#FAA967"
            d="M-785.1 785.7c0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2c0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2-3.2 1.5-3.2 3.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="ge"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gf">
            <use xlinkHref="#ge" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gf)"
            fill="#FAA966"
            d="M-785.1 785.7c0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2c0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2-3.2 1.5-3.2 3.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="gg"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gh">
            <use xlinkHref="#gg" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gh)"
            fill="#FAA865"
            d="M-785.1 785.7c0-1.8 1.4-3.2 3.2-3.2s3.3 1.4 3.3 3.2-1.4 3.2-3.2 3.2-3.3-1.4-3.3-3.2c0 1.8 1.4 3.3 3.2 3.3s3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3-3.2 1.6-3.2 3.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="gi"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gj">
            <use xlinkHref="#gi" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gj)"
            fill="#F9A764"
            d="M-785.1 785.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3c-1.9 0-3.3-1.5-3.3-3.3 0 1.8 1.5 3.3 3.3 3.3s3.2-1.5 3.2-3.3-1.5-3.3-3.3-3.3-3.3 1.6-3.2 3.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="gk"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gl">
            <use xlinkHref="#gk" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gl)"
            fill="#F9A763"
            d="M-785.2 785.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3c0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3-3.3 1.6-3.3 3.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="gm"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gn">
            <use xlinkHref="#gm" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gn)"
            fill="#F9A663"
            d="M-785.2 785.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3c0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3-3.3 1.6-3.3 3.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="go"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gp">
            <use xlinkHref="#go" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gp)"
            fill="#F9A662"
            d="M-785.2 785.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3c0 1.8 1.5 3.3 3.3 3.3s3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3-3.3 1.6-3.3 3.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="gq"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gr">
            <use xlinkHref="#gq" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gr)"
            fill="#F9A560"
            d="M-785.2 785.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3c0 1.8 1.5 3.3 3.3 3.3s3.4-1.5 3.4-3.3-1.5-3.3-3.3-3.3-3.4 1.6-3.4 3.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="gs"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gt">
            <use xlinkHref="#gs" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gt)"
            fill="#F9A55F"
            d="M-785.2 785.7c0-1.8 1.5-3.3 3.3-3.3 1.9 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3c0 1.9 1.5 3.4 3.3 3.4 1.9 0 3.4-1.5 3.4-3.4s-1.5-3.4-3.4-3.4c-1.8.1-3.3 1.7-3.3 3.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="gu"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gv">
            <use xlinkHref="#gu" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gv)"
            fill="#F9A55F"
            d="M-785.2 785.7c0-1.9 1.5-3.4 3.4-3.4 1.8 0 3.3 1.6 3.3 3.4 0 1.9-1.5 3.4-3.4 3.4-1.8 0-3.3-1.5-3.3-3.4 0 1.9 1.5 3.4 3.4 3.4s3.3-1.5 3.3-3.4-1.5-3.4-3.4-3.4c-1.9.1-3.4 1.6-3.3 3.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="gw"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gx">
            <use xlinkHref="#gw" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gx)"
            fill="#F9A45E"
            d="M-785.3 785.7c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4c0 1.9 1.5 3.4 3.4 3.4s3.4-1.5 3.4-3.4-1.5-3.4-3.4-3.4c-1.9.1-3.4 1.6-3.4 3.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="gy"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gz">
            <use xlinkHref="#gy" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gz)"
            fill="#F9A35D"
            d="M-785.3 785.7c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4c0 1.9 1.5 3.4 3.4 3.4s3.4-1.5 3.4-3.4-1.5-3.4-3.4-3.4c-1.9.1-3.4 1.6-3.4 3.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="gaa"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gB">
            <use xlinkHref="#gaa" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gB)"
            fill="#F9A35C"
            d="M-785.3 785.7c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4c0 1.9 1.5 3.4 3.4 3.4s3.4-1.5 3.4-3.4-1.5-3.4-3.4-3.4c-1.9.1-3.4 1.6-3.4 3.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="gC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gD">
            <use xlinkHref="#gC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gD)"
            fill="#F9A25B"
            d="M-785.3 785.7c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4c0 1.9 1.5 3.4 3.4 3.4s3.5-1.5 3.5-3.4-1.5-3.4-3.4-3.4c-1.9.1-3.5 1.6-3.5 3.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="gE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gF">
            <use xlinkHref="#gE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gF)"
            fill="#F9A25A"
            d="M-785.3 785.7c0-1.9 1.5-3.4 3.4-3.4s3.5 1.5 3.5 3.4-1.6 3.4-3.5 3.4-3.4-1.5-3.4-3.4c0 1.9 1.5 3.5 3.4 3.5s3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5c-1.9.1-3.4 1.7-3.4 3.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="gG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gH">
            <use xlinkHref="#gG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gH)"
            fill="#F9A25A"
            d="M-785.4 785.7c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5c0 1.9 1.5 3.5 3.5 3.5s3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5c-1.9.1-3.5 1.7-3.5 3.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="gI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gJ">
            <use xlinkHref="#gI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gJ)"
            fill="#F9A159"
            d="M-785.4 785.7c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5c0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5c-1.9.1-3.5 1.7-3.5 3.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="gK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gL">
            <use xlinkHref="#gK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gL)"
            fill="#F9A057"
            d="M-785.4 785.7c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5c0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5c-1.9.1-3.5 1.7-3.5 3.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="gM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gN">
            <use xlinkHref="#gM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gN)"
            fill="#F9A056"
            d="M-785.4 785.7c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5-1.5 3.5-3.5 3.5-3.5-1.6-3.5-3.5c0 1.9 1.5 3.5 3.5 3.5s3.5-1.5 3.5-3.5-1.6-3.5-3.5-3.5c-1.9.1-3.5 1.7-3.5 3.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="gO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gP">
            <use xlinkHref="#gO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gP)"
            fill="#F99F55"
            d="M-785.4 785.7c0-2 1.6-3.5 3.5-3.5 2 0 3.5 1.6 3.5 3.5s-1.5 3.5-3.5 3.5c-1.9 0-3.5-1.5-3.5-3.5 0 2 1.6 3.6 3.5 3.6s3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6c-2 .1-3.5 1.7-3.5 3.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="gQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gR">
            <use xlinkHref="#gQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gR)"
            fill="#F99F54"
            d="M-785.4 785.7c0-2 1.6-3.6 3.6-3.6s3.5 1.6 3.5 3.6-1.6 3.6-3.6 3.6-3.5-1.6-3.5-3.6c0 2 1.6 3.6 3.5 3.6s3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6c-2 .1-3.6 1.7-3.5 3.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="gS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gT">
            <use xlinkHref="#gS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gT)"
            fill="#F99F54"
            d="M-785.5 785.7c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6c0 2 1.6 3.6 3.6 3.6s3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6c-2 .1-3.6 1.7-3.6 3.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="gU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gV">
            <use xlinkHref="#gU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gV)"
            fill="#F89E53"
            d="M-785.5 785.7c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6c0 2 1.6 3.6 3.6 3.6s3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6c-2 .1-3.6 1.7-3.6 3.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="gW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gX">
            <use xlinkHref="#gW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gX)"
            fill="#F89D52"
            d="M-785.5 785.7c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6c0 2 1.6 3.6 3.6 3.6s3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6c-2 .1-3.6 1.7-3.6 3.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="gY"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="gZ">
            <use xlinkHref="#gY" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#gZ)"
            fill="#F89D51"
            d="M-785.5 785.7c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6c0 2 1.6 3.6 3.6 3.6s3.7-1.6 3.7-3.6-1.6-3.6-3.6-3.6c-2 .1-3.7 1.7-3.7 3.6"
          />
        </g>
        <g>
          <defs>
            <path
              id="ha"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hb">
            <use xlinkHref="#ha" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hb)"
            fill="#F89C4F"
            d="M-785.5 785.7c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6c0 2 1.6 3.7 3.6 3.7s3.7-1.6 3.7-3.7c0-2-1.6-3.7-3.7-3.7-2 .1-3.6 1.8-3.6 3.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="hc"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hd">
            <use xlinkHref="#hc" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hd)"
            fill="#F89C4E"
            d="M-785.5 785.7c0-2 1.6-3.7 3.7-3.7 2 0 3.7 1.6 3.7 3.7 0 2-1.6 3.7-3.7 3.7s-3.7-1.7-3.7-3.7c0 2 1.6 3.7 3.7 3.7s3.6-1.7 3.6-3.7-1.7-3.7-3.7-3.7c-2 .1-3.7 1.8-3.6 3.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="he"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hf">
            <use xlinkHref="#he" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hf)"
            fill="#F89B4E"
            d="M-785.5 785.7c0-2 1.6-3.7 3.7-3.7s3.6 1.7 3.6 3.7-1.6 3.7-3.7 3.7-3.6-1.7-3.6-3.7c0 2 1.6 3.7 3.7 3.7s3.6-1.7 3.6-3.7-1.6-3.7-3.7-3.7-3.7 1.8-3.6 3.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="hg"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hh">
            <use xlinkHref="#hg" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hh)"
            fill="#F89B4D"
            d="M-785.6 785.7c0-2 1.7-3.7 3.7-3.7s3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7-3.7-1.7-3.7-3.7c0 2 1.6 3.7 3.7 3.7 2 0 3.7-1.7 3.7-3.7s-1.7-3.7-3.7-3.7c-2 .1-3.7 1.8-3.7 3.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="hi"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hj">
            <use xlinkHref="#hi" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hj)"
            fill="#F89A4C"
            d="M-785.6 785.7c0-2 1.7-3.7 3.7-3.7s3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7-3.7-1.6-3.7-3.7c0 2.1 1.6 3.7 3.7 3.7s3.7-1.6 3.7-3.7-1.7-3.7-3.7-3.7c-2 .1-3.7 1.7-3.7 3.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="hk"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hl">
            <use xlinkHref="#hk" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hl)"
            fill="#F8994B"
            d="M-785.6 785.7c0-2.1 1.7-3.7 3.7-3.7 2.1 0 3.7 1.7 3.7 3.7 0 2.1-1.7 3.7-3.7 3.7s-3.7-1.6-3.7-3.7c0 2.1 1.7 3.7 3.7 3.7 2.1 0 3.7-1.7 3.7-3.7 0-2.1-1.7-3.7-3.7-3.7-2.1.1-3.7 1.7-3.7 3.7"
          />
        </g>
        <g>
          <defs>
            <path
              id="hm"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hn">
            <use xlinkHref="#hm" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hn)"
            fill="#F8994A"
            d="M-785.6 785.7c0-2.1 1.7-3.7 3.7-3.7s3.8 1.6 3.8 3.7-1.7 3.7-3.7 3.7-3.8-1.6-3.8-3.7c0 2.1 1.7 3.8 3.7 3.8s3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8-3.7 1.8-3.7 3.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="ho"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hp">
            <use xlinkHref="#ho" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hp)"
            fill="#F89848"
            d="M-785.7 785.7c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8c0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8-3.8 1.8-3.8 3.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="hq"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hr">
            <use xlinkHref="#hq" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hr)"
            fill="#F89848"
            d="M-785.7 785.7c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8c0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8-3.8 1.8-3.8 3.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="hs"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ht">
            <use xlinkHref="#hs" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ht)"
            fill="#F89847"
            d="M-785.7 785.7c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8c0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8-3.8 1.8-3.8 3.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="hu"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hv">
            <use xlinkHref="#hu" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hv)"
            fill="#F89746"
            d="M-785.7 785.7c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8c0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8-3.8 1.8-3.8 3.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="hw"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hx">
            <use xlinkHref="#hw" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hx)"
            fill="#F89645"
            d="M-785.7 785.7c0-2.1 1.7-3.8 3.8-3.8s3.9 1.7 3.9 3.8-1.8 3.8-3.9 3.8-3.8-1.7-3.8-3.8c0 2.1 1.7 3.9 3.8 3.9s3.9-1.8 3.9-3.9-1.8-3.8-3.9-3.8-3.8 1.8-3.8 3.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="hy"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hz">
            <use xlinkHref="#hy" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hz)"
            fill="#F89644"
            d="M-785.7 785.7c0-2.1 1.7-3.9 3.9-3.9 2 .1 3.8 1.8 3.8 3.9s-1.7 3.9-3.9 3.9c-2.1 0-3.8-1.8-3.8-3.9 0 2.1 1.7 3.9 3.9 3.9s3.8-1.8 3.8-3.9-1.7-3.9-3.9-3.9c-2.2.1-3.9 1.9-3.8 3.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="hA"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hB">
            <use xlinkHref="#hA" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hB)"
            fill="#F79543"
            d="M-785.7 785.7c0-2.1 1.7-3.9 3.9-3.9s3.8 1.8 3.8 3.9-1.7 3.9-3.9 3.9-3.8-1.8-3.8-3.9c0 2.1 1.7 3.9 3.9 3.9 2.1 0 3.8-1.8 3.8-3.9s-1.7-3.9-3.9-3.9c-2.1.1-3.9 1.9-3.8 3.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="hC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hD">
            <use xlinkHref="#hC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hD)"
            fill="#F79542"
            d="M-785.8 785.7c0-2.1 1.7-3.9 3.9-3.9s3.9 1.8 3.9 3.9-1.7 3.9-3.9 3.9c-2.1 0-3.9-1.8-3.9-3.9 0 2.2 1.7 3.9 3.9 3.9s3.9-1.7 3.9-3.9-1.7-3.9-3.9-3.9c-2.2.1-3.9 1.8-3.9 3.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="hE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hF">
            <use xlinkHref="#hE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hF)"
            fill="#F79541"
            d="M-785.8 785.7c0-2.2 1.7-3.9 3.9-3.9s3.9 1.7 3.9 3.9-1.7 3.9-3.9 3.9c-2.1 0-3.9-1.7-3.9-3.9 0 2.2 1.7 3.9 3.9 3.9s3.9-1.7 3.9-3.9-1.8-3.9-3.9-3.9-3.9 1.8-3.9 3.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="hG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hH">
            <use xlinkHref="#hG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hH)"
            fill="#F79440"
            d="M-785.8 785.7c0-2.2 1.8-3.9 3.9-3.9 2.2 0 3.9 1.7 3.9 3.9s-1.8 3.9-3.9 3.9c-2.2 0-3.9-1.7-3.9-3.9 0 2.2 1.8 3.9 3.9 3.9s4-1.7 4-3.9-1.8-3.9-3.9-3.9-4 1.8-4 3.9"
          />
        </g>
        <g>
          <defs>
            <path
              id="hI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hJ">
            <use xlinkHref="#hI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hJ)"
            fill="#F7933F"
            d="M-785.8 785.7c0-2.2 1.8-3.9 3.9-3.9 2.2 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9-2.2 0-3.9-1.7-3.9-3.9 0 2.2 1.8 4 3.9 4 2.2 0 4-1.8 4-4s-1.8-4-4-4c-2.2.1-3.9 1.9-3.9 4"
          />
        </g>
        <g>
          <defs>
            <path
              id="hK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hL">
            <use xlinkHref="#hK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hL)"
            fill="#F7933E"
            d="M-785.8 785.7c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4c-2.3 0-4-1.8-4-4 0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4c-2.2.1-4.1 1.9-4 4"
          />
        </g>
        <g>
          <defs>
            <path
              id="hM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hN">
            <use xlinkHref="#hM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hN)"
            fill="#F7933D"
            d="M-785.9 785.7c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4c-2.2.1-4 1.9-4 4"
          />
        </g>
        <g>
          <defs>
            <path
              id="hO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hP">
            <use xlinkHref="#hO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hP)"
            fill="#F7923C"
            d="M-785.9 785.7c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4c-2.2.1-4 1.9-4 4"
          />
        </g>
        <g>
          <defs>
            <path
              id="hQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hR">
            <use xlinkHref="#hQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hR)"
            fill="#F7913B"
            d="M-785.9 785.7c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4c-2.2.1-4 1.9-4 4"
          />
        </g>
        <g>
          <defs>
            <path
              id="hS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hT">
            <use xlinkHref="#hS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hT)"
            fill="#F7913A"
            d="M-785.9 785.7c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4c-2.2.1-4 1.9-4 4"
          />
        </g>
        <g>
          <defs>
            <path
              id="hU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hV">
            <use xlinkHref="#hU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hV)"
            fill="#F79039"
            d="M-785.9 785.7c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4c0 2.2 1.8 4.1 4 4.1s4.1-1.9 4.1-4.1-1.8-4.1-4.1-4.1-4 2-4 4.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="hW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hX">
            <use xlinkHref="#hW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hX)"
            fill="#F79038"
            d="M-786 785.7c0-2.2 1.8-4.1 4.1-4.1 2.2 0 4.1 1.8 4.1 4.1 0 2.2-1.8 4.1-4.1 4.1-2.2 0-4.1-1.9-4.1-4.1 0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1c-2.2.1-4.1 2-4.1 4.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="hY"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="hZ">
            <use xlinkHref="#hY" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#hZ)"
            fill="#F79037"
            d="M-786 785.7c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1c-2.2 0-4.1-1.8-4.1-4.1 0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1-4.1 1.9-4.1 4.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="ia"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ib">
            <use xlinkHref="#ia" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ib)"
            fill="#F78F36"
            d="M-786 785.7c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1-4.1-1.8-4.1-4.1c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1-4.1 1.9-4.1 4.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="ic"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="id">
            <use xlinkHref="#ic" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#id)"
            fill="#F78E35"
            d="M-786 785.7c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1-4.1-1.8-4.1-4.1c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1-4.1 1.9-4.1 4.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="ie"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="if">
            <use xlinkHref="#ie" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#if)"
            fill="#F78E34"
            d="M-786 785.7c0-2.3 1.9-4.1 4.1-4.1 2.3 0 4.1 1.9 4.1 4.1 0 2.3-1.9 4.1-4.1 4.1-2.3 0-4.1-1.8-4.1-4.1 0 2.3 1.8 4.2 4.1 4.2s4.2-1.9 4.2-4.2-1.9-4.1-4.2-4.1-4.1 1.9-4.1 4.1"
          />
        </g>
        <g>
          <defs>
            <path
              id="ig"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ih">
            <use xlinkHref="#ig" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ih)"
            fill="#F78E33"
            d="M-786 785.7c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.1 1.9 4.1 4.2s-1.9 4.2-4.2 4.2-4.1-1.9-4.1-4.2c0 2.3 1.8 4.2 4.1 4.2s4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2-4.2 2-4.1 4.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="ii"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ij">
            <use xlinkHref="#ii" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ij)"
            fill="#F78D33"
            d="M-786.1 785.7c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2-4.2 2-4.2 4.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="ik"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="il">
            <use xlinkHref="#ik" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#il)"
            fill="#F68C31"
            d="M-786.1 785.7c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0 2.3 1.8 4.2 4.2 4.2s4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2-4.2 2-4.2 4.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="im"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="in">
            <use xlinkHref="#im" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#in)"
            fill="#F68C30"
            d="M-786.1 785.7c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2-4.2 2-4.2 4.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="io"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ip">
            <use xlinkHref="#io" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ip)"
            fill="#F68B2F"
            d="M-786.1 785.7c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0 2.3 1.9 4.2 4.2 4.2s4.3-1.9 4.3-4.2-1.9-4.2-4.3-4.2-4.2 2-4.2 4.2"
          />
        </g>
        <g>
          <defs>
            <path
              id="iq"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ir">
            <use xlinkHref="#iq" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ir)"
            fill="#F68B2F"
            d="M-786.1 785.7c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0 2.3 1.9 4.3 4.2 4.3 2.4 0 4.3-1.9 4.3-4.3s-1.9-4.3-4.3-4.3c-2.3.1-4.2 2.1-4.2 4.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="is"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="it">
            <use xlinkHref="#is" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#it)"
            fill="#F68A2E"
            d="M-786.1 785.7c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-2 4.3-4.4 4.3c-2.3 0-4.2-1.9-4.2-4.3 0 2.4 1.9 4.3 4.3 4.3s4.2-1.9 4.2-4.3-1.9-4.3-4.3-4.3-4.3 2-4.2 4.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="iu"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iv">
            <use xlinkHref="#iu" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iv)"
            fill="#F68A2C"
            d="M-786.2 785.7c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3c-2.3 0-4.3-1.9-4.3-4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3-1.9-4.3-4.3-4.3-4.3 2-4.3 4.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="iw"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="ix">
            <use xlinkHref="#iw" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#ix)"
            fill="#F6892B"
            d="M-786.2 785.7c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3c0 2.4 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3-1.9-4.3-4.3-4.3-4.3 2-4.3 4.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="iy"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iz">
            <use xlinkHref="#iy" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iz)"
            fill="#F6892A"
            d="M-786.2 785.7c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3c0 2.4 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3-1.9-4.3-4.3-4.3-4.3 2-4.3 4.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="iA"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iB">
            <use xlinkHref="#iA" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iB)"
            fill="#F68929"
            d="M-786.2 785.7c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3c0 2.4 1.9 4.3 4.3 4.3s4.4-1.9 4.4-4.3-2-4.3-4.4-4.3-4.3 2-4.3 4.3"
          />
        </g>
        <g>
          <defs>
            <path
              id="iC"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iD">
            <use xlinkHref="#iC" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iD)"
            fill="#F68829"
            d="M-786.2 785.7c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3c0 2.4 1.9 4.4 4.3 4.4s4.4-2 4.4-4.4-2-4.4-4.4-4.4-4.3 2.1-4.3 4.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="iE"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iF">
            <use xlinkHref="#iE" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iF)"
            fill="#F68727"
            d="M-786.3 785.7c0-2.4 2-4.4 4.4-4.4s4.4 2 4.4 4.4-2 4.4-4.4 4.4-4.4-2-4.4-4.4c0 2.4 1.9 4.4 4.4 4.4 2.5 0 4.4-2 4.4-4.4s-2-4.4-4.4-4.4-4.4 2.1-4.4 4.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="iG"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iH">
            <use xlinkHref="#iG" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iH)"
            fill="#F68626"
            d="M-786.3 785.7c0-2.4 2-4.4 4.4-4.4s4.4 2 4.4 4.4-2 4.4-4.4 4.4-4.4-2-4.4-4.4c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4-2-4.4-4.4-4.4-4.4 2.1-4.4 4.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="iI"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iJ">
            <use xlinkHref="#iI" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iJ)"
            fill="#F68625"
            d="M-786.3 785.7c0-2.4 2-4.4 4.4-4.4s4.4 2 4.4 4.4-2 4.4-4.4 4.4-4.4-2-4.4-4.4c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4-2-4.4-4.4-4.4-4.4 2.1-4.4 4.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="iK"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iL">
            <use xlinkHref="#iK" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iL)"
            fill="#F68625"
            d="M-786.3 785.7c0-2.4 2-4.4 4.4-4.4s4.4 2 4.4 4.4-2 4.4-4.4 4.4-4.4-2-4.4-4.4c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4-2-4.4-4.4-4.4-4.4 2.1-4.4 4.4"
          />
        </g>
        <g>
          <defs>
            <path
              id="iM"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iN">
            <use xlinkHref="#iM" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iN)"
            fill="#F68524"
            d="M-786.3 785.7c0-2.4 2-4.4 4.4-4.4s4.4 2 4.4 4.4-2 4.4-4.4 4.4-4.4-1.9-4.4-4.4c0 2.5 2 4.5 4.4 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.4 2.1-4.4 4.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="iO"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iP">
            <use xlinkHref="#iO" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iP)"
            fill="#F58423"
            d="M-786.3 785.7c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.6 2.1-4.5 4.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="iQ"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iR">
            <use xlinkHref="#iQ" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iR)"
            fill="#F58422"
            d="M-786.3 785.7c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.6 2.1-4.5 4.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="iS"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iT">
            <use xlinkHref="#iS" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iT)"
            fill="#F58321"
            d="M-786.4 785.7c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.5 2.1-4.5 4.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="iU"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iV">
            <use xlinkHref="#iU" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iV)"
            fill="#F58321"
            d="M-786.4 785.7c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.5 2.1-4.5 4.5"
          />
        </g>
        <g>
          <defs>
            <path
              id="iW"
              d="M-781.9 781.3c-2.5 0-4.6 2-4.6 4.4 0 2.6 2 4.5 4.6 4.5s4.6-2 4.6-4.5c0-2.4-2-4.4-4.6-4.4"
            />
          </defs>
          <clipPath id="iX">
            <use xlinkHref="#iW" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#iX)"
            fill="#F58321"
            d="M-786.4 785.7c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.5 2.1-4.5 4.5m9.1 4.5h-9.1v-9h9.1v9"
          />
        </g>
        <path
          fill="#001722"
          d="M-805.9 787.8c-.5.5-1.2.7-2.3.7h-.4v-5.7h.4c1.1 0 1.7.2 2.3.7.6.6.9 1.4.9 2.2s-.3 1.6-.9 2.1zm-2-6.3h-2.5v8.6h2.4c1.3 0 2.2-.3 3.1-1 1-.8 1.6-2 1.6-3.3 0-2.5-1.9-4.3-4.6-4.3M-802.5 790h1.7v-8.5h-1.7v8.5M-796.7 784.8c-1-.4-1.3-.6-1.3-1.1s.5-1 1.2-1c.5 0 .9.2 1.4.7l.9-1.1c-.7-.6-1.6-1-2.5-1-1.5 0-2.7 1.1-2.7 2.5 0 1.2.5 1.8 2.1 2.4.7.2 1 .4 1.2.5.3.2.5.5.5.9 0 .7-.6 1.2-1.3 1.2-.8 0-1.4-.4-1.8-1.1l-1.1 1c.8 1.1 1.7 1.6 3 1.6 1.7 0 3-1.2 3-2.8-.2-1.5-.7-2-2.6-2.7M-793.7 785.7c0 2.5 2 4.5 4.5 4.5.7 0 1.3-.1 2.1-.5v-2c-.7.7-1.3.9-2 .9-1.7 0-2.9-1.2-2.9-2.9s1.2-3 2.8-3c.8 0 1.4.3 2.1 1v-2c-.7-.4-1.3-.5-2.1-.5-2.5.1-4.5 2.1-4.5 4.5M-773.5 787.2l-2.3-5.7h-1.8l3.6 8.7h.9l3.7-8.7h-1.8l-2.3 5.7M-768.6 790h4.7v-1.5h-3.1v-2.3h3v-1.3h-3v-2h3.1v-1.4h-4.7v8.5M-760.6 785.4h-.5v-2.6h.5c1 0 1.6.4 1.6 1.3s-.5 1.3-1.6 1.3zm3.4-1.4c0-1.6-1.1-2.5-3-2.5h-2.5v8.6h1.7v-3.5h.2l2.3 3.5h2.1l-2.7-3.6c1.2-.4 1.9-1.2 1.9-2.5"
        />
        <path
          fill="#231F20"
          d="M-756.4 782.2v-.2c.1 0 .1 0 0 .2.2 0 .1 0 0 0zm.3-.1c0-.2-.1-.2-.3-.2h-.2v.8h.2v-.3l.2.3h.2l-.3-.3c.2-.1.2-.2.2-.3"
        />
        <path
          fill="#231F20"
          d="M-756.3 782.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5zm0-1.2c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.3.7-.7-.1-.4-.4-.7-.7-.7"
        />
      </g>
      <g>
        <path
          fill="#A7A9AC"
          d="M-742.1 813.5h-81.8c-2.3 0-4.2-1.9-4.2-4.2v-46.9c0-2.3 1.9-4.2 4.2-4.2h81.8c2.3 0 4.2 1.9 4.2 4.2v46.9c0 2.3-1.9 4.2-4.2 4.2zm-81.8-54.8c-2.1 0-3.8 1.7-3.8 3.8v46.9c0 2.1 1.7 3.8 3.8 3.8h81.8c2.1 0 3.8-1.7 3.8-3.8v-46.9c0-2.1-1.7-3.8-3.8-3.8h-81.8z"
        />
      </g>
    </svg>
  </div>
);

Discover.displayName = 'Discover';

export default Discover;

import React from 'react';

interface Props {
  subscriptionInfo: SubscriptionInfo;
}

export default function SubscriptionPricing({ subscriptionInfo }: Props) {
  let kitBundleSubsDisplayPrice =
    subscriptionInfo.currentPrice + ' / ' + subscriptionInfo.frequency;
  let discountedPrice = subscriptionInfo.discountedPrice;
  const discountAmountNumber = subscriptionInfo.discountAmountNumber;
  let discountedCycles = subscriptionInfo.discountedCycles;
  let isSubFree = subscriptionInfo.isSubFree;
  if (subscriptionInfo.isHealthUpgrade) {
    discountedPrice = '$0.00';
    discountedCycles = 1;
    isSubFree = true;
  }

  const hasDiscount = !!discountAmountNumber;
  const isPromoPrice = discountedCycles ? parseInt(discountedCycles.toString()) === 1 : false;
  if (hasDiscount) {
    if (isPromoPrice) {
      let promoPrice = '';
      if (isSubFree) {
        kitBundleSubsDisplayPrice = 'First ' + subscriptionInfo.frequency + ' Free';
      } else if (subscriptionInfo.isPendingRenewal) {
        promoPrice =
          'pre-paid next ' + subscriptionInfo.frequency + ' promotional period ' + discountedPrice;
        kitBundleSubsDisplayPrice =
          'Current membership ' +
          subscriptionInfo.currentPrice +
          '; ' +
          promoPrice +
          ' (Renews at ' +
          kitBundleSubsDisplayPrice +
          ')';
      } else {
        kitBundleSubsDisplayPrice = 'First ' + subscriptionInfo.frequency + ' ' + discountedPrice;
      }
    } else if (isSubFree) {
      kitBundleSubsDisplayPrice = 'Free';
    } else {
      kitBundleSubsDisplayPrice = discountedPrice + ' / ' + subscriptionInfo.frequency;
    }
  }

  return <span>{kitBundleSubsDisplayPrice}</span>;
}

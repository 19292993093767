import React from 'react';
import CreditCard from '../PaymentMethodOption/CreditCard';
import SavedPayment from '../PaymentMethodOption/SavedPayment';
import Paypal from '../PaymentMethodOption/Paypal';
import { ELEMENTS } from '../../../constants';

interface Props {
  removeSavePayment: any;
  savedPayment: SavedPayment;
  setupHostedFields: any;
  method: string;
  rootClass: string;
  handleEditSavedPayment: any;
}
export default function PaymentMethodBody({
  removeSavePayment,
  savedPayment,
  method,
  rootClass,
  setupHostedFields,
  handleEditSavedPayment,
}: Props) {
  return (
    <div
      className={rootClass}
      role="group"
      aria-describedby="payment-description"
      id={ELEMENTS.ccForm}
    >
      <div id="payment-description" className="spc-aria-description">
        Payment Method Details
      </div>
      <SavedPayment
        {...{
          removeSavePayment,
          savedPayment,
          handleEditSavedPayment,
          method,
          setupHostedFields,
        }}
      />
      <CreditCard
        {...{
          method,
          setupHostedFields,
        }}
      />
      <Paypal method={method} />
    </div>
  );
}

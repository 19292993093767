interface Window {
  androidInterface?: any;
}

/**
 * Detects whether the page is executing inside a Android WebView
 */
export const isAndroidWebView = (): boolean => {
  return !!window.androidInterface;
};

import React from 'react';
import { URLS } from '../../regions';
import handleKeyDown from '../../utils/handleKeyDown';
import { Store } from '../../pageSetup';
import { updateStore } from '../../utils/storeUtils';

const CookieBanner = () => {
  const { storeState, dispatch } = React.useContext(Store);
  const { showCookieBanner } = storeState;
  const hideBanner = () => updateStore(dispatch, { showCookieBanner: false });
  return showCookieBanner ? (
    <div className="spc-cookies-banner">
      <div className="spc-cookies-banner-notice">
        This site uses cookies. By continuing to browse the site you are agreeing to our use of
        cookies. Find out more{' '}
        <a href={URLS.COOKIES} target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
        <i
          className="spc-cookies-banner-close glyph"
          onClick={hideBanner}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        />
      </div>
    </div>
  ) : (
    <span />
  );
};

export default CookieBanner;

import { sendToNative, EVENTS_TO_MOBILE } from '../../utils/nativeMobile';
import { setOptInSubscriptionRenewal } from '../../utils/storeApi';
import { showError } from '../../utils/storeUtils';
import { Dispatch } from 'react';
import GaEventHandler from '../../utils/dataLayer';
import { isAndroidWebView } from '../../utils/browserDetection/isAndroidWebView';
import { isiOSWebView } from '../../utils/browserDetection/isiOSWebView';

const TTAM_PLUS_PREMIUM_CUSTOMER_CARE_URL =
  'https://customercare.23andme.com/hc/en-us/articles/360042867394-23andMe#:~:text=23andMe%2B%20Premium%20Membership%20Activation';
const TTAM_TOTAL_HEALTH_CUSTOMER_CARE_URL =
  'https://customercare.23andme.com/hc/en-us/articles/18680166647191-About-the-23andMe-Total-Health-Service#:~:text=23andMe%2B%20Total%20Health%20Membership%20Activation';

export interface RedirectToYouDotParams {
  youdotUrl: string;
  currentProfileId: string;
}

export const redirectToYouDot = ({ youdotUrl, currentProfileId }: RedirectToYouDotParams) => {
  sendToNative(EVENTS_TO_MOBILE.EXIT_RENEWAL_OPT_IN);
  window.location.href = `${youdotUrl}p/${currentProfileId}/user/subscription-activation`;
};

export const redirectToCustomerCare = (isTotalHealth: boolean) => {
  const inWebView = isAndroidWebView() || isiOSWebView();
  const target = inWebView ? '_self' : '_blank';
  const customerCareUrl = isTotalHealth
    ? TTAM_TOTAL_HEALTH_CUSTOMER_CARE_URL
    : TTAM_PLUS_PREMIUM_CUSTOMER_CARE_URL;
  // open the customer care url in a new tab
  window.open(customerCareUrl, target);
};

export interface SetOptInSubscriptionRenewalValueParams {
  optInStatus: 'yes' | 'no';
  currentProfileId: string;
}

export const setOptInSubscriptionRenewalValue = async (
  { currentProfileId, optInStatus }: SetOptInSubscriptionRenewalValueParams,
  dispatch: Dispatch<any>,
): Promise<any> => {
  try {
    return await setOptInSubscriptionRenewal({
      profileId: currentProfileId,
      optInStatus: optInStatus,
    });
  } catch (e) {
    const errorData: ErrorType = {};
    if (e) errorData.body = e;
    showError(dispatch, errorData);
  }
};

export interface SubscriptionRenewalOptInParams {
  currentProfileId: string;
  youdotUrl: string;
}

export const subscriptionRenewalOptOut = async (
  { currentProfileId, youdotUrl }: SubscriptionRenewalOptInParams,
  dispatch: Dispatch<any>,
) => {
  GaEventHandler.pushToDataLayer({
    event: 'gaEvent',
    gaEventCategory: 'product_interstitial_activation',
    gaEventAction: 'cta_click',
    gaEventLabel: 'skip_activation',
    gaEventValue: 0,
    gaEventNonInteractionBool: false,
  });
  await setOptInSubscriptionRenewalValue(
    {
      optInStatus: 'no',
      currentProfileId,
    },
    dispatch,
  );
  redirectToYouDot({
    youdotUrl,
    currentProfileId,
  });
};

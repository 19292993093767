import React from 'react';

interface Props {
  forShipping?: boolean;
  readOnly?: boolean;
}

const ShippingCountryTooltipEnInt = ({ forShipping = true, readOnly = false }: Props) =>
  forShipping && !readOnly ? (
    <div className="spc-tooltip mod-select">
      <span
        data-placement="right"
        data-trigger="hover"
        className="tooltip mod-select spc-tooltip-a"
        aria-disabled="true"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <span className="tooltip-span" />?
        <div className="tooltip-data" role="tooltip">
          If your shipping country is not listed, we do not ship to that country at this time.
        </div>
      </span>
    </div>
  ) : (
    <span />
  );

export default ShippingCountryTooltipEnInt;

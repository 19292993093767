import ShippingCountryTooltipEnUs from './ShippingCountryTooltipEnUs';
import ShippingCountryTooltipEnCa from './ShippingCountryTooltipEnCa';
import ShippingCountryTooltipEnGb from './ShippingCountryTooltipEnGb';
import ShippingCountryTooltipEnEu from './ShippingCountryTooltipEnEu';
import ShippingCountryTooltipEnInt from './ShippingCountryTooltipEnInt';

const ShippingCountryTooltipGroup = {
  enus: ShippingCountryTooltipEnUs,
  enca: ShippingCountryTooltipEnCa,
  engb: ShippingCountryTooltipEnGb,
  eneu: ShippingCountryTooltipEnEu,
  enint: ShippingCountryTooltipEnInt,
};

export default ShippingCountryTooltipGroup;

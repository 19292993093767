/* eslint jsx-a11y/anchor-is-valid:0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from './Provider';
import { Div, Content, Span } from './Styled/Modal';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const stopPropagation = e => e.stopPropagation();
const Modal = ({ children }) => (
  <Consumer>
    {({ state, hide }) =>
      state.isShown ? (
        <Div onClick={hide}>
          <Content onClick={stopPropagation}>
            <Span onClick={hide} onKeyDown={hide} role="button" tabIndex={0}>
              &times;
            </Span>
            {children}
          </Content>
        </Div>
      ) : null
    }
  </Consumer>
);

Modal.propTypes = propTypes;
export default Modal;

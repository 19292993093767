import React from 'react';
import handleKeyDown from '../../utils/handleKeyDown';

interface Props {
  addresses: Address[];
  handleAddressSelect: (id: string) => void;
  handleAddAddress: () => void;
  savedAddressId: String;
}

export default function SavedBillingAddress({
  addresses,
  handleAddressSelect,
  handleAddAddress,
  savedAddressId,
}: Props) {
  return (
    <div>
      {addresses.map((address: Address) => (
        <div key={address.id} className="spc-spi-address-container">
          <div className="spc-spi-address-radio-container">
            <input
              type="radio"
              value={address.id}
              onChange={e => handleAddressSelect(e.target.value)}
              tabIndex={0}
              checked={address.id == savedAddressId}
              className="spc-spi-address-radio"
            />
          </div>
          <div>
            <div>
              {address.firstName} {address.lastName}
            </div>
            <div>{address.company}</div>
            <div>
              {address.address} {address.address2}
            </div>
            <div>
              {address.city}, {address.state} {address.postalCode}
            </div>
            <div>{address.country}</div>
          </div>
        </div>
      ))}
      <div className="spc-spi-address-container">
        <div className="spc-spi-address-radio-container"></div>
        <div
          className="spc-spi-paymentmethod-link"
          onClick={handleAddAddress}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          aria-label="add new address"
        >
          + Add New Address
        </div>
      </div>
    </div>
  );
}

import React from 'react';

interface Props {
  height?: string;
  width?: string;
  className?: string;
}
const Caret = ({ height = '13px', width = '13px', className = '' }: Props) => (
  <div className={className}>
    <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        fill="#6B6B6B"
        d="M20.333 31.667c-1 0-1.667-.333-2.333-1L1 14c-1.333-1.333-1.333-3.333 0-4.667C2.333 8 4.333 8 5.667 9.333l14.667 14.333 14-14.333C35.667 8 37.667 8 39 9.333c1.333 1.333 1.333 3.333 0 4.667L22.667 30.666c-.667.667-1.667 1-2.334 1z"
      />
    </svg>
  </div>
);

Caret.displayName = 'Caret';

export default Caret;

/* globals window, document */
import { ELEMENTS } from '../constants';

const currentYPosition = () => {
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) {
    return window.pageYOffset;
  }
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  }
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) {
    return document.body.scrollTop;
  }
  return 0;
};

const elmYPosition = query => {
  const elm = document.querySelector(query);
  if (!elm) {
    return undefined;
  }
  let y = elm.offsetTop;
  let node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }

  return y;
};

export const guideFocusToElement = elementId => {
  //TODO do not use this externally
  const el = document.getElementById(elementId);
  if (el) {
    el.focus();
  }
};

export const handleFocusTransition = (beforeState, afterState) => {
  const focusElement = [
    ELEMENTS.addressSection,
    ELEMENTS.verification,
    ELEMENTS.shipping,
    ELEMENTS.billing,
    ELEMENTS.review,
  ];
  if (afterState - 1 in focusElement) {
    guideFocusToElement(focusElement[afterState - 1]);
  } else {
    if (window.newrelic && window.newrelic.noticeError) {
      const failure = new Error(`handleFocusTransition failed because state not found`);
      window.newrelic.noticeError(failure);
    }
  }
};

export const scroller = (query, duration, offBy) => {
  if (typeof query === 'undefined') {
    return;
  }
  const startingY = currentYPosition();
  const targetY = elmYPosition(query);
  const diff = targetY - startingY + offBy;
  const easing = function easing(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };
  let start;

  if (!diff) return;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    let percent = Math.min(time / duration, 1);
    percent = easing(percent);

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};

import React from 'react';
import CountryFieldSelect from './CountryFieldSelect';
import CountryFieldStatic from './CountryFieldStatic';
import ShippingCountryTooltipGroup from '../ShippingCountryTooltipGroup/ShippingCountryTooltipGroup';
import { REGION } from '../../../../regions';

interface Props {
  country: string;
  countries: Country[];
  forShipping?: boolean;
  handleChange: (e: any) => void;
  errors: FormError[];
  focusLabel?: string;
  disableCountrySelector?: boolean;
  readOnly?: boolean;
  isBookPurchase?: boolean;
}

const CountryField = ({
  country,
  countries,
  forShipping = true,
  handleChange,
  errors,
  focusLabel = '',
  disableCountrySelector = false,
  readOnly = false,
  isBookPurchase = false,
}: Props) => {
  const ShippingCountryTooltip = ShippingCountryTooltipGroup[REGION];

  function getCountry(country) {
    return country.value === country;
  }

  function getCountryLabel() {
    const country = countries.find(getCountry);
    if (country !== undefined) {
      return country.text;

      // user has not entered an address yet.
      // apply rules for a default
    }
    if (countries.length > 1) {
      return '';
    }

    return countries[0].text;
  }

  // Set US as the first country in the dropdown if it exists
  const USIndex = countries.findIndex(country => country.value === 'US');
  if (countries.length > 1 && USIndex > 0) {
    const USCountry = countries[USIndex];
    countries.splice(USIndex, 1);
    countries.unshift(USCountry);
  }

  return countries.length > 1 ? (
    <div className="spc-input-wrap-select spc-input-wrap-pushdown">
      <CountryFieldSelect
        country={country}
        countries={countries}
        handleChange={handleChange}
        focusLabel={focusLabel}
        errors={errors}
        disableCountrySelector={disableCountrySelector}
      />
      <ShippingCountryTooltip
        forShipping={forShipping}
        readOnly={readOnly}
        isBookPurchase={isBookPurchase}
      />
    </div>
  ) : (
    <div className="spc-input-wrap-select spc-input-wrap-pushdown">
      <CountryFieldStatic countryText={getCountryLabel()} />
      <ShippingCountryTooltip
        forShipping={forShipping}
        readOnly={readOnly}
        isBookPurchase={isBookPurchase}
      />
    </div>
  );
};

export default CountryField;

import TermsOfServiceAll from './TermsOfServiceAll';
import TermsOfServiceEnEu from './TermsOfServiceEnEu';

const TermsOfServiceGroup = {
  enus: TermsOfServiceAll,
  enca: TermsOfServiceAll,
  engb: TermsOfServiceAll,
  eneu: TermsOfServiceEnEu,
  enint: TermsOfServiceAll,
};

export default TermsOfServiceGroup;

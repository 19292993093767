import { TotalHealthAccountBasedEligibilityDisclaimer } from './TotalHealthProfileBasedEligibilityDisclaimer/TotalHealthAccountBasedEligibilityDisclaimer';
import { TotalHealthGenericEligibilityDisclaimer } from './TotalHealthGenericEligibilityDisclaimer/TotalHealthGenericEligibilityDisclaimer';
import { TotalHealthStateBasedEligibilityDisclaimer } from './TotalHealthStateBasedEligibilityDisclaimer/TotalHealthStateBasedEligibilityDisclaimer';
import React from 'react';

const EligibilityModals = () => {
  /*
    NOTE! These modals are ordered in priority from lowest to highest. 
    i.e. In the case that both modals are triggered simultaneously,
    the last modal will display and the previously listed modals will not!
     */
  return (
    <>
      <TotalHealthStateBasedEligibilityDisclaimer />
      <TotalHealthAccountBasedEligibilityDisclaimer />
      <TotalHealthGenericEligibilityDisclaimer />
    </>
  );
};

export { EligibilityModals };

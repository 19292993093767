import React from 'react';
import { YourOrderSection } from './YourOrderSection';
import { PaymentInfoSection } from './PaymentInfoSection';

export const PaymentInfoScreen = () => {
  return (
    <div>
      <h1 className="spc-labs-h1">Complete Your Order</h1>
      <h2 className="spc-labs-eyebrow">Your order</h2>
      <div className="sd-card mod-card-0 sd-elevation-1 mod-add-border-radius-mobile">
        <YourOrderSection />
      </div>
      <PaymentInfoSection />
    </div>
  );
};

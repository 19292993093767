import React from 'react';
import { URLS } from '../../regions';
import CaretRight from '../Svgs/CaretRight';
import { REGIONS } from '../../regions';

interface Props {
  title: string;
  description: any;
  icon: any;
  iconLabel: string;
  hasItiLink?: boolean;
  isResearchPowered?: boolean;
  region?: string;
}

function renderSuperscript(region: string) {
  return region === REGIONS.US ? <sup>SM</sup> : null;
}

const SubscriptionInfoValueProp = ({
  title,
  description,
  icon,
  iconLabel,
  hasItiLink = false,
  isResearchPowered = false,
  region = '',
}: Props) => {
  return (
    <li className="spc-subs-info-li">
      <img className="spc-subs-info-icon" src={icon} alt={iconLabel} />
      <div>
        {title}
        {renderSuperscript(region)}
        {isResearchPowered && <p className="research-powered">POWERED BY 23ANDME RESEARCH</p>}
        <p>{description}</p>
        {hasItiLink && (
          <a
            href={URLS.PGT_PERMALINK}
            rel="noopener noreferrer"
            target="_blank"
            className={`spc-subs-info-test-info mod-hide-for-mobile`}
            data-mdv-id="subscription-payment-test-info-link"
          >
            Important Test Info
            <span>
              <CaretRight className="caret-right" height="24" width="24" />
            </span>
          </a>
        )}
      </div>
    </li>
  );
};

export default SubscriptionInfoValueProp;

/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components';

const pink = '#d50f67';

const font = 'font-family: Atkinson Hyperlegible, Helvetica Neue, Helvetica, Roboto, sans-serif;';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fader = css`
  animation: ${fadeIn} 0.5s;
`;

const reveal = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 99em;
  }
`;
const RevealIn = styled.div`
  animation: ${reveal} 1.5s;
`;

const media = {
  mobile: (...args) => css`
    @media only screen and (max-width: 737px) {
      ${css(...args)};
    }
  `,
  nonMobile: (...args) => css`
    @media only screen and (min-width: 738px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media only screen and (min-width: 738px) and (max-width: 1024px) {
      ${css(...args)};
    }
  `,
  desk: (...args) => css`
    @media only screen and (min-width: 1024px) {
      ${css(...args)};
    }
  `,
};

const Ul = styled.ul`
  padding: 0;
  margin: 25px 0 0;
  ${props =>
    props.last &&
    css`
      margin-bottom: 25px;
    `};
`;

const Li = styled.li`
  list-style-type: none;
  margin-left: 15px;
  line-height: 21px;
  font-size: 14px;
  &:before {
    content: '\\2013';
    position: absolute;
    margin-left: -12px;
  }
  ${media.nonMobile`
    font-size: 16px;
  `};
  ${props =>
    props.strict &&
    css`
      color: #9c9ea1;
    `};
`;

const DescHdr = styled.h5`
  margin: 20px 15% 10px 0px;
  padding: 0 0 10px;
  border-bottom: 1px solid #d4d6d6;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  color: #0d0e0e;
  ${font};
`;

const DescInput = styled.input`
  position: absolute;
  left: -999em;
  ${media.mobile`
    &:checked + label::before {
      color: $store-blue;
      transform: scale(1.6, 1);
    }
    &:checked + label + ul {
      opacity: 1;
      max-height: 99em;
      opacity: 1;
      height: auto;
      overflow: hidden;
      transition: opacity 0.5s linear, max-height 1s linear;
      padding-bottom: 15px;
      padding-top: 10px;
    }
    pointer-events: all;
  `};
`;

const DescUl = styled.ul`
  margin: 0;
  padding: 0;
  margin: 0 0 0 48px;
  padding-top: 20px;
  list-style-type: none;
  color: #9c9ea1;
  pointer-events: none;
  ${font} ${media.mobile`
    opacity: 0;
    max-height: 0;
  `};
  ${media.nonMobile`
    width: 55%;
    display: inline-block;
    margin: 0;
    padding: 10px 0 5px;
    pointer-events: all;
    > li {
      font-size: 14px;
      line-height: 1.29;
      color: #333435;
    }
    ${props =>
      props.last &&
      css`
        padding-bottom: 20px;
      `};
  `};
`;

const DescLi = styled.li`
  ${font};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  color: #9c9ea1;
  ${media.nonMobile`
    
  color: #333435;
  `};
`;
const DescLabel = styled.label`
  display: block;
  margin: 0 0 0 30px;
  ${font};
  font-size: 12px;
  font-weight: normal;
  color: #0d0e0e;
  cursor: pointer;
  &::before {
    content: '\\2227';
    transform: rotate(180deg) scale(1.6, 1) translateY(-2px);
    font-weight: 600;
    display: inline-block;
    font-size: 13px;
    margin-right: 8px;
    margin-left: 2px;
    vertical-align: bottom;
  }
  ${media.nonMobile`
    margin: 0;
    padding: 10px 0 5px;
    width: 30%;
    font-size: 14px;
    line-height: 1.29;
    color: #333435;
    input[type="checkbox"] + label& {
      display: inline-block;
    }
    display: inline-block;
    vertical-align: top;
    cursor: inherit;
    &::before {
      display: none;
    }
  `};
`;

const Header = styled.h3`
  font-weight: 500;
  font-size: 18px;
  color: #0d0e0e;
  line-height: 1.44;
  margin: 25px 0;
  ${font};
  ${media.nonMobile`
    font-weight: 500;
    font-size: 20px;
    line-height: 1.55;
  `};
`;

const SpanHdr = styled.span`
  ${media.nonMobile`
    display: inline-block;
    width: 35%;
  `};
`;

const DivPartial = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #0d0e0e;
  ${font};
`;

const A = styled.a`
  &:link,
  &:hover,
  &:visited,
  &:active {
    color: #0d0e0e;
    text-decoration: underline;
  }
  font-size: 14px;
  ${media.nonMobile`
    font-size: 16px;
  `};
  ${props =>
    props.strict &&
    css`
      &:link,
      &:hover,
      &:visited,
      &:active {
        color: #9c9ea1;
      }
    `};
`;

const AdvancedDiv = styled(DivPartial)`
  margin: 10px 0 20px;
  ${props =>
    props.strict &&
    css`
      color: #9c9ea1;
    `};
  ${media.nonMobile`
    width: 60%;
    font-size: 16px;
  `};
`;

const SubmitDiv = styled(DivPartial)`
  margin: -10px 0 30px;
  text-align: center;
  ${media.nonMobile`
    font-size: 16px;
    line-height: 1.31;
  `};
`;

const H4 = styled.h4`
  margin: 30px 0 0;
  color: #0d0e0e;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  ${font};
  ${props =>
    props.strict &&
    css`
      color: #9c9ea1;
      & > ul {
        color: #9c9ea1;
      }
    `};
  ${props =>
    props.required &&
    css`
      &::after {
        content: 'required';
        float: right;
        font-size: 12px;
        font-weight: 500;
        color: ${pink};
      }
    `};
  ${media.nonMobile`
      width: 100%;
      font-size: 16px;
      line-height: 1;
      ${props =>
        props.required &&
        css`
          &::after {
            padding-top: 30px;
          }
        `};
  `};
`;

const SpanSubHdr = styled.span`
  display: none;
  ${media.nonMobile`
    display: inline-block;
    width: 65%;
  `};
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  margin-bottom: 20px;
  ${media.nonMobile`
    flex-direction: row;
    align-items: space-between;
    margin-bottom: -30px;
  `};
`;

const Button = styled.button`
  padding: 7px 1px;
  width: 200px;
  color: ${pink};
  background: transparent;
  border: 2px solid ${pink};
  border-radius: 47px;
  font-size: 16px;
  cursor: pointer;
`;

const ButtonHollow = styled(Button)`
  background: transparent !important;
  height: auto;
  line-height: 18px;
  ${media.mobile`
    ${props =>
      props.short &&
      css`
        margin-bottom: 20px;
      `};
  `};
  ${media.nonMobile`
    ${props =>
      props.short &&
      css`
        width: 100px;
      `};
  `};
`;

const ButtonFull = styled(Button)`
  color: #fff;
  background: ${pink} !important;
  border-radius: 47px;
  font-size: 16px;
  cursor: pointer;
  height: auto;
  line-height: 18px;
  ${media.nonMobile`
    ${props =>
      props.left &&
      css`
        margin-left: 20px;
      `};
  `};
`;
export {
  media,
  fadeIn,
  fader,
  font,
  ButtonsWrap,
  ButtonHollow,
  ButtonFull,
  RevealIn,
  DescHdr,
  SpanHdr,
  SpanSubHdr,
  DescInput,
  DescUl,
  DescLi,
  DescLabel,
  H4,
  AdvancedDiv,
  SubmitDiv,
  Ul,
  Li,
  pink,
  Header,
  A,
};

import React from 'react';

interface Props {
  height?: string;
  width?: string;
  className?: string;
}
const CaretRight = ({ height = '24px', width = '24px', className = '' }: Props) => (
  <div className={className}>
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="prefix__a"
          d="M.294 1.707c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0l5 5c.391.39.391 1.024 0 1.414l-5 5c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414L4.587 6 .294 1.707z"
        />
      </defs>
      <g fillRule="evenodd" transform="translate(9 6)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#333435" fillRule="nonzero" className="caretright" xlinkHref="#prefix__a" />
        <g mask="url(#prefix__b)">
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(-9 -6)" />
        </g>
      </g>
    </svg>
  </div>
);

CaretRight.displayName = 'CaretRight';

export default CaretRight;

import styled, { css } from 'styled-components';
import { media, font, pink } from '../../Styled/common';

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 25px;
  ${font};
  ${media.nonMobile`
    width: 40%;
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    align-self: flex-start;
  `};
`;
const SpanOpt = styled.span`
  color: #c0c1c2;
  background: transparent !important;
  height: auto;
  padding: 0;
  cursor: pointer;
  ${font};
  ${props =>
    props.active &&
    css`
      color: ${pink};
      pointer-events: none;
      cursor: default;
    `};
`;
const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  margin: 0 15px;
  width: 60px;
  height: 27px;
`;

const SwitchInput = styled.input`
  display: none;
  input[type='checkbox']& {
    display: none;
  }
  &:checked + span {
    background-color: #fff;
  }

  &:checked + span::before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
  }
`;

const SliderSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #fff;
  height: auto;
  background: #fff !important;
  border: 2px solid #e3e5e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: '';
    height: 25px;
    width: 25px;
    left: -2px;
    bottom: -1px;
    border-radius: 50%;
    background-color: ${pink};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

export { Form, SpanOpt, SwitchLabel, SwitchInput, SliderSpan };

import ContactPrivacyEnUs from './ContactPrivacyEnUs';
import ContactPrivacyAll from './ContactPrivacyAll';

const ContactPrivacyGroup = {
  enus: ContactPrivacyEnUs,
  enca: ContactPrivacyAll,
  engb: ContactPrivacyAll,
  eneu: ContactPrivacyAll,
  enint: ContactPrivacyAll,
};

export default ContactPrivacyGroup;

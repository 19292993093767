import React from 'react';
import getDisplayName from '../../utils/getDisplayName';

export default function withDisabledButton(WrappedComponent) {
  class WithDisabledButton extends React.Component {
    constructor(props) {
      super(props);
      this.disableButton = this.disableButton.bind(this);
      this.state = {
        isProcessing: false,
      };
    }

    disableButton(isDisabled) {
      this.setState({
        isProcessing: isDisabled,
      });
    }

    render() {
      const disabledBtnClass = this.state.isProcessing ? ' mod-disabled' : '';

      return (
        <div>
          <WrappedComponent
            disabledBtnClass={disabledBtnClass}
            disableButton={this.disableButton}
            {...this.props}
          />
        </div>
      );
    }
  }

  WithDisabledButton.displayName = `withDisabledButton(${getDisplayName(WrappedComponent)})`;

  return WithDisabledButton;
}

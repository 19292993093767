import React from 'react';
interface Props {
  isBookPurchase?: boolean;
}

const ShippingDetailsEnUs = (props: Props) => {
  const shippingDetails = props.isBookPurchase ? (
    <div>
      <p className="spc-title-div">
        Express Shipments cannot be delivered to PO boxes or APO/FPO/DPO addresses. Shipments to AK
        and HI may add 1-2 additional business days.
      </p>
      <p className="spc-title-div">
        Orders ship within 4 business days after order date. Shipping and processing times may vary
        due to high volume.
      </p>
    </div>
  ) : (
    <div>
      <p className="spc-title-div">
        Shipping costs include delivery and return from recipient to our lab.
      </p>
      <p className="spc-title-div">
        Express Shipments cannot be delivered to PO boxes or APO/FPO/DPO addresses. Shipments to AK
        and HI may add 1-2 additional business days.
      </p>
      <p className="spc-title-div">
        Orders ship from the warehouse on the next business day. Shipping and processing times may
        vary due to high volume.
      </p>
    </div>
  );
  return shippingDetails;
};

export default ShippingDetailsEnUs;

import React from 'react';
import TextInput from '../../TextInput';
import { ELEMENTS } from '../../../../constants';

interface Props {
  state: string;
  handleChange: (e: any) => void;
  errors?: FormError[];
  isRequired?: boolean;
  placeHolder: string;
}

const StateFieldText = ({
  isRequired = true,
  errors = [],
  placeHolder,
  state,
  handleChange,
}: Props) => (
  <TextInput
    id={ELEMENTS.state}
    name="state"
    text={placeHolder}
    value={state}
    onChange={handleChange}
    isRequired={isRequired}
    showError={errors.includes('state')}
  />
);

export default StateFieldText;

import React from 'react';
import SubscriptionName from './SubscriptionName';
import ordinal from '../../utils/ordinalSuffix';

interface Props {
  subscriptionInfo: SubscriptionInfo;
  currentProfile?: Profile;
  isDelayedBilling: boolean;
  renewalDate?: string;
  className: string;
  showCancelTerms?: boolean;
}

const getStartDate = (activatedAt?: string) => {
  if (activatedAt) return activatedAt;
  // If activatedAt doesn't exist, then this is a new sub -- start date is today
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  const startDate = `${month} ${date.getDate()}, ${date.getFullYear()}`;
  return startDate;
};

const getNextBillingDate = (
  frequency: SubscriptionFrequencies,
  renewDate?: string,
  showYear?: boolean,
) => {
  // TODO Update logic for monthly cadence when copy is finalized
  if (renewDate && frequency != 'month' && !showYear) return renewDate;
  // If renewDate doesn't exist, generate one from current date & frequency
  let nextDate = new Date(renewDate);
  if (renewDate == undefined) {
    nextDate = new Date();
  }
  let renewalDate;
  if (frequency == 'year') {
    nextDate.setFullYear(nextDate.getFullYear() + 1);
    const nextMonth = nextDate.toLocaleString('default', { month: 'long' });
    renewalDate = `${nextMonth} ${nextDate.getDate()}`;
  } else if (frequency == 'month') {
    renewalDate = `the ${ordinal(nextDate.getDate())}`;
  }
  if (showYear) {
    renewalDate = `${renewalDate} ${nextDate.getFullYear()}`;
  }

  return renewalDate;
};

export default function SubscriptionRenewalTerms({
  subscriptionInfo,
  currentProfile,
  isDelayedBilling,
  className,
  showCancelTerms,
}: Props) {
  const {
    price,
    frequency,
    discountedPrice,
    discountedCycles,
    isHealthUpgrade,
    isSubscriptionRenewal,
    discountAmountNumber,
    isPendingRenewal,
    isTotalHealth,
  } = subscriptionInfo;

  let { activatedAt, renewDate } = subscriptionInfo;
  const period = frequency === 'year' ? 'annual' : 'monthly';
  const isFreeSub = isHealthUpgrade || discountedPrice === '$0.00';
  const isFreeSubForever = !isHealthUpgrade && discountAmountNumber && !discountedCycles;
  const isPromoDiscount = discountAmountNumber && discountedCycles; // cycles is 1, or null (unlimited)

  const willRenew = isPendingRenewal || isSubscriptionRenewal;

  let promotionalOfferNotice = null;
  let renewPriceText = `at ${price}`;
  let cancelText = null;
  if (showCancelTerms) {
    cancelText = `We’ll send you a reminder before your renewal date. You can cancel at anytime
    from your Account Settings. Membership fees are non-refundable. See Membership Terms for additional details.`;
  }

  if (isHealthUpgrade) {
    promotionalOfferNotice = (
      <>
        Promotional offer of first {frequency} free is for the new{' '}
        <SubscriptionName isTotalHealth={isTotalHealth} /> members only and not for renewal of
        current memberships.&nbsp;
      </>
    );
  } else if (isPromoDiscount) {
    promotionalOfferNotice = (
      <>
        This promotional offer of{' '}
        {isFreeSub ? (
          <>
            a free {frequency} of <SubscriptionName isTotalHealth={isTotalHealth} />
          </>
        ) : (
          discountedPrice
        )}{' '}
        is valid for the first {frequency} of your
        <SubscriptionName isTotalHealth={isTotalHealth} /> {period} membership.&nbsp;
      </>
    );
  } else {
    if (isFreeSubForever) {
      renewPriceText = 'for free';
    } else if (discountAmountNumber) {
      renewPriceText = `at ${discountedPrice}`;
    }
  }

  if (isDelayedBilling) {
    return (
      <div className={className}>
        {promotionalOfferNotice}
        Your {period} membership will begin when your Health + Ancestry Service reports are ready
        and automatically renew {renewPriceText} on each {frequency} thereafter. {cancelText}
      </div>
    );
  }

  if (isSubscriptionRenewal) {
    activatedAt = currentProfile.subscriptionInfo.activatedAt;
    renewDate = currentProfile.subscriptionInfo.renewDate;
  }

  const firstBillingDateString = getStartDate(activatedAt);
  const nextBillingDate = getNextBillingDate(frequency, renewDate);
  const firstBillingDate = new Date(firstBillingDateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const billingHasStarted = firstBillingDate < today;

  if (isSubscriptionRenewal && isPromoDiscount) {
    return (
      <div className={className}>
        *<SubscriptionName isTotalHealth={isTotalHealth} /> Membership offer is valid for your next
        year of membership only. Your annual membership billing cycle will begin on{' '}
        {firstBillingDateString} for {discountedPrice} and will automatically renew on{' '}
        {nextBillingDate} at the regular membership price of {price} each subsequent year until
        canceled. You can cancel at any time from your Account Settings at least one day before your
        renewal date. <SubscriptionName isTotalHealth={isTotalHealth} /> Membership fees are
        non-refundable. See Membership Terms for additional details.
      </div>
    );
  }

  if (isPendingRenewal && isPromoDiscount) {
    const beginningOfPromoRenewalTerm = activatedAt;
    const endOfPromoTerm = getNextBillingDate(frequency, beginningOfPromoRenewalTerm, true);
    return (
      <div className={className}>
        Thank you for purchasing the <SubscriptionName isTotalHealth={isTotalHealth} /> membership
        promotional offer. Your promotional offer of {discountedPrice} is valid for the next{' '}
        {frequency} of your <SubscriptionName isTotalHealth={isTotalHealth} /> annual membership.
        Your promotional year will expire on {endOfPromoTerm}. Your next annual membership billing
        cycle will begin on {beginningOfPromoRenewalTerm} and automatically renew {price} each year
        thereafter.
      </div>
    );
  }

  return (
    <div className={className}>
      {promotionalOfferNotice}
      {willRenew && (
        <>
          Your first year of <SubscriptionName isTotalHealth={isTotalHealth} /> membership has
          already been paid for.{' '}
        </>
      )}
      Your {period} membership {willRenew && 'billing cycle'}{' '}
      {billingHasStarted && !willRenew ? 'began' : 'will begin'} on {firstBillingDateString} and
      will auto-renew {renewPriceText} on {nextBillingDate} of each {frequency} thereafter.{' '}
      {cancelText}
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { STEP } from '../../constants';

const propTypes = {
  country: PropTypes.string.isRequired,
  displayTaxAmount: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  taxAmount: PropTypes.number.isRequired,
  taxes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }),
  ),
};

const ItemizedTaxes = ({ country, displayTaxAmount, step, taxAmount, taxes }) => {
  if (step < STEP.SHIP_METHOD) {
    return null;
  }

  const handleSalesTax = amount => (
    <div className="spc-summary-text">
      <div>Sales Tax</div>
      <div>{amount}</div>
    </div>
  );

  const renderItemized = taxes =>
    taxes.map((tax, index) => (
      <div key={`taxItem_${index}`}>
        <div className="spc-summary-text">
          <div>{tax['name']}</div>
          <div>{tax['price']}</div>
        </div>
      </div>
    ));
  let display = null;
  const itemizedCountries = ['CA', 'AU'];
  if (itemizedCountries.includes(country)) {
    display = renderItemized(taxes);
  } else if (taxAmount > 0) {
    display = handleSalesTax(displayTaxAmount);
  }
  return display;
};

ItemizedTaxes.propTypes = propTypes;

export default ItemizedTaxes;

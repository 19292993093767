import { useStoreContext } from '../../../../utils/storeUtils';
import Address from '../../../Address';

export const useSavedBillingAddresses = () => {
  const { storeState } = useStoreContext();
  const savedBillingAddresses = storeState.account.addresses || [];
  const hasSavedBillingAddress = savedBillingAddresses.length > 0;

  const getSavedBillingAddressById = (addressId: string, fallback: Address = null) =>
    savedBillingAddresses.find(billingAddress => billingAddress.id === addressId) || fallback;

  const firstSavedAddressId =
    storeState.account.addresses.length > 0 ? storeState.account.addresses[0].id : null;

  const hasNewBillingAddress = () => {
    const { customAddress } = storeState.billing;
    const hasAddress = Object.keys(customAddress).length > 0;
    const isNewAddress = !customAddress.hasOwnProperty('id');
    return hasAddress && isNewAddress;
  };
  const newBillingAddress = (forDisplay = false) => {
    const id = forDisplay ? { id: '' } : {};
    return hasNewBillingAddress() ? Object.assign({}, storeState.billing.customAddress, id) : null;
  };
  const defaultSelectedAddress: string = hasNewBillingAddress() ? '' : firstSavedAddressId;

  return {
    savedBillingAddresses,
    hasSavedBillingAddress,
    getSavedBillingAddressById,
    hasNewBillingAddress,
    firstSavedAddressId,
    defaultSelectedAddress,
    newBillingAddress,
  };
};

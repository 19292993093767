import React from 'react';
import { useStoreContext } from '../../utils/storeUtils';
import { BILLING_METHODS } from '../../constants';
import paypal from '../../images/paypal.png';

interface Props {
  editLink?: React.ReactNode;
}

export const ReviewPaymentMethod = ({ editLink }: Props) => {
  const {
    storeState: {
      billing: { lastFour, cardType, imageUrl, method },
      orderCreationDate,
    },
  } = useStoreContext();

  let billingDetails = null;
  let cardImageUrl = null;
  switch (method) {
    case BILLING_METHODS.CREDIT_CARD:
    case BILLING_METHODS.SAVED_PAYMENT:
      billingDetails = (
        <>
          {cardType} ending in{' '}
          <span data-stor-id="clinic-billing-details-last-four">{lastFour}</span>
        </>
      );
      cardImageUrl = imageUrl;
      break;
    case BILLING_METHODS.PAYPAL:
      billingDetails = <img src={paypal} alt="Paypal" width="75" />;
      break;
    case BILLING_METHODS.APPLEPAY:
      billingDetails = 'Apple Pay';
      break;
    case BILLING_METHODS.VENMO:
      billingDetails = 'Venmo';
      break;
  }

  return (
    <>
      {editLink}
      {orderCreationDate && (
        <>
          <h3 className="spc-labs-h3">Purchase Date</h3>
          <div>
            <span>{orderCreationDate}</span>
          </div>
        </>
      )}

      <h3 className="spc-labs-h3">Payment Method</h3>
      <div>
        {cardImageUrl && <img src={cardImageUrl} alt={billingDetails} />}
        <span data-stor-id="clinic-billing-details">{billingDetails}</span>
      </div>
      {orderCreationDate && (
        <div className="spc-labs-review-payment">
          <br />
          Please refer to 23andMe&apos;s{' '}
          <a
            href="https://customercare.23andme.com/hc/en-us/articles/202907780"
            target="_blank"
            rel="noopener noreferrer"
            className="refund-policy-link"
          >
            Refund Policy.
          </a>
        </div>
      )}
    </>
  );
};

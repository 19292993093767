import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { A, H4, Ul, Li, AdvancedDiv } from '../Styled/common';
import { cookiePolicyLink } from '../common/utils';

const propTypes = {
  choice: PropTypes.string.isRequired,
};
const AdsInfo = ({ choice }) => {
  const strict = choice === 'strict';
  return (
    <Fragment>
      <H4 strict={strict}>FUNCTIONALITY &amp; ADVERTISING COOKIES</H4>
      <AdvancedDiv strict={strict}>
        These cookies are important to understanding, providing and improving your preferences when
        you visit our website. These cookies consist of both first party and third party cookies,
        and enable:
        <Ul last>
          <Li strict={strict}>Linking to third party tools and applications</Li>
          <Li strict={strict}>Remembering language and country settings</Li>
          <Li strict={strict}>Providing analytic information</Li>
          <Li strict={strict}>
            Serving advertisements relevant to your interests while you browse other websites
          </Li>
        </Ul>
        Some third party functionality and advertising cookies cannot be removed by 23andMe once
        they have been enabled. In order to learn more about removing cookies from your browser,
        please visit our{' '}
        <A strict={strict} href={cookiePolicyLink()} target="_blank" rel="noopener noreferrer">
          Cookie Policy
        </A>
        .
      </AdvancedDiv>
    </Fragment>
  );
};
AdsInfo.propTypes = propTypes;
export default AdsInfo;

enum BILLING_ADDRESS {
  SHIPPING = 0,
  CUSTOM = 1,
  SAVED = 2,
  SAVED_CUSTOM = 3,
}

enum API_URLS {
  PAYMENTS = '/en-us/payments/',
  FAMILY_TREE = '/en-us/family_tree/',
  SAVE_PAYMENT = '/payments/save/',
  SUBSCRIPTION_PAYMENTS = '/subscribe/',
  RENEW_SUBSCRIPTION_PAYMENTS = '/renew-subscribe/',
  SUBSCRIPTION_RENEWAL_OPTIN = '/renew-optin/',
  HEALTH_UPGRADE_UPDATE_HUMAN = '/kits/update_human/',
}

enum STEP {
  ADDRESS = 1,
  VERIFICATION = 2,
  SHIP_METHOD = 3,
  BILLING = 4,
  REVIEW = 5,
}

const ANIMATION_CLASSNAME = 'mod-anim';
const PREPAID_SUBSCRIPTION_KIT_ALIAS = 'prepaid_subscription_kit';
const CUSTOM_ADDRESS_SOURCES = [BILLING_ADDRESS.SAVED_CUSTOM, BILLING_ADDRESS.CUSTOM];

const AMERICAN_SAMOA = 'AS';
const ARMED_FORCES_AFRICA = 'AF';
const ARMED_FORCES_AMERICAS = 'AA';
const ARMED_FORCES_CANADA = 'AC';
const ARMED_FORCES_EUROPE = 'AE';
const ARMED_FORCES_MIDDLE_EAST = 'AM';
const ARMED_FORCES_PACIFIC = 'AP';
const GUAM = 'GU';
const HAWAII = 'HI';
const MARSHALL_ISLANDS = 'MH';
const NORTHERN_MARIANA_ISLANDS = 'MP';
const NEW_JERSEY = 'NJ';
const NEW_YORK = 'NY';
const PUERTO_RICO = 'PR';
const RHODE_ISLAND = 'RI';
const VIRGIN_ISLANDS = 'VI';

export const DISALLOWED_STATES_FOR_TOTAL_HEALTH_KITS = [
  AMERICAN_SAMOA,
  ARMED_FORCES_AFRICA,
  ARMED_FORCES_AMERICAS,
  ARMED_FORCES_CANADA,
  ARMED_FORCES_EUROPE,
  ARMED_FORCES_MIDDLE_EAST,
  ARMED_FORCES_PACIFIC,
  GUAM,
  HAWAII,
  MARSHALL_ISLANDS,
  NORTHERN_MARIANA_ISLANDS,
  NEW_JERSEY,
  NEW_YORK,
  PUERTO_RICO,
  RHODE_ISLAND,
  VIRGIN_ISLANDS,
];

export {
  API_URLS,
  ANIMATION_CLASSNAME,
  STEP,
  PREPAID_SUBSCRIPTION_KIT_ALIAS,
  CUSTOM_ADDRESS_SOURCES,
};

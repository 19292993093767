import React from 'react';

interface Props {
  height?: string;
  width?: string;
}

const ClinicCardioMetabolicScreening = ({ height = '48px', width = '48px' }: Props) => (
  <div className="spc-no-events spc-float-right" tabIndex={-1}>
    <svg
      height={height}
      width={width}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cardiometabolic Screening</title>
      <path
        d="M22.0813 1.916L5.70355 11.597C5.18062 11.9058 4.74798 12.3468 4.449 12.8759C4.15002 13.4049 3.99522 14.0033 4.00011 14.6111L4.15497 33.7127C4.15945 34.3212 4.3238 34.9177 4.63151 35.4425C4.93922 35.9672 5.37945 36.4016 5.90796 36.702L22.4468 46.107C22.9761 46.4096 23.5757 46.5672 24.1852 46.5639C24.7947 46.5607 25.3926 46.3967 25.9187 46.0884L42.2964 36.4074C42.8202 36.0988 43.2535 35.6574 43.5526 35.1277C43.8516 34.598 44.0059 33.9987 43.9998 33.3902L43.8388 14.2886C43.8366 13.68 43.6743 13.0826 43.3682 12.5567C43.0621 12.0309 42.623 11.595 42.0951 11.2931L25.5563 1.88809C25.0256 1.58696 24.425 1.431 23.8151 1.4359C23.2051 1.4408 22.6071 1.60638 22.0813 1.916Z"
        fill="#AF1111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70355 11.597L22.0813 1.916C22.6071 1.60638 23.2051 1.4408 23.8151 1.4359C23.8768 1.43541 23.9385 1.43656 24 1.43935V46.4358H25.1004C24.8037 46.5188 24.496 46.5623 24.1852 46.5639C23.5757 46.5672 22.9761 46.4096 22.4468 46.107L5.90796 36.702C5.37945 36.4016 4.93922 35.9672 4.63151 35.4425C4.3238 34.9177 4.15945 34.3212 4.15497 33.7127L4.00011 14.6111C3.99522 14.0033 4.15002 13.4049 4.449 12.8759C4.74798 12.3468 5.18062 11.9058 5.70355 11.597Z"
        fill="#74125D"
      />
      <path
        d="M29.3237 15.4358C26.8881 15.4358 24.941 16.7937 23.9385 18.7764C23.1368 16.7937 21.0728 15.4358 18.6372 15.4358C15.1654 15.4358 13 18.1908 13 21.5901C13 29.3304 24.0052 35.4358 24.0119 35.4358H24.0174C24.0174 35.4358 35 29.3326 35 21.5901C35 18.1897 32.796 15.4358 29.3237 15.4358Z"
        fill="#FBE7F0"
      />
    </svg>
  </div>
);

ClinicCardioMetabolicScreening.displayName = 'ClinicCardioMetabolicScreening';

export default ClinicCardioMetabolicScreening;

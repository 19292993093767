import React from 'react';
import SelectInput from '../../SelectInput';
import { ELEMENTS } from '../../../../constants';

interface Props {
  state: string;
  states: GeoState[];
  handleChange: (e: any) => void;
  errors?: FormError[];
  placeHolder: string;
}

const StateFieldSelect = ({ state, states, handleChange, errors = [], placeHolder }: Props) => {
  const placeHolderSelect = `Select ${placeHolder}`;
  return (
    <div className="spc-input-wrap-select spc-input-wrap-pushdown">
      <SelectInput
        id={ELEMENTS.state}
        name="state"
        placeholder={placeHolderSelect}
        focusLabel={placeHolder}
        selectedOption={state}
        options={states}
        onChange={handleChange}
        showError={errors.includes('state')}
      />
    </div>
  );
};

export default StateFieldSelect;

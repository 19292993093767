import * as React from 'react';

import { useStore } from '../../../utils/storeUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import IC_ERROR_SVG from '../../../images/icons/24px/ic_error.svg';

import Modal from '../../common/withModalv2';

import { URLS } from '../../../regions';

import { sendToNativeTotalHealthPurchaseAbandonment } from '../../../utils/nativeMobile';

const ACCOUNT_BASED_ELIGIBILITY_MODAL = 'ACCOUNT_BASED_ELIGIBILITY';

interface GetShouldShowTotalHealthAccountBasedEligibilityDisclaimerModalProps {
  isAuthenticated: boolean;
  modalHasBeenClosed: boolean;
  hasTotalHealth: boolean;
  hasTotalHealthUpgrade: boolean;
}

const getShouldShowTotalHealthAccountBasedEligibilityDisclaimerModal = ({
  isAuthenticated,
  modalHasBeenClosed,
  hasTotalHealth,
  hasTotalHealthUpgrade,
}: GetShouldShowTotalHealthAccountBasedEligibilityDisclaimerModalProps): boolean => {
  const hasTotalHealthStandalone = hasTotalHealth && !hasTotalHealthUpgrade;
  return isAuthenticated && hasTotalHealthStandalone && !modalHasBeenClosed;
};

const TotalHealthAccountBasedEligibilityDisclaimer = () => {
  const [storeState, updateStoreState] = useStore();
  const {
    account: { isAuthenticated },
    hasTotalHealth,
    hasTotalHealthUpgrade,
  } = storeState;

  const [modalHasBeenClosed, setModalHasBeenClosed] = useState(false);
  const shouldShowTotalHealthEligibilityDisclaimerModal = useMemo(
    () =>
      getShouldShowTotalHealthAccountBasedEligibilityDisclaimerModal({
        isAuthenticated,
        modalHasBeenClosed,
        hasTotalHealth,
        hasTotalHealthUpgrade,
      }),
    [modalHasBeenClosed, hasTotalHealth, hasTotalHealthUpgrade, isAuthenticated],
  );

  useEffect(() => {
    if (shouldShowTotalHealthEligibilityDisclaimerModal) {
      updateStoreState({ modalOpen: ACCOUNT_BASED_ELIGIBILITY_MODAL });
    }
  }, [shouldShowTotalHealthEligibilityDisclaimerModal]);

  const closeModal = useCallback(() => {
    updateStoreState({ modalOpen: '' });
    setModalHasBeenClosed(true);
  }, [setModalHasBeenClosed]);

  const handleAbandon = useCallback(() => {
    sendToNativeTotalHealthPurchaseAbandonment();
  });

  return (
    shouldShowTotalHealthEligibilityDisclaimerModal && (
      <Modal modalName={ACCOUNT_BASED_ELIGIBILITY_MODAL} klass="th-eligibility">
        <div className="spc-mx-50 spc-my-30">
          <div className="spc-txtC">
            <img src={IC_ERROR_SVG} width="48" height="48" alt="Error Icon" />
          </div>
          <p>
            It looks like the email you provided already has an account associated with 23andMe.
            Return to your existing account to see if you’re eligible to upgrade to 23andMe+ Total
            Health™ with a special price.
          </p>
          <p>
            Note that, you can continue with this purchase if it’s a gift for a non-customer or if
            you want to create a new 23andMe account for your Total Health experience.
          </p>
          <div className="spc-txtC spc-mt-15">
            <button className="sd-button" onClick={closeModal}>
              Continue with purchase
            </button>
          </div>
          <div className="spc-txtC">
            <a className="sd-secondary-button spc-mt-15" href={URLS.YOUDOT} onClick={handleAbandon}>
              Return to existing account
            </a>
          </div>
        </div>
      </Modal>
    )
  );
};

export {
  getShouldShowTotalHealthAccountBasedEligibilityDisclaimerModal,
  TotalHealthAccountBasedEligibilityDisclaimer,
};

/* globals */
import React, { ReactElement } from 'react';
import handleKeyDown from '../../utils/handleKeyDown';
import Caret from '../Svgs/Caret';
interface Props {
  children: ReactElement;
  drawerLabel?: string;
  useButton: boolean;
  seeLessText?: string;
}

function WithToggleDrawer({ children, drawerLabel = '', useButton, seeLessText = '' }: Props) {
  const [showContent, setShowContent] = React.useState(false);
  const label = showContent ? seeLessText : drawerLabel;
  const drawerTrigger = (
    <>
      {label}
      <Caret className={`spc-drawer-caret ${showContent ? 'mod-flipped' : ''}`} />
    </>
  );
  return (
    <div className="spc-outer">
      <div className="spc-drawer-wrap">
        <div className="spc-align">
          <div className={`spc-drawer-text ${showContent ? 'show' : 'hide'}`}>{children}</div>
          <h3
            className="spc-drawer-h3"
            data-stor-id="spc-drawer-toggle-button"
            onClick={() => setShowContent(!showContent)}
            onKeyDown={handleKeyDown}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            tabIndex={0}
          >
            {useButton ? (
              <button
                className="spc-drawer-button-trigger"
                data-jest-id="spc-drawer-button-trigger"
              >
                {drawerTrigger}
              </button>
            ) : (
              drawerTrigger
            )}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default WithToggleDrawer;

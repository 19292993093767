/* globals */
import * as React from 'react';
import { REGION, SALE_AND_USE_COUNTRY } from '../../regions';
import handleKeyDown from '../../utils/handleKeyDown';
import Caret from '../Svgs/Caret';

function OrderDetails() {
  const [showLearnMore, setShowLearnMore] = React.useState(false);
  const [showContent, setShowContent] = React.useState(false);
  const label = 'Order Details';
  const drawerTrigger = (
    <div
      data-stor-id="spc-drawer-toggle-button"
      onClick={() => {
        setShowContent(!showContent);
      }}
      onKeyDown={handleKeyDown}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
    >
      <Caret className={`spc-drawer-caret ${showContent ? 'mod-flipped' : ''}`} />
    </div>
  );

  const showLearnMoreDetails = () => {
    setShowLearnMore(!showLearnMore);
  };

  const getContent = () => {
    const warehouseText = REGION === 'enus' ? 'from warehouse' : '';
    return (
      <div className="spc-details-body" data-stor-id="spc-order-details">
        <div className="spc-details-list">
          <ul className="spc-details-ul">
            <li>Results should take 3-5 weeks, but processing times may vary.</li>
            <li>Ships {warehouseText} in 1-2 business days.</li>
            <li>Standard and Express shipping methods are available.</li>
            <li>You will have 12 months from the date of purchase to use your kit.</li>
            <li>Shipping and processing times may vary due to high volume.</li>
            <li>
              Intended for sale and use in {SALE_AND_USE_COUNTRY[REGION]} only. The resale of our
              kits is prohibited. 23andMe can only guarantee services and provide support for kits
              purchased directly through our website, www.23andme.com, or through one of our
              authorized resellers.&nbsp;
              {!showLearnMore && (
                <span
                  className="spc-details-ul-link"
                  onClick={showLearnMoreDetails}
                  onKeyDown={handleKeyDown}
                  role="link"
                  tabIndex={0}
                >
                  Learn more
                </span>
              )}
              {showLearnMore && (
                <p>
                  Kits purchased on any external website, such as Craigslist, or eBay, among others,
                  may not be authentic or functional. To protect our customers and prevent privacy
                  and billing issues, 23andMe routinely deactivates the barcode and genotyping chips
                  for kits that are attempted to be resold by unauthorized resellers.
                </p>
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className="spc-outer">
      <div className="spc-drawer-wrap">
        <div className="spc-align">
          <h3 className="spc-drawer-h3">{label}</h3>
          {drawerTrigger}
        </div>
        <div className={`spc-drawer-text ${showContent ? 'show' : 'hide'}`}>{getContent()}</div>
      </div>
    </div>
  );
}

export default OrderDetails;

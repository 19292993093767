/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useState } from 'react';
import Checkbox from '../components/common/Checkbox';
import SurveyRadio from './SurveyRadio';
import {
  subscriptionName,
  injectSubscriptionName,
} from '../components/SubscriptionPayment/SubscriptionName';
import { useStore } from '../utils/storeUtils';

interface SurveyQuestionProps {
  fieldType: string;
  questionId: string;
  question: string;
  answers: object[];
  active: boolean;
  setCurrentResponse: (questionId: string, input: object[], isReadyToSubmit: boolean) => void;
  hasError?: boolean;
  disabled?: boolean;
}

function answersToDict(answers) {
  // converts a checkbox answers list into its initial response dictionary
  // eg [{'text': 'very satisfied', 'value': 'answer1'}, {'text': 'very unsatisifed', 'value': 'answers2'}]
  //     ==> {'answer1': false, 'answer2': false}
  return answers.reduce((responseList, key) => {
    responseList[key['value']] = false;
    return responseList;
  }, {});
}

function responsesToList(responses) {
  // converts a checkbox response dict into a POSTable list
  // eg {'answer1': true, 'answer2': false, 'answer3': true}
  //    ==> ['answer1', 'answer3']   (only the TRUE responses)
  return Object.keys(responses).reduce((responseList, key) => {
    if (responses[key]) responseList.push(key);
    return responseList;
  }, []);
}

function getInitState(answers, fieldType) {
  if (fieldType == 'checkbox') {
    return answersToDict(answers);
  } else if (fieldType == 'textarea') {
    return '';
  }
  return null;
}

export default function SurveyQuestion({
  fieldType,
  questionId,
  question,
  answers,
  active,
  setCurrentResponse,
  hasError,
  disabled,
}: SurveyQuestionProps) {
  const [storeState, _] = useStore();
  const isTotalHealth = storeState?.subscriptionInfo?.isTotalHealth;
  const [response, setResponse] = useState(getInitState(answers, fieldType));
  if (!active) return null;

  const handleCheck = e => {
    if (disabled) return;
    const updatedResponse = { ...response, [e.target.name]: !response[e.target.name] };
    setResponse({ ...updatedResponse });
    const responses = responsesToList(updatedResponse);
    setCurrentResponse(questionId, responsesToList(updatedResponse), responses.length > 0);
  };

  const handleChange = e => {
    if (disabled) return;
    setResponse(e.target.value);
    setCurrentResponse(questionId, e.target.value, true);
  };

  const handleTextEntry = e => {
    if (disabled) return;
    let textEntry = e.target.value;
    if (textEntry.length > 2000) textEntry = textEntry.substring(0, 2000);
    setResponse(textEntry);
    setCurrentResponse(questionId, e.target.value, e.target.value !== '');
  };

  let formFields = null;
  if (fieldType === 'checkbox') {
    formFields = (
      <ul
        className="answer-section checkbox"
        role="group"
        aria-label={question}
        tabIndex={0}
        id="survey-answer-section"
      >
        {answers.map(item => (
          <li className="answer" key={item['value']}>
            <Checkbox
              text={subscriptionName(item['text'], isTotalHealth)}
              checked={response[item['value']]}
              name={item['value']}
              id={item['value']}
              handleCheck={handleCheck}
              ariaLabel={item['text']}
              hasError={hasError}
            />
          </li>
        ))}
      </ul>
    );
  } else if (fieldType === 'radio') {
    formFields = (
      <ul
        className="answer-section radio"
        role="radiogroup"
        aria-label={question}
        tabIndex={0}
        id="survey-answer-section"
      >
        {answers.map(item => (
          <li className="answer" key={item['value']}>
            <SurveyRadio
              id={`${questionId}_${item['value']}`}
              name={questionId}
              value={item['value']}
              text={subscriptionName(item['text'], isTotalHealth)}
              checked={item['value'] === response}
              handleChange={handleChange}
              ariaLabel={item['text']}
            />
          </li>
        ))}
      </ul>
    );
  } else {
    formFields = (
      <textarea
        value={response}
        onChange={handleTextEntry}
        maxLength={2000}
        className="answer-section"
        aria-label={question}
        id="survey-answer-section"
      />
    );
  }

  const questionComponent = injectSubscriptionName(question, { isTotalHealth });

  return (
    <div className="question-section" id="question-section">
      <h3 aria-hidden={true}>{questionComponent}</h3>
      {formFields}
    </div>
  );
}

export const updateGlobalStore = (data: Partial<StoreState>): Action => {
  return { type: 'UPDATE_VALUE', data: data };
};

export const setGlobalBraintreeInstance = (braintreeInstance): Action => {
  return { type: 'SET_BRAINTREE_INSTANCE', data: { braintreeInstance } };
};

export const spcStep3Complete = (): Action => {
  return { type: 'SPC_STEP_3_COMPLETE' };
};
